import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import plus from '../../../../assets/icons/utils/ico-plus.svg';
import minus from '../../../../assets/icons/utils/ico-minus.svg';

import { Flex, FlexCols } from '../../../../components/Common/Display';
import { Text } from '../../../../components/Common/Text';
import { useRecoilValue } from 'recoil';
import { userOptionState } from '../../../../store/request';
import SelectPackageModal from './SelectPackageModal';
import SelectTableTypeModal from './SelectTableTypeModal';
import PackageBox from './PackageBox';
import SelectTimeModal from './SelectTimeModal';
import { useRoomCategoryQuery } from '../../../../hooks/queries/category';

const RowDiv = styled(Flex)`
  &:first-child {
    z-index: 42;
  }
  width: 100%;
  height: auto;
  gap: 16px;
`;
const Row = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const InputDiv = styled(Flex)`
  width: ${(props) => props._width ?? '150px'};
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  cursor: pointer;

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 8px 16px;
    height: 40px;
  }
`;
const ImgDiv = styled(Flex)`
  width: ${(props) => props._width ?? '24px'};
  height: ${(props) => props._height ?? '24px'};
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors[props._borderColor] ?? props.theme.colors.gray300};
  border-radius: 50px;
  img {
    width: ${(props) => props.img_width ?? '12px'};
    height: ${(props) => props.img_hegiht ?? '12px'};
  }
`;
const UnitDiv = styled(Flex)`
  padding: 0 5px;
  justify-content: flex-end;
`;
const Unit = styled(Text)`
  width: 32px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 30px;

  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.xxs};
  color: ${(props) => props.theme.colors.gray900};
  text-align: center;
  cursor: pointer;
`;

const Button = styled.button`
  overflow: hidden;
  height: 100%;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};

  & > form {
    width: 45px;
    & > input {
      width: 100%;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: ${(props) => props.theme.fontSize.normal};
      color: ${(props) => props.theme.colors.gray900};
    }
  }
`;
const PeopleText = styled(Text)`
  display: inline-block;
  width: 45px;
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};
`;

const PackageDiv = styled(FlexCols)`
  width: 100%;
  height: auto;
  gap: 16px;
`;

const TableText = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-size: ${(props) => (props.isSelect ? props.theme.fontSize.xs : props.theme.fontSize.xxs)};
  color: ${(props) => (props.isSelect ? props.theme.colors.gray800 : props.theme.colors.purple300)};
  text-decoration: underline;
`;

const units = [-10, -50, 10, 50];

// component: 해당 날짜 옵션 box(직접 선택)
// /space/request 페이지, SelectOptionsByDate에서 호출, UserList에서 노출
function UserBox({ index, date, selectOption, setSelectOption }) {
  // 전체 테이블 배치 조회
  const { data, isLoading } = useRoomCategoryQuery();
  // 상위 컴포넌트(index.js)에서 사용하는 사용자 옵션 설정
  const userOption = useRecoilValue(userOptionState);

  // 수량을 집접 입력(true|false)
  const [edit, setEdit] = useState(false);

  // modal 제어
  // 0,1,2,3,4 - modal종료,체크인,체크아웃,식사 패키지, 테이블 배치
  const [onModal, setOnModal] = useState(0);

  // 체크인 시간
  const [checkIn, setCheckIn] = useState(
    !!userOption[index]?.checkIn ? userOption[index].checkIn : '10:00:00'
  );
  // 체크아웃 시간
  const [checkOut, setCheckOut] = useState(
    !!userOption[index]?.checkOut ? userOption[index].checkOut : '15:00:00'
  );

  // 인원
  const [room, setRoom] = useState(!!userOption[index]?.room ? userOption[index].room : 0);
  // 객실 인원
  const [people, setPeople] = useState(!!userOption[index]?.people ? userOption[index].people : 1);

  // 테이블 배치
  const [arrangement, setArrangement] = useState('');
  // 테이블 배치 id
  const [arrangementId, setArrangementId] = useState(
    !!userOption[index]?.arrangementId ? userOption[index].arrangementId : 0
  );
  // 식사 패키지
  const [meal, setMeal] = useState(!!userOption[index]?.meal ? userOption[index].meal : []);

  const closeModal = () => setOnModal(0);

  // 수량 직접 입력 종료(input>span 변경)
  const handleSubmit = (e) => {
    e.preventDefault();
    setEdit(null);
  };

  // 옵션 변경 저장(날짜별, 상위 컴포넌트 사용자 옵션)
  // > 1일 옵션 변경, 상위 컴포넌트에서 1일 옵션 수정
  const getUserOption = useCallback(() => {
    const optionObj = { ...selectOption[index] };

    optionObj.checkIn = checkIn;
    optionObj.checkOut = checkOut;
    optionObj.people = people;
    optionObj.arrangementId = arrangementId;
    optionObj.meal = meal;
    optionObj.room = room;

    setSelectOption((prev) => {
      const prevArr = [...prev];
      prevArr[index] = optionObj;

      return prevArr;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIn, checkOut, people, arrangementId, meal, room]);

  useEffect(() => {
    getUserOption();
  }, [getUserOption]);

  // 선택한 테이블 배치 id를 사용해서 전체 테이블 배치에서 해당하는 옵션 저장
  useEffect(() => {
    const result = data?.find((item) => item.arrangementId === arrangementId);

    setArrangement(result ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrangementId, userOption]);

  // 옵션 초기 설정(local user option)
  useEffect(() => {
    const jsonDate = date.split('. ').join('-');
    if ((userOption[index]?.requestDate ?? jsonDate) !== jsonDate) {
      setCheckIn('10:00:00');
      setCheckOut('15:00:00');
      setPeople(1);
      setArrangementId(0);
      setArrangement('');
      setRoom(0);
      setMeal([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return isLoading ? (
    ''
  ) : (
    <>
      {/* 체크인 modal */}
      {onModal === 1 && (
        <SelectTimeModal
          closeModal={() => setOnModal(0)}
          time={checkIn}
          setTime={(selectTime) => setCheckIn(selectTime)}
          title="체크인"
        />
      )}
      {/* 체크아웃 modal */}
      {onModal === 2 && (
        <SelectTimeModal
          closeModal={() => setOnModal(0)}
          time={checkOut}
          setTime={(selectTime) => setCheckOut(selectTime)}
          title="체크아웃"
        />
      )}
      {/* 식사 패키지 modal */}
      {onModal === 3 && <SelectPackageModal setMeal={setMeal} meal={meal} closeModal={closeModal} />}
      {/* 테이블 타입 modal */}
      {onModal === 4 && (
        <SelectTableTypeModal
          data={data}
          arrangementId={arrangementId}
          setArrangementId={setArrangementId}
          closeModal={closeModal}
        />
      )}

      <FlexCols gap="44px">
        <RowDiv>
          <Row>
            <Text _weight="medium" _size="l" _color="gray900">
              체크인
            </Text>
            <InputDiv _justify="center">
              <Button onClick={() => setOnModal(1)}>{checkIn.substring(0, 5)}</Button>
            </InputDiv>
          </Row>
          <Row>
            <Text _weight="medium" _size="l" _color="gray900">
              체크아웃
            </Text>
            <InputDiv _justify="center">
              <Button onClick={() => setOnModal(2)}>{checkOut.substring(0, 5)}</Button>
            </InputDiv>
          </Row>
          <FlexCols _width="100%" _gap={8}>
            <Row>
              <Text _weight="medium" _size="l" _color="gray900">
                객실
              </Text>
              <InputDiv _justify="space-between">
                <ImgDiv onClick={() => setRoom((prev) => (prev === 0 ? 0 : prev - 1))}>
                  <img src={minus} alt="minus-img" />
                </ImgDiv>
                <Button onClick={() => setEdit('room')}>
                  {edit === 'room' ? (
                    <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                      <input
                        required
                        autoFocus
                        autoComplete="off"
                        type="text"
                        name="room"
                        value={room}
                        onChange={(e) => {
                          const {
                            currentTarget: { value },
                          } = e;
                          if (!isNaN(value)) {
                            setRoom(+value);
                          }
                        }}
                      />
                    </form>
                  ) : (
                    <PeopleText>{String(room).padStart(2, '0')}</PeopleText>
                  )}
                </Button>
                <ImgDiv onClick={() => setRoom((prev) => parseInt(prev) + 1)}>
                  <img src={plus} alt="plus-img" />
                </ImgDiv>
              </InputDiv>
            </Row>
          </FlexCols>
          <FlexCols _width="100%" _gap={8}>
            <Row>
              <Text _weight="medium" _size="l" _color="gray900">
                인원
              </Text>
              <InputDiv _justify="space-between">
                <ImgDiv onClick={() => setPeople((prev) => (prev === 0 ? 0 : prev - 1))}>
                  <img src={minus} alt="minus-img" />
                </ImgDiv>

                <Button onClick={() => setEdit('people')}>
                  {edit === 'people' ? (
                    <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                      <input
                        required
                        autoFocus
                        autoComplete="off"
                        type="text"
                        name="people"
                        value={people}
                        onChange={(e) => {
                          const {
                            currentTarget: { value },
                          } = e;
                          if (!isNaN(value)) {
                            setPeople(+value);
                          }
                        }}
                      />
                    </form>
                  ) : (
                    <PeopleText>{String(people).padStart(2, '0')}</PeopleText>
                  )}
                </Button>
                <ImgDiv onClick={() => setPeople((prev) => parseInt(prev) + 1)}>
                  <img src={plus} alt="plus-img" />
                </ImgDiv>
              </InputDiv>
            </Row>
            <UnitDiv _width="100%" _height="20px" _gap={4}>
              {units.map((item, index) => (
                <Unit
                  key={index}
                  onClick={() => {
                    let sum = people + item;
                    const result = sum < 0 ? 0 : sum;
                    return setPeople(result);
                  }}
                >
                  {item}
                </Unit>
              ))}
            </UnitDiv>
          </FlexCols>
        </RowDiv>
        <RowDiv>
          <Row>
            <Text _weight="medium" _size="l" _color="gray900">
              식사 및 패키지
            </Text>

            <button onClick={() => setOnModal(3)}>
              <Text _size="xxs" _weight="normal" _color="purple300" _decoration="underline">
                옵션을 선택해주세요.
              </Text>
            </button>
          </Row>
          <PackageDiv>
            {userOption[index]?.meal.map((item, index) => (
              <PackageBox key={index} index={index} meal={meal} setMeal={setMeal} />
            ))}
          </PackageDiv>
        </RowDiv>
        <RowDiv>
          <Row>
            <Text _weight="medium" _size="l" _color="gray900">
              테이블 배치
            </Text>
            <button onClick={() => setOnModal(4)}>
              <TableText isSelect={arrangement !== ''}>
                {arrangement === '' ? '타입을 선택해 주세요.' : arrangement.arrangementName}
              </TableText>
            </button>
          </Row>
        </RowDiv>
      </FlexCols>
    </>
  );
}

export default UserBox;
