import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Message from '../../components/Common/Message/Message';
import { RoundBtn } from '../Common/Button';
import * as D from '../Common/Display';
import { Text } from '../Common/Text';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { InputField } from '../Common/InputField';
import { flatNum, KoNum } from '../../utils/number';
import { ModalOverlay } from '../../layouts/Modal';
import xIcon from '../../assets/icons/utils/ico-x.svg';

const mobileMotion = keyframes`
    0% { transform: translateY(100%); }
    100% { transform: translateY(0); }
  `;
const S = {
  Wrapper: styled.div`
    z-index: 29;
    position: absolute;
    top: 140px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 32px 40px;
    width: 381px;
    border-radius: 20px;

    background: ${(props) => props.theme.colors.white};
    ${(props) => props.theme.effectStyle.selected};

    .rc-slider-handle {
      margin-top: -6px;
      border-color: ${(props) => props.theme.colors.mainPurple} !important;
      opacity: 1 !important;
      box-shadow: none !important;
    }

    .rc-slider-track,
    .rc-slider-rail {
      height: 2px;
    }

    @media (${(props) => props.theme.media.laptop}) {
      right: 20px;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      position: fixed;
      top: auto;
      right: 0;
      width: 100%;
      bottom: 0;
      padding: 32px 20px;
      border-radius: 20px 20px 0 0;
      transition: all 0.2s ease-in;
      ${(props) =>
        props.off &&
        css`
          transform: translateY(100%);
        `};
      ${(props) =>
        !props.off &&
        css`
          animation: ${mobileMotion} 0.2s ease-in;
        `};
    }
  `,
  SelectWrapper: styled.div`
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    gap: 8px;

    @media (${(props) => props.theme.media.tabletSm}) {
      justify-content: center;
    }
  `,
  SelectButton: styled.button`
    width: 95px;
    height: 100%;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 20px;
    background: ${(props) => (props.current ? props.theme.colors.mainPurple : props.theme.colors.white)};
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => (props.current ? props.theme.colors.white : props.theme.colors.gray900)};

    @media (${(props) => props.theme.media.tabletSm}) {
      widht: 100px;
    }
  `,
  Close: styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    display: none;
    align-items: center;
    justify-content: center;
    @media (${(props) => props.theme.media.tabletSm}) {
      display: flex;
    }
  `,
};

/**
 * 가격 범위 컴포넌트
 */
export default function SortingFilter({ handleSubmit, defaultValue, handleClose }) {
  // sort
  const [sort, setSort] = useState(''); // new, review, like
  // range
  const MAX_RANGE = 100;
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [rangeMsg, setRangeMsg] = useState(null);
  // ui
  const mRef = useRef(null);
  const [isTablet, setIsTablet] = useState(true);
  const [tabCloseFn, setTabCloseFn] = useState(null);
  const uiClassName = 'st-ft';

  const handleSliderChange = (values) => {
    setRangeMsg(null);
    setPriceRange(values);
  };

  const handleInputChange = (idx, value) => {
    setRangeMsg(null);

    if (isNaN(value)) {
      return;
    }
    if (idx === 1 && value > MAX_RANGE * 10000) {
      setRangeMsg('최대값은 100만원 이하로 입력해 주세요.');
    }
    if ((idx === 0 && value > priceRange[1] * 10000) || (idx === 1 && value < priceRange[0] * 10000)) {
      setRangeMsg('최저값과 최대값을 올바르게 입력해 주세요.');
    }
    const newVal = [...priceRange];
    newVal[idx] = value / 10000;
    setPriceRange(newVal);
  };

  const handleTabletClose = () => {
    if (tabCloseFn) {
      return;
    }
    setTabCloseFn(
      setTimeout(() => {
        handleClose();
      }, 200)
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit({
      sort: sort,
      min: priceRange[0],
      max: priceRange[1],
    });
    handleClose();
  };

  useEffect(() => {
    let listener;
    let timeoutFn;
    if (mRef.current) {
      listener = (e) => {
        const excp = e.target === mRef.current || e.target.closest('.' + uiClassName);
        if (excp) {
          return;
        }
        handleClose();
      };
      timeoutFn = setTimeout(() => {
        window.addEventListener('click', listener);
      }, 100);
    }
    return () => {
      clearTimeout(timeoutFn);
      window.removeEventListener('click', listener);
    };
  }, [mRef]);

  useEffect(() => {
    setSort(defaultValue.sort);
    setPriceRange([defaultValue.min, defaultValue.max]);
  }, [defaultValue]);

  useLayoutEffect(() => {
    const resizeFn = () => {
      setIsTablet(window.innerWidth <= 767);
    };
    resizeFn();
    window.addEventListener('resize', resizeFn);
    return () => {
      window.removeEventListener('resize', resizeFn);
      setTabCloseFn(null);
    };
  }, []);

  const filterBody = ({ isTablet, off }) => (
    <S.Wrapper
      className={uiClassName}
      as="form"
      onSubmit={handleFormSubmit}
      {...(!isTablet && { ref: mRef })}
      {...(isTablet && { off: off })}>
      {isTablet && (
        <S.Close type="button" onClick={handleTabletClose}>
          <img src={xIcon} />
        </S.Close>
      )}
      <D.FlexCols _items="center" _justify="center" _width="100%" _gap={40}>
        <D.FlexCols _items="center" _justify="center" _width="100%" _gap={16}>
          <Text _size="l" _weight="medium">
            정렬
          </Text>
          <S.SelectWrapper>
            {[
              { text: '최신 순', value: 'new' },
              { text: '좋아요 순', value: 'like' },
              { text: '리뷰 순', value: 'review' },
            ].map((item, idx) => (
              <S.SelectButton
                key={idx}
                current={sort === item.value}
                onClick={(e) => {
                  e.preventDefault();
                  setSort((sort) => (sort === item.value ? '' : item.value));
                }}>
                {item.text}
              </S.SelectButton>
            ))}
          </S.SelectWrapper>
        </D.FlexCols>
        <D.FlexCols _items="center" _justify="center" _width="100%" _gap={16}>
          <Text _size="l" _weight="medium">
            가격범위
          </Text>
          <Slider
            min={0}
            max={MAX_RANGE}
            range
            trackStyle={[{ backgroundColor: '#8757AD' }, { backgroundColor: '#8757AD' }]}
            handleStyle={[{ backgroundColor: '#8757AD' }, { backgroundColor: '#8757AD' }]}
            railStyle={{ backgroundColor: '#EEEEEE' }}
            onChange={handleSliderChange}
            value={priceRange}
          />
          <D.FlexCols _width="100%" _gap={8} _items="flex-start">
            <D.FlexRows _gap={16} _justify="flex-start" _width="100%" _items="flex-start">
              <InputField
                _width="calc(50% - 8px)"
                label="최저"
                name="minP"
                value={priceRange[0] > 0 ? KoNum(priceRange[0] * 10000) : '00,000'}
                handleChange={(v) => {
                  handleInputChange(0, flatNum(v));
                }}
              />
              <InputField
                _width="calc(50% - 8px)"
                label="최고"
                name="minP"
                value={priceRange[1] > 0 ? KoNum(priceRange[1] * 10000) : '00,000'}
                handleChange={(v) => {
                  handleInputChange(1, flatNum(v));
                }}
              />
            </D.FlexRows>
            <Message isError={rangeMsg} text={rangeMsg ?? '*시간 당 공간 금액'} />
          </D.FlexCols>
        </D.FlexCols>
      </D.FlexCols>
      <RoundBtn _width="100%" text="적용" _height={48} _primary _capsule type="submit" disabled={rangeMsg} />
    </S.Wrapper>
  );
  return isTablet ? (
    <ModalOverlay handleOverlayClick={handleTabletClose}>
      {filterBody({ isTablet: true, off: tabCloseFn !== null })}
    </ModalOverlay>
  ) : (
    filterBody({ isTablet: false })
  );
}
