import { useQuery } from '@tanstack/react-query';
import {
  getMealCategory,
  getRoomCategory,
  getRoomInfoCategory,
  getSpaceCatgory,
} from '../../service/client/category';

const CATEGORY_KEYS = {
  space: ['category', 'space'],
  meal: ['category', 'meal'],
  room: ['category', 'room'],
  roomInfo: ['category', 'roomInfo'],
};

// 공간 카테고리 요청 query
export const useSpaceCategoryQuery = () => {
  const { data, error, isFetching } = useQuery(CATEGORY_KEYS.space, async () => {
    const { data } = await getSpaceCatgory();

    return data.data;
  });

  return { data, error, isLoading: isFetching };
};

// 전체 식사 패키지 목록 조회 query
export const useMealCategoryQuery = () => {
  const { data, error, isFetching } = useQuery(CATEGORY_KEYS.meal, async () => {
    const { data } = await getMealCategory();
    return data.data;
  });
  return { data, error, isLoading: isFetching };
};

// 전체 테이블 배치 조회 query
export const useRoomCategoryQuery = () => {
  const { data, error, isFetching } = useQuery(CATEGORY_KEYS.room, async () => {
    const { data } = await getRoomCategory();
    return data.data;
  });

  return { data, error, isLoading: isFetching };
};

// 전체 객실 목록 조회 query
export const useRoomInfoCategoryQuery = () => {
  const { data, error, isFetching } = useQuery(CATEGORY_KEYS.roomInfo, async () => {
    const { data } = await getRoomInfoCategory();
    return data.data;
  });
  return { data, error, isLoading: isFetching };
};
