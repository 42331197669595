import React, { useEffect, useLayoutEffect, useState } from 'react';

import styled from 'styled-components';
import { RoundBtn } from '../../Common/Button';
import { yScrollBoxCss } from '../../Common/Display';
import HiddenInfo from '../../Common/HiddenInfo';
import plus from '../../../assets/icons/utils/ico-plus.svg';
import minus from '../../../assets/icons/utils/ico-minus.svg';
import { Flex, FlexCols } from '../../Common/Display';
import { Text } from '../../Common/Text';
import CheckTimeOption from './CheckTimeOption';

export const HideBox = styled(Flex)`
  z-index: 42;
  width: 100%;
  height: 204px;
  gap: 16px;
`;
export const Row = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const InputDiv = styled(Flex)`
  width: ${(props) => props._width ?? '150px'};
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.colors.gray300};

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 8px 16px;
    height: 40px;
  }
`;
export const ImgDiv = styled(Flex)`
  width: ${(props) => props._width ?? '24px'};
  height: ${(props) => props._height ?? '24px'};
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors[props._borderColor] ?? props.theme.colors.gray300};
  border-radius: 50px;
  img {
    width: ${(props) => props.img_width ?? '12px'};
    height: ${(props) => props.img_hegiht ?? '12px'};
  }
  cursor: pointer;
`;
export const UnitDiv = styled(Flex)`
  padding: 0 5px;
  justify-content: flex-end;
`;
export const Unit = styled(Text)`
  // padding: 0 4px;
  width: 32px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 30px;

  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.xxs};
  color: ${(props) => props.theme.colors.gray900};
  text-align: center;
  cursor: pointer;
`;

export const Subtitle = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.l};
  color: ${(props) => props.theme.colors.gray900};
`;

export const Button = styled.button`
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};

  margin: auto;
  & > .form {
    width: 45px;
    & > input {
      width: 100%;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: ${(props) => props.theme.fontSize.normal};
      color: ${(props) => props.theme.colors.gray900};
    }
  }
`;

export const HideDiv = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Wrapper = styled.div`
  width: 380px;
  max-width: calc(100vw - 40px);
  margin: 0 auto;
  max-height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray400};
  border-radius: 20px;
  ${(props) => props.theme.effectStyle.selected};

  @media (${(props) => props.theme.media.tabletSm}) {
    max-height: calc(100% - 200px);
  }
`;

export const ContentDiv = styled.div`
  padding: 32px 24px;
  width: 100%;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  ${yScrollBoxCss()};
  @media (${(props) => props.theme.media.tabletSm}) {
    max-height: 430px;
  }
`;

export const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.gray900};
`;

export const ButtonDiv = styled.div`
  width: 100%;
  padding: 16px 24px;
`;


export default function CheckOption({ value, handleSubmit }) {
  const [options, setOptions] = useState([]);
  const [checkInMode, setCheckInMode] = useState(-1);
  const [checkOutMode, setCheckOutMode] = useState(-1);

  const handleCheckIn = (value) => {
    setOptions((opt) =>
      opt.map((v, idx) =>
        checkInMode === idx
          ? {
              ...v,
              checkIn: value,
            }
          : v
      )
    );
    setCheckInMode(-1);
  };

  const handleCheckOut = (value) => {
    setOptions((opt) =>
      opt.map((v, idx) =>
        checkOutMode === idx
          ? {
              ...v,
              checkOut: value,
            }
          : v
      )
    );
    setCheckOutMode(-1);
  };

  const handlePeople = (index, value) => {
    setOptions((opt) =>
      opt.map((v, idx) =>
        index === idx
          ? {
              ...v,
              people: isNaN(value) || value > 1000000000 ? v.people : value <= 0 ? 0 : value,
            }
          : v
      )
    );
  };

  const handleDetailsSubmit = () => {
    handleSubmit(options);
  };

  useEffect(() => {
    setOptions(value);
  }, [value]);

  return (
    <Wrapper>
      <ContentDiv>
        <Title>
          {options.length > 0 ? '선택하신 날짜의 조건을 설정해 주세요.' : '새부 일정을 먼저 선택해 주세요.'}
        </Title>
        {options.length > 0 && (
          <HideDiv>
            {options?.map((item, index) => (
              <HiddenInfo key={index} title={item.date}>
                <HideBox>
                  <Row>
                    <Subtitle>체크인</Subtitle>
                    <InputDiv _justify="center">
                      <Button onClick={() => setCheckInMode(index)}>{`${String(options[index].checkIn).padStart(
                        2,
                        0
                      )}:00`}</Button>
                    </InputDiv>
                  </Row>
                  <Row>
                    <Subtitle>체크아웃</Subtitle>
                    <InputDiv _justify="center">
                      <Button onClick={() => setCheckOutMode(index)}>{`${String(options[index].checkOut).padStart(
                        2,
                        0
                      )}:00`}</Button>
                    </InputDiv>
                  </Row>
                  <FlexCols _width="100%" _gap={8}>
                    <Row>
                      <Subtitle>인원</Subtitle>
                      <InputDiv _justify="space-between">
                        <ImgDiv
                          onClick={() => {
                            handlePeople(index, options[index].people - 1);
                          }}>
                          <img src={minus} alt="minus-img" />
                        </ImgDiv>
                        <Button as="div">
                          <div className="form">
                            <input
                              required
                              type="text"
                              name="people"
                              value={options[index].people}
                              onChange={(e) => {
                                handlePeople(index, e.currentTarget.value);
                              }}
                            />
                          </div>
                        </Button>
                        <ImgDiv
                          onClick={() => {
                            handlePeople(index, options[index].people + 1);
                          }}>
                          <img src={plus} alt="plus-img" />
                        </ImgDiv>
                      </InputDiv>
                    </Row>
                    <UnitDiv _width="100%" _height="20px" _gap={4}>
                      {[-10, -50, +50, +300].map((v, idx) => (
                        <Unit key={idx} onClick={() => handlePeople(index, options[index].people + v)}>
                          {v}
                        </Unit>
                      ))}
                    </UnitDiv>
                  </FlexCols>
                </HideBox>
              </HiddenInfo>
            ))}
          </HideDiv>
        )}
        {checkInMode >= 0 && (
          <CheckTimeOption
            title="체크인 시간"
            max={options[checkInMode].checkOut}
            time={options[checkInMode].checkIn}
            handleSubmit={(v) => handleCheckIn(v)}
          />
        )}
        {checkOutMode >= 0 && (
          <CheckTimeOption
            title="체크아웃 시간"
            min={options[checkOutMode].checkIn}
            time={options[checkOutMode].checkOut}
            handleSubmit={(v) => handleCheckOut(v)}
          />
        )}
      </ContentDiv>
      <ButtonDiv>
        <RoundBtn
          _width="100%"
          text="적용"
          _primary
          _capsule
          disabled={options.length === 0}
          onClick={handleDetailsSubmit}
        />
      </ButtonDiv>
    </Wrapper>
  );
}
