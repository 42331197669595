import { useEffect, useState } from 'react';

/**
 * 기준 너비 기반 모바일 여부 반환 훅
 * @param {number} width 기준 너비 
 */
export const useMobile = (width) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= width);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let tId = null;
    const reszieFn = () => {
      setIsResizing(true);
      clearTimeout(tId);
      tId = setTimeout(() => {
        setIsMobile(window.innerWidth <= 768);
        tId = null;
        setIsResizing(false);
      }, 500);
    };
    window.addEventListener('resize', reszieFn);
    return () => {
      if (tId) clearTimeout(tId);
      window.removeEventListener('resize', reszieFn);
    };
  }, [width]);

  return [isMobile, isResizing];
};
