/**
 * URL 클립보드 복사 함수
 */
export const copyToClipboard = (contents) => {
  const temp = document.createElement('textarea');
  temp.style.width = '1px';
  temp.style.height = '1px';
  document.body.appendChild(temp);
  temp.value = contents;
  temp.select();
  document.execCommand('copy');
  document.body.removeChild(temp);
};
