import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import arrowLeft from '../../../assets/icons/utils/ico-slider_left.svg';
import arrowRight from '../../../assets/icons/utils/ico-slider_right.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const S = {
  Wrapper: styled.div`
    width: 100%;
    overflow-x: visible;
  `,
  SliderWrappper: styled.div`
    width: calc(50vw + 590px);
    max-width: 1550px;
    overflow-x: visible;

    @media (max-width: 1080px) {
      width: calc(100vw - 20px);
    }
  `,
  Slide: styled.div`
    margin-right: ${({ _gap }) => _gap + 'px'};
  `,
  Navs: styled.div`
    margin-bottom: 24px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    button {
      width: 32px;
      height: 32px;
    }

    @media (${(props) => props.theme.media.tablet}) {
      display: none;
    }
  `,
};

/**
 * 카드 슬라이더 컴포넌트
 * @description 메인 페이지에서 사용하는 컴포넌트입니다.
 */
export default function CardSlider({ cards, gap = 20 }) {
  const [slider, setSlider] = useState(null);

  return (
    <S.Wrapper>
      <S.Navs>
        <button
          onClick={() => {
            slider.slickPrev();
          }}>
          <img src={arrowLeft} alt="이전슬라이드" />
        </button>
        <button
          onClick={() => {
            slider.slickNext();
          }}>
          <img src={arrowRight} alt="다음슬라이드" />
        </button>
      </S.Navs>
      <S.SliderWrappper>
        <Slider
          infinite
          slidesToShow={1}
          slidesToScroll={1}
          swipeToSlide={true}
          variableWidth
          draggable={true}
          autoplay={!isMobile}
          autoplaySpeed={2500}
          ref={(c) => setSlider(c)}>
          {cards.map((item, idx) => (
            <S.Slide _gap={gap} key={idx}>
              {item}
            </S.Slide>
          ))}
        </Slider>
      </S.SliderWrappper>
    </S.Wrapper>
  );
}
