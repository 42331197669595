import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

const mediaCss = (props, medias) => {
  let mqCss = "";
  let mqDirection = props._direction;
  let mqGap = props._gap;
  let mqCount = props.count;

  for (const media of medias) {
    let css = "";

    if (media.gap) {
      mqGap = media.gap;
    }
    if (media.direction) {
      mqDirection = media.direction;
    }
    if (media.count) {
      mqCount = media.count;
    }

    css += `gap: ${mqGap}px;`;
    css += `flex-direction: ${mqDirection};`;

    if (mqDirection === "column") {
      css += "> * { width: 100% !important; }";
    } else {
      if (!isNaN(mqCount) && !isNaN(mqGap)) {
        css += `
          > * { 
            width: calc(${Math.floor(100 / mqCount)}% - ${Math.ceil((mqGap * (mqCount - 1)) / mqCount)}px) !important
          };
        `;
      }
    }

    mqCss += `
      @media(${media.media ? props.theme.media[media.media] : `max-width: ${media.width}px`}) {
        ${css}
      }
    `;
  }
  return mqCss;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props._direction};
  flex-wrap: wrap;
  gap: ${(props) => props._gap}px;
  padding-bottom: ${(props) => (props._btmGap ? 1 : 0)}px;

  ${(props) =>
    props._direction === "row" &&
    css`
      > * {
        width: ${props.count
          ? `calc(${Math.floor(100 / props.count)}% - ${Math.ceil((props._gap * (props.count - 1)) / props.count)}px) !important;`
          : "100%;"};
      }
    `}

  ${(props) => props.medias && mediaCss(props, props.medias)}
`;

const NextLoader = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
`;

/**
 * 카드 목록 컴포넌트
 * @description 메인 페이지에서 사용하는 컴포넌트입니다.
 */
export default function CardList({
  direction = "row",
  gap = 24,
  count = 3,
  medias,
  children,
  infinite = false,
  handleNextPage,
  hasNextPage,
  nextLoading,
  pageLen,
}) {
  const nRef = useRef(null);

  useEffect(() => {
    let io;

    if (infinite && nRef.current) {
      io = new IntersectionObserver(
        (entries, _) => {
          if (entries[0].isIntersecting) {
            handleNextPage();
          }
        },
        {
          threshold: 1.0,
        }
      );
      io.observe(nRef.current);
    }

    return () => {
      if (io && nRef) {
        io.disconnect();
      }
    };
  }, [nRef, infinite, pageLen]);

  return (
    <Wrapper _direction={direction} _gap={gap} count={count} medias={medias} _btmGap={infinite}>
      {children}
      {infinite && hasNextPage && !nextLoading && <NextLoader ref={nRef} />}
    </Wrapper>
  );
}
