import React, { useLayoutEffect } from "react";
import { useRecoilState } from "recoil";
import bellIcon from "../../assets/icons/utils/ico-bell.svg";
import bellDotIcon from "../../assets/icons/utils/ico-bell_dot.svg";
import { useNewAliemQuery } from "../../hooks/queries/user";
import { aliemModalState } from "../../store/modal";
import { useUser } from "../../store/user";

/**
 * 알림벨 컴포넌트
 */
export default function AliemBell() {
  const [aliemState, setAliemState] = useRecoilState(aliemModalState);
  const { token } = useUser();
  const { data, isSuccess, refetch } = useNewAliemQuery(token);

  // 로그인 시에만 정보 조회
  useLayoutEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  return (
    <button
      style={{ width: "24px", height: "24px" }}
      onClick={() => {
        setAliemState(!aliemState);
      }}>
      <img src={isSuccess && data ? bellDotIcon : bellIcon} />
    </button>
  );
}
