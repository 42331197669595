import { client } from ".";

const BOOK_URLS = {
  requestBook: "/api/reservation/insert",
  availableDate: "/api/reservation/date",
};

const authConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// 공간 예약 요청
export const getSpaceBook = (token, data) => client.post(BOOK_URLS.requestBook, data, authConfig(token));

// 예약 가능 일자 요청
export const getAvailableDate = (id) => client.post(BOOK_URLS.availableDate, { id });
