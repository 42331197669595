// 유효성 검증 패턴
export const TEL_PATTERN = /^[0-9]{6,12}$/;
export const EMAIL_PATTERN = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
export const PWD_PATTERN = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\$`~!@$!%*#^?&\\(\\)\-_=+]{8,16}$/;
export const IMG_PATTERN = /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
export const LAMBDA_IMG_PATTERN = /\.(gif|jpe?g|png|webp)$/i;
export const NAME_PATTERN = /^[a-zA-Z0-9ㄱ-ㅎ가-힣]{1,10}$/;
export const NUMBER_PATTERN = /^([0-9]|\n)*?$/;

// 지역 검색 시 사용하는 필터
export const WORD_PATTERN = /^([0-9|가-힣|-|. ]{2,}|\n)*?$/;
