import React from "react";
import styled from "styled-components";

import { Text } from "../../../../components/Common/Text";
import { useMealCategoryQuery, useRoomInfoCategoryQuery } from "../../../../hooks/queries/category";
import { cdnUrl } from "../../../../constants/url";

const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 140px));
  flex-wrap: wrap;
  gap: 16px;

  @media (${(props) => props.theme.media.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (${(props) => props.theme.media.tabletM}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 90px;
  border-radius: 20px;
  border: ${(props) =>
    props.hasItem ? `2px solid ${props.theme.colors.mainPurple}` : `1px solid ${props.theme.colors.gray300}`};

  img {
    width: 25px;
    height: 22.5px;
  }
`;

// component: 식사 패키지 box
// /space/book/:id 페이지에 노출, SpaceBook/SpaceContent, SpaceBook/index에서 호출
export function PackageList({ spaceMeals }) {
  // 전체 식사 패키지 목록 조회
  const { data: meals, error, isLoading } = useMealCategoryQuery();

  // 호텔의 식사 및 패키지 정보에서 mealId만 get
  const currentSpaceMeals = spaceMeals?.map((meal) => meal.mealId);
  // 전체 식사 패키지 목록에서 호텔의 mealId와 일치하는 패키지만 get
  const myPacakgeCategory = meals?.filter((meal) => currentSpaceMeals.includes(meal.mealId));

  return isLoading ? (
    ""
  ) : (
    <List>
      {myPacakgeCategory?.map((item, index) => (
        <Item
          key={index}
          hasItem={true}
          _width="140px"
          _height="90px"
          _justify="center"
          _items="center"
          _gap={10}
        >
          {/* 아이콘 이미지 */}
          <img src={cdnUrl(item.mealIcon)} alt="meal-img" />
          <Text _size="l" _color="gray800">
            {item.mealName}
          </Text>
        </Item>
      ))}
    </List>
  );
}

// component: 객실 정보 box
// /space/book/:id 페이지에 노출, SpaceBook/SpaceContent, SpaceBook/index에서 호출
export function RoomList({ spaceRooms }) {
  // 전체 객실 목록 조회
  const { data: rooms, error, isLoading } = useRoomInfoCategoryQuery();

  // 호텔의 전체 객실 목록에서 roomId만 get
  const currentSpaceRooms = spaceRooms?.map((room) => room?.roomId);
  // 전체 객실 목록에서 호텔의 roomId와 일치하는 객실만 get
  const myRoomsCategory = rooms?.filter((room) => currentSpaceRooms.includes(room?.roomId));

  return isLoading ? (
    ""
  ) : (
    <List>
      {myRoomsCategory?.map((item, index) => (
        <Item
          key={index}
          hasItem={true}
          _width="140px"
          _height="90px"
          _justify="center"
          _items="center"
          _gap={10}
        >
          <img src={cdnUrl(item.roomIcon)} alt="room-img" />
          <Text _size="l" _color="gray800">
            {item.roomName}
          </Text>
        </Item>
      ))}
    </List>
  );
}
