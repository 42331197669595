import React from 'react';
import { Helmet } from 'react-helmet';
import RulesComponent from '../../../components/Service/Rules';

/**
 * 약관 페이지
 */
function Rules() {
  return (
    <React.Fragment>
      <Helmet>
        <title>알아봐봐 | 약관</title>
      </Helmet>
      <RulesComponent />
    </React.Fragment>
  );
}

export default Rules;
