import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FullModal } from '../../layouts/Modal';
import styled from 'styled-components';
import PicSlider from '../Common/PicSlider';
import * as D from '../Common/Display';
import SubHeader from '../../layouts/SubHeader';
import { useResetRecoilState } from 'recoil';
import { picViewerModal } from '../../store/modal';

const S = {
  Container: styled.div`
    width: 100vw;
    height: 100dvh;
    background-color: #fff;
    transform: translate(0, ${(props) => (props.active ? 0 : '100%')});
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: all 0.3s ease-in;
  `,
  Inner: styled.div`
    max-width: 1120px;
    padding: 80px 70px 80px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    @media (${(props) => props.theme.media.tablet}) {
      height: 100dvh;
      padding: 0 0;
    }
  `,
};

/**
 * 사진 보기 뷰어 컴포넌트
 */
export default function PicViewer({ srcs, active }) {
  const [motion, setMotion] = useState(false);
  const [display, setDisplay] = useState(false);
  const [tId, settId] = useState(null);
  const closeViewer = useResetRecoilState(picViewerModal);

  useEffect(() => {
    if (tId) {
      clearTimeout(tId);
    }

    if (active) {
      setDisplay(active);
      settId(
        setTimeout(() => {
          setMotion(active);
        }, 100)
      );
    } else {
      setMotion(active);
      settId(
        setTimeout(() => {
          setDisplay(active);
        }, 300)
      );
    }
  }, [active]);

  useLayoutEffect(() => {
    if (tId) {
      clearTimeout(tId);
      settId(null);
      setDisplay(false);
      setMotion(false);
    }
  }, []);

  if (!display) {
    return null;
  }

  return (
    <FullModal handleModalClose={closeViewer}>
      <S.Container active={motion}>
        <S.Inner>
          <SubHeader title="사진 보기" handleClose={closeViewer} />
          <D.FlexCols _width="100%" _height="100%" _items="center" _justify="center">
            <PicSlider
              pics={srcs}
              _nav={true}
              _nav_position="out"
              _media="tablet"
              _m_nav={false}
              _paging={true}
              _m_paging={true}
              _height="550px"
              _width="980px"
              _m_width="full"
              _m_height="full"
            />
          </D.FlexCols>
        </S.Inner>
      </S.Container>
    </FullModal>
  );
}
