import React from "react";
import { useRecoilValue } from "recoil";
import { FlexCols, yScrollBoxCss } from "../../../../components/Common/Display";
import { DotedText } from "../../../../components/Common/Text";
import { spaceDetailsState } from "../../../../store/space";
import LayoutModal from "./LayoutModal";
import styled from "styled-components";

const S = {
  Container: styled.div`
    height: 320px;
    padding: 0 40px;
    ${yScrollBoxCss()}

    @media (${(props) => props.theme.media.tabletSm}) {
      height: 60vh;
      max-height: 320px;
      padding: 0 20px;
    }
  `,
};

// component: 예약 완료 전 규정 안내 modal
// /space/book/:id 페이지에 노출, SelectBox에서 호출
function RuleInfoModal({ closeModal, isRuleCheck }) {
  // 호텔 예약 정보 get
  const spaceDetails = useRecoilValue(spaceDetailsState);

  return (
    <LayoutModal
      w_width="370px"
      title="예약 취소 규정"
      padding="40px 0 96px"
      m_padding="20px 0 96px"
      mobileFloat
      closeModal={closeModal}
      onClick={() => {
        // 예약 확인 버튼을 누르면 호출하는 함수
        isRuleCheck();
        closeModal();
      }}
    >
      {/* 취소 규정 안내 문자 */}
      <S.Container>
        <FlexCols _width="100%" _gap={8} _justify="flex-start">
          {spaceDetails.cancelPolicy.split("\n").map((item, index) => (
            <DotedText key={index} _size="l">
              {item}
            </DotedText>
          ))}
        </FlexCols>
      </S.Container>
    </LayoutModal>
  );
}

export default RuleInfoModal;
