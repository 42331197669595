import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { FormModal } from '../../../layouts/Modal';
import { requestWithdraw } from '../../../service/client/user';
import { customAlert } from '../../../store/modal';
import { useUser } from '../../../store/user';
import { RoundBtn } from '../../Common/Button';
import * as D from '../../Common/Display';
import { InputField } from '../../Common/InputField';
import { DotedText, Text } from '../../Common/Text';

/**
 * 마이페이지 > 탈퇴 컴포넌트
 */
export default function WithDrawalForm({ on, handleFormClose }) {
  const [reason, setReason] = useState('');
  const { withdraw } = useUser();
  const setAlret = useSetRecoilState(customAlert);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const res = await requestWithdraw({ reason: reason });
    handleFormClose();
    if (res.status === 200) {
      withdraw();
    } else {
      setAlret({
        text: '회원탈퇴 처리중 오류가 발생했습니다. \n 다시 시도해 주세요.',
      });
    }
  };

  useEffect(() => {
    setReason('');
  }, []);

  if (!on) {
    return;
  }

  return (
    <FormModal
      title="회원 탈퇴"
      action={
        <RoundBtn
          onClick={() => {
            handleSubmit();
          }}
          _primary
          _capsule
          type="submit"
          text="확인"
        />
      }
      handleModalClose={handleFormClose}
      _height="582px"
      _size="s">
      <D.FlexCols _gap={40} _items="flex-start" _width="100%">
        <D.FlexCols _gap={16} _width="100%">
          <Text _size="s">
            탈퇴 사유를 작성해 주시면 보다 더 좋은 서비스를 제공하기 위해 소중한 정보로 활용하겠습니다.
          </Text>
          <D.FlexCols as="ul" _gap={8} _items="flex-start">
            <DotedText as="li" _size="xxs" _weight="light" _color="gray600">
              탈퇴 후에도 등록한 게시물은 그대로 남아있습니다.
            </DotedText>
            <DotedText as="li" _size="xxs" _weight="light" _color="gray600">
              등록한 게시글은 탈퇴 시 삭제되지 않고 그대로 남아있습니다
            </DotedText>
            <DotedText as="li" _size="xxs" _weight="light" _color="gray600">
              탈퇴 후에는 회원 정보가 삭제되어 본인 여부를 확인할 수 있는 방법이 없기 때문에 게시글을 임의로 삭제해 드릴
              수 없습니다.
            </DotedText>
            <DotedText as="li" _size="xxs" _weight="light" _color="gray600">
              회원 탈퇴 시 00일 동안 재가입이 제한됩니다.
            </DotedText>
          </D.FlexCols>
        </D.FlexCols>
        <InputField value={reason} handleChange={setReason} textarea name="wdText" placeholder="탈퇴 사유 작성" />
      </D.FlexCols>
    </FormModal>
  );
}
