import React from 'react';
import CardList from '../../Common/CardList';
import ClientBox from '../../Home/ClientBox';
import {useSetRecoilState} from 'recoil';
import {applyModal, customAlert} from '../../../store/modal';
import {useMemberApplicationQuery} from '../../../hooks/queries/mypage';
import {useNavigate, useParams} from 'react-router-dom';
import {ErrorStatus, LoadingStatus} from '../../Common/Status';
import {SITE} from '../../../constants/site';

/**
 * 마이페이지 > 공간 예약 신청자 목록 컴포넌트
 */
export default function ApplyList() {
  const requestApplyModal = useSetRecoilState(applyModal);
  const navigate = useNavigate();
  const { requestId: id } = useParams();
  const setAlert = useSetRecoilState(customAlert);
  const { isLoading, error, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useMemberApplicationQuery({
    id: id,
  });

  if (error) {
    setAlert({
      text: '지원자 목록을 불러올 수 없습니다.',
      handleClose: () => {
        navigate(SITE.myRequest);
      },
    });
    return null;
  }
  
  return isLoading ? (
    <LoadingStatus box />
  ) : data && data.pages[0].application.length > 0 ? (
    <CardList
      count={3}
      gap={40}
      medias={[
        { media: 'laptop', count: 2 },
        { media: 'tabletSm', direction: 'column', gap: 40 },
      ]}
      infinite={true}
      handleNextPage={fetchNextPage}
      nextLoading={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      pageLen={data.pages.length}>
      {data.pages
        .reduce((acc, apply) => acc.concat(apply.application), [])
        .map((item, idx) => {
          return (
            <ClientBox
              key={idx}
              type="space"
              {...item}
              {...(item.requestStatus === '대기' &&
                item.status === '지원' && {
                  handleClick: () => {
                    requestApplyModal({ id: item.applicationId, content: item });
                  },
                })}
              {...(item.status === '확정' && { badge: { text: '예약 확정', color: 'purple' } })}
              _shadow={true}
              _blur={item.requestStatus === '완료' && item.status !== '확정'}
            />
          );
        })}
    </CardList>
  ) : (
    <ErrorStatus message="지원자가 없습니다." />
  );
}
