import React, { useLayoutEffect, useState } from 'react';
import CardList from '../Common/CardList';
import ClientBox from '../Home/ClientBox';
import { useInfiniteSpaceListquery } from '../../hooks/queries/space';
import { ErrorStatus, LoadingStatus } from '../Common/Status';

/**
 * 공간 검색 목록 컴포넌트
 * @param {object} props.filter 필터 컴포넌트
 */
export default function SpaceSearchList({ filter }) {
  const [searchFilter, setSearchFilter] = useState(null);
  const {
    data: pageData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isError,
  } = useInfiniteSpaceListquery(searchFilter);

  useLayoutEffect(() => {
    setSearchFilter(filter);
  }, [filter]);

  return (
    <React.Fragment>
      {isLoading && <LoadingStatus />}
      {((pageData && pageData.pages[0].data.data.count < 1) || isError) && (
        <ErrorStatus message="검색 결과가 없습니다." />
      )}
      {pageData && pageData.pages[0].data.data.count > 0 && (
        <CardList
          _direction="row"
          _gap={24}
          count={4}
          medias={[
            { media: 'laptop', count: 3 },
            { media: 'tablet', count: 2 },
            { media: 'tabletSm', count: 1 },
          ]}
          infinite={true}
          handleNextPage={fetchNextPage}
          nextLoading={isLoading || isFetchingNextPage}
          hasNextPage={hasNextPage}
          pageLen={pageData.pages.length}>
          {pageData &&
            pageData.pages
              .reduce((acc, page) => acc.concat(page.data.data.space), [])
              .map((item, idx) => {
                return <ClientBox type="space" key={idx} {...item} />;
              })}
        </CardList>
      )}
    </React.Fragment>
  );
}
