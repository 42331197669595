import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import xIcon from '../assets/icons/utils/ico-x.svg';
import arrowLeftIconf from '../assets/icons/utils/ico-arrow_left.svg';
import { RoundBtn } from '../components/Common/Button';
import { NonScrollBarCss, YScrollBox, yScrollBoxCss } from '../components/Common/Display';
import { alertModal, confirmModal, customAlert } from '../store/modal';
import { useMobile } from '../hooks/ui/mediaQuery';
import { isMobile } from 'react-device-detect';
import * as D from '../components/Common/Display';
import { Text } from '../components/Common/Text';

const FormModalSize = {
  m: {
    width: 560,
    gap: 60,
    px: 40,
    py: 48,
    th: 84,
    ah: 108,
  },
  m2: {
    width: 500,
    gap: 60,
    px: 32,
    py: 48,
    th: 72,
    ah: 108,
  },
  m3: {
    width: 400,
    gap: 40,
    px: 40,
    py: 48,
    th: 94,
    ah: 90,
  },
  s: {
    width: 380,
    gap: 40,
    px: 32,
    py: 48,
    th: 64,
    ah: 88,
  },
  s2: {
    width: 300,
    gap: 32,
    px: 30,
    py: 60,
    th: 54,
    ah: 70,
  },
};

export const S = {
  Overlay: styled.div`
    z-index: 99;
    position: fixed;
    /* position: sticky; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.visible ? 'rgba(0, 0, 0, 0.2)' : 'transparent')};
  `,
  FrameModal: styled.div`
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);

    @media (${(props) => props.theme.media.tabletSm}) {
      max-width: 100%;
      max-height: 100%;
    }
  `,
  NormalModal: {
    Wrapper: styled.div`
      position: relative;
      max-width: calc(100% - 40px);
      max-height: calc(100% - 40px);
      height: ${(props) => props._height ?? 'auto'};
      width: ${(props) => FormModalSize[props._size].width}px;
      /* padding: ${(props) => FormModalSize[props._size].py}px ${(props) => FormModalSize[props._size].px}px; */
      padding: ${(props) => FormModalSize[props._size].py}px 10px;
      background-color: #fff;
      border-radius: 50px;

      @media (${(props) => props.theme.media.tabletSm}) {
        max-width: 100%;
        max-height: 100%;
        width: 100vw;
        border-radius: 0;
        /* padding: 48px 20px; */
        padding: 48px 10px 10px;
        height: 100%;
      }
    `,
    Body: styled.div`
      height: auto;
      max-height: 100%;
      ${yScrollBoxCss()}
      ${isMobile && NonScrollBarCss}
      /* padding: 0 ${(props) => FormModalSize[props._size].px - 10}px; */
      padding: 0 10px;
    `,
    Title: styled.div`
      width: 100%;
      height: ${(props) => FormModalSize[props._size].th}px;
      font-size: ${(props) => props.theme.fontSize.xxl};
      font-weight: ${(props) => props.theme.fontWeight.medium};
      text-align: center;
    `,
    Close: styled.button`
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
  },
  FormModal: {
    Wrapper: styled.div`
      position: ${({ _position }) => (_position ? _position : 'relative')};
      left: ${({ _left }) => (_left ? _left + 'px' : 0)};
      top: ${({ _top }) => (_top ? _top + 'px' : 0)};
      max-width: calc(100% - 40px);
      max-height: calc(100% - 40px);
      height: ${(props) => props._height ?? 'auto'};
      width: ${(props) => FormModalSize[props._size].width}px;
      /* padding: ${(props) => FormModalSize[props._size].py}px ${(props) => FormModalSize[props._size].px}px; */
      padding: ${(props) => FormModalSize[props._size].py}px 10px;
      background-color: #fff;
      border-radius: 50px;

      ${(props) =>
        props._shadow &&
        css`
          box-shadow: 0 5px 24px rgba(0, 0, 0, 0.25);
        `}

      @media (${(props) => props.theme.media.tabletSm}) {
        width: 100%;
        height: 100dvh;
        max-width: 100%;
        max-height: 100dvh;
        border-radius: 0;
        padding-bottom: 0;
      }
    `,
    Body: styled.div`
      height: auto;
      max-height: calc(100% - ${(props) => FormModalSize[props._size].ah + FormModalSize[props._size].th}px);
      /* padding: 0 ${(props) => FormModalSize[props._size].px - 10}px; */
      padding: 0 10px;
      ${yScrollBoxCss()}
      ${isMobile && NonScrollBarCss}
    `,
    Action: styled.div`
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: ${(props) => FormModalSize[props._size].ah}px;
      padding: 0 ${(props) => FormModalSize[props._size].px - 10}px;

      @media (${(props) => props.theme.media.tabletSm}) {
        position: absolute;
        bottom: 0;
        left: 0;
        align-items: center;
        padding-left: ${(props) => FormModalSize[props._size].px}px;
        padding-right: ${(props) => FormModalSize[props._size].px}px;
      }
    `,
  },
  AlertModal: styled.article`
    position: relative;
    width: 360px;
    padding: 24px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 40px;
    background-color: #fff;
    p {
      padding-top: 24px;
      font-size: ${(props) => props.theme.fontSize.l};
      word-break: keep-all;
      white-space: pre-wrap;
      text-align: center;
    }

    @media (${(props) => props.theme.media.mobile}) {
      width: 300px;
    }
  `,
  SwipeModal: {
    Wrapper: styled.article`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: max-content;
      border-radius: 20px;
      box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25);
      overflow: hidden;

      @media (${(props) => props.theme.media.tabletSm}) {
        top: auto;
        bottom: 0;
        left: 0;
        transform: translate(0, 0);
        width: 100%;
        max-height: calc(100vh - 150px);
        border-radius: 20px 20px 0 0;
        box-shadow: none;
      }
    `,
    Body: styled.div`
      width: max-content;
      padding: 24px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      background-color: #fff;
      @media (${(props) => props.theme.media.tabletSm}) {
        width: 100%;
        height: calc(100% - 56px);
        padding: 0;
        gap: 0;
      }
    `,
    Top: styled.div`
      width: 100%;
      height: 22px;
      @media (${(props) => props.theme.media.tabletSm}) {
        display: flex;
        justify-content: center;
        align-self: center;
        height: 66px;
        padding: 20px;
      }
    `,
    Title: styled.h3`
      width: 100%;
      text-align: center;
      color: ${(props) => props.theme.colors.gray900};
      font-size: ${(props) => props.theme.fontSize.xxxl};
      font-weight: ${(props) => props.theme.fontWeight.medium};
      @media (${(props) => props.theme.media.tabletSm}) {
      }
    `,
    Close: styled.button`
      position: absolute;
      top: 16px;
      left: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    `,
    Action: styled.button`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 56px;
      background-color: ${(props) => props.theme.colors.mainPurple};
      color: #fff;
      font-weight: ${(props) => props.theme.fontWeight.bold};
    `,
    Contents: styled.div`
      width: 100%;
      height: calc(100% - 66px);
      ${yScrollBoxCss()}

      @media (${(props) => props.theme.media.tabletSm}) {
        padding: 40px 10px 24px;
        .inner {
          padding: 0 10px;
          height: auto;
        }
      }
    `,
  },
  ConfirmModal: {
    Wrapper: styled.div`
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 48px 24px 24px;
      width: 360px;
      max-width: calc(100% - 20px);
      border-radius: 50px;
      box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25);
      background-color: #fff;
    `,
    Btn: styled.button`
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 30px;
      border: 1px solid ${(props) => props.theme.colors.mainPurple};
      color: ${(props) => props.theme.colors.mainPurple};

      ${(props) =>
        props.confirm &&
        css`
          background-color: ${(props) => props.theme.colors.mainPurple};
          color: #fff;
          font-weight: ${(props) => props.theme.fontWeight.bold};
        `}
    `,
  },
};

// 모달 오버레이(공통)
export function ModalOverlay({ handleOverlayClick, children, visible = true }) {
  const overlayRef = useRef(null);
  const onOverlayClick = (e, callback) => {
    if (overlayRef.current === e.target) {
      callback();
    }
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return ReactDOM.createPortal(
    <S.Overlay
      ref={overlayRef}
      visible={visible}
      {...(handleOverlayClick && {
        onClick: (e) => {
          onOverlayClick(e, handleOverlayClick);
        },
      })}>
      {children}
    </S.Overlay>,
    document.getElementById('portal')
  );
}

// 프레임 모달
export function FrameModal({ children, handleOverlayClick }) {
  return (
    <ModalOverlay handleOverlayClick={handleOverlayClick}>
      <S.FrameModal>{children}</S.FrameModal>
    </ModalOverlay>
  );
}

// 일반 모달
export function NormalModal({ children, overlayClick = true, handleModalClose, _size = 'm', _height, _width }) {
  return (
    <ModalOverlay {...(overlayClick && { handleOverlayClick: handleModalClose })}>
      <S.NormalModal.Wrapper _size={_size} _height={_height} _width={_width}>
        <S.NormalModal.Close onClick={handleModalClose}>
          <img src={xIcon} alt="닫기" />
        </S.NormalModal.Close>
        <S.NormalModal.Body _size={_size}>
          <div>{children}</div>
        </S.NormalModal.Body>
      </S.NormalModal.Wrapper>
    </ModalOverlay>
  );
}

/**
 * 입력폼 모달
 * @param {'m'|'s'} param._size m or s 
 */
export function FormModal({
  title,
  children,
  action,
  _size = 'm',
  _height,
  handleModalClose,
  overlayClick = true,
  overlayVisible = true,
  _left,
  _top,
  _position,
  as,
}) {
  return (
    <ModalOverlay {...(overlayClick && { handleOverlayClick: handleModalClose })} visible={overlayVisible}>
      <S.FormModal.Wrapper
        _size={_size}
        _height={_height}
        _shadow={!overlayVisible}
        _left={_left}
        _top={_top}
        _position={_position}>
        <S.NormalModal.Title _size={_size}>{title}</S.NormalModal.Title>
        <S.NormalModal.Close
          onClick={() => {
            handleModalClose();
          }}>
          <img src={xIcon} alt="닫기" />
        </S.NormalModal.Close>
        <S.FormModal.Body _size={_size} as={as ?? 'div'}>
          <div>{children}</div>
        </S.FormModal.Body>
        <S.FormModal.Action _size={_size}>{action}</S.FormModal.Action>
      </S.FormModal.Wrapper>
    </ModalOverlay>
  );
}

// alert dialog 모달
export const AlertModal = ({ title, text, handleClose, handleConfirm, handleCancel }) => {
  const resetAlertModal = useResetRecoilState(alertModal);

  const closeModal = handleClose
    ? () => {
        handleClose();
        resetAlertModal();
      }
    : resetAlertModal;

  return (
    <ModalOverlay handleOverlayClick={closeModal}>
      <S.AlertModal>
        <S.NormalModal.Close
          onClick={() => {
            closeModal();
          }}>
          <img src={xIcon} alt="닫기" />
        </S.NormalModal.Close>
        <p>{text}</p>
        {handleConfirm ? (
          <D.FlexRows _width="100%" _gap={8}>
            <RoundBtn
              text="취소"
              onClick={() => {
                if (handleCancel) {
                  handleCancel();
                }
                closeModal();
              }}
            />
            <RoundBtn
              text="확인"
              _primary
              onClick={() => {
                handleConfirm();
                closeModal();
              }}
            />
          </D.FlexRows>
        ) : (
          <RoundBtn
            text="확인"
            _primary
            _capsule
            onClick={() => {
              closeModal();
            }}
          />
        )}
      </S.AlertModal>
    </ModalOverlay>
  );
};

// 풀페이지 모달
export const FullModal = ({ children, handleModalClose }) => {
  return (
    <ModalOverlay visible={false} handleOverlayClick={handleModalClose}>
      {children}
    </ModalOverlay>
  );
};

// 필터 모달
export const SwipeModal = ({ title, children, handleModalClose, action, handleAction, media }) => {
  const [isMobile, isResizing] = useMobile(media ?? 767);
  const contents = () => (
    <S.SwipeModal.Wrapper>
      <S.SwipeModal.Body>
        <S.SwipeModal.Top>
          {title && <S.SwipeModal.Title>{title}</S.SwipeModal.Title>}
          {isMobile && (
            <S.SwipeModal.Close
              onClick={() => {
                handleModalClose();
              }}>
              <img src={arrowLeftIconf} />
            </S.SwipeModal.Close>
          )}
        </S.SwipeModal.Top>
        {
          <S.SwipeModal.Contents>
            <div className="inner">{children}</div>
          </S.SwipeModal.Contents>
        }
      </S.SwipeModal.Body>
      {action && (
        <S.SwipeModal.Action
          onClick={() => {
            handleAction();
          }}>
          {action}
        </S.SwipeModal.Action>
      )}
    </S.SwipeModal.Wrapper>
  );

  if (isResizing) {
    return null;
  }

  if (isMobile) {
    return <ModalOverlay handleOverlayClick={handleModalClose}>{contents()}</ModalOverlay>;
  } else {
    return contents();
  }
};

// confirm dialog 모달
export const ConfirmModal = ({ title, text, cancel, confirm, handleCancel, handleConfirm }) => {
  const resetConfirm = useResetRecoilState(confirmModal);
  return (
    <ModalOverlay
      handleOverlayClick={() => {
        if (handleCancel) handleCancel();
        resetConfirm();
      }}>
      <S.ConfirmModal.Wrapper>
        <D.FlexCols _gap={24} _items="center" _justify="center">
          <Text _weight="bold" _align="center">
            {title}
          </Text>
          <Text _align="center" _line="xxl">
            {text}
          </Text>
        </D.FlexCols>
        <D.FlexRows _gap={8}>
          {handleCancel && (
            <S.ConfirmModal.Btn
              onClick={() => {
                handleCancel();
                resetConfirm();
              }}>
              {cancel ?? '취소'}
            </S.ConfirmModal.Btn>
          )}
          <S.ConfirmModal.Btn
            confirm
            onClick={() => {
              handleConfirm();
              resetConfirm();
            }}>
            {confirm ?? '확인'}
          </S.ConfirmModal.Btn>
        </D.FlexRows>
      </S.ConfirmModal.Wrapper>
    </ModalOverlay>
  );
};
