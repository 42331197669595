import React from 'react';
import styled from 'styled-components';
import * as D from '../Common/Display';
import * as T from '../Common/Text';
import ReviewProfile from './ReviewProfile';
import { Link } from 'react-router-dom';
import { spaceDetails } from '../../constants/site';
import TagBox from '../Common/TagBox';
import { cdnUrl } from '../../constants/url';
import ReviewStars from './ReviewStars';
import { useSetRecoilState } from 'recoil';
import { reviewModal } from '../../store/modal';
import { Badge } from '../Common/Badge';

const S = {
  Card: styled.article`
    position: relative;
    width: 280px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Img: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
    border-radius: 20px;
    overflow: hidden;
  `,
};

export default function ReivewCard03({ review }) {
  const setReviewViewer = useSetRecoilState(reviewModal);
  return (
    <S.Card
      as={Link}
      onClick={(e) => {
        e.preventDefault();
        setReviewViewer({
          id: review.targetId,
          contents: { ...review, type: '공간' },
        });
      }}>
      <S.Img>
        <img src={cdnUrl(review.image[0], 'h=210&q=80')} alt={review.hotelName} />
      </S.Img>
      <D.FlexCols _width="100%" _gap={12}>
        <D.FlexCols _width="100%" _gap={8}>
          <T.Text _size="xxl" _weight="medium">
            {review.hotelName}
          </T.Text>
          <D.FlexRows _width="100%" _justify="space-between" _items="center">
            <T.Text _size="xs">{review.nickname}</T.Text>
            <D.FlexRows _gap={4} _items="center">
              <ReviewStars score={review.star} size="xs" />
              <T.Text _size="xxs" _weight="medium">
                {review.star}
              </T.Text>
            </D.FlexRows>
          </D.FlexRows>
        </D.FlexCols>
        <T.LinelimitText as="p" line={6} _height={88}>
          {review.contents}
        </T.LinelimitText>
        {review.category && (
          <D.FlexRows _width="100%" _gap={8}>
            {review.category.map((ctg, idx) => (
              <Badge text={ctg} key={idx} _color="pink600" />
            ))}
          </D.FlexRows>
        )}
      </D.FlexCols>
    </S.Card>
  );
}
