import React from "react";
import styled from "styled-components";
import * as D from "../../Common/Display";
import { CostText, Text } from "../../Common/Text";
import { Line } from "../../Common/Line";
import { FreeTable, LinedTable } from "../../Common/Table";
import DownLoadLink from "../../Common/DownloadLink";
import { Badge, ColorBadge } from "../../Common/Badge";
import HiddenInfo from "../../Common/HiddenInfo";
import { BackBtn, RoundBtn } from "../../Common/Button";
import { MYPAGE_KEYS, useBookingDetailsQuery } from "../../../hooks/queries/mypage";
import { Navigate, useNavigate, useParams } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import { SITE, spaceBook, spaceDetails } from "../../../constants/site";
import { ErrorStatus, LoadingStatus } from "../../Common/Status";
import { fileUrl } from "../../../constants/url";
import { useSetRecoilState } from "recoil";
import { customAlert } from "../../../store/modal";
import { requestBookingCancel, requestBookingConfirm } from "../../../service/client/mypage";
import { bookColor } from "../../../constants/color";
import { useQueryClient } from "@tanstack/react-query";

const S = {
  Abst: {
    Wrapper: styled.article`
      display: flex;
      gap: 56px;
      width: 100%;
      border-radius: 20px;
      background-color: ${(props) => props.theme.colors.gray50};
      border: 1px solid ${(props) => props.theme.colors.gray300};
      padding: 32px;

      @media (${(props) => props.theme.media.tablet}) {
        padding: 24px;
        gap: 36px;
      }
      @media (${(props) => props.theme.media.tabletSm}) {
        flex-direction: column;
        gap: 24px;
      }
    `,
    Pic: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 200px;
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;

      > img {
        min-height: 100%;
        max-height: 120%;
      }

      @media (${(props) => props.theme.media.tabletSm}) {
        width: 100%;
      }
    `,
    Contents: styled.div`
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 18px;
      @media (${(props) => props.theme.media.tabletSm}) {
        gap: 16px;
      }
    `,
    Infos: styled.ul`
      display: flex;
      flex-direction: column;
      gap: 6px;

      li {
        display: flex;
        gap: 8px;
        align-items: center;
        color: ${(props) => props.theme.colors.gray800};
        font-weight: ${(props) => props.theme.fontWeight.medium};
        line-height: ${(props) => props.theme.lineHeight.xxs};
        i {
          font-style: normal;
        }
        > span {
          font-size: ${(props) => props.theme.fontSize.l};
        }
      }

      @media (${(props) => props.theme.media.tabletSm}) {
        gap: 4px;
      }
    `,
  },
  Field: {
    Wrapper: styled.article`
      display: flex;
      flex-direction: column;
    `,
    Titie: styled.h4``,
  },
  Reason: styled.div`
    width: 100%;
    padding: 56px 32px;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    text-align: center;
    color: ${(props) => props.theme.colors.error};
  `,
};

/**
 * 마이페이지 > 예약 상세 컴포넌트
 */
export default function BookingDetails({ handleReviewOpen }) {
  const { bookingId } = useParams();
  const { isLoading, error, data, isSuccess, refetch } = useBookingDetailsQuery({ id: bookingId });
  const setAlert = useSetRecoilState(customAlert);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const cancelBooking = async () => {
    const res = await requestBookingCancel(bookingId);
    if (res && res.data.status === 200) {
      setAlert({
        text: "예약을 취소하였습니다.",
        handleClose: () => {
          queryClient.invalidateQueries({ queryKey: MYPAGE_KEYS.booking });
          refetch();
        },
      });
    }
  };

  const confirmBooking = async () => {
    const res = await requestBookingConfirm(bookingId);
    if (res && res.data.status === 200) {
      setAlert({
        text: "예약을 확정하였습니다.",
        handleClose: () => {
          queryClient.invalidateQueries({ queryKey: MYPAGE_KEYS.booking });
          refetch();
        },
      });
    }
  };

  console.log(data);

  if (error) {
    setAlert({
      text: "예약 정보를 불러올 수 없습니다.",
      handleClose: () => {
        navigate(SITE.myBooking);
      },
    });
    return null;
  }

  return (
    <D.Container _width="100%" _mn_height="100vh">
      {isLoading || !data ? (
        <LoadingStatus />
      ) : (
        <D.FlexCols _width="100%" _gap={60}>
          <S.Abst.Wrapper>
            <S.Abst.Pic as={Link} to={spaceDetails(data.hotelId)}>
              <img src={`https://cdn.alabwaba.com${data.thumbnail}`} alt="예약 공간" />
            </S.Abst.Pic>
            <S.Abst.Contents>
              <D.FlexCols _width="100%" _gap={22} _media="tabletSm" _m_gap={16}>
                <D.FlexCols _gap={4}>
                  <D.FlexRows _gap={8} _items="center">
                    <ColorBadge _color={bookColor[data.status.replaceAll(" ", "")]} text={data.status} />
                    <Text as={Link} to={spaceDetails(data.hotelId)} _size="xxl" _weight="bold">
                      {data.hotelName}
                    </Text>
                  </D.FlexRows>
                  <Text _size="l">
                    <Link to={spaceBook(data.spaceId)}>{data.spaceName}</Link>
                  </Text>
                </D.FlexCols>
                <D.Flex
                  _direction="row"
                  _justify="space-between"
                  _items="flex-end"
                  _media="tabletSm"
                  _m_direction="column"
                  _m_items="flex-start"
                  _m_gap={4}
                >
                  <S.Abst.Infos>
                    <li>
                      <i>예약 날짜 :</i>
                      <span>
                        {moment(data.startDate).format("YY.MM.DD")} ~{" "}
                        {moment(data.endDate).format("YY.MM.DD")}
                      </span>
                    </li>
                    <li>
                      <i>결제 금액 :</i>{" "}
                      <CostText
                        as="span"
                        cost={data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        per="시간"
                        _c_size="l"
                        _c_color="gray800"
                        _p_size="s"
                        _p_color="gray600"
                      />
                    </li>
                  </S.Abst.Infos>
                  <Text _size="l" _weight="medium" _color="gray700" _line="xxs">
                    예약번호 {data.reservationNumber}
                  </Text>
                </D.Flex>
              </D.FlexCols>
              <D.FlexRows _width="100%" _gap={24} _media="tabletSm" _m_gap={12}>
                {/* left side */}
                {["예약 신청", "예약 확정"].findIndex((w) => w === data.status) >= 0 && (
                  <RoundBtn
                    _capsule
                    text="예약 취소"
                    onClick={() => {
                      setAlert({
                        text: "예약을 취소하시겠습니까?",
                        handleConfirm: () => {
                          cancelBooking();
                        },
                      });
                    }}
                  />
                )}
                {["예약 불가", "예약 취소", "이용 완료"].findIndex((w) => w === data.status) >= 0 && (
                  <RoundBtn
                    onClick={() => {
                      navigate(spaceBook(data.spaceId));
                    }}
                    _capsule
                    text="상세 보기 "
                  />
                )}
                {/* right side */}
                {data.status === "예약 신청" && (
                  <RoundBtn
                    _capsule
                    _primary
                    disabled={!data.file || data?.file?.length < 1}
                    text="예약 확정"
                    onClick={() => {
                      setAlert({
                        text: "예약을 확정하시겠습니까?",
                        handleConfirm: () => {
                          confirmBooking();
                        },
                      });
                    }}
                  />
                )}
                {data.status === "예약 확정" && <RoundBtn _capsule _primary disabled text="예약 완료" />}
                {data.status === "예약 불가" && (
                  <RoundBtn
                    onClick={() => {
                      navigate(spaceDetails(data.hotelId));
                    }}
                    _capsule
                    _dark
                    text="다른 공간 보기 "
                  />
                )}
                {data.status === "예약 취소" && (
                  <RoundBtn
                    onClick={() => {
                      navigate(spaceBook(data.spaceId));
                    }}
                    _capsule
                    _primary
                    text="다시 예약하기 "
                  />
                )}
                {data.status === "이용 완료" && (
                  <RoundBtn
                    _capsule
                    _primary
                    text="리뷰 작성"
                    disabled={!data.reviewWrite}
                    onClick={() => {
                      handleReviewOpen({
                        hotelId: data.hotelId,
                        managerId: data.managerId,
                        reservationId: data.reservationId,
                        spacId: data.spaceId,
                        spaceName: `${data.hotelName} - ${data.spaceName}`,
                      });
                    }}
                  />
                )}
              </D.FlexRows>
            </S.Abst.Contents>
          </S.Abst.Wrapper>
          <D.FlexCols _width="100%" _gap={40}>
            {data.status === "예약 불가" && data.returnReason && (
              <React.Fragment>
                <D.FlexCols _gap={40}>
                  <Text _weight="medium" _size="xxxl" _media="tablet" _m_size="xxl">
                    예약 불가 사유
                  </Text>
                  <S.Reason>{data.returnReason}</S.Reason>
                </D.FlexCols>
                <Line _color="gray700" />
              </React.Fragment>
            )}
            <D.FlexCols _gap={24}>
              <Text _weight="medium" _size="xxxl" _media="tablet" _m_size="xxl">
                예약 정보
              </Text>
              {data.details.map((x, index) => {
                return (
                  <HiddenInfo title={x.reservationDate} _hidden={true} key={index}>
                    <D.FlexCols _gap={24} _media="tabletSm" _m_gap={16}>
                      <FreeTable
                        data={[
                          {
                            th: "시간",
                            td: `${x.checkIn.split(":")[0]}:${x.checkIn.split(":")[1]}
														~
														${x.checkOut.split(":")[0]}:${x.checkOut.split(":")[1]}`,
                          },
                          { th: "인원", td: `${x.people} 명` },
                        ]}
                      />
                      {x.room?.length > 0 && (
                        <React.Fragment>
                          <Line _color="gray200" />
                          <FreeTable
                            caption="객실"
                            _th_size="s"
                            _th_weight="normal"
                            data={x.room.map((room) => {
                              return { th: room.roomName, td: `${room?.amount}` };
                            })}
                          />
                        </React.Fragment>
                      )}
                      {x.meal && (
                        <React.Fragment>
                          <Line _color="gray200" />
                          <FreeTable
                            caption="식사 및 패키지"
                            _th_size="s"
                            _th_weight="normal"
                            data={x.meal.map((mealData) => {
                              return { th: mealData.mealName, td: mealData.amount };
                            })}
                          />
                        </React.Fragment>
                      )}
                      {x.arrangementName && (
                        <React.Fragment>
                          <Line _color="gray200" />
                          <FreeTable
                            data={[
                              {
                                th: "테이블 배치",
                                td: `${x.arrangementName} (최대 ${x.arrangementPeople}명)`,
                              },
                            ]}
                          />
                        </React.Fragment>
                      )}
                    </D.FlexCols>
                  </HiddenInfo>
                );
              })}
              {data.details.length < 1 && <ErrorStatus _height="120px" message="-" />}
            </D.FlexCols>
            <Line _color="gray700" />
            <D.FlexCols _gap={24}>
              <Text _weight="medium" _size="xxxl" _media="tablet" _m_size="xxl">
                견적 정보
              </Text>
              {data.file.length > 0 ? (
                <LinedTable
                  _td_align="right"
                  data={data.file.map((f, idx) => ({
                    th: `${idx + 1}차`,
                    td: <DownLoadLink url={fileUrl(f.savedName)} text={f.fileName} />,
                  }))}
                />
              ) : (
                <ErrorStatus
                  _height="120px"
                  message={
                    ["예약 불가", "예약 취소"].filter((status) => status === data.status).length > 0
                      ? "-"
                      : "견적서 확인 후 예약 확정이 가능합니다."
                  }
                />
              )}
            </D.FlexCols>
            <Line _color="gray700" />
            <D.FlexCols _gap={24}>
              <Text _weight="medium" _size="xxxl" _media="tablet" _m_size="xxl">
                예약자 정보
              </Text>
              <LinedTable
                _th_width={130}
                data={[
                  { th: "이름", td: data.reservationName },
                  { th: "연락처", td: data.reservationPhone },
                  { th: "이메일", td: data.reservationEmail },
                  {
                    th: "요청사항",
                    td: "-",
                  },
                  {
                    th: "예약일자",
                    td: data.reservationCreateDate
                      ? moment(data.reservationCreateDate).format("YYYY년 MM월 DD일")
                      : "-",
                  },
                ]}
              />
            </D.FlexCols>
          </D.FlexCols>
          <BackBtn text="목록으로" />
        </D.FlexCols>
      )}
    </D.Container>
  );
}
