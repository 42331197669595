import React from 'react';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';

import { yScrollBoxCss } from '../../../../components/Common/Display';
import { useSearchLocationQuery } from '../../../../hooks/queries/search';
import { searchLocationState } from '../../../../store/request';

import search from '../../../../assets/search.svg';
import erase from '../../../../assets/x.png';
import marker from '../../../../assets/icons/utils/ico-search_location.svg';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchDiv = styled.div`
  padding: 24px;
  width: 500px;
  max-width: calc(100% - 20px);
  height: 328px;
  ${(props) => props.theme.effectStyle.boxShadow}
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (${(props) => props.theme.media.tabletSm}) {
    height: 388px;
    gap: 16px;
    padding: 32px 30px 52px 30px;
  }
`;
const InputBox = styled.div`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid ${(props) => props.theme.colors.mainPurple};

  & > img:first-child {
    width: 24px;
    height: 24px;
  }
  & > img:last-child {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  & input {
    width: 100%;
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.gray900};

    &::placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;
const Results = styled.ul`
  width: 100%;
  height: auto;
  ${yScrollBoxCss()}
  display: flex;
  flex-direction: column;

  @media (${(props) => props.theme.media.tabletSm}) {
    height: 240px;
  }
`;
const Result = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 44px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.gray900};

  & > img {
    width: 20px;
    height: 20px;
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    gap: 18px;
    font-size: ${(props) => props.theme.fontSize.s};
  }
`;

// component: 선호위치 선택 box
// /space/request 페이지에 노출 및 호출
function SelectLocation({ word, setWord }) {
  // 검색한 단어를 포함하는 content 검색 출력
  const { data: searchResult, isLoading } = useSearchLocationQuery(word);
  // 목록에서 선택한 content 저장
  const setSearchLocation = useSetRecoilState(searchLocationState);

  return (
    <Wrapper>
      <SearchDiv>
        <InputBox>
          <img src={search} alt="search-img" />
          <input
            id="location"
            name="location"
            placeholder="동 또는 업체 명을 입력해 주세요."
            value={word}
            onChange={(e) => setWord(e.currentTarget.value)}
            autoComplete="off"
          />
          <img
            src={erase}
            alt="erase-img"
            onClick={() => {
              setSearchLocation('');
              setWord('');
            }}
          />
        </InputBox>

        {/* 자동완성 목록 */}
        <Results>
          {!isLoading &&
            searchResult?.map((address, i) => (
              <Result key={i}>
                <Button
                  onClick={() => {
                    setWord(address.name);
                    setSearchLocation(address.name);
                  }}
                >
                  <img src={marker} alt="marker-img" />
                  <span>{address.name}</span>
                </Button>
              </Result>
            ))}
        </Results>
      </SearchDiv>
    </Wrapper>
  );
}

export default SelectLocation;
