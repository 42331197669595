import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FormModal } from "../../layouts/Modal";
import { RoundBtn } from "../Common/Button";
import * as D from "../Common/Display";
import PicUploader from "../Common/PicUploader";
import { Text } from "../Common/Text";
import { useReviewWriteData } from "../../hooks/queries/mypage";
import { useSetRecoilState } from "recoil";
import { customAlert } from "../../store/modal";
import { isValidFileSize } from "../../utils/file";

const S = {
  Tabs: styled.ul`
    width: 100%;
    height: 35px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `,
  Tab: styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 100%;
    line-height: 22px;
    color: ${(props) => props.theme.colors[props.active ? "mainPurple" : "gray700"]};
    font-weight: ${(props) => props.theme.fontWeight[props.active ? "bold" : "base"]};
    font-size: ${(props) => props.theme.fontSize.s};

    &::after {
      content: "";
      width: 100%;
      height: 5px;
      background-color: ${(props) => props.theme.colors[props.active ? "mainPurple" : "gray50"]};
    }
  `,
  Star: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  `,
  Text: styled.textarea`
    width: 100%;
    height: 216px;
    border-radius: 20px;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    padding: 16px 24px;
    resize: none;
    font-size: ${(props) => props.theme.fontSize.l};
    &::placeholder {
      color: ${(props) => props.theme.colors.gray500};
    }
  `,
  Contents: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.gray50};
  `,
};

/**
 * 리뷰 등록폼 컴포넌트
 * @param {func} props.handleClose 등록폼 닫기 핸들러
 */
export default function ReviewForm({ hotelId, spaceId, managerId, spaceName, handleClose, reservationId }) {
  const [step, setStep] = useState(0);
  const [review, setReview] = useState([
    {
      type: "공간",
      star: 0,
      contents: "",
      image: [],
    },
    {
      type: "담당자",
      star: 0,
      contents: "",
      image: [],
    },
  ]);
  const setAlert = useSetRecoilState(customAlert);
  const { mutate: submitReview, data, isSuccess } = useReviewWriteData();

  const hasFilled = (data) => {
    return data.star > 0 && data.contents.length > 0;
  };

  const handleNext = () => {
    if (!hasFilled(review[step])) {
      return;
    }

    if (step === review.length - 1) {
      handleSubmit();
    } else {
      setStep((s) => s + 1);
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const data = [
      { ...review[0], hotelId: hotelId, targetId: spaceId, reservationId: reservationId },
      { ...review[1], hotelId: hotelId, targetId: managerId, reservationId: reservationId },
    ];

    if (!isValidFileSize([...data[0].image, ...data[1].image], 100)) {
      alert("이미지 파일은 총 100mb까지 등록할 수 있습니다.");
    }

    for (let i = 0; i < data.length; i++) {
      for (const key in data[i]) {
        if (key === "image") {
          for (const img of data[i][key]) {
            formData.append(`review[${i}].${key}`, img);
          }
        } else {
          formData.append(`review[${i}].${key}`, data[i][key]);
        }
      }
    }
    submitReview(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      setAlert({
        text: "리뷰를 등록하였습니다.",
        handleClose: () => {
          handleClose();
        },
      });
    }
  }, [isSuccess]);

  return (
    <FormModal
      title="리뷰 작성"
      _height="836px"
      action={
        <RoundBtn
          text={step >= review.length - 1 ? "완료" : "다음"}
          onClick={handleNext}
          disabled={!hasFilled(review[step])}
          _primary
          _capsule
          _width="300px"
        />
      }
      handleModalClose={() => {
        handleClose();
      }}
    >
      <D.FlexCols _width="100%" _gap={60} _items="center">
        <S.Tabs>
          {["STEP.1 공간", "STEP.2 담당자서비스"].map((text, idx) => (
            <S.Tab key={idx} active={idx === step}>
              {text}
            </S.Tab>
          ))}
        </S.Tabs>
        {review.map((rv, idx) =>
          step === idx ? (
            <D.FlexCols _width="100%" _gap={20}>
              <S.Contents key={idx}>
                <D.FlexCols _width="100%" _gap={8} _items="center">
                  <Text _size="xl" _weight="medium">
                    {idx === 0 ? spaceName : "담당자"}
                  </Text>
                  <D.FlexRows _gap={8} _items="center">
                    <D.FlexRows>
                      {new Array(5).fill(0).map((_, sIdx) => (
                        <S.Star
                          key={sIdx}
                          onClick={() => {
                            setReview((r) =>
                              r.map((d, rIdx) => (idx === rIdx ? { ...d, star: sIdx + 1 } : d))
                            );
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.52237 1.21192C7.66756 0.742082 8.33261 0.742083 8.47779 1.21192L9.85843 5.67974C9.92317 5.88924 10.1169 6.03212 10.3361 6.03212H14.8553C15.3335 6.03212 15.5389 6.63911 15.1588 6.92944L11.4603 9.75486C11.2938 9.88206 11.2242 10.0996 11.2861 10.2998L12.6885 14.8381C12.8322 15.3032 12.2941 15.6786 11.9073 15.383L8.30361 12.6301C8.12441 12.4932 7.87575 12.4932 7.69655 12.6301L4.0929 15.383C3.70605 15.6786 3.16793 15.3032 3.31165 14.8381L4.71406 10.2998C4.77593 10.0996 4.70639 9.88206 4.53988 9.75486L0.841366 6.92944C0.461309 6.63911 0.666629 6.03212 1.1449 6.03212H5.66403C5.88331 6.03212 6.077 5.88924 6.14174 5.67974L7.52237 1.21192Z"
                              fill={sIdx + 1 <= rv.star ? "#F7D046" : "#EEEEEE"}
                            />
                          </svg>
                        </S.Star>
                      ))}
                    </D.FlexRows>
                    <Text>{rv.star}점</Text>
                  </D.FlexRows>
                </D.FlexCols>
                <S.Text
                  placeholder="리뷰를 작성해 주세요."
                  onChange={(e) =>
                    setReview((r) =>
                      r.map((d, rIdx) => (rIdx === idx ? { ...d, contents: e.target.value } : d))
                    )
                  }
                />
              </S.Contents>
              <PicUploader
                pics={rv.image}
                handleChange={(values) => {
                  setReview((r) => r.map((d, rIdx) => (rIdx === idx ? { ...d, image: values } : d)));
                }}
                max={5}
              />
              <Text _color="gray700" _size="l">
                * 사진은 최대 5장까지 등록 가능합니다.
              </Text>
            </D.FlexCols>
          ) : (
            <></>
          )
        )}
      </D.FlexCols>
    </FormModal>
  );
}
