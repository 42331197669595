import React from 'react';
import styled from 'styled-components';
import Message from '../Message/Message';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const MiidleWrapper = styled.div`
  padding: 24px 10px 24px 16px;
  width: 100%;
  height: ${(props) => props.height};
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.error : props.theme.colors.gray300};
  border-radius: 20px;
`;
const TextAreaWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  textarea {
    padding-right: 6px;
    width: 100%;
    resize: none;
    overflow-y: scroll;
    &::placeholder {
      font-size: ${(props) => props.theme.fontSize.s};
      font-weight: ${(props) => props.theme.fontWeight.normal};
      color: ${(props) => props.theme.colors.gray400};
      line-height: ${(props) => props.theme.lineHeight.l};
    }

    // track scroll bar (전체)
    &::-webkit-scrollbar {
      width: 4px;
      height: 100%;
      background: rgba(238, 238, 238, 0.6);
      border-radius: 232px;
    }
    // thumb scroll bar (현재)
    &::-webkit-scrollbar-thumb {
      width: 4px;
      height: auto;
      background: ${(props) => props.theme.colors.mainPurple};
      border-radius: 232px;
    }
  }
`;

/**
 * textarea 래퍼 컴포넌트
 */
function TextareaLayout({
  wrapperHeight = 'auto',
  name,
  placeholder,
  value,
  setValue,
  isError = false,
  message,
}) {
  return (
    <Wrapper>
      <MiidleWrapper height={wrapperHeight} isError={isError}>
        <TextAreaWrapper isError={isError}>
          <textarea
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
        </TextAreaWrapper>
      </MiidleWrapper>
      {message && <Message isError={isError} text={message} />}
    </Wrapper>
  );
}

export default TextareaLayout;
