import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import InputLayout from '../../../components/Common/Inputs/InputLayout';
import TextareaLayout from '../../../components/Common/Inputs/TextareaLayout';
import { Text } from '../../../components/Common/Text';
import { usePartnerData } from '../../../hooks/queries/mypage';
import { SITE } from '../../../constants/site';
import { useSetRecoilState } from 'recoil';
import { customAlert } from '../../../store/modal';
import { EMAIL_PATTERN, TEL_PATTERN } from '../../../constants/pattern';
import { checkNewPhone, checkValidId } from '../../../service/client/user';
import { requestPartner } from '../../../service/client/mypage';

const Wrapper = styled.div`
  width: 100%;
`;
const PartnerRequestDiv = styled.div`
  max-width: 500px;
  padding: 80px 0 120px 0;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 60px 20px 120px 20px;
  }
`;
const Title = styled(Text)`
  margin-bottom: 60px;
  ${(props) => props.theme.textStyle.mainTitle}
`;
const Description = styled(Text)`
  margin-bottom: 32px;
`;

const FormDiv = styled.div`
  padding: 40px 24px;
  width: 388px;
  ${(props) => props.theme.effectStyle.boxShadow}
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (${(props) => props.theme.media.tabletSm}) {
    max-width: 350px;
  }
`;

const Button = styled.button`
  margin-top: 60px;
  padding: 8px 16px;
  width: 300px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.colors.mainPurple};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.l};
  color: ${(props) => props.theme.colors.white};
  border-radius: 30px;
  @media (${(props) => props.theme.media.tabletSm}) {
    margin-top: 40px;
  }
`;

const formValidation = {
  userName: { required: true, pattern: null, patternWarn: '', name: '이름' },
  email: { required: true, pattern: EMAIL_PATTERN, patternWarn: '이메일 형식으로 입력해 주세요.', name: '이메일' },
  phone: { required: true, pattern: TEL_PATTERN, patternWarn: '전화번호 형식으로 입력해 주세요.', name: '전호번호' },
  company: { required: true, pattern: null, patternWarn: '', name: '업체 명' },
  contents: { required: true, pattern: null, patternWarn: '', name: '요청사항' },
};

/**
 * 공간 파트너 신청 페이지
 */
const PartnerRequest = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const { mutate: reqeustPartner, isSuccess } = usePartnerData();
  const navigate = useNavigate();
  const setAlert = useSetRecoilState(customAlert);
  const [data, setData] = useState(Object.keys(formValidation).reduce((acc, k) => ({ ...acc, [k]: '' }), {}));

  // 입력값 유효성 검사
  const errorValidation = (key) => {
    if (formValidation[key].required && data[key].length < 1) {
      return '*' + formValidation[key].name + '은(는) 필수로 입력하여야 합니다.';
    }
    if (formValidation[key].pattern && !formValidation[key].pattern.test(data[key])) {
      return '*' + formValidation[key].patternWarn ?? formValidation[key].name + '형식으로 입력해 주세요.';
    }

    return '';
  };

  // 신청하기 핸들러
  const handleSubmit = async () => {
    const idRes = await checkValidId(data.email);
    const phoneRes = await checkNewPhone(data.phone);

    if (idRes.data.status !== 200) {
      setAlert({ text: '해당 이메일로 계정이 있습니다. \n 다른 이메일로 신청해 주세요.' });
      return;
    }

    if (phoneRes.data.status !== 200) {
      setAlert({ text: '해당 전화번호로 계정이 있습니다. \n 다른 전화번호로 신청해 주세요.' });
      return;
    }
    reqeustPartner(data);
  };

  useEffect(() => {
    if (isSuccess) {
      setAlert({
        text: '파트너 신청이 완료되었습니다.',
        handleClose: () => {
          navigate(SITE.home);
        },
      });
    }
  }, [isSuccess, setAlert]);

  return (
    <Wrapper>
      <PartnerRequestDiv>
        <Title>공간 파트너 신청하기</Title>
        <Description _size="l" _weight="medium" _color="gray900" _space="pre-line" _align="center">
          특급 업체부터 컨벤션, 파티룸까지 ~!{`\n`}
          다양한 공간을 알아봐봐와 함께 공유하여 프리미엄 파트너가 되실 수 있습니다.
        </Description>
        <FormDiv>
          {}
          <InputLayout
            width="100%"
            height="48px"
            name="userName"
            placeholder="*이름"
            value={data.userName}
            setValue={(value) => setData((dt) => ({ ...dt, userName: value }))}
            isError={isSubmit && errorValidation('userName').length > 0}
            message={isSubmit && errorValidation('userName')}
          />
          <InputLayout
            width="100%"
            height="48px"
            name="email"
            placeholder="*이메일"
            value={data.email}
            setValue={(value) => setData((dt) => ({ ...dt, email: value }))}
            isError={isSubmit && errorValidation('email').length > 0}
            message={isSubmit && errorValidation('email')}
          />
          <InputLayout
            width="100%"
            height="48px"
            name="phone"
            placeholder="*전화번호"
            value={data.phone}
            setValue={(value) => setData((dt) => ({ ...dt, phone: value }))}
            isError={isSubmit && errorValidation('phone').length > 0}
            message={isSubmit && errorValidation('phone')}
          />
          <InputLayout
            width="100%"
            height="48px"
            name="client"
            placeholder="*업체 명"
            value={data.company}
            setValue={(value) => setData((dt) => ({ ...dt, company: value }))}
            isError={isSubmit && errorValidation('company').length > 0}
            message={isSubmit && errorValidation('company')}
          />
          <TextareaLayout
            wrapperHeight="150px"
            name="content"
            placeholder="*요청사항 상세 내용 입력"
            value={data.contents}
            setValue={(value) => setData((dt) => ({ ...dt, contents: value }))}
            isError={isSubmit && errorValidation('contents').length > 0}
            message={isSubmit && errorValidation('contents')}
          />
        </FormDiv>
        <Button
          onClick={() => {
            setIsSubmit(true);
            const errs = Object.keys(data).map((k) => errorValidation(k));
            if (errs.find((err) => err.length > 0)) {
              setAlert({ text: errs.find((err) => err.length > 0).replaceAll('*', '') });
            } else {
              handleSubmit();
            }
          }}>
          신청하기
        </Button>
      </PartnerRequestDiv>
    </Wrapper>
  );
};

export default PartnerRequest;
