import React, { useLayoutEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';

/**
 * 유효 parameter value 반환 함수
 */
const paramValue = (data) => {
  if (data === undefined || data === null) {
    return null;
  }
  if (typeof data === 'string' || Array.isArray(data)) {
    return data.length <= 0 ? null : data;
  }
  if (Number.isInteger(data)) {
    return data === 0 ? null : data;
  }
  return null;
};

/**
 * 기간 반환 함수
 */
export const getDuration = (values = []) => {
  const dates = values.map((v) => moment(v).format('YYYY-MM-DD'));

  if (dates.length <= 1) {
    return dates;
  }

  const duration = [];
  const dStart = moment(dates[0]);
  const dEnd = moment(dates[1]);
  for (let now = dStart; now <= dEnd; now.add(1, 'd')) {
    duration.push(now.format('YYYY-MM-DD'));
  }
  return duration;
};

/**
 * 공간 검색 params 반환 함수
 */
export const useSearchSpace = () => {
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const [detailsFilter, setDetailsFilter] = useState({
    type: '',
    keyword: '',
    details: [],
    dates: [],
  });
  const [listFilter, setListFilter] = useState({
    category: '',
    sort: '',
    min: 0,
    max: 0,
  });
  const [apiParams, setApiParam] = useState(null);

  const getUrlParams = (paramObj) => {
    return `/space/list?${createSearchParams(paramObj)}`;
  };

  const getApiParams = (searchParam) => {
    const ListParam = {
      category: paramValue(searchParam.get('category')),
      sort: paramValue(searchParam.get('sort')),
      min: searchParam.get('min') === '0' && searchParam.get('max') === '0' ? null : searchParam.get('min'),
      max: searchParam.get('min') === '0' && searchParam.get('max') === '0' ? null : searchParam.get('max'),
    };

    const type = paramValue(searchParam.get('type'));
    const keyword = paramValue(searchParam.get('keyword'));
    const dates = paramValue(searchParam.get('dates'));
    const duration = dates ? getDuration(dates.split('|')) : [];
    const details = searchParam.get('details');
    const detailsArr =
      details && duration.length > 0
        ? details.split('|').map((d, idx) => ({
            checkIn: duration[idx] + ` ${d.substring(0, 2) + ':00'}`,
            checkOut: duration[idx] + ` ${d.substring(2, 4) + ':00'}`,
            people: d.substring(4),
          }))
        : [];
    const detailsParam = {
      type: type,
      ...(type === 'location' && keyword && { location: keyword }),
      ...((!type || type === 'hotel') && { hotel: keyword }),
      details: detailsArr,
    };

    return { ...ListParam, ...detailsParam };
  };

  const getDetailsFilter = (searchParams) => {
    const params = {
      keyword: searchParams.get('keyword') ?? '',
      type: searchParams.get('type') ?? '',
    };
    const dates = searchParams.get('dates') ? searchParams.get('dates').split('|') : [];
    const details = searchParams.get('details') ? searchParams.get('details').split('|') : [];
    const duration = getDuration(dates);

    return {
      ...params,
      dates: dates.map((d) => moment(d).toDate()),
      details: duration.map((dt, idx) =>
        details[idx] && details[idx].length > 4
          ? {
              date: dt,
              checkIn: Number(details[idx].substring(0, 2)),
              checkOut: Number(details[idx].substring(2, 4)),
              people: Number(details[idx].substring(4)),
            }
          : { date: dt, checkIn: 0, checkOut: 23, people: 1 }
      ),
    };
  };

  const getListFilter = (searchParams) => {
    return {
      category: searchParams.get('category') ?? '',
      sort: searchParams.get('sort') ?? '',
      min: searchParams.get('min') ?? 0,
      max: searchParams.get('max') ?? 0,
    };
  };

  const searchByDetailsFilter = (ftObj) => {
    const params = {
      keyword: ftObj.keyword ?? '',
      type: ftObj.type ?? '',
      dates: ftObj.dates.length > 0 ? ftObj.dates.map((dt) => moment(dt).format('YYYYMMDD')).join('|') : '',
      details:
        ftObj.dates.length > 0
          ? ftObj.details
              .map((dt) => `${String(dt.checkIn).padStart(2, 0)}${String(dt.checkOut).padStart(2, 0)}${dt.people + ''}`)
              .join('|')
          : '',
    };
    navigate(getUrlParams(params));
  };

  const searchByListFilter = (ftObj) => {
    const params = {
      keyword: searchParams.get('keyword') ?? '',
      type: searchParams.get('type') ?? '',
      details: searchParams.get('details') ?? '',
      dates: searchParams.get('dates') ?? '',
      category: searchParams.get('category') ?? '',
      sort: searchParams.get('sort') ?? '',
      min: searchParams.get('min') ?? 0,
      max: searchParams.get('max') ?? 0,
      ...ftObj,
    };
    navigate(getUrlParams(params));
  };

  useLayoutEffect(() => {
    setDetailsFilter(getDetailsFilter(searchParams));
    setListFilter(getListFilter(searchParams));
    setApiParam(getApiParams(searchParams));
  }, [searchParams]);

  return {
    detailsFilter: detailsFilter,
    listFilter: listFilter,
    searchByDetailsFilter: searchByDetailsFilter,
    searchByListFilter: searchByListFilter,
    apiParams: apiParams,
  };
};
