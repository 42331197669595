import React, { useLayoutEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { S } from '../../../layouts/Modal';
import { getAuthPass } from '../../../service/client/user';
import { customAlert } from '../../../store/modal';

/**
 * PASS 인증 컴포넌트
 */
export default function PassAuth({ handleToken, handlePopupClose, handleFail, onAuth }) {
  const [popup, setPopup] = useState(null);
  const setAlert = useSetRecoilState(customAlert);

  const initPassAuth = async () => {
    const data = await getPassData();

    if (data) {
      handleToken(data.mdl_tkn);
      openPassPopup(data);
    } else {
      handleToken(null);
    }
  };

  // pass data request handler
  const getPassData = async () => {
    const res = await getAuthPass();
    if (res) {
      const { url, tc, cp_cd, mdl_tkn } = res.data.data;
      return {
        url: url,
        tc: tc,
        cp_cd: cp_cd,
        mdl_tkn: mdl_tkn,
      };
    } else {
      return null;
    }
  };

  // pass popup handler
  const openPassPopup = (props) => {
    const passPopup = window.open(
      '',
      '알아봐봐 휴대폰 인증',
      'modal,scrollBars=no,resizable=no,toolbar=no,menubar=no,location=no,directories=no,width=400,height=700'
    );

    if (!passPopup) {
      setAlert({
        text: '팝업창이 차단되어 있습니다. \n 팝업창 허용후 이용해 주세요.',
        handleClose: () => {
          handleFail();
        },
      });
    } else {
      passPopup.document.write(`
                 <form name="passForm">
                      <input type="hidden" name="tc" value="" />
                      <input type="hidden" name="cp_cd" value="" />
                      <input type="hidden" name="mdl_tkn" value="" />
                      <input type="hidden" name="target_id" value="" />
                  </form>
             `);
      const form = passPopup.document.passForm;
      form.elements['tc'].value = props.tc;
      form.elements['cp_cd'].value = props.cp_cd;
      form.elements['mdl_tkn'].value = props.mdl_tkn;
      form.action = props.url;
      form.method = 'post';
      form.submit();

      setPopup(passPopup);
    }
  };

  useLayoutEffect(() => {
    let limitInterval;
    let limit = 3000;

    if (!popup) {
      initPassAuth();
    }

    if (popup) {
      const quitInterval = () => {
        clearInterval(limitInterval);
        handlePopupClose();
        return;
      };

      limitInterval = setInterval(function () {
        if (limit <= 0) {
          quitInterval();
          popup.close();
          return;
        }

        if (popup.closed) {
          quitInterval();
          return;
        }

        limit--;
      }, 200);

      if (!onAuth) {
        quitInterval();
        popup.close();
        return;
      }
    }
    return () => {
      if (popup) {
        popup.close();
      }
    };
  }, [popup, onAuth]);

  return <S.Overlay visible />;
}
