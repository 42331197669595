import React, { useRef } from 'react';
import styled from 'styled-components';
import { Badge, BgBadge } from '../Common/Badge';
import * as D from '../Common/Display';
import * as T from '../Common/Text';
import ReviewProfile from './ReviewProfile';
import Slider from 'react-slick';
import { useSetRecoilState } from 'recoil';
import { customAlert, reportModal, reviewModal } from '../../store/modal';
import moment from 'moment';
import { useUser } from '../../store/user';

const S = {
  Card: styled.a`
    width: 100%;
    padding: 24px 20px;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  ReportBtn: styled.button`
    height: 22px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    transition: opacity 0.1s ease-in;

    &:hover {
      opacity: 0.6;
    }
  `,
  Pics: styled.div`
    width: 100%;
    height: 78px;
    ${D.xScrollBoxCss(6, 'gray400')}

    ul {
      display: flex;
      gap: 8px;
      width: max-content;

      li {
        width: 104px;
        height: 70px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
    }
  `,
  SliderWrap: styled.div`
    .slick-slide {
      width: 104px;
      height: 70px;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 8px;

      .img {
        width: 104px;
        height: 74px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  `,
};

/**
 * 리뷰 카드 컴포넌트
 * @param {object} props.review 리뷰 데이터 
 */
export default function ReviewCard({ review, nameTag }) {
  const { loggedIn } = useUser();
  const setReviewModal = useSetRecoilState(reviewModal);
  const setReportModal = useSetRecoilState(reportModal);
  const reportRef = useRef(null);
  const setAlert = useSetRecoilState(customAlert);

  return (
    <S.Card
      onClick={(e) => {
        e.preventDefault();
        if (e.target !== reportRef.current) {
          setReviewModal({ id: 1, contents: { ...review, type: nameTag ? '담당자' : '공간' } });
        }
      }}>
      <D.FlexRows _gap={4} _items="flex-end" _justify="space-between">
        <ReviewProfile
          name={review.nickname}
          date={moment(review.reviewCreateDate).format('YYYY.MM.DD')}
          score={review.star}
          profile={review.profileImage}
        />
        <S.ReportBtn
          ref={reportRef}
          onClick={(e) => {
            e.stopPropagation();
            if (loggedIn) {
              setReportModal({
                id: review.reviewId,
                stdBox: e.currentTarget,
              });
            } else {
              setAlert({ text: '로그인 후 이용 가능한 서비스입니다.' });
            }
          }}>
          신고하기
        </S.ReportBtn>
      </D.FlexRows>
      <D.FlexCols _gap={8} _width="100%">
        {!nameTag && (
          <D.FlexRows _gap={6} _items="center" _width="100%">
            <Badge text={review.categoryName || '공간'} _color="pink600" />
            <T.Text _size="l" _weight="medium">
              {review.name}
            </T.Text>
          </D.FlexRows>
        )}
        {nameTag && review.name && <BgBadge text={review.name + ' 담당자님'} />}
        <T.LinelimitText line={3} _height={72} _size="l" _space="pre-wrap">
          {review.contents}
        </T.LinelimitText>
      </D.FlexCols>
      <S.SliderWrap>
        <Slider swipeToSlide={true} draggable={true} variableWidth infinite>
          {review.image.map((x, index) => (
            <img src={`https://cdn.alabwaba.com${x}`} className="img" key={index} />
          ))}
        </Slider>
      </S.SliderWrap>
    </S.Card>
  );
}
