import React, { useEffect, useRef } from 'react';
import { PinkPinLg } from './icons/Marker';
import * as S from './styles/mapStyle';

/**
 * 상세페이지 지도 컴포넌트
 * @param {[number, number]} props.latlng 위경도 배열 
 */
export default function DetailsMap({ latlng = [37.55, 126.914444] }) {
  const mRef = useRef(null);

  useEffect(() => {
    const { naver } = window;
    let map;
    let marker;
    if (mRef && naver) {
      map = new naver.maps.Map(mRef.current, {
        center: new naver.maps.LatLng(latlng[0], latlng[1]),
        zoom: 13,
        zoomControl: false,
        scaleControl: false,
        scrollWheel: false,
      });
      marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(latlng[0], latlng[1]),
        map: map,
        icon: {
          content: PinkPinLg,
          size: new naver.maps.Size(22, 35),
          anchor: new naver.maps.Point(11, 35),
        },
      });
    }
  }, [mRef]);

  return (
    <S.SectionWrap>
      <S.MapFrame ref={mRef}></S.MapFrame>
    </S.SectionWrap>
  );
}
