import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../Common/Text';
import { SwipeModal } from '../../../layouts/Modal';

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 78px);
  gap: 8px;
  margin: 0 auto;
  width: min-content;

  @media (${(props) => props.theme.media.tabletSm}) {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Time = styled.button`
  padding: 8px;
  width: 100%;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontWeight.medium};

  ${(props) =>
    props.isSelected
      ? `
      background: ${props.theme.colors.purple50};
      border: 2px solid ${props.theme.colors.mainPurple};
      `
      : `
      background: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.gray300};
      `}
  ${(props) =>
    props.disabled &&
    `
    background: ${props.theme.colors.gray100};
    border: none;
    color: ${props.theme.colors.gray300};
    `}
`;

export default function CheckTimeOption({ title, time, handleSubmit, min = null, max = null }) {
  const [selected, setSelected] = useState(time);

  return (
    <SwipeModal
      title={title}
      action="확인"
      handleAction={() => {
        handleSubmit(selected);
      }}
      handleModalClose={() => {
        handleSubmit(time);
      }}>
      <List>
        {new Array(24).fill(0).map((_, index) => {
          return (
            <Time
              key={index}
              _items="center"
              _justify="center"
              isSelected={selected === index}
              onClick={() => setSelected(index)}
              disabled={min ? index <= min : max ? index >= max : false}>
              <span>{`${String(index).padStart(2, 0)}:00`}</span>
              <Text _color="gray900" _weight="medium" _size="l"></Text>
            </Time>
          );
        })}
      </List>
    </SwipeModal>
  );
}
