import React from 'react';
import { Helmet } from 'react-helmet';
import List from '../../../components/Service/Notice/List';

/**
 * 공지사항 목록 페이지
 */
export default function NoticeList() {
  return (
    <div>
      <Helmet>
        <title>알아봐봐 | 공지사항</title>
      </Helmet>
      <List />
    </div>
  );
}
