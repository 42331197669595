import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSiteRules, requestNoticeList, requestNoticeCount, requestOneToOne } from '../../service/client/service';

const SERVICE_KEYS = {
  rules: ['rules'],
  noticeList: ['noticeList'],
  noticeCount: ['noticeCount'],
};

// 약관 요청 쿼리
export const useRulesQuery = () =>
  useQuery(SERVICE_KEYS.rules, async () => {
    return await getSiteRules();
  });

// 공지 목록 쿼리
export const useNoticeQuery = (params) => {
  return useQuery(SERVICE_KEYS.noticeList, async () => {
    const { data } = await requestNoticeList(params);

    return data.data;
  });
};

/**
 * 공지 목록 갯수 쿼리
 * @description 페이지네이션에 사용합니다.
 */
export const useNoticeCountQuery = (params) => {
  return useQuery(SERVICE_KEYS.noticeCount, async () => {
    const { data } = await requestNoticeCount({});

    return data.data.count;
  });
};

// 공지 검색 쿼리
export const useNoticeEditQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params) => {
      return requestNoticeList(params);
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(SERVICE_KEYS.noticeList, () => {
        return data.data.data;
      });
    },
  });
};

// 공지 검색 목록 갯수 편집 쿼리
export const useNoticeCountEditQuery = () => {
  const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async(params)=> { return requestNoticeCount(params);},
      onSuccess: (data, variables) => {
        queryClient.setQueryData(SERVICE_KEYS.noticeCount, ()=> {
          return data.data.data.count;
        })
      }
    }
  );
}

// 일대일 문의 등록 쿼리
export const useAddInquiryData = () => {
  return useMutation(requestOneToOne);
};
