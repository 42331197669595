import { client } from '.';
const CATEGORY_URL = {
  space: '/api/category',
  meal: '/api/meal',
  room: '/api/arrangement',
  roomInfo: '/api/room',
};
// 공간 카테고리 
export const getSpaceCatgory = () => client.get(CATEGORY_URL.space);

// 식사 패키지
export const getMealCategory = () => client.get(CATEGORY_URL.meal);

// arrange ex. 돌잔치... (테이블 배치)
export const getRoomCategory = () => client.get(CATEGORY_URL.room);

// 객실 정보
export const getRoomInfoCategory = () => client.get(CATEGORY_URL.roomInfo);
