import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  getAliemHasNew,
  getAliemList,
  getUserInfo,
  updateAliemRead,
  updateUserInfo,
  updateUserProfile,
} from '../../service/client/user';

export const USER_KEYS = {
  all: ['user'],
  info: ['user_info'],
  profile: ['user_profile'],
  aliemList: ['user_aliem_list'],
  aliemRead: ['user_aliem_read'],
  aliemNew: ['user_aliem_new'],
};

//  사용자 정보 요청 쿼리
export const useUserInfoQuery = (token) =>
  useQuery({
    queryKey: USER_KEYS.info,
    queryFn: async () => {
      const response = await getUserInfo(token);
      if (response && response.status === 200) {
        return response.data.data;
      }
      return null;
    },
  });

// 사용자 정보 업데이트 쿼리
export const useUpdateUserQuery = () =>
  useMutation({
    mutationFn: async (params) => {
      const res = await updateUserInfo(params.info, params.token);
      // => onSuccess
      if (res && res.data.status === 200) {
        params.callback();
      }
    },
  });

// 사용자 프로필 업데이트 쿼리
export const useUpdateProfileQuery = () =>
  useMutation({
    mutationFn: async (params) => {
      const res = await updateUserProfile(params.profile, params.token);
      return res;
    },
    onSuccess: (d, v, c) => {
      const profileUrl = d.data.data.profileImage;
      v.callback(profileUrl);
    },
  });

// 알림 목록 쿼리
export const useInfiniteAliemListQuery = () =>
  useInfiniteQuery({
    queryKey: USER_KEYS.aliemList,
    queryFn: async ({ pageParam = 1 }) => {
      return await getAliemList({ page: pageParam });
    },
    getNextPageParam: (lastPage, allPages) => {
      const hasNext = allPages.length * 10 < lastPage.data.data.count;
      return hasNext ? allPages.length + 1 : undefined;
    },
    staleTime: 1 * (60 * 1000),
    cacheTime: 2 * (60 * 1000),
    enabled: false,
  });

// 알림 읽음 처리 쿼리
export const useUpdateAliemReadQuery = (params) =>
  useMutation({
    mutationFn: async () => {
      return await updateAliemRead(params.params);
    },
  });

// 새 알림 여부 요청 쿼리
export const useNewAliemQuery = (tocken) =>
  useQuery({
    queryKey: USER_KEYS.aliemNew,
    queryFn: async () => await getAliemHasNew(tocken),
    select: (data) => data.data.data.new,
    staleTime: 1000 * 60 * 1,
    cacheTime: 1000 * 60 * 2,
  });
