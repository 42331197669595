const Theme = {
  font: 'Pretendard',
  colors: {
    mainPurple: '#8757AD',
    error: '#EF5353',
    blue: '#3565E1',
    orange: '#F8C180',
    sky: '#F29A48',
    purple900: '#543185',
    purple800: '#4A2B7C',
    purple700: '#543185',
    purple600: '#5F398E',
    purple500: '#663E94',
    purple400: '#9276B3',
    purple300: '#9F76B3',
    purple200: '#C09DC9',
    purple100: '#CFC4DE',
    purple50: '#F7F1FF',
    gray900: '#212121',
    gray800: '#424242',
    gray700: '#616161',
    gray600: '#757575',
    gray500: '#9E9E9E',
    gray400: '#BDBDBD',
    gray300: '#E0E0E0',
    gray200: '#EEEEEE',
    gray100: '#F5F5F5',
    gray50: '#FAFAFA',
    pink600: '#DC2ECA',
    white: '#FFFFFF',

    // 이름 확인
    greennn600: '#04B98E', // << Primary600
    purple1: '#87529D', // << 이름없음
    purple2: '#551B86', // << 이름없음
    purple3: '#5F268A', // << 이름없음
  },
  fontWeight: {
    bolder: 700,
    bold: 600,
    medium: 500,
    normal: 400,
    light: 300,
  },
  fontSize: {
    xxxxl: '24px',
    xxxl: '22px',
    xxl: '20px',
    xl: '18px',
    l: '16px',
    base: '15px',
    s: '14px',
    xs: '13px',
    xxs: '12px',
    xxxs: '10px',
  },
  lineHeight: {
    xxl: 1.8,
    xl: 1.7,
    l: 1.6,
    base: 1.5,
    s: 1.4,
    xs: 1.3,
    xxs: 1.2,
  },
  media: {
    PC: 'max-width: 1180px;',
    laptop: 'max-width: 1279px',
    tablet: 'max-width: 1023px',
    tabletM: 'max-width: 901px',
    tabletSm: 'max-width: 767px',
    mobile: 'max-width: 390px',
    //mobile: 'max-width: 479px',
  },
  textStyle: {
    mainTitle: "font-family: 'Pretendard';font-style: normal;font-weight: 500;font-size: 24px;line-height: 29px;",
    subTitle:
      "font-family: 'Pretendard';font-style: normal;font-weight: 500;font-size: 22px;line-height: 26px;color: #000000;",
    // 본문 텍스트 lg
    contentTextLg: "font-family: 'Pretendard';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;",
    // 본문 텍스트 sm
    contentTextSm: "font-family: 'Pretendard';font-style: normal;font-weight: 400;font-size: 14px;line-height: 22px;",
  },
  effectStyle: {
    boxShadow: 'box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);',
    selected: 'box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.25);',
    gnbShadow: 'box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);',
    tabShadow: 'box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);',
    moPopup: 'box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.15);',
  },
};

export default Theme;
