import { authConfig, client } from '.';
const SPACE_URL = {
  search: '',
  details: '',
  request: '',
  newPwd: '',
  filteredList: '/api/space/list',
  hotelList: '/api/hotel/space',
  mapList: '/api/space/map',
  reviewList: '/api/review/hotel',
  reviewReport: '/api/review/report',
  hotelDetails: '/api/hotel/details',
  near: '/api/space/near',
  boookmark: '/api/bookmark/action',
  spaceDetails: '/api/space/details',
};

// 호텔 목록 요청
export const requestHotelList = (params) => client.post(SPACE_URL.hotelList, params);

// 공간 목록 요청
export const requestSpaceList = (params) => client.post(SPACE_URL.filteredList, params);

// 지도 목록 요청
export const getMapList = (params) => client.post(SPACE_URL.mapList, params);

// 리뷰 목록 요청
export const requestReviewList = (params) => client.post(SPACE_URL.reviewList, params);

// 리뷰 신고 요청
export const requestReviewReport = (params) =>
  client.post(SPACE_URL.reviewReport, params.params, authConfig(params.token));

// 호텔 상세 요청
export const requestHotelDetails = (params) => client.post(SPACE_URL.hotelDetails, params);

// 근처 추천 공간 요청
export const requestNear = (params) => client.post(SPACE_URL.near, params);

// 북마크 추가 요청
export const requestAddBookmark = (params, token) =>
  client.post(SPACE_URL.boookmark, { mode: 'add', ...params }, authConfig(token));

// 북마크 해제 요청
export const requestDelBookmark = (params, token) =>
  client.post(SPACE_URL.boookmark, { mode: 'del', ...params }, authConfig(token));

// 공간 상세 요청
export const getSpaceDetails = (id) => client.post(SPACE_URL.spaceDetails, { id });
