import { QueryCache, QueryClient } from '@tanstack/react-query';

/*
  React-Query Client 설정
    queryClient
    staleTime: 5mins
    cacheTime: 10mins
*/
export const queryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        console.log(error);
      },
      onSuccess: (data) => {
        // console.log(data);
      },
    }),
  });

// 로딩 시간 설정
export const waitLoading = async (sec = 800) => {
  await new Promise((resolve) => setTimeout(resolve, sec));
};
