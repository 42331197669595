import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import logoIcon from "../../../assets/icons/logo/ico-logo_136.svg";
import chat from "../../../assets/icons/menu/ico-menu-chat.svg";
import hand from "../../../assets/icons/menu/ico-menu-hand.svg";
import kakao from "../../../assets/icons/sns/Kakao.svg";
import naver from "../../../assets/icons/sns/Naver.svg";
import checkedIcon from "../../../assets/icons/utils/ico-checked_purple.svg";
import { SITE, chatRoom } from "../../../constants/site";
import { NormalModal } from "../../../layouts/Modal";
import { getNewChatId } from "../../../service/client/chat";
import { requestLogin } from "../../../service/client/user";
import { customAlert, findIdModalState, joinModalState, loginModalState } from "../../../store/modal";
import { loginId as loginIdState, useUser } from "../../../store/user";
import { RoundBtn } from "../../Common/Button";
import * as D from "../../Common/Display";
import { InputField } from "../../Common/InputField";
import { Text } from "../../Common/Text";

const SaveId = styled.div`
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  label {
    position: relative;
    height: 100%;
    padding-left: 28px;
    font-size: ${(props) => props.theme.fontSize.s};
    color: ${(props) => props.theme.colors.gray800};

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 10px;
      border: 1px solid ${(props) => props.theme.colors.gray300};
    }
  }

  input:checked + label::before {
    background: url(${checkedIcon}) center / 10.5px auto no-repeat white;
  }
`;
const SubTitle = styled.div`
  z-index: 10;
  position: relative;
  width: 100%;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.white};
  overflow-x: visible;
  text-align: center;

  span {
    z-index: 12;
    position: relative;
    display: inline-block;
    padding: 0 16px;
    background-color: #fff;
    font-size: ${(props) => props.theme.fontSize.xs};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.gray600};
  }

  &::before {
    content: "";
    z-index: 11;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.gray200};
    transform: translateY(-1px);
  }
`;
const IconDiv = styled.div`
  width: 120px;
  height: 52px;
  display: flex;
  gap: 16px;
`;

const JoinDiv = styled.div`
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: ${(props) => props.theme.fontSize.xs};

  .text {
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.gray900};
  }
  .join-link {
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.colors.mainPurple};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Button = styled.button`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.purple50};

  img {
    position: absolute;
    left: 18px;
    width: 24px;
    height: 24px;
  }
`;
const ButtonName = styled.span`
  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.purple900};
`;

/**
 * 로그인 컴포넌트
 */
const Login = ({ redirectPath }) => {
  const navigate = useNavigate();
  // state
  const resetLogin = useResetRecoilState(loginModalState);
  const setIsFindId = useSetRecoilState(findIdModalState);
  const setIsJoin = useSetRecoilState(joinModalState);
  const { login } = useUser();
  const [loginId, setLoginId] = useRecoilState(loginIdState);
  const resetLoginId = useResetRecoilState(loginIdState);
  const setAlert = useSetRecoilState(customAlert);
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      uid: loginId.email,
      upwd: "",
    },
  });

  // login request handler
  const handleFormSubmit = async (data) => {
    const formData = {
      email: data.uid,
      password: data.upwd,
    };
    const response = await requestLogin(formData);
    if (response && response.data.status === 200) {
      const { accessToken } = response.data.data;
      login({
        token: accessToken,
      });
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      }
      if (loginId.save) {
        setLoginId({ save: true, email: data.uid });
      }
      resetLogin();
    } else {
      setAlert({ text: "이메일 또는 비밀번호를 확인해 주세요." });
    }
  };

  // ID save handler
  const handleSaveId = (e) => {
    const checked = e.target.checked;
    if (!checked) {
      resetLoginId();
      return;
    }

    if (checked) {
      setLoginId({ save: true, email: getValues("uid") });
    }
  };

  return (
    <NormalModal
      _size="m3"
      _height="734px"
      handleModalClose={() => {
        resetLogin();
      }}
      overlayClick={false}>
      <D.Container _width="100%" _padding="0 20px" _media="tabletSm" _m_padding="0">
        <D.FlexCols _width="100%" _gap={50} _items="center">
          <img src={logoIcon} />
          <D.FlexCols _width="100%" _gap={60}>
            <D.FlexCols _width="100%" _gap={16} as="form" onSubmit={handleSubmit(handleFormSubmit)}>
              <D.FlexCols _width="100%" _gap={8} _items="flex-start">
                {[
                  {
                    name: "uid",
                    placeholder: "이메일 입력",
                    registerProps: {
                      required: { value: true, message: "이메일을 입력해 주세요." },
                    },
                  },
                  {
                    name: "upwd",
                    placeholder: "비밀번호 입력",
                    type: "password",
                    registerProps: {
                      required: { value: true, message: "비밀번호를 입력해 주세요." },
                    },
                  },
                ].map((item, idx) => (
                  <InputField
                    key={idx}
                    name={item.name}
                    type={item.type}
                    placeholder={item.placeholder}
                    register={register}
                    registerProps={item.registerProps}
                    error={errors[item.name]}
                    errorMsg={errors[item.name] ? errors[item.name].message : ""}
                  />
                ))}
              </D.FlexCols>
              <D.FlexCols _width="100%" _gap={12}>
                <RoundBtn text="로그인" type="submit" _primary _capsule />
                <D.FlexRows _width="100%" _justify="space-between">
                  <SaveId>
                    <input type="checkbox" id="save-id" checked={loginId.save} onChange={handleSaveId} />
                    <label htmlFor="save-id">아이디 저장</label>
                  </SaveId>
                  <Text
                    as="button"
                    _size="s"
                    _color="gray800"
                    onClick={() => {
                      setIsFindId(true);
                      resetLogin();
                    }}>
                    아이디/비밀번호 찾기
                  </Text>
                </D.FlexRows>
              </D.FlexCols>
            </D.FlexCols>
            <D.FlexCols _width="100%" _gap={40}>
              <D.FlexCols _width="100%" _gap={24} _items="center">
                <SubTitle>
                  <span>SNS 간편 로그인</span>
                </SubTitle>
                <IconDiv>
                  <a href="https://alabwaba.com/oauth2/authorization/kakao">
                    <img src={kakao} alt="kakao-img" />
                  </a>
                  <a href="https://alabwaba.com/oauth2/authorization/naver">
                    <img src={naver} alt="naver-img" />
                  </a>
                </IconDiv>
                <JoinDiv>
                  <span className="text">아직 알아봐봐 계정이 없으신가요?</span>
                  <span
                    className="join-link"
                    onClick={() => {
                      setIsJoin({ mode: true });
                      resetLogin();
                    }}>
                    회원가입
                  </span>
                </JoinDiv>
              </D.FlexCols>
              <D.FlexCols _width="100%" _gap={8}>
                <Button
                  onClick={() => {
                    resetLogin();
                    navigate(SITE.partnerRequest);
                  }}>
                  <img src={hand} alt="hand-img" />
                  <ButtonName>공간 파트너 신청</ButtonName>
                </Button>
                <Button
                  onClick={async () => {
                    resetLogin();
                    const res = await getNewChatId(0);
                    const { room } = res.data.data;
                    window.open(`${chatRoom(room)}?s=0`, "_blank");
                  }}>
                  <img src={chat} alt="chat-img" />
                  <ButtonName>문의하기</ButtonName>
                </Button>
              </D.FlexCols>
            </D.FlexCols>
          </D.FlexCols>
        </D.FlexCols>
      </D.Container>
    </NormalModal>
  );
};

export default Login;
