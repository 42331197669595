import { authConfig, client } from '.';
const HOME_URL = {
  banner: '/api/banner',
  hotel: '/api/hotel/new',
  spaceHot: '/api/space/hot',
  reviewMain: '/api/review/main',
};

// 배너 요청
export const getBanner = () => client.get(HOME_URL.banner);

// 호텔 목록 요청
export const getHotel = (token) => client.get(HOME_URL.hotel, authConfig(token));

// 인기 공간 요청
export const getSpaceHot = (token) => client.get(HOME_URL.spaceHot, authConfig(token));

// 리뷰 요청
export const getReviewMain = (token) => client.get(HOME_URL.reviewMain, authConfig(token));
