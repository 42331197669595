import React, { useEffect, useRef, useState } from 'react';

/**
 * 반응형 이미지 구현 훅
 * @param {string} url 이미지 소스 
 */
export const useResponsiveImg = (url) => {
  const boxRef = useRef();
  const [imgRef, setImgRef] = useState(null);
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (!boxRef.current || !boxRef.current.querySelector('img')) return;
    setImgRef(boxRef.current.querySelector('img'));
  }, [boxRef.current]);

  useEffect(() => {
    let handler, resizeFn, to;
    if (imgRef && boxRef.current && !handler) {
      const img = new Image();
      handler = () => {
        const boxRate = boxRef.current.getBoundingClientRect().width / boxRef.current.getBoundingClientRect().height;
        const imgRate = img.width / img.height;
        imgRef.style.width = boxRate > imgRate ? '100%' : 'auto';
        imgRef.style.height = boxRate > imgRate ? 'auto' : '100%';
      };
      img.onload = handler;
      img.src = url;
      setSrc(url);
      resizeFn = () => {
        if (to) clearTimeout(to);
        to = setTimeout(() => {
          handler();
        }, 200);
      };
      window.addEventListener('resize', resizeFn);
    }

    return () => {
      window.removeEventListener('resize', resizeFn);
    };
  }, [imgRef, boxRef]);

  return [boxRef, src];
};
