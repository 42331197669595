import { useNavigate } from "react-router-dom";
import { atom, selector, useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { STORE_KEYS } from ".";
import { getUserInfo } from "../service/client/user";
import { customAlert } from "./modal";

// storage - effect
const STORAGE_KEYS = {
  login: "ALBB_LOGIN",
  loginId: "ALBB_ID",
  location: "ALBB_LOC",
};
const localStorageEffect =
  (key, limitDate) =>
  ({ setSelf, onSet, resetSelf }) => {
    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
        return;
      }
      const today = new Date();
      const expireDate = new Date(today.setDate(today.getDate() + limitDate));
      localStorage.setItem(key, JSON.stringify({ ...newValue, expireDate: expireDate }));
    });

    const loginData = localStorage.getItem(key);
    if (loginData) {
      const data = JSON.parse(loginData);
      const expireDate = new Date(data.expireDate);

      if (new Date() > expireDate) {
        resetSelf();
      } else {
        setSelf(data);
      }
    } else {
      resetSelf();
    }
  };

export const loginUser = atom({
  key: STORE_KEYS.user,
  default: null,
  effects: [localStorageEffect(STORAGE_KEYS.login, 14)],
});

export const isLoggedIn = selector({
  key: `${STORE_KEYS.user}_loggedIn`,
  get: ({ get }) => get(loginUser) !== null,
});

export const loginId = atom({
  key: STORE_KEYS.userId,
  default: { save: false, email: "" },
  effects: [localStorageEffect(STORAGE_KEYS.loginId, 90)],
});

export const useUser = () => {
  const [user, setUser] = useRecoilState(loginUser);
  const resetUser = useResetRecoilState(loginUser);
  const setAlert = useSetRecoilState(customAlert);
  const navigate = useNavigate();

  const setLogin = async (newValue) => {
    const res = await getUserInfo(newValue.token);
    if (res && res.data.status === 200) {
      const { username, nickname, profileImage, provider } = res.data.data;
      setUser({
        email: username,
        token: newValue.token,
        nickname: nickname,
        profileImage: profileImage,
        snsLogin: provider && provider.length > 0,
      });
    } else {
      setAlert({ text: "로그인 오류 - 회원정보 요청 실패" });
      resetUser();
    }
  };

  const setLogout = () => {
    resetUser();
  };

  const setModify = (newValue) => {
    setUser({
      ...user,
      ...newValue,
    });
  };

  const setWithdraw = () => {
    setAlert({
      text: "회원탈퇴가 정상적으로 처리되었습니다.",
      handleClose: () => {
        resetUser();
        navigate("/");
      },
    });
  };

  return {
    loggedIn: user !== null,
    email: user?.email ?? null,
    nickname: user?.nickname ?? null,
    token: user?.token ?? null,
    profile: user?.profileImage ?? null,
    snsLogin: user?.snsLogin ?? false,
    login: setLogin,
    logout: setLogout,
    modify: setModify,
    withdraw: setWithdraw,
  };
};

const getUserPosition = () => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject));
export const userLocation = atom({
  key: STORE_KEYS.userLoc,
  default: {
    agree: false,
    latlng: null,
  },
  effects: [localStorageEffect(STORAGE_KEYS.userLoc, 30)],
});
export const useUserLocation = () => {
  const [loc, setLoc] = useRecoilState(userLocation);
  const setAlert = useSetRecoilState(customAlert);

  const intitLocation = async () => {
    let latlng = null;
    if ("geolocation" in navigator) {
      const res = await getUserPosition();
      if (res) {
        latlng = [res.coords.latitude, res.coords.longitude];
      } else {
        setAlert({ text: "사용자 위치를 가져오지 못했습니다." });
      }
    }
    setLoc({
      agree: latlng !== null,
      latlng: latlng,
    });
  };

  if (!loc.agree) {
    intitLocation();
  }

  return loc;
};
