import React from 'react';
import { Helmet } from 'react-helmet';
import Details from '../../../components/Service/Notice/Details';

/**
 * 공지사항 상세 페이지
 */
export default function NoticeDetails() {
  return (
    <React.Fragment>
      <Helmet>
        <title>알아봐봐 | 공지사항</title>
      </Helmet>
      <Details />
    </React.Fragment>
  );
}
