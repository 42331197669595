import moment from 'moment/moment';
import { atom, selector } from 'recoil';
import { BOOK_KEYS } from '.';
import { bookPeriodState } from './search';

export const availableDateState = atom({
  key: 'avaiable-date_state',
  default: [],
});
export const onModalHiddenState = atom({
  key: BOOK_KEYS.onModalHiddenState,
  default: false,
});

// page/space/SpaceBook의 selectBox 단계를 나타내는 state
// 0: 날짜 선택 단계, 1: 날짜별 옵션 선택 단계, 2: 예약자 정보 입력..
export const onModalState = atom({
  key: BOOK_KEYS.onModalState,
  default: 0,
});

// 예약 규정 안내 modal 확인 여부 state
// 규정 안내 modal 확인을 누르면 true
export const onSpaceBookQueryState = atom({
  key: BOOK_KEYS.onSpaceBookQuery,
  default: false,
});

// 예약 시 전체 비용 계산 state
export const spaceBookTotalPriceState = atom({
  key: BOOK_KEYS.spaceBookTotalPrice,
  default: 0,
});

// 공간 예약 시 날짜 별 비용
// ex. 10/11, 10/12 예약 > [1000,1000]
export const spaceBookPriceArrState = atom({
  key: BOOK_KEYS.spaceBookPriceArr,
  default: [],
});

// 설정한 예약 날짜
export const stringPeriodState = selector({
  key: BOOK_KEYS.dateToString,
  get: ({ get }) => {
    const period = get(bookPeriodState);

    let resultArr = [];
    const daytime = 86400000;

    const howlong = Math.ceil((period[period.length - 1] - period[0]) / daytime);

    for (let i = 0; i <= howlong; i++) {
      resultArr.push(moment(period[0]).add(i, 'days').format('yyyy. MM. DD'));
    }

    return resultArr;
  },
});

export const spaceBookPeriodState = selector({
  key: BOOK_KEYS.spaceBookPeriod,
  get: ({ get }) => {
    const period = get(stringPeriodState).map((item) => item.split('. ').join('-'));

    return period?.length > 0 && period?.length === 1
      ? {
          startDate: period[0],
          endDate: period[0],
        }
      : {
          startDate: period[0],
          endDate: period[period.length - 1],
        };
  },
});

// 공간 예약자 정보
export const spaceBookPersonInfoState = atom({
  key: BOOK_KEYS.spaceBookPersonInfo,
  default: {
    reservationName: '',
    reservationPhone: '',
    reservationEmail: '',
  },
});

// 공간 예약 시 설정한 전체 날짜별 옵션 설정 저장
export const spaceBookDetailState = atom({
  key: BOOK_KEYS.spaceBookDetails,
  default: [],
});

// 예약 요청 formData state
export const reqSpaceBookState = atom({
  key: BOOK_KEYS.requestSpaceBook,
  default: {
    spaceId: 0,
    reservationName: '',
    reservationPhone: '',
    reservationEmail: '',
    startDate: '',
    endDate: '',
    price: 0,
    details: [],
  },
});
