import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import filterImg from "../../../assets/icons/utils/ico-filter_controller.svg";
import listImg from "../../../assets/icons/utils/ico-mode_list.svg";
import mapImg from "../../../assets/icons/utils/ico-mode_map.svg";
import SortingFilter from "../../../components/Filter/SortingFilter";
import SearchMap from "../../../components/Map/SearchMap";
import CategoryList from "../../../components/Space/CategoryList";
import SpaceSearchList from "../../../components/Space/SpaceSearchList";
import { useSearchSpace } from "../../../hooks/utils/filter";
import { footerUi, headerUi } from "../../../store/ui";

export const S = {
  Wrapper: styled.div`
    z-index: 21;
    position: relative;
    width: 100%;
    padding: 0 0 160px;
    margin: 0 auto;
  `,
  Category: styled.div`
    z-index: 25;
    position: sticky;
    top: 80px;
    overflow: visible;
    background-color: #fff;
    padding-top: 16px;
    ${(props) => props.sticky && props.theme.effectStyle.gnbShadow};

    .inner {
      position: relative;
      max-width: 1220px;
      padding: 0 20px;
      margin: 0 auto;
    }

    @media (${({ theme }) => theme.media.tabletSm}) {
      top: 132px;
    }
  `,
  Filter: styled.div`
    z-index: 25;
    position: absolute;
    right: 20px;
    bottom: -52px;
    /* margin-right: ${(props) => (props.isMap ? "24px" : 0)}; */
    padding: 8px 12px;
    width: 77px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray500};
    border-radius: 99px;
    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: ${(props) => props.theme.fontSize.s};
      font-weight: ${(props) => props.theme.fontWeight.medium};
      color: ${(props) => props.theme.colors.gray900};
    }
  `,
  Contents: styled.div`
    z-index: 21;
    position: relative;
    width: 100%;
    margin: 90px auto 0;
    max-width: 1220px;
    padding: 0 20px;
  `,
  Mode: styled.button`
    z-index: 22;
    position: fixed;
    left: 50%;
    bottom: ${(props) => (props.up ? 180 : 40)}px;
    transform: translate(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 120px;
    height: 48px;
    background: ${(props) => props.theme.colors.mainPurple};
    color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.45);
    border-radius: 50px;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: ${(props) => props.theme.fontSize.l};
      font-weight: ${(props) => props.theme.fontWeight.bolder};
      color: ${(props) => props.theme.colors.white};
    }
  `,
};

const ListWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

/**
 * 공간 목록 페이지
 */
const SpaceList = () => {
  const [isMap, setIsMap] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const location = useLocation();
  // layout
  // const [stickyRef, stickyMode] = useSticky();
  const setFooterUi = useSetRecoilState(footerUi);
  const setHeaderUi = useSetRecoilState(headerUi);

  const [moreUp, setMoreUp] = useState(false);
  const { apiParams: filter, listFilter, searchByListFilter } = useSearchSpace();

  // 푸터 레이아웃
  useEffect(() => {
    setFooterUi({ display: false });
    setHeaderUi((h) => ({ ...h, mobileSearchBar: true }));
    return () => {
      setFooterUi({ display: true });
    };
  }, [location]);

  return (
    <S.Wrapper>
      <Helmet>
        <title>알아봐봐 | 공간찾기</title>
      </Helmet>
      {/* (flow) category => change params */}
      {/* <S.Category ref={stickyRef} sticky={stickyMode}> */}
      <S.Category>
        <div className="inner">
          <CategoryList
            handleCategoryChange={(value) => {
              searchByListFilter({ category: value });
            }}
            category={listFilter.category}
          />
          <S.Filter isMap={isMap} as="button" onClick={() => setFilterOn((prev) => !prev)}>
            <img src={filterImg} alt="filter-img" />
            <span>필터</span>
          </S.Filter>
          {filterOn && (
            <SortingFilter
              handleClose={() => setFilterOn(false)}
              handleSubmit={(value) => {
                searchByListFilter(value);
              }}
              defaultValue={listFilter}
            />
          )}
        </div>
      </S.Category>
      {filter !== null && (
        <React.Fragment>
          {isMap ? (
            <SearchMap filter={filter} setMobileIsOn={(v) => setMoreUp(v)} />
          ) : (
            <S.Contents>
              <SpaceSearchList filter={filter} />
            </S.Contents>
          )}
        </React.Fragment>
      )}
      <S.Mode
        as="button"
        onClick={() => {
          if (isMap && moreUp) setMoreUp(false);
          setIsMap((prev) => !prev);
        }}
        up={moreUp}>
        <img src={isMap ? listImg : mapImg} alt="mode-img" />
        <span>{isMap ? "목록" : "지도"}</span>
      </S.Mode>
    </S.Wrapper>
  );
};

export default SpaceList;
