import React, { useState } from 'react';

import plus from '../../../../assets/icons/utils/ico-plus.svg';
import minus from '../../../../assets/icons/utils/ico-minus.svg';

import styled from 'styled-components';
import { Flex, FlexRows } from '../../../../components/Common/Display';
import { Text } from '../../../../components/Common/Text';

const InputDiv = styled(Flex)`
  width: ${(props) => props._width ?? '150px'};
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.colors.gray300};

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 8px 16px;
    height: 40px;
  }
`;
const ImgDiv = styled.button`
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors[props._borderColor] ?? props.theme.colors.gray300};
  border-radius: 50px;
  img {
    width: 12px;
    height: 12px;
  }
`;

const Number = styled(Text)`
  display: inline-block;
  width: 45px;
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};
`;
const Button = styled.button`
  overflow: hidden;
  height: 100%;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};

  & > form {
    width: 45px;
    & > input {
      width: 100%;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: ${(props) => props.theme.fontSize.normal};
      color: ${(props) => props.theme.colors.gray900};
    }
  }
`;

// component: 시간 선택 모달창(체크인, 체크아웃)
// /space/book/:id 페이지에 노출, selectBox 컴포넌트 중 HideBox에서 호출
function MealBox({ objKey, d_index, m_index, item, setTempData }) {
  // 수량을 집접 입력(true|false)
  const [edit, setEdit] = useState(false);

  // 모든 날짜 별 옵션 데이터에서 해당 날짜의 패키지만 수량 변경
  const setAmount = (obj) =>
    setTempData((dt) =>
      dt.map((dt, _idx) =>
        _idx === d_index
          ? {
              ...dt,
              [objKey]: dt[objKey].map((dt, _idx) => (_idx === m_index ? obj : dt)),
            }
          : dt
      )
    );

  // 값을 직접 입력하는 input handler
  const handleChange = (e) => {
    const {
      currentTarget: { value },
    } = e;

    if (!isNaN(value)) {
      const obj = { ...item };
      obj.amount = +value;

      setAmount(obj);
    }
  };

  // 수량 직접 입력 종료(input>span 변경)
  const handleSubmit = (e) => {
    e.preventDefault();
    setEdit(false);
  };

  return (
    <FlexRows key={m_index} _gap={8} _width="100%">
      <Text _size="l" _weight="medium" _color="gray900">
        {item?.mealName || item?.hotelRoomName}
      </Text>
      <InputDiv _width="100%" _justify="space-between">
        {/* 수량 감소 */}
        <ImgDiv
          onClick={() => {
            const obj = { ...item };

            if (obj.amount === 0) {
              obj.amount = 0;
              return setAmount(obj);
            }

            obj.amount = obj.amount - 1;
            return setAmount(obj);
          }}
        >
          <img src={minus} alt="minus-img" />
        </ImgDiv>

        {/* edit 값에 따라 input or span으로 element 변경 */}
        <Button onClick={() => setEdit(true)}>
          {!edit ? (
            <Number>{String(item.amount).padStart(2, '0')}</Number>
          ) : (
            <form onSubmit={handleSubmit} onBlur={handleSubmit}>
              <input
                required
                autoFocus
                type="text"
                name="amount"
                value={item.amount}
                onChange={handleChange}
              />
            </form>
          )}
        </Button>

        {/* 수량 증가 */}
        <ImgDiv
          onClick={() => {
            const obj = { ...item };

            obj.amount = obj.amount + 1;
            return setAmount(obj);
          }}
        >
          <img src={plus} alt="plus-img" />
        </ImgDiv>
      </InputDiv>
    </FlexRows>
  );
}

export default MealBox;
