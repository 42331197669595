import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as D from '../../Common/Display';
import { Line } from '../../Common/Line';
import Pagination from '../../Common/Pagination';
import { Text } from '../../Common/Text';
import searchIcon from '../../../assets/icons/utils/ico-search.svg';
import { Link } from 'react-router-dom';
import {
  useNoticeCountQuery,
  useNoticeQuery,
  useNoticeEditQuery,
  useNoticeCountEditQuery,
} from '../../../hooks/queries/service';
import { ErrorStatus } from '../../Common/Status';

const S = {
  Search: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    height: 40px;
    border-radius: 20px;
    padding: 10px 16px;
    background-color: ${(props) => props.theme.colors.gray100};
    overflow: hidden;

    input {
      width: calc(100% - 36px);
      height: 100%;
      font-size: ${(props) => props.theme.fontSize.s}px;
      background-color: transparent;
      &::place-holder {
        color: ${(props) => props.theme.colors.gray400};
      }
    }

    button {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      width: calc(100% - 150px);
      max-width: 400px;
    }
  `,
  Item: styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    padding: 16px 8px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
  `,
  ItemTitle: styled.span`
    width: calc(100% - 88px);
    height: 14px;
    line-height: 14px;
    font-size: ${(props) => props.theme.fontSize.s}px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  ItemDate: styled.span`
    width: 64px;
    color: ${(props) => props.theme.colors.gray500};
    font-size: ${(props) => props.theme.fontSize.xxs}px;
  `,
};

/**
 * 공지사항 목록 컴포넌트
 */
export default function List() {
  const [search, setSearch] = useState();
  const { mutate: requestNotice } = useNoticeEditQuery({ page: 1 });
  const { data, isLoading } = useNoticeQuery({ page: 1 });
  const { data: count } = useNoticeCountQuery();
  const { mutate: requestNoticeCount } = useNoticeCountEditQuery({});

  const [page, setPage] = useState(1);

  useEffect(() => {
    requestNotice({ page: page });
  }, [page]);

  return isLoading ? (
    '' // undefined 아닐시 동작
  ) : (
    <D.Container _mx_width="1120px" _padding="80px 20px 120px">
      <D.FlexCols as="section" _width="100%" _gap={60} _justify="center" _items="center">
        <D.FlexCols _width="100%">
          <D.FlexRows _width="100%" _justify="space-between" _items="center">
            <Text as="h2" _size="xxxxl" _weight="bold" _media="tablet" _m_size="xxl">
              공지사항
            </Text>
            <S.Search
              as="form"
              onSubmit={(e) => {
                e.preventDefault();
                requestNotice({ page: 1, query: search });
                requestNoticeCount({ query: search });
              }}>
              <input placeholder="검색어를 입력해주세요." onChange={(e) => setSearch(e.target.value)} />
              <button type="submit">
                <img src={searchIcon} alt="검색하기" />
              </button>
            </S.Search>
          </D.FlexRows>
          <Line _color="purple1" _m_top={25} _m_btm={25} _height={2} />
          {data?.length > 0 ? (
            <D.FlexCols as="ul" _width="100%">
              {data.map((item, index) => (
                <li key={index} style={{ width: '100%' }}>
                  <S.Item to={String(index + 1)} relative="path" state={item}>
                    <S.ItemTitle>{item.title}</S.ItemTitle>
                    <S.ItemDate>{item.date}</S.ItemDate>
                  </S.Item>
                </li>
              ))}
            </D.FlexCols>
          ) : (
            <ErrorStatus message="등록된 공지사항이 없습니다." />
          )}
        </D.FlexCols>
        <Pagination
          pageLen={count % 10 == 0 ? count / 10 : Math.floor(count / 10) + 1}
          activePage={page}
          startPage={1}
          onPageClick={setPage}
          onNextClick={setPage}
          onPrevClick={setPage}
        />
      </D.FlexCols>
    </D.Container>
  );
}
