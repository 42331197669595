import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { Flex, FlexCols } from '../../../../components/Common/Display';
import { spaceDetailsState } from '../../../../store/space';
import { useRoomInfoCategoryQuery } from '../../../../hooks/queries/category';
// import { customAlert } from "../../../../store/modal";

import chevronIcon from '../../../../assets/icons/utils/ico-chevron_up.svg';

import LayoutModal from './LayoutModal';

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;
const Row = styled(Flex)`
  position: relative;
  width: 100%;

  @media (${(props) => props.theme.media.tabletSm}) {
    height: ${(props) => (props._optionCount ? `${props._optionCount * 48}px` : '48px')};
  }
`;
const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  height: 48px;

  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => (props._hidden ? props.theme.fontWeight.gray700 : props.theme.fontWeight.gray900)};

  background: ${(props) => (props._hidden ? props.theme.colors.white : props.theme.colors.gray50)};

  border: 1px solid ${(props) => props.theme.colors.gray300};
  ${(props) => !props._hidden && `border-bottom: none`};
  border-radius: ${(props) => (props._hidden ? '10px' : '10px 10px 0 0')};

  &::after {
    content: '';
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url(${chevronIcon}) center no-repeat;
    transform: rotate(${(props) => (props._hidden ? 180 : 0)}deg);
  }
`;
const SelectList = styled(FlexCols)`
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.colors.white};

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  border: 1px solid ${(props) => props.theme.colors.gray300};
  z-index: 20;

  display: ${(props) => (props.hidden ? 'none' : 'flex')};
`;
const SelectButton = styled.button`
  padding: 10px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;

  font-size: ${(props) => props.theme.fontSize.l};
  color: ${(props) => props.theme.colors.gray900};
  &:hover {
    background: ${(props) => props.theme.colors.purple50};
  }
`;

// component: 객실 정보 선택 modal
// /space/book/:id 페이지에 노출, selectBox 컴포넌트의 hideBox에서 호출
function SelectRoomModal({ setRoom, closeModal }) {
  // 전체 객실 목록 조회
  const { data: roomInfo, isLoading } = useRoomInfoCategoryQuery();
  // 실시간으로 저장하는 호텔 예약 정보
  const spaceDetails = useRecoilValue(spaceDetailsState);
  // const setAlert = useSetRecoilState(customAlert);

  const [hidden1, setHidden1] = useState(true);

  const [selectedRoom, setSelectedRoom] = useState({});

  // 전체 객실 정보중에 호텔의 객실 정보 filter
  const hasRoom = roomInfo?.filter((room) => {
    // 호텔 정보에 객실 정보가 없을 때 예외 처리
    // if (!spaceDetails?.room || !spaceDetails?.room[0]) return false;
    if (!spaceDetails?.room.length) return false;

    let result;
    for (const item of spaceDetails.room) {
      if (room.roomId === item.roomId) {
        result = true;
      }
    }
    return result;
  });

  // const hasOpts = spaceDetails?.room[0]
  //   ? spaceDetails.room.filter((item) => selectedRoom.hotel === item.hotelRoomId)
  //   : [];

  // useEffect(() => {
  //   setSelectedOpt({});
  // }, [selectedRoom]);

  if (isLoading) return null;

  // // // 요청할 객실 obj

  return (
    <LayoutModal
      w_width="380px"
      title="객실"
      b_text="확인"
      padding="24px 40px 70px"
      m_padding="20px 20px 70px"
      closeModal={closeModal}
      onClick={() => {
        if (Object.values(selectedRoom).length > 0) {
          // 요청할 객실 obj
          // 공간이 소유한 객실 중 선택한 객실과 roomId가 일치하는 객실
          const getRoom = spaceDetails.room.find((room) => room.roomId === selectedRoom.roomId);

          const obj = {
            hotelRoomName: selectedRoom.roomName,
            hotelRoomId: getRoom.hotelRoomId,
            roomPrice: getRoom.roomPrice,
            amount: 1,
          };
          setRoom(obj);
        }
        closeModal();
      }}
    >
      <Wrapper>
        {/* 객실 선택 row */}
        <Row _optionCount={!hidden1 && hasRoom.length}>
          <Button
            _hidden={hidden1}
            onClick={() => {
              // setHidden2(true);
              setHidden1((prev) => !prev);
            }}
          >
            {selectedRoom.roomName ?? '객실 선택'}
          </Button>
          <SelectList hidden={hidden1}>
            {hasRoom.map((item) => (
              <SelectButton
                onClick={(e) => {
                  setHidden1(true);
                  setSelectedRoom(item);
                }}
                key={item.roomId}
              >
                {item.roomName}
              </SelectButton>
            ))}
          </SelectList>
        </Row>
        {/* <Row _optionCount={!hidden2 && hasOpts.length}>
          <Button
            _hidden={hidden2}
            onClick={() => {
              setHidden1(true);
              setHidden2((prev) => !prev);
            }}
          >
            {selectedOpt.mealName ?? "옵션"}
          </Button>

          <SelectList hidden={hidden2}>
            {hasOpts.map((item) => (
              <SelectButton
                onClick={() => {
                  setHidden2(true);
                  setSelectedOpt(item);
                }}
                key={item.hotelMealId}
              >
                {item.mealName}
              </SelectButton>
            ))}
          </SelectList>
        </Row> */}
      </Wrapper>
    </LayoutModal>
  );
}

export default SelectRoomModal;
