/**
 * 예약 태그 컬러
 */
export const bookColor = {
  예약중: 'blue',
  예약취소: 'orange',
  이용완료: 'gray',
  예약불가: 'red',
  예약확정: 'purple',
};
