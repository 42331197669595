import styled from 'styled-components';

export const PageWrapper = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MapFrame = styled.div`
  z-index: 11;
  width: 100%;
  max-width: 1180px;
  height: 100%;
`;
export const SectionWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const StatusBar = styled.div`
  z-index: 12;
  position: absolute;
  top: 174px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 20px;
  border-radius: 24px;
  background-color: #fff;
  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: 500;

  @media (${(props) => props.theme.media.tablet}) {
    top: 220px;
  }
`;
