import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { yScrollBoxCss } from '../../../../components/Common/Display';
import RDatePicker from '../../../../components/Common/RDatePicker';
import { moreInfoState } from '../../../../store/request';
import { bookPeriodState } from '../../../../store/search';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 39;
`;
const CalendarWrapper = styled.div`
  z-index: 40;
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: min-content;
  height: min-content;
  max-width: 100%;

  padding: 48px 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.effectStyle.boxShadow};
  border-radius: 50px;

  & > div {
    width: 400px;
    height: 400px;
    ${yScrollBoxCss()}
  }
  .rdp {
    margin: 0 15px;
  }
  .rdp-months {
    flex-direction: column;
    gap: 40px;
  }
  .rdp-caption_end {
    margin: 0;
  }
  .rdp-table {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    .rdp-table {
      margin: 0 auto;
    }
  }
  @media (${(props) => props.theme.media.mobile}) {
    padding: 40px 20px 24px 20px;
    width: 100%;
    top: auto;
    bottom: 0;
  }
`;
// component: 마감 날짜 입력 캘린더 modal
// /space/request 페이지에 노출, SelectMoreInfo에서 호출
function SelectLimitDate({ closeModal }) {
  const [maxDueDate, setMaxDueDate] = useState('');
  // 선택한 이용 날짜
  const period = useRecoilValue(bookPeriodState);

  const [moreInfo, setMoreInfo] = useRecoilState(moreInfoState);

  const [dueDate, setDueDate] = useState([]);

  // 마감날짜를 선택하면 format 변경 저장
  const saveDueDate = useCallback(() => {
    if (dueDate.length > 0) {
      const strDueDate = moment(dueDate[0]).format('yyyy-MM-DD');
      const newInfo = { ...moreInfo };
      newInfo.requestDueDate = strDueDate;
      setMoreInfo(newInfo);
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate]);

  useEffect(() => {
    saveDueDate();
  }, [saveDueDate]);

  // 선택한 이용 날짜에 따라 설정 가능한 날짜 설정
  useEffect(() => {
    const maxDueDateObj = {
      from: new Date(period[0]?.getFullYear(), period[0]?.getMonth(), period[0]?.getDate() + 1),
      to: new Date(period[0]?.getFullYear() + 1, period[0]?.getMonth(), period[0]?.getDate()),
    };
    setMaxDueDate(maxDueDateObj);
  }, [period]);

  return (
    <>
      <Overlay onClick={closeModal}></Overlay>
      <CalendarWrapper>
        <div>
          <RDatePicker maxDueDate={maxDueDate} dates={dueDate} handleChange={setDueDate} />
        </div>
      </CalendarWrapper>
    </>
  );
}

export default SelectLimitDate;
