import React, { useState } from 'react';
import ChatList from '../../../components/MyPage/Chat/ChatList';
import ChatWindow from '../../../components/MyPage/Chat/ChatWindow';
import MyPageSection from '../../../components/MyPage/MyPageSection';

/**
 * 마이페이지 > 채팅 페이지
 */
export default function Chat() {
  // 채팅창 활성화
  const [chatViewidx, setChatViewIdx] = useState(-1);
  const [chatViewShow, setChatViewShow] = useState(false);

  return (
    <MyPageSection title="My 채팅">
      <ChatList handleChatClick={setChatViewIdx} setChatViewShow={setChatViewShow} />
      <ChatWindow
        chatId={chatViewidx}
        chatShow={chatViewShow}
        handleChatClose={() => {
          setChatViewIdx(-1);
        }}
      />
    </MyPageSection>
  );
}
