/**
 * 파일 사이즈 유효성 검증 함수
 * @param {File} file 파일 객체
 * @param {Number} max 최대 사이즈 (단위: MB) 
 * @returns {Boolean} 유효 여부
 */
export const isValidFileSize = (file, max = 10) => {
  const maxSize = max * 1024 ** 2;
  if (Array.isArray(file)) {
    const totalSize = file.reduce((acc, item) => {
      return acc + item.size;
    }, 0);

    return totalSize <= maxSize;
  } else {
    return file.size <= maxSize;
  }
};

/**
 * 파일 확장자 유효성 검증 함수
 * @param {File} file 파일 객체
 * @param {String[]} extenstions 확장자 목록 
 * @returns {Boolean} 유효 여부
 */
export const isValidFileExtension = (file, extenstions = []) => {
  const nameArr = file.name.split('.').pop().toLowerCase();
  const extArr = extenstions.map((item) => item.toLowerCase());

  return extArr.indexOf(nameArr) >= 0;
};

/**
 * 이미지 파일 여부 반환 함수
 * @param {File} file 파일 객체
 * @returns {Boolean} 이미지 여부
 */
export const isImageFile = (file) => {
  return file.name.match(/(.*?)\.(jpg|jpeg|png|gif|svg)$/);
};
