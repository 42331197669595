import React from 'react';
import styled from 'styled-components';

import { RoundBtn } from '../../../../components/Common/Button';
import { FlexCols, yScrollBoxCss } from '../../../../components/Common/Display';
import { Text } from '../../../../components/Common/Text';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 42;
`;
const Wrapper = styled(FlexCols)`
  z-index: 43;
  position: fixed;

  height: min-content;
  // PC modal 위치
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  max-width: ${(props) => props.width ?? '100%'};

  ${(props) => props.theme.effectStyle.selected};
  background: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 20px;

  // mobile modal 위치
  @media (${(props) => props.theme.media.tabletSm}) {
    // margin: 0 10px;
    top: auto;
    bottom: 0;
  }
`;

const ContentsWrapper = styled(FlexCols)`
  ${yScrollBoxCss()}
  padding: ${(props) => props.padding ?? 0};

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: ${(props) => props.m_padding ?? (props.padding || 'inherit')};
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  & > button {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

// component: modal layout
// /space/SpaceRequest 페이지에 노출, /space/SpaceRequest의 모든 modal에서 사용
function LayoutModal({
  right,
  w_width,
  title = false,
  b_text = '확인',
  padding,
  m_padding,
  onClick,
  children,
  closeModal,
}) {
  return (
    <>
      <Overlay onClick={closeModal}></Overlay>
      <Wrapper width={w_width} right={right} _items="center" className="wrap">
        <ContentsWrapper _gap={40} _width="100%" padding={padding} m_padding={m_padding}>
          {title && (
            <Text _weight="medium" _size="xxxl" _color="gray900">
              {title}
            </Text>
          )}
          {children}
        </ContentsWrapper>

        <ButtonDiv>
          <RoundBtn
            text={b_text}
            onClick={() => {
              onClick();
              closeModal();
            }}
            _primary={true}
            _capsule={false}
            disabled={false}
          />
        </ButtonDiv>
      </Wrapper>
    </>
  );
}

export default LayoutModal;
