import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import delIcon from '../../../assets/icons/utils/ico-del_round.svg';
import plusIcon from '../../../assets/icons/utils/ico-plus.svg';
import { isImageFile, isValidFileExtension, isValidFileSize } from '../../../utils/file';

const S = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
  `,
  UploadBtn: styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 80px;
      height: 80px;
      background: url(${plusIcon}) center no-repeat;
      color: ${(props) => props.theme.colors.gray700};
      cursor: pointer;
    }
  `,
  Pics: styled.div`
    width: calc(100% - 88px);
    overflow-y: hidden;
    overflow-x: auto;
    > div {
      display: flex;
      gap: 8px;
      width: max-content;
    }
  `,
  Pic: {
    Box: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      width: 80px;
      height: 120px;
    `,
    Del: styled.button`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
    `,
    Img: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80px;
      border: 1px solid ${(props) => props.theme.colors.gray300};
      border-radius: 10px;
      overflow: hidden;

      img {
        height: 100%;
      }
    `,
  },
};

/** 
 * 사진 업로드 컴포넌트
 */
export default function PicUploader({ pics = [], handleChange, max, extensions }) {
  const picListRef = useRef(null);

  const handleUpload = (e) => {
    if (e.target.files.length <= 0 || pics.length >= max) {
      return;
    }

    const file = e.currentTarget.files[0];

    if (!isValidFileSize(file)) {
      alert('10mb 이하의 이미지를 등록해 주세요.');
      e.currentTarget.value = '';
      return;
    }
    if (!isImageFile(file)) {
      alert('jpg, jpeg, png, gif, svg 형식의 이미지를 등록해 주세요.');
      e.currentTarget.value = '';
      return;
    }
    handleChange([...pics, file]);
  };

  const handleDel = (delIdx) => {
    handleChange(pics.filter((_, idx) => idx !== delIdx));
  };

  return (
    <S.Wrapper>
      <S.UploadBtn>
        <input type="file" name="picFile" id="picFile" onChange={handleUpload} />
        <label htmlFor="picFile">사진 첨부</label>
      </S.UploadBtn>
      <S.Pics>
        <div ref={picListRef}>
          {pics.map((pic, idx) => (
            <S.Pic.Box key={idx}>
              <S.Pic.Img>
                <img src={URL.createObjectURL(pic)} alt="" />
              </S.Pic.Img>
              <S.Pic.Del
                onClick={() => {
                  handleDel(idx);
                }}>
                <img src={delIcon} alt="삭제" />
              </S.Pic.Del>
            </S.Pic.Box>
          ))}
        </div>
      </S.Pics>
    </S.Wrapper>
  );
}
