import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import arrowIcon from '../../../assets/icons/utils/ico-arrow_btm.svg';

const S = {
  List: styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  Item: styled.li`
    width: 100%;
    height: auto;

    .title {
      display: flex;
      width: 100%;
      padding: 16px 8px;
      font-size: ${(props) => props.theme.fontSize.xl};
      font-weight: ${(props) => props.theme.fontWeight.medium};
      border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
      cursor: pointer;
    }

    .contents {
      width: 100%;
      height: ${(props) => (props.active ? 'auto' : '0')};
      overflow: hidden;
      p {
        font-size: ${(props) => props.theme.fontSize.l};
        font-weight: ${(props) => props.theme.fontWeight.medium};
        padding: 40px 20px;
        color: ${(props) => props.theme.colors.gray700};
        background-color: ${(props) => props.theme.colors.gray50};
      }
    }

    ${(props) =>
      props.icon &&
      css`
        border-bottom: 0;
        border-top: 1px solid ${(props) => props.theme.colors.gray300};
        padding: 20px 24px;

        .title {
          gap: 8px;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          border-bottom: 0;
          height: 24px;

          &::after {
            content: '';
            width: 24px;
            height: 24px;
            background: url(${arrowIcon}) center / 14px 8px no-repeat;
            transform: rotate(${props.active ? '180deg' : '0deg'});
          }
        }

        .contents {
          p {
            background-color: ${(props) => props.theme.colors.white};
            padding: 16px 0 0;
            color: ${(props) => props.theme.colors.gray900};
          }
        }
      `}

    @media (${(props) => props.theme.media.tabletSm}) {
      .title {
        font-size: ${(props) => props.theme.fontSize.base};
        padding: 18px 8px;
      }
      .contents {
        p {
          font-size: ${(props) => props.theme.fontSize.s};
          padding: 24px 16px;
          color: ${(props) => props.theme.colors.gray700};
        }
      }

      ${(props) =>
        props.icon &&
        css`
          padding: 24px 0;
          .title {
            padding: 0;
            font-size: ${(props) => props.theme.fontSize.xl};
          }

          .contents {
            p {
              background-color: ${(props) => props.theme.colors.white};
              padding: 16px 16px 0;
              font-size: ${(props) => props.theme.fontSize.l};
              color: ${(props) => props.theme.colors.gray900};
            }
          }
        `}
    }
  `,
};

/**
 * 정보 노출 목록 컴포넌트
 */
export default function InfoList({ data, activeIdx = -1, icon = false }) {
  const [active, setActive] = useState(activeIdx);

  return (
    <S.List icon={icon}>
      {data.map((info, idx) => (
        <S.Item key={idx} active={idx === active} icon={icon}>
          <span
            className="title"
            onClick={() => {
              setActive(active === idx ? -1 : idx);
            }}
          >
            {info.title}
          </span>
          <div className="contents">
            <p>{info.contents}</p>
          </div>
        </S.Item>
      ))}
    </S.List>
  );
}
