import { client } from '.';

const URL = {
  newChatId: '/api/chatting/inquiry',
  newChatRoom: '/api/chatting/room',
};

// 챗 아이디 요청
export const getNewChatId = (params) => {
  return client.get(URL.newChatId, {s: params})
}

// 새로운 챗 요청
export const requestNewChatRoom = (params) => {
  return client.post(URL.newChatRoom, {managerId: params});
}

