import { useQuery } from '@tanstack/react-query';
import { WORD_PATTERN } from '../../constants/pattern';
import { getSearchLocation } from '../../service/client/search';

const SEARCH_KEYS = {
  location: ['search', 'location'],
  date: ['search', 'date'],
  conditions: ['search', 'conditions'],
};

// 검색한 단어를 포함하는 content 검색 출력// 검색한 단어를 포함하는 content 검색 출력
export const useSearchLocationQuery = (word) => {
  const { data, error, isFetching } = useQuery(
    [...SEARCH_KEYS.location, word],
    async () => {
      const { data } = await getSearchLocation(word);

      return data.data;
    },
    {
      enabled: word?.length > 0 && WORD_PATTERN.test(word),
    }
  );

  return { data, error, isLoading: isFetching };
};
