import React, { useEffect, useLayoutEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { EMAIL_PATTERN, NAME_PATTERN, PWD_PATTERN } from "../../../constants/pattern";
import { SITE } from "../../../constants/site";
import { FormModal } from "../../../layouts/Modal";
import { checkNewPhone, checkValidId, getAuthCheck, requestJoin } from "../../../service/client/user";
import { customAlert, joinModalState, loginModalState, snsJoinState } from "../../../store/modal";
import { RoundBtn } from "../../Common/Button";
import * as D from "../../Common/Display";
import { CheckBox, InputField } from "../../Common/InputField";
import { Text } from "../../Common/Text";
import PassAuth from "../Auth/PassAuth";

const AGREEMENTS = [
  {
    label: (
      <Text as={Link} _size="xxs" _color="gray800" _decoration="underline" to={SITE.useRule} target="_blank">
        (필수) 이용약관
      </Text>
    ),
    registerProps: { required: { value: true, message: "이용약관에 동의해 주세요." } },
  },
  {
    label: (
      <Text as={Link} _size="xxs" _color="gray800" _decoration="underline" to={SITE.privacyRule} target="_blank">
        (필수) 개인정보 수집 및 이용 동의
      </Text>
    ),
    registerProps: {
      required: { value: true, message: "개인정보 수집 및 이용 동의에 동의해 주세요." },
    },
  },
  {
    label: (
      <Text as={Link} _size="xxs" _color="gray800" _decoration="underline" to={SITE.locationRule} target="_blank">
        (필수) 위치기반서비스 이용약관
      </Text>
    ),
    registerProps: {
      required: { value: true, message: "위치기반서비스 이용약관에 동의해 주세요." },
    },
  },
  {
    label: (
      <Text as={Link} _size="xxs" _color="gray800" _decoration="underline" to={SITE.marketingRule} target="_blank">
        이벤트 및 프로모션 수신 동의
      </Text>
    ),
  },
  {
    label: (
      <Text as={Link} _size="xxs" _color="gray800">
        (필수)만 14세 이상입니다.
      </Text>
    ),
    registerProps: { required: { value: true, message: "필수 약관에 동의해 주세요." } },
  },
];

const joinInitialState = {
  authOn: false,
  authResult: -1, // 0: 실패, 1: 성공
  joinResult: -1, // 0: 실패, 1: 성공
};
const joinReducer = (state, action) => {
  switch (action.type) {
    case "authOn":
      return { ...state, authOn: action.payload, authResult: -1 };
    case "authResult":
      return { ...state, authResult: action.payload };
    case "joinResult":
      return { ...state, joinResult: action.payload };
    case "snsJoin":
      return { ...joinInitialState, authOn: false, authResult: 1 };
    case "initialize":
      return joinInitialState;
    default:
      throw new Error();
  }
};

/**
 *  회원가입 컴포넌트
 * @desription
 * - 동의, 닉네임 필수
 */
const Join = () => {
  const resetJoin = useResetRecoilState(joinModalState);
  const setLogin = useSetRecoilState(loginModalState);
  // const joinState = useRecoilValue(joinModalState);
  const snsJoin = useRecoilValue(snsJoinState);
  const setAlert = useSetRecoilState(customAlert);
  const [state, dispatch] = useReducer(joinReducer, joinInitialState);
  const [token, setToken] = useState(null);
  const [phone, setPhone] = useState(null);
  const [authWarn, setAuthWarn] = useState(false);
  const {
    register,
    getValues,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const formWatching = watch();
  const [submitable, setSubmitable] = useState(false);

  // 본인인증
  const handleAuthCheck = async () => {
    const response = await getAuthCheck(token);
    const { status, data } = response.data;
    const isNewUser = await getIsNewPhone(data.phone);
    const authSuccess = status === 200;

    if (!authSuccess) {
      setAuthWarn(true);
      dispatch({ type: "authOn", payload: false });
      return;
    }

    if (!isNewUser) {
      setAlert({ text: "이미 사용중인 번호입니다. \n 다른 번호로 시도해 주세요." });
      dispatch({ type: "authOn", payload: false });
      return;
    }

    if (isNewUser) {
      setPhone(data.phone);
      dispatch({ type: "authResult", payload: 1 });
      return;
    }
  };

  // 이용약관 전체 동의
  const handleAgreeAllChange = (e) => {
    const checked = e.target.checked;
    for (let i = 0; i < AGREEMENTS.length; i++) {
      setValue(`uagree${i + 1}`, checked);
    }
  };

  // 이용약관 동의
  const handleSubAgreeChange = (e) => {
    const checked = e.target.checked;

    if (!checked && getValues("uagree0")) {
      setValue("uagree0", false);
      return;
    }

    if (
      AGREEMENTS.filter((_, idx) => getValues(`uagree${idx + 1}`) !== false).length >= AGREEMENTS.length &&
      getValues("uagree0") === false
    ) {
      setValue("uagree0", true);
      return;
    }
  };

  const isFilledForm = () => {
    const notFilledLen = getValues(["uid", "unick", ...((!snsJoin && ["upwd", "upwd02"]) || [])]).filter((item) => item.length <= 0).length;
    const notAgreeLen = AGREEMENTS.reduce((acc, item, idx) => {
      if (item.registerProps && item.registerProps.required) return acc.concat(idx);
      else return acc;
    }, [])
      .map((idx) => getValues(`uagree${idx + 1}`))
      .filter((item) => item === false).length;
    return notFilledLen <= 0 && notAgreeLen <= 0;
  };

  const isValidForm = () => {
    return (
      isFilledForm() && (Object.keys(errors).length <= 0 || Object.keys(errors).filter((key) => errors[key].message.length > 0).length <= 0)
    );
  };

  // 유효 전화번호
  const getIsNewPhone = async (phoneNum) => {
    const res = await checkNewPhone(phoneNum);
    return res && res.data.status === 200;
  };

  // 유효 아이디
  const getIsValidId = async () => {
    const uid = getValues("uid");
    const response = await checkValidId(uid);

    return response && response.data.status === 200;
  };
  // 유효 아이디 체크
  const handleValidIdCheck = async () => {
    const isValidId = await getIsValidId();

    if (!isValidId) {
      setError("uid", { type: "validId", message: "이미 사용중인 아이디입니다." });
    }
  };

  // 회원가입
  const handleFormSubmit = async () => {
    if (!isValidForm()) {
      return;
    }

    if (!phone) {
      setAuthWarn(true);
      return;
    }

    const formData = {
      username: getValues("uid"),
      nickname: getValues("unick"),
      phone: phone,
      adFlag: true,
      password: snsJoin ? "" : getValues("upwd"),
      ...(snsJoin && {
        provider: snsJoin.provider,
        providerId: snsJoin.providerId,
      }),
    };

    const response = await requestJoin(formData);
    const { status } = response.data;

    dispatch({ type: "joinResult", payload: status === 200 ? 1 : 0 });
    setAlert({
      text: status === 200 ? "알아봐봐 서비스 \n 회원가입이 완료 되었습니다." : "회원가입에 실패하였습니다. \n 다시 시도해 주세요.",
      handleClose: () => {
        resetJoin();
        if (status === 200) {
          setLogin({ mode: true, redirect: null });
        }
      },
    });
  };

  useEffect(() => {
    if (state.authResult === 1) {
      setSubmitable(isFilledForm());
    }
  }, [formWatching, state.authResult]);

  useLayoutEffect(() => {
    // initail state
    dispatch({ type: "initialize" });
    setToken(null);
    setPhone(null);
  }, []);

  useEffect(() => {
    if (snsJoin) {
      setValue("uid", snsJoin.email);
    }
  }, [snsJoin]);

  return (
    <React.Fragment>
      {state.joinResult < 0 && (
        <FormModal
          title={
            <D.FlexCols _gap={8}>
              <Text _size="xxl" _weight="bold" _line="xxs">
                GYPP에 오신 걸 환영합니다.
              </Text>
              <Text _size="xs" _line="xxs">
                아래 정보만 작성하면 회원가입이 완료 됩니다.
              </Text>
            </D.FlexCols>
          }
          _size="m3"
          _height={`${snsJoin ? 740 : 835}px`}
          overlayClick={false}
          action={
            <RoundBtn
              text="회원가입"
              type="submit"
              _primary={true}
              _capsule={true}
              disabled={!submitable}
              onClick={() => {
                handleFormSubmit();
              }}
            />
          }
          handleModalClose={() => {
            resetJoin();
          }}>
          <D.FlexCols
            _width="100%"
            as="form"
            _gap={24}
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}>
            {state.authResult !== 1 && (
              <D.FlexCols _gap={8}>
                <Text _size="s" _weight="medium" _color="gray900">
                  본인 인증
                </Text>
                <RoundBtn
                  _gray
                  text="휴대전화 번호 인증"
                  onClick={() => {
                    dispatch({ type: "authOn", payload: true });
                  }}
                />
                {(authWarn || snsJoin) && (
                  <Text _size="xxs" _color={authWarn ? "error" : "purple1"}>
                    본인인증을 진행해 주세요.
                  </Text>
                )}
              </D.FlexCols>
            )}
            {state.authResult === 1 && <InputField label="휴대전화 번호" name="tel" readOnly={true} value={phone} />}
            {[
              {
                label: "아이디",
                name: "uid",
                placeholder: "이메일(아이디를 입력해 주세요.)",
                readOnly: snsJoin !== null,
                registerProps: {
                  required: { value: snsJoin === null, message: "아이디(이메일)을 입력해 주세요." },
                  pattern: { value: EMAIL_PATTERN, message: "이메일을 정확히 입력해 주세요." },
                  onBlur: handleValidIdCheck,
                },
              },
              {
                label: "닉네임/회사명",
                name: "unick",
                placeholder: "사용하실 닉네임 또는 회사명을 입력해 주세요.",
                maxLength: 10,
                registerProps: {
                  required: { value: true, message: "닉네임(회사명)을 입력해 주세요." },
                  pattern: { value: NAME_PATTERN, message: "*문자, 숫자를 조합하여 1~10자리로 입력해 주세요." },
                },
              },
              ...((!snsJoin && [
                {
                  label: "비밀번호",
                  name: "upwd",
                  placeholder: "비밀번호를 입력해 주세요.",
                  type: "password",
                  registerProps: {
                    required: { value: true, message: "비밀번호를 입력해 주세요." },
                    pattern: { value: PWD_PATTERN, message: "*영문, 숫자를 조합하여 8~16자리로 입력해 주세요." },
                  },
                },
                {
                  label: "비밀번호 확인",
                  name: "upwd02",
                  placeholder: "새 비밀번호를 다시 입력해주세요.",
                  type: "password",
                  registerProps: {
                    required: { value: true, message: "비밀번호를 확인해 주세요." },
                    pattern: { value: new RegExp(getValues("upwd")), message: "비밀번호를 확인해 주세요." },
                  },
                },
              ]) ||
                []),
            ].map((item, idx) => (
              <InputField
                key={idx}
                label={item.label}
                name={item.name}
                type={item.type}
                placeholder={item.placeholder}
                readOnly={item.readOnly}
                register={register}
                registerProps={item.registerProps}
                error={errors[item.name]}
                errorMsg={errors[item.name] ? errors[item.name].message : ""}
                maxLength={item.maxLength}
              />
            ))}
            <D.FlexCols _gap={8}>
              <D.FlexCols _gap={2}>
                <Text _size="s" _weight="medium" _color="gray900">
                  이용약관동의
                </Text>
              </D.FlexCols>
              <D.FlexRows _justify="space-between">
                <D.FlexRows _items="start">
                  <CheckBox
                    name="uagree0"
                    label={
                      <Text _size="xxs" _weight="medium">
                        전체동의
                      </Text>
                    }
                    value="uagree0"
                    type="square"
                    register={register}
                    registerProps={{ onChange: handleAgreeAllChange }}
                  />
                </D.FlexRows>
                <D.FlexCols _gap={8} _items="start" _justify="center">
                  {AGREEMENTS.map((item, idx) => (
                    <CheckBox
                      key={idx}
                      name={`uagree${idx + 1}`}
                      label={item.label}
                      labelAs="a"
                      value={`uagree${idx + 1}`}
                      register={register}
                      type="square"
                      registerProps={{
                        ...(item.registerProps ? item.registerProps : {}),
                        onChange: handleSubAgreeChange,
                      }}
                    />
                  ))}
                </D.FlexCols>
              </D.FlexRows>
            </D.FlexCols>
          </D.FlexCols>
        </FormModal>
      )}
      {state.authOn && <PassAuth handleToken={setToken} handlePopupClose={handleAuthCheck} handleFail={resetJoin} onAuth={state.authOn} />}
    </React.Fragment>
  );
};

export default Join;
