import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { cdnUrl } from '../../../constants/url';
import { KoNum } from '../../../utils/number';
import Slider from 'react-slick';

const S = {
  Wrap: styled.div`
    z-index: 99;
    position: fixed;
    bottom: 24px;
    left: 50%;
    width: 350px;
    max-width: calc(100% - 40px);
    transform: translateX(-50%);

    .gp-iw {
      width: 100% !important;
    }
  `,
  SlideWraps: styled.div`
    z-index: 40 !important;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 184px !important;
    padding: 20px 0 24px 20px;
    overflow: hidden;

    .slick-list {
      overflow: visible;
    }

    .slick-track {
      width: max-content !important;
    }

    .gp-giw__slide {
      margin-right: 8px;
      width: 350px !important;
    }

    .gp-iw {
      width: 100% !important;
    }
  `,
  InfoWindow: styled.div`
    width: 350px !important;
  `,
};

/**
 * 지도 인포윈도우 컴포넌트 (모바일)
 * @param {object} space 공간 정보
 */
function InfoWindow(space) {
  return (
    <S.InfoWindow className="gp-iw">
      <Link to={space.link} replace={true} target="_blank">
        <div className="img">
          <img src={`${cdnUrl(space.thumbnail)}`} alt={space.spaceName} />
        </div>
        <div className="inner">
          <span className="name">{space.hotelName}</span>
          <span class="room">
            <span>{space.spaceName}</span>
            {space.count && space.count > 1 && <strong>+{space.count}</strong>}
          </span>
          <div className="conts">
            <span className="time">시간당</span>
            <span className="won">{KoNum(space.priceTime)} 원</span>
          </div>
        </div>
      </Link>
    </S.InfoWindow>
  );
}

/**
 * 지도 그룹핑 인포윈도우 컴포넌트 (모바일)
 */
export default function MobileInfoWindow({ data }) {
  if (data.length > 1) {
    return (
      <S.SlideWraps className="gp-giw__slider">
        <Slider variableWidth draggable={true} swipeToSlide={true} infinite={false}>
          {data.map((item, idx) => {
            const isGrouped = item.length > 1;
            const target = item[0];
            return (
              <div key={idx} className="gp-giw__slide">
                {InfoWindow({
                  ...target,
                  spaceName: target.spaceName,
                  link: `/space/${isGrouped ? 'details' : 'book'}/${isGrouped ? target.hotelId : target.spaceId}`,
                  count: item.length,
                })}
              </div>
            );
          })}
        </Slider>
      </S.SlideWraps>
    );
  }

  // 1개 항목
  if (data.length <= 1) {
    return <S.Wrap>{InfoWindow({ ...data[0], link: `/space/book/${data[0].spaceId}` })}</S.Wrap>;
  }

  return null;
}
