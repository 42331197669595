import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import BookingList from '../../../components/MyPage/Booking/BookingList';
import { myBookingCategory, SITE } from '../../../constants/site';

/**
 * 마이페이지 > 마이 예약 > 예약 목록 페이지
 */
export default function MyBookingList() {
  const { category } = useParams();

  if (myBookingCategory.filter((ctg) => category === ctg).length < 1) {
    return <Navigate key="nav" to={SITE.myBooking} />;
  }

  return <BookingList key="list" category={category} />;
}
