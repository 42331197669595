import React from 'react';
import EditForm from '../../../components/MyPage/MyInfo/EditForm';
import MyPageSection from '../../../components/MyPage/MyPageSection';

/**
 * 마이페이지 > 회원 정보 변경 페이지
 */
export default function MyInfo() {
  return (
    <MyPageSection title="회원 정보 변경">
      <EditForm />
    </MyPageSection>
  );
}
