// 사이트 네비게이션
export const SITE = {
  home: '/',
  myInfo: '/mypage/edit',
  myWish: '/mypage/wishes/space',
  myRequest: '/mypage/request/all',
  myRequestDetails: '/mypage/request/details',
  myBooking: '/mypage/booking/all',
  myReview: '/mypage/review',
  myChat: '/mypage/chat',
  onToOne: '/service/contact',
  useRule: '/service/rules?rule=TERMS',
  privacyRule: '/service/rules?rule=PRIVACY',
  locationRule: '/service/rules?rule=LOCATION',
  marketingRule: '/service/rules?rule=MARKETING',
  notice: '/service/notice',
  spaceList: '/space/list',
  spaceDetails: '/space/details',
  spaceRequest: '/space/request',
  partnerRequest: '/partner/request',
  company: '',
  admin: '/admin',
  manage: '/manage',
};

export const myBookingCategory = ['all', 'request', 'confirm', 'end', 'cancel'];
export const myBookingList = (ctg) => `/mypage/booking/${ctg}`;
export const myBookingDetails = (id) => `/mypage/booking/details/${id}`;
export const myWishCategory = ['space', 'hotel'];
export const myWishList = (ctg) => `/mypage/wishes/${ctg}`;
export const myRequestCategory = ['all', 'end'];
export const myRequestList = (ctg) => `/mypage/request/${ctg}`;

export const spaceCtgList = (ctgId) => `/space/list?category=${ctgId}`;
export const spaceDetails = (spaceId) => `/space/details/${spaceId}`;
export const spaceBook = (spaceId) => `/space/book/${spaceId}`;
export const myRequestDetails = (requestId) => `/mypage/request/details/${requestId}`;
export const chatRoom = (roomId) => `/inquiry/chat/${roomId}`;
export const spaceRequestMod = (reqId) => `/space/request/mod/${reqId}`;
