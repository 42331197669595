import * as StompJs from '@stomp/stompjs';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useChatingContentListQuery } from '../../hooks/queries/mypage';
import { chatHotelName } from '../../store/chat';
import './chat.css';

const isEmpty = (obj) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

const groupBy = (data, key) => {
  return data.reduce((list, item) => {
    const group = moment(item[key]).format('yyyy.MM.DD');
    if (list[group] === undefined) {
      list[group] = [];
    }
    list[group].push(item);
    return list;
  }, {});
};

const ChatTime = ({ t }) => {
  const ap = moment(t).format('a') == 'am' ? '오전' : '오후';
  const time = moment(t).format('h:mm');

  return (
    <span className="time">
      {ap} {time}
    </span>
  );
};

const DateLine = ({ t }) => {
  const date = moment(t).format('yyyy.MM.DD');

  return (
    <div className="date">
      <hr />
      <span>{date}</span>
      <hr />
    </div>
  );
};

/**
 * 채팅 페이지
 */
export default function ChatPage() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hotelName, setHotelName] = useRecoilState(chatHotelName);
  const sender = searchParams.get('s');
  const scrollRef = useRef();
  const [list, setList] = useState([]);
  const { data, refetch } = useChatingContentListQuery({ room: id, hotelName: hotelName });
  const client = useRef({});
  const [message, setMessage] = useState('');

  // 채팅 연결
  useEffect(() => {
    connect();
    return () => disconnect();
  }, []);

  // 마지막 챗 스크롤 이동
  useLayoutEffect(() => {
    setTimeout(() => {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }, 1000);
  }, [id]);

  // 채팅 데이터 변경
  useEffect(() => {
    if (data?.chatting.length > 0) {
      setList(groupBy(data.chatting, 'chat_date'));
      refetch();
    }
  }, [data, message]);

  // 채팅 소켓 연결
  const connect = () => {
    client.current = new StompJs.Client({
      brokerURL: 'wss://alabwaba.com/ws',
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: () => {
        subscribe();
      },
      onStompError: (frame) => {
        console.error(frame);
      },
    });

    client.current.activate();
  };

  // 채팅 연결 해제
  const disconnect = () => {
    client.current.deactivate();
  };

  // 채팅 메시지 수령
  const subscribe = () => {
    client.current.subscribe(`/sub/${id}`, ({ body }) => {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;

      // setChatMessages((_chatMessages) => [..._chatMessages, JSON.parse(body)]);
      refetch();
    });
  };

  // 채팅 메시지 발송
  const publish = (message) => {
    if (!client.current.connected) {
      return;
    }

    client.current.publish({
      destination: '/pub/send',
      body: JSON.stringify({
        sender: sender,
        room: id,
        msg: message,
      }),
    });

    setMessage('');
  };

  // 채팅 목록 없을 경우 빈 화면 렌더링
  if (isEmpty(list)) return;

  return (
    <div className="section">
      <div className="top">
        <span>{data?.hotelName == '' ? '채팅문의' : data?.hotelName}</span>
      </div>
      {/* 채팅 컨텐츠 영역 */}
      <div className="mid" ref={scrollRef}>
        <div className="mid-wrap">
          {Object.values(list).map((item, i) => (
            <React.Fragment key={i}>
              <DateLine t={item[0].chat_date}></DateLine>

              {item.map((d, j) => (
                <div className={d.member_id == sender ? 'chat-wrap right' : 'chat-wrap left'} key={j}>
                  <div className="chat">
                    <ChatTime t={d.chat_date}></ChatTime>
                    <span className="msg">{d.chat}</span>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* 채팅 입력, 전송 영역 */}
      <div className="bottom">
        <div className="action">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              publish(message);
            }}
            style={{ width: '100%' }}>
            <input
              type="text"
              placeholder="채팅을 입력해 주세요."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              // onKeyPress={(e) => e.which === 13 && publish(message)}
            />
          </form>
          <button onClick={() => publish(message)}>전송</button>
        </div>
      </div>
    </div>
  );
}
