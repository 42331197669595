import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import arrowIcon from '../../../assets/icons/utils/ico-arrow_left_white.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.css';

export const S = {
  Wrapper: styled.div`
    z-index: 20;
    position: relative;
    width: 100%;
    max-width: ${(props) => props._width ?? '100%'};
    height: ${(props) => props._height ?? '550px'};
    margin: 0 auto;

    > div {
      z-index: 21;
      width: 100%;
      height: 100%;
      max-height: ${(props) => props._height ?? '550px'};
      border-radius: 10px;
      overflow: hidden;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
        border: 20px;
      }
    }

    ${(props) =>
      props._media &&
      css`
        @media (${props.theme.media[props._media]}) {
          max-width: ${props._m_width ? (props._m_width === 'full' ? '100%' : `${props._m_width}px`) : ''};
          ${props._m_height && props._m_height !== 'full' && `height: ${props._m_height};`}
          ${props._m_height && props._m_height === 'full' && `height: 68vw;`}

          > div {
            border-radius: 0;
            ${props._m_height && props._m_height !== 'full' && `max-height: ${props._m_height};`}
            ${props._m_height && props._m_height === 'full' && `max-height: 68vw;`}
          }
        }
      `}
  `,
  Nav: styled.button`
    display: ${(props) => (props._nav ? 'block' : 'none')};
    z-index: 22;
    position: absolute;
    top: calc(50% - 16.5px);
    left: ${(props) => (props._position === 'in' ? 12 : -56)}px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: url(${arrowIcon}) center no-repeat rgba(0, 0, 0, 0.1);
    transform: rotate(${(props) => (props.next ? '180deg' : '0')});

    ${(props) =>
      props.next &&
      css`
        left: auto;
        right: ${(props) => (props._position === 'in' ? 12 : -56)}px;
      `}

    ${(props) =>
      props._media &&
      css`
        @media (${props.theme.media[props._media]}) {
          display: ${(props) => (props._m_nav ? 'block' : 'none')};
          ${props._m_position && `left: ${(props) => (props._position === 'in' ? 12 : -56)}px;`}
          ${(props) =>
            props._m_position &&
            props.next &&
            css`
              right: ${(props) => (props._position === 'in' ? 12 : -56)}px;
            `}
        }
      `}
  `,
  Page: styled.span`
    display: ${(props) => (props._active ? 'block' : 'none')};
    z-index: 22;
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
    height: 33px;
    padding: 8px 16px;
    border-radius: 17px;
    background-color: rgba(255, 255, 255, 0.7);
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: ${(props) => props.theme.lineHeight.xxs};

    ${(props) =>
      props._media &&
      css`
        @media (${props.theme.media[props._media]}) {
          display: ${(props) => (props._m_active ? 'block' : 'none')};
        }
      `}
  `,
};

/**
 * 이미지 슬라이드 컴포넌트
 */
export default function PicSlider({
  pics = [],
  _width,
  _m_width,
  _height,
  _m_height,
  _nav = false,
  _nav_position = 'in',
  _paging,
  _m_paging,
  _media,
  _m_nav,
  _m_nav_position,
}) {
  const pageRef = useRef(null);
  const [slider, setSlider] = useState(null);

  const onNavClick = (idx) => {
    if (slider) {
      if (idx > 0) {
        slider.slideNext();
      } else {
        slider.slidePrev();
      }
    }
  };

  return (
    <S.Wrapper _height={_height} _width={_width} _media={_media} _m_height={_m_height} _m_width={_m_width}>
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={true}
        onSwiper={(swiper) => {
          setSlider(swiper);
        }}
        onSlideChange={(swiper) => {
          if (pageRef && pageRef.current) {
            pageRef.current.innerText = swiper.realIndex + 1;
          }
        }}>
        {pics.map((pic, idx) => (
          <SwiperSlide key={idx}>
            <img src={`https://cdn.alabwaba.com${pic}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <S.Nav
        _nav={_nav}
        _media={_media}
        _m_nav={_m_nav}
        _position={_nav_position}
        _m_posiotion={_m_nav_position}
        onClick={() => {
          onNavClick(-1);
        }}
      />
      <S.Nav
        _nav={_nav}
        _media={_media}
        _m_nav={_m_nav}
        _position={_nav_position}
        _m_posiotion={_m_nav_position}
        next
        onClick={() => {
          onNavClick(1);
        }}
      />
      <S.Page _active={_paging} _media={_media} _m_active={_m_paging}>
        <span ref={pageRef}>1</span> / <span>{pics.length}</span>
      </S.Page>
    </S.Wrapper>
  );
}
