import { createBrowserRouter, Navigate } from 'react-router-dom';
import App from './App';
// home
import Home from './pages/Home';
// space
import SpaceList from './pages/space/SpaceList';
import SpaceDeatils from './pages/space/SpaceDetails';
import SpaceBook from './pages/space/SpaceBook';
import SpaceRequest from './pages/space/SpaceRequest';
// mypage
import MyPageWrap from './pages/mypage/MyPageWrap';
import MyInfo from './pages/mypage/MyInfo';
import MyWishes from './pages/mypage/MyWishes';
import MyReviews from './pages/mypage/MyReviews';
import MyRequestList from './pages/mypage/MyRequests/MyRequestList';
import MyRequestDetails from './pages/mypage/MyRequests/MyRequestDetails';
import Chat from './pages/mypage/Chat';
// booking
import MyBookingList from './pages/mypage/MyBooking/MyBookingList';
import MyBookingDetails from './pages/mypage/MyBooking/MyBookingDetails';
// partner
import PartnerRequest from './pages/Partner/PartnerRequest';
// service
import Rules from './pages/service/Rules';
import NoticeList from './pages/service/NoticeList';
import NoticeDetails from './pages/service/NoticeDetails';
import OneToOne from './pages/service/OneToOne';
import MyRequestApplys from './pages/mypage/MyRequests/MyRequestApplys';
import ChatPage from './pages/Chat';
import { SITE } from './constants/site';
//test
import Error404 from './pages/Error/Error404';
import { LoadingStatus } from './components/Common/Status';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error404 />,
    children: [
      { index: true, element: <Home /> },
      {
        path: 'space',
        children: [
          { path: 'list', element: <SpaceList /> },
          { path: 'details/:spaceId', element: <SpaceDeatils /> },
          // { path: 'pics/:spaceId', element: <SpacePics /> },
          { path: 'book/:id', element: <SpaceBook /> },
          {
            path: 'request',
            children: [
              { index: true, element: <SpaceRequest /> },
              { path: 'mod/:id', element: <SpaceRequest modify /> },
            ],
          },
        ],
      },
      {
        path: 'partner/request',
        element: <PartnerRequest />,
      },
      {
        path: 'mypage',
        element: <MyPageWrap />,
        children: [
          { index: true, element: <Navigate to="edit" relative="path" /> },
          { path: 'edit', element: <MyInfo /> },
          { path: 'wishes', element: <Navigate to={SITE.myWishes} /> },
          { path: 'wishes/:category', element: <MyWishes /> },
          {
            path: 'request',
            children: [
              { index: true, element: <Navigate to={SITE.myRequest} /> },
              { path: ':category', element: <MyRequestList /> },
              { path: 'details/:requestId', element: <MyRequestDetails /> },
              { path: 'applys/:requestId', element: <MyRequestApplys /> },
            ],
          },
          { path: 'chat', element: <Chat /> },
          { path: 'review', element: <MyReviews /> },
          { path: 'booking', element: <Navigate to={SITE.myBooking} /> },
          { path: 'booking/:category', element: <MyBookingList /> },
        ],
      },
      {
        path: 'mypage/booking/details/:bookingId',
        element: <MyBookingDetails />,
      },
      {
        path: 'service',
        children: [
          {
            path: 'notice',
            children: [
              { index: true, element: <NoticeList /> },
              { path: ':noticeId', element: <NoticeDetails /> },
            ],
          },
          { path: 'rules', element: <Rules /> },
          { path: 'contact', element: <OneToOne /> },
        ],
      },
      {
        path: 'test',
        element: <LoadingStatus />,
      },
    ],
  },
  {
    path: 'inquiry/chat/:id',
    element: <ChatPage />,
  },
]);

export default router;
