import { Outlet } from 'react-router-dom';
import React from 'react';
import { MyPageWrapper } from '../../../components/MyPage/MyPageWrapper';
import { useLoginOnly } from '../../../hooks/utils/login';
import { Helmet } from 'react-helmet';

/**
 * 마이페이지 래퍼 컴포넌트
 * @description 마이페이지 메뉴, 하위 페이지 렌더링
 */
const MyPageWrap = () => {
  return useLoginOnly(
    <MyPageWrapper>
      <Helmet>
        <title>알아봐봐 | 마이페이지</title>
      </Helmet>
      <Outlet />
    </MyPageWrapper>
  );
};

export default MyPageWrap;
