import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as D from '../Common/Display';
import { Text } from '../Common/Text';
import starIcon from '../../assets/icons/utils/ico-star_yellow.svg';
import { cdnUrl } from '../../constants/url';
import defaultUser from '../../assets/icons/user/ico-profile_72.svg';

const S = {
  Profile: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.gray50};
    border: 1px solid ${(props) => props.theme.colors.gray300};
    overflow: hidden;
  `,
  Score: styled.span`
    position: relative;
    padding-left: 23px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: 16px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(${starIcon}) center no-repeat;
    }
  `,
};

/**
 * 리뷰어 프로필 컴포넌트
 */
export default function ReviewProfile({ name, date, score, profile, _size = 'normal' }) {
  return (
    <D.FlexRows _gap={8} _items="center">
      <S.Profile>
        {profile ? <img src={cdnUrl(profile, 'h=40&q=80')} /> : <img src={defaultUser} style={{ width: '100%' }} />}
      </S.Profile>
      <D.FlexCols _gap={4} _items="flex-start">
        <Text _size={_size === 'normal' ? 'l' : 'xl'} _weight="medium">
          {name}
        </Text>
        <D.FlexRows _gap={8}>
          <Text _size="s" _color="gray600">
            {date}
          </Text>
          <S.Score>{score}</S.Score>
        </D.FlexRows>
      </D.FlexCols>
    </D.FlexRows>
  );
}
