import React from 'react';
import { FlexRows } from '../Display';
import styled from 'styled-components';
import prevIcon from '../../../assets/icons/utils/ico-page_prev.svg';
import nextIcon from '../../../assets/icons/utils/ico-page_next.svg';

const S = {
    Page: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        border-radius: 4px;
        font-weight: ${props => props.theme.fontWeight.medium};
        background-color: ${props => props.active? props.theme.colors.purple50 : '#FFF'};
        color: ${props => props.theme.colors[props.active? 'purple1' : 'gray900']};
    `
}

/**
 * 페이지네이션 컴포넌트
 */
export default function Pagination({
    pageLen=5,
    startPage=1,
    activePage=1,
    onPrevClick,
    onNextClick,
    onPageClick
}) {

  return (
    <FlexRows _gap={8} _items="center">
        {
            !(startPage === 1 && pageLen === 1) &&
            <S.Page onClick={() => {
                if(activePage > 1) onNextClick(activePage-1);
                onPrevClick()
            }}><img src={prevIcon} alt="이전 페이지"/></S.Page>
        }
        {
            new Array(pageLen).fill(0).map((_, idx) => {
                const page = startPage + idx;

                return (
                    <S.Page 
                        key={idx}
                        active={page === activePage}
                        onClick={() => {onPageClick(page)}}
                    >{page}</S.Page>
                )
            })
        }
        {
            !(startPage === 1 && pageLen === 1) &&
            <S.Page onClick={() => {
                if(pageLen > activePage) onNextClick(activePage+1);
            }}><img src={nextIcon} alt="다음 페이지"/></S.Page>
        }
    </FlexRows>
  )
}
