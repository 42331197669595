import React from 'react';
import styled from 'styled-components';
import * as D from '../Common/Display';
import * as T from '../Common/Text';
import ReviewStars from './ReviewStars';
import { useSetRecoilState } from 'recoil';
import { reviewModal } from '../../store/modal';
import moment from 'moment';
import { useUserInfoQuery } from '../../hooks/queries/user';

const S = {
  ReviewCard: styled.article`
    width: 100%;
    padding: 24px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.gray50};

    > a {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      height: 100%;
    }
  `,
  Star: styled.img`
    width: 17px;
    height: 17px;
  `,
  Pics: styled.div`
    display: block;
    width: 100%;
    height: 120px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 6px;
    ${D.xScrollBoxCss(4, 'gray300')}

    ul {
      width: max-content;
      display: flex;
      gap: 16px;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border-radius: 8px;
        overflow: hidden;

        > img {
          min-height: 100%;
          max-height: 120%;
        }
      }
    }
  `,
};

/**
 * 리뷰 카드 컴포넌트
 * @param {object} props.review 리뷰 데이터 
 * @description 마이페이지에서 사용하는 리뷰 컴포넌트 입니다.
 */
export default function ReviewCard02({ review }) {
  const setReviewModal = useSetRecoilState(reviewModal);

  return (
    <S.ReviewCard key={review.id}>
      <a
        onClick={() => {
          setReviewModal({ id: 1, contents: review });
        }}>
        <D.FlexRows gap={15} _justify="space-between">
          <D.FlexCols _width="calc(100% - 85px)">
            <T.Text _size="xl" _weight="bold">
              {review.hotelName}
            </T.Text>
            <T.Text _size="xs" _color="gray600">
              {moment(review.reviewCreateDate).format('YYYY.MM.DD')}
            </T.Text>
          </D.FlexCols>
          <ReviewStars score={review.star} size="s" />
        </D.FlexRows>
        <T.LinelimitText as="p" line={2} _height={48} _size="s" _color="gray800" _space="pre-wrap">
          {review.contents}
        </T.LinelimitText>
        <T.Text _size="s" _weight="bold">
          {review.hotelName}
        </T.Text>
        {review.image.length > 0 && (
          <S.Pics>
            <ul>
              {review.image.map((pic, idx) => (
                <li key={idx}>
                  <img src={`https://cdn.alabwaba.com${pic}`} />
                </li>
              ))}
            </ul>
          </S.Pics>
        )}
      </a>
    </S.ReviewCard>
  );
}
