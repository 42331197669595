import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as D from '../../../components/Common/Display';
import Details from '../../../components/Space/Details';
import { customAlert } from '../../../store/modal';
import { floatingChatUi } from '../../../store/ui';

/**
 * 공간 상세 페이지
 */
const SpaceDetails = () => {
  const { spaceId } = useParams();
  const setAlert = useSetRecoilState(customAlert);
  const navigate = useNavigate();
  const setFloatingChat = useSetRecoilState(floatingChatUi);

  useEffect(() => {
    // 유효한 접근이 아닐 때 경고창 띄우고 이전 페이지로 이동
    if (isNaN(spaceId)) {
      setAlert({
        text: '잘못된 접근입니다.',
        handleClose: () => {
          navigate(-1);
        },
      });
    } else {
      // 유효한 접근일 때, floating chat 설정
      setFloatingChat({ id: Number(spaceId) });
    }
  }, [spaceId]);

  return (
    <D.Container _mx_width="1220px" _mn_height="100vh" _padding="80px 20px" _media="tabletSm" _m_padding="0 0 100px">
      {!isNaN(spaceId) && <Details id={spaceId} />}
    </D.Container>
  );
};

export default SpaceDetails;
