import React, { useState } from 'react';
import styled from 'styled-components';

import LayoutModal from './LayoutModal';
import { Text } from '../../../../components/Common/Text';
import { useRecoilValue } from 'recoil';
import { spaceDetailsState } from '../../../../store/space';

const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(98px, 1fr));
  justify-content: space-between;
  gap: 24px;

  @media (${(props) => props.theme.media.tabletSm}) {
    gap: 8px;
  }
`;

const Button = styled.button`
  padding: 30px 16px;
  border: 1px solid
    ${(props) => (props.isSelected ? props.theme.colors.mainPurple : props.theme.colors.gray300)};
  border-radius: 20px;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.gray800};
  min-width: calc(33% - 15px);

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 30px 7.5px;
  }
`;

// component: 테이블 타입 선택 modal
// /space/book/:id 페이지에 노출, selectBox 컴포넌트의 hideBox에서 호출
function SelectTableTypeModal({ setArrangementId, closeModal }) {
  // 실시간으로 저장하는 호텔 예약 정보
  const spaceDetails = useRecoilValue(spaceDetailsState);
  const [selected, setSelected] = useState(null);

  return (
    <LayoutModal
      title="테이블 타입 선택"
      b_text="확인"
      padding="24px 40px 80px"
      m_padding="20px 20px 80px"
      closeModal={closeModal}
      onClick={() => {
        setArrangementId(selected);
        closeModal();
      }}
    >
      <List>
        {spaceDetails.arrange.map((item) => (
          <Button
            key={item.spaceArrangementId}
            isSelected={selected === item.spaceArrangementId}
            onClick={() => setSelected(item.spaceArrangementId)}
          >
            <Text _size="l" _weight="normal" _color="gray800" _space="pre-line">
              {`${item.arrangementName}\n(최대 ${item.arrangementPeople}명)`}
            </Text>
          </Button>
        ))}
      </List>
    </LayoutModal>
  );
}

export default SelectTableTypeModal;
