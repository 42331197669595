import { authConfig, client } from '.';

const URL = {
  join: '/api/signup',
  login: '/api/login',
  findId: '/api/find/email',
  checkId: '/api/check/id',
  checkNewPhone: '/api/check/phone',
  findPwd: '/api/find/password',
  newPwd: '/api/password/update',
  authPass: '/api/pass',
  authcheck: '/api/pass/info',
  info: '/api/member/info',
  infoUpdate: '/api/member/update',
  profileUpdate: '/api/member/profile/update',
  aliemList: '/api/notification/list',
  aliemRead: '/api/notification/read',
  aliemNew: '/api/notification/new',
  withdraw: 'api/member/delete',
};
// 본인인증 요청
export const getAuthPass = () => client.get(URL.authPass);
// 본인인증 결과
export const getAuthCheck = (token) => client.post(URL.authcheck, {}, { params: { mdl_tkn: token } });
// 아이디 요청
export const getUserId = (phone) => client.post(URL.findId, { phone: phone });
// 가입 요청
export const requestJoin = (formData) => client.post(URL.join, formData);
// 로그인 요청
export const requestLogin = (formData) => client.post(URL.login, formData);
// 휴대폰 가입 이력 확인
export const checkNewPhone = (phone) => client.post(URL.checkNewPhone, { phone: phone });
// 아이디 가입 여부 확인
export const checkValidId = (id) => client.post(URL.checkId, { username: id });
// 휴대폰-아이디 가입 여부 확인
export const checkValidAccount = (formData) => client.post(URL.findPwd, formData);
// 새 패스워드 등록
export const registerNewPwd = (formData) => client.post(URL.newPwd, formData);
// 회원 정보
export const getUserInfo = (token) => {
  return client.get(URL.info, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// 회원 정보 수정
export const updateUserInfo = (formData, token) => client.post(URL.infoUpdate, formData, authConfig(token));
// 프로필 이미지 (변경)
export const updateUserProfile = (formData, token) =>
  client.post(URL.profileUpdate, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
// 탈퇴 요청
export const requestWithdraw = (params) => client.post(URL.withdraw, params);

// 알림 목록 요청
export const getAliemList = (params) => client.post(URL.aliemList, params);

// 알림 읽음 처리 요청
export const updateAliemRead = (params) => client.post(URL.aliemRead, params);

// 새 알림 여부 요청
export const getAliemHasNew = (token) => client.get(URL.aliemNew, authConfig(token));
