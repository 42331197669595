import React from 'react';
import leftArrow from '../assets/icons/utils/ico-arrow_left.svg';
import { Text } from '../components/Common/Text';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const S = {
  Header: styled.header`
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 80px;
    padding: 0 16px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};

    @media (${(props) => props.theme.media.tablet}) {
      height: 56px;
    }
  `,
  Inner: styled.div`
    position: relative;
    ${(props) => props.theme.media.PC}
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a,
    button {
      position: absolute;
      top: 24px;
      left: 0;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (${(props) => props.theme.media.tablet}) {
      a,
      button {
        top: 18px;
      }
    }
  `,
};

/**
 * 서브헤더 컴포넌트
 * @param {string} param.title - 서브헤더 타이틀
 * @param {function} param.handleClose - 닫기 버튼 클릭시 실행할 함수 
 */
export default function SubHeader({ title, handleClose }) {
  return (
    <S.Header>
      <S.Inner>
        {handleClose ? (
          <button
            onClick={() => {
              handleClose();
            }}>
            <img src={leftArrow} alt="닫기" />
          </button>
        ) : (
          <Link to={-1}>
            <img src={leftArrow} alt="닫기" />
          </Link>
        )}
        <Text _size="xl" _weight="medium">
          {title}
        </Text>
      </S.Inner>
    </S.Header>
  );
}
