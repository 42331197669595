import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import router from './Router';
import GlobalStyles from './theme/GlobalStyles';
import Theme from './theme/GlobalTheme';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './hooks/queries';

const root = ReactDOM.createRoot(document.getElementById('root'));
const client = queryClient();

root.render(
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <RecoilRoot>
      <QueryClientProvider client={client}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </RecoilRoot>
  </ThemeProvider>
);
