import styled from 'styled-components';

/**
 * 레이아웃 컴포넌트
 */
const GlobalWrapper = styled.div`
  z-index: 1;
  position: inherit;
  width: 100%;
  height: 100%;
  background-color: #fff;

  header {
    z-index: 30;
  }

  aside {
    z-index: 40;
  }

  footer {
    z-index: 10;
  }

  > main {
    z-index: 20;
    position: relative;
    width: 100%;
    height: auto;
  }
`;

export default GlobalWrapper;
