import jwt_decode from "jwt-decode";
import React, { useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { snsJoinState } from "../../../store/modal";
import { useUser } from "../../../store/user";

const authParams = {
  auth: "isAuth",
  token: "token",
};
const expireParam = "tokenExpiresIn";

/**
 * SNS 인증 컴포넌트
 */
export default function SnsAuth() {
  const [searchParams, _] = useSearchParams();
  const setSnsJoin = useSetRecoilState(snsJoinState);
  const { login } = useUser();

  const removeParamsHistory = (paramKeys) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    for (const key of Array.isArray(paramKeys) ? paramKeys : [paramKeys]) {
      params.delete(key);
    }

    const rpUrl = `${url.origin}${params.toString() && `?${params.toString()}`}`;
    window.history.replaceState(null, "", rpUrl);
  };

  const handleAuth = async (auth, token) => {
    const decoded = jwt_decode(token);
    if (decoded) {
      if (auth) {
        login({ token: token });
      } else {
        setSnsJoin({
          email: decoded.email,
          provider: decoded.provider,
          providerId: decoded.providerId,
        });
      }
    }
  };

  useLayoutEffect(() => {
    // before auth
    if (searchParams.has(authParams.auth) && searchParams.has(authParams.token)) {
      handleAuth(searchParams.get(authParams.auth) === "true", searchParams.get(authParams.token));
      removeParamsHistory(Object.keys(authParams).map((key) => authParams[key]));
    }

    // after auth
    if (searchParams.has(expireParam)) {
      removeParamsHistory(expireParam);
    }
  }, [searchParams]);

  return <></>;
}
