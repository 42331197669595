import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */
    // reset css
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
      display: block;
    }
    ol, ul {
      list-style: none;
    }
    blockquote, q {
      quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

	* {
		box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
		  font: 15px/1.5 Pretendard;
      font-weight: 400;
      color: #212121;
      letter-spacing: -0.5px;
      overflow-x: hidden;
    }

    a, 
    a:visited {
        text-decoration: none;
        color: #212121;
        cursor: pointer;
    }

    button {
      background-color: transparent;
      cursor: pointer;
      border: none;
      color: #212121;
      font-size: 1rem;
	  	font-family: Pretendard;
    }

    button:disabled {
      cursor: default;
    }

    a * , button * {
      color: #212121;
    }

    input,
    textarea {
        border: 0;
    }

    button,
    input,
    textarea,
    input::placeholder,
    textarea::placeholder {
		font-family: Pretendard;
    }

    input:focus,
    textarea:focus,
    input:active,
    textarea:active {
        outline: none;
    }

    strong {
        font-weight: 700;
    }

    ul, ol, li {
        list-style: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
      background: transparent;
    }
`;

export default GlobalStyles;
