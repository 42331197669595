import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import HiddenInfo from '../../../../components/Common/HiddenInfo';
import HotelBox from './HotelBox';
import { hotelOptionState, defaultHotelOptionState, stringPeriodState2 } from '../../../../store/request';

const HideDiv = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

// component: 날짜별 호텔 제안 옵션 box
// /space/request 페이지, SelectOptionsByDate에서 노출
function HotelList() {
  // period 설정되지 않았을 때 주의(이용 날짜)
  const period = useRecoilValue(stringPeriodState2);
  // 호텔 제안 옵션 초기 설정값(local component)
  const defaultHotelOption = useRecoilValue(defaultHotelOptionState);
  const [selectOption, setSelectOption] = useState(defaultHotelOption);
  // 상위 컴포넌트(index.js)에서 사용하는 호텔 제안 옵션 설정
  const setHotelOption = useSetRecoilState(hotelOptionState);

  useEffect(() => {
    setHotelOption(selectOption);
  }, [selectOption, setHotelOption]);

  return (
    <HideDiv>
      {/* toggle button */}
      {period?.map((date, index) => (
        <HiddenInfo key={index} title={date} _hidden={true}>
          {/* 호텔 제안 옵션 설정 목록 */}
          <HotelBox index={index} date={date} selectOption={selectOption} setSelectOption={setSelectOption} />
        </HiddenInfo>
      ))}
    </HideDiv>
  );
}

export default HotelList;
