import React from 'react';
import styled, { css } from 'styled-components';

const S = {
  RowTab: {
    Wrapper: styled.div`
      width: 100%;
      padding: 0 16px;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    `,
    Tabs: styled.div`
      display: flex;
      gap: 8px;
      width: 100%;
    `,
    Tab: styled.button`
      position: relative;
      width: ${(props) => `calc(${100 / props.length}% - ${(8 * (props.length - 1)) / props.length}px)`};
      height: 42px;
      text-align: center;
      color: ${(props) => props.theme.colors[props.active ? 'gray900' : 'gray500']};
      /* font-size: ${(props) => props.theme.fontSize.s}; */
      font-weight: ${(props) => props.theme.fontWeight[props.active ? 'bold' : 'medium']};
      line-height: ${(props) => props.theme.lineHeight.xxs};

      ${(props) =>
        props.active &&
        css`
          &::before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: ${(props) => props.theme.colors.purple1};
          }
        `}
    `,
  },
};

/**
 * 탭 컴포넌트
 */
export function RowTab({ tabs, activeTab }) {
  return (
    <S.RowTab.Wrapper>
      <S.RowTab.Tabs>
        {tabs.map((tab, idx) => (
          <S.RowTab.Tab
            key={idx}
            onClick={(e) => {
              e.preventDefault();
              tab.onClick(idx);
            }}
            active={idx === activeTab}
            length={tabs.length}>
            {tab.text}
          </S.RowTab.Tab>
        ))}
      </S.RowTab.Tabs>
    </S.RowTab.Wrapper>
  );
}
