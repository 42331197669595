import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/icons/logo/ico-logo_136.svg';
import { SITE } from '../constants/site';

const FooterWrap = styled.footer`
	display: flex;
	width: 100%;
	padding: 50px 0;
	border: 1px solid ${(props) => props.theme.colors.gray200};
	overflow: hidden;
	@media (${(props) => props.theme.media.tabletSm}) {
		display: none;
	}
`;
const FooterWrapper = styled.div`
	max-width: 1220px;
	width: 100%;
	padding: 0 20px;
	margin: auto;

	display: flex;
	align-items: center;
	gap: 40px;
	.logo-img {
		width: 117px;
		height: 34px;
	}

	@media (${(props) => props.theme.media.tabletSm}) {
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;

		.logo-img {
			width: 100px;
			height: auto;
		}
	}
`;

const Column = styled.div`
	flex: 2;
`;

const Row = styled.div`
	display: flex;
	&:first-child {
		justify-content: flex-start;
		align-items: center;
		gap: 16px;
		color: ${(props) => props.theme.colors.gray900};
		font-weight: ${(props) => props.theme.fontWeight.medium};
		font-size: ${(props) => props.theme.fontSize.s};
		margin-bottom: 12px;
		@media (${(props) => props.theme.media.tabletSm}) {
			gap: 12px;
		}
	}
	&:nth-child(2) {
		flex-direction: column;
		justify-content: center;
		color: ${(props) => props.theme.colors.gray700};
		font-weight: ${(props) => props.theme.fontWeight.normal};
		font-size: ${(props) => props.theme.fontSize.xxs};
		margin-bottom: 8px;
	}
	&:last-child {
		flex-direction: column;
		color: ${(props) => props.theme.colors.gray500};
		font-weight: ${(props) => props.theme.fontWeight.normal};
		font-size: ${(props) => props.theme.fontSize.xxxs};
	}
`;

/**
 * 글로벌 Footer
 */
function Footer() {
	const { pathname } = useLocation();
	const exception = pathname === '/partner/request';

	return (
		<FooterWrap exception={exception}>
			<FooterWrapper>
				<img className="logo-img" src={logo} alt="alababa-logo" />
				<Column>
					<Row>
						<Link to={SITE.useRule} replace>
							이용약관
						</Link>
						<Link to={SITE.privacyRule} replace>
							개인정보처리방침
						</Link>
						{/* <Link to={SITE.company} replace>
              회사소개
            </Link> */}
						<Link to={SITE.manage} replace target="_blank">
							내 업체 관리
						</Link>
					</Row>
					<Row>
						<span>
							주소 : 경기도 안양시 동안구 엘에스로 136 금정역2차 SKV1타워 A동 1306호 | 대표자명 : 신규호 | 사업자등록번호:
							828-86-02773
						</span>
						<span>
							​통신판매번호 : 2023-안양동안-0740 | 전화번호: 031-387-9123 | 전자우편주소 : alababa_co@naver.com​
						</span>
					</Row>
					<Row>
						<span>
							알아봐봐는 통신판매중개자로서 통신판매의 당사자가 아니며, 상품의 예약, 이용 및 환불 등과 관련한 의무와
							책임은 각 판매자에게 있습니다​
						</span>
						<span>Copyright 2022. alababa All rights reserved</span>
					</Row>
				</Column>
			</FooterWrapper>
		</FooterWrap>
	);
}

export default Footer;
