import React from 'react';
import styled from 'styled-components';

const S = {
  Section: styled.section`
    display: flex;
    flex-direction: column;

    @media (${(props) => props.theme.media.tablet}) {
      padding: 60px 20px 120px;
    }
  `,
  SectionTop: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.purple1};
    margin-bottom: 40px;
  `,
  Title: styled.h3`
    font: ${(props) => props.theme.textStyle.mainTitle};
    line-height: 1.1;

    @media (${(props) => props.theme.media.tablet}) {
      font-size: ${(props) => props.theme.fontSize.xxl};
    }
  `,
};

/**
 * 마이페이지 섹션 레이아웃 컴포넌트
 */
export default function MyPageSection({ title, meta, children }) {
  return (
    <S.Section>
      {title && (
        <S.SectionTop>
          <S.Title>{title}</S.Title>
          {meta && meta}
        </S.SectionTop>
      )}
      {children}
    </S.Section>
  );
}
