import React, { useState } from 'react';
import MyPageSection from '../../../components/MyPage/MyPageSection';
import RequestDetails from '../../../components/MyPage/Request/RequestDetails';
import { Text } from '../../../components/Common/Text';
import { FlexRows } from '../../../components/Common/Display';
import editIcon from '../../../assets/icons/utils/ico-pencil.svg';
import { Link, useParams } from 'react-router-dom';
import { spaceRequestMod } from '../../../constants/site';

/**
 * 마이페이지 > 마이 의뢰 > 의뢰 상세 페이지
 */
export default function MyRequestDetails() {
  const { requestId } = useParams();
  const [onGoing, setOnGoing] = useState(false);

  return (
    <MyPageSection
      title="의뢰 상세"
      meta={
        onGoing ? (
          <FlexRows as={Link} to={spaceRequestMod(requestId)} _gap={6} _items="center">
            <img src={editIcon} alt=" 수정" />
            <Text _size="l" _weight="medium">
              수정 하기
            </Text>
          </FlexRows>
        ) : (
          <></>
        )
      }>
      <RequestDetails id={requestId} handleModifyMode={setOnGoing} />
    </MyPageSection>
  );
}
