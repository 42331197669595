import React from 'react';
import styled from 'styled-components';

const S = {
  NormalBadge: styled.span`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    height: 18px;
    line-height: 1;
    padding: 0 8px;
    color: ${(props) => props.theme.colors[props._color ?? 'blue']};
    border-radius: 11px;
    font-size: ${(props) => props.theme.fontSize.xxs};
    font-weight: ${(props) => props.theme.fontWeight.bold};

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background-color: transparent;
      border: 1.2px solid ${(props) => props.theme.colors[props._color ?? 'blue']};
      box-sizing: border-box;
    }
  `,
  BgBadge: styled.span`
    display: inline-block;
    padding: 8px 16px;
    height: 28px;
    width: max-content;
    line-height: 1;
    background-color: ${(props) => props.theme.colors.purple50};
    border-radius: 15px;
    font-size: ${(props) => props.theme.fontSize.s};
  `,
  ColorBadge: styled.span`
    z-index: 10;
    position: relative;
    padding: 0 8px;
    height: 26px;
    width: max-content;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    background: transparent;
    color: ${(props) => props._color.color};
    overflow: hidden;
    border: 0;

    &::before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: #fff;
      border-radius: 20px;
    }

    &::after {
      z-index: 13;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background-color: transparent;
      border: 2px solid ${(props) => props._color.background};
      box-sizing: border-box;
    }
  `,
};

export function Badge({ text, _color }) {
  return <S.NormalBadge _color={_color}>{text}</S.NormalBadge>;
}

export function BgBadge({ text }) {
  return <S.BgBadge>{text}</S.BgBadge>;
}

const colorChip = {
  blue: { background: '#477AFE', color: '#3565E1' },
  purple: { background: '#A640EA', color: '#8851CA' },
  gray: { background: '#9E9E9E', color: '#616161' },
  red: { background: '#FF5757', color: '#EF5353' },
  orange: { background: '#F29A48', color: '#F29A48' },
};

/**
 * 컬러 뱃지 컴포넌트
 * @description 예약 목록, 상세 등에서 사용하는 컴포넌트 입니다.
 */
export function ColorBadge({ _color, text }) {
  return <S.ColorBadge _color={colorChip[_color] ?? colorChip['blue']}>{text}</S.ColorBadge>;
}
