import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import * as D from '../../Common/Display';
import CountTab from '../../Common/CountTab';
import RequestCard from './RequestCard';
import { resetScroll, useRefScrollUp, useScrollUp } from '../../../hooks/ui/scroll';
import { useEditRequestData, useMemberRequestCountsQuery, useRequestQuery } from '../../../hooks/queries/mypage';
import { useInView } from 'react-intersection-observer';
import { Text } from '../../Common/Text';
import { Line } from '../../Common/Line';
import { myRequestCategory, myRequestList } from '../../../constants/site';
import { useNavigate } from 'react-router-dom';
import { ErrorStatus, LoadingStatus } from '../../Common/Status';

/*
    [sub] 0201
    - 카테고리 공통 컴포넌트 작성
*/

const S = {
  RequestCard: {
    Wrapper: styled.article`
      display: flex;
      flex-direction: row;
      gap: 60px;
      width: 100%;
      // height: 78px;
      padding: 24px 20px;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    `,
    Badges: styled.ul`
      display: flex;
      gap: 8px;
      margin-top: 8px;

      li {
        padding: 2px 8px;
        border: 1px solid ${(props) => props.theme.colors.pink600};
        border-radius: 20px;
        color: ${(props) => props.theme.colors.pink600};
        font-size: ${(props) => props.theme.fontSize.xxs};
        font-weight: ${(props) => props.theme.fontWeight.medium};
      }
    `,
    Apply: styled.button`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 126px;
      height: 65px;
      border: 1px solid ${(props) => props.theme.colors.gray300};
      border-radius: 20px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

      span {
        line-height: 20px;
        font-size: ${(props) => props.theme.fontSize.s};
      }

      strong {
        font: ${(props) => props.theme.textStyle.mainTitle};
        line-height: 30px;
      }
    `,
  },
};

const ctgParams = {
  all: '전체',
  end: '지원 종료',
};

/**
 * 마이페이지 > 의뢰 목록 컴포넌트
 */
export default function RequestList({ category }) {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState(
    myRequestCategory.map((ctg, _) => ({ name: ctgParams[ctg], category: ctg, count: null, accent: false }))
  );
  const [page, setPage] = useState([]);
  const { data: count, refetch: requestCount, isLoading: isCountLoading } = useMemberRequestCountsQuery();
  const { data, isFetching, isFetchingNextPage, fetchNextPage, status, hasNextPage } = useRequestQuery(category);
  const [ref, inView] = useInView();
  const [scrollRef, _] = useRefScrollUp();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  useEffect(() => {
    if (!data) return;
    let arr = [];
    data.pages.map((x) => {
      arr.push(...x.products);
    });
    setPage(arr);
  }, [data]);

  useEffect(() => {
    if (count) {
      setTabs((tabs) => tabs.map((tb, _) => ({ ...tb, count: count[tb.category] })));
    }
  }, [count]);

  useEffect(() => {
    requestCount();
  }, [category]);

  if (!count) return null;

  return (
    <D.FlexCols _gap={40} _width="100%">
      <CountTab
        tabs={tabs}
        activeIdx={tabs.findIndex((tb) => tb.category === category)}
        onTabClick={(tabIdx) => {
          navigate(myRequestList(tabs[tabIdx].category), { relative: false });
        }}
      />
      {status === 'loading' && !(count[category] && count[category] === 0) ? (
        <LoadingStatus box />
      ) : page.length > 0 ? (
        <D.FlexCols _width="100%" ref={scrollRef}>
          {page.map((req, idx) => (
            <RequestCard data={req} key={idx} />
          ))}
          <div ref={ref}>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
        </D.FlexCols>
      ) : (
        <ErrorStatus message="의뢰 내역이 없습니다." />
      )}
    </D.FlexCols>
  );
}
