import React from 'react';
import styled, { keyframes } from 'styled-components';
import * as D from '../Display';
import { Text } from '../Text';
import nameImg from '../../../assets/icons/loading/ico-text_only.svg';
import faceToLeft from '../../../assets/icons/logo/ico-face_to_left.svg';
import faceToRight from '../../../assets/icons/logo/ico-face_to_right.svg';
import { ModalOverlay } from '../../../layouts/Modal';

const K = {
  Loading: keyframes`
    0% { left: 0 }
    100% { left: calc(100% - 40px) }
  `,
  Letter: keyframes`
	0% { width: 40px }
	50% { width: 140px }
	70% { width: 140px }
	80% { width: 140px }
	90% { width:40px }
	100% { width: 40px }
  `,
  Letter2: keyframes`
	0% { width: 40px }
	50% { width: 0 }
	80% { width: 0 }
  100% {width: 40px}
  `,
  Face: keyframes`
		0%{ background: #7B59A3; }
		2%{ background: #7B59A3; }
		3%{ background: url(${faceToLeft}) center / 100% no-repeat; }
		4%{ background: url(${faceToLeft}) center / 100% no-repeat; }
		5%{ background: url(${faceToRight}) center / 100% no-repeat; }
		80% { background: url(${faceToRight}) center / 100% no-repeat;  }
		81% { background: url(${faceToLeft}) center / 100% no-repeat;  }
		90% { background: url(${faceToLeft}) center / 100% no-repeat;  }
		96% { background: #7B59A3; }
  	100% {background: #7B59A3; }
	`,
};

const S = {
  Loading: {
    Wrapper: styled.div`
      z-index: 10;
      position: relative;
      width: 140px;
      height: 40px;
    `,
    Back: styled.div`
      z-index: 11;
      position: relative;
      height: 40px;
      width: 40px;
      overflow-x: hidden;
      border-radius: 80px;
      overflow: hidden;
      background-color: ${(props) => props.theme.colors.purple600};
      animation: ${K.Letter} 1.5s 0s infinite forwards;

      // LETTER
      &::before {
        z-index: 12;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 140px;
        height: 40px;
        border-radius: 40px;
        background: url(${nameImg}) no-repeat;
        background-position: 44px 12px;
        border-radius: 40px;
      }

      // OVERLAY
      &::after {
        z-index: 13;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: ${(props) => props.theme.colors.purple600};
        animation: ${K.Letter2} 1.5s 0s infinite forwards;
      }
    `,
    Face: styled.div`
      z-index: 14;
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: #7b59a3;
      background-size: 100% 100% !important;
      animation: ${K.Face} 1.5s 0s infinite forwards;
    `,
  },
};

/**
 * ERRRO UI 컴포넌트
 */
export function ErrorStatus({ _height, message, isError }) {
  return (
    <D.FlexCols _width="100%" _height={_height ? '100%' : '50vh'} _justify="center" _items="center">
      <Text _color={isError ? 'error' : 'gray700'} _size="s">
        {message}
      </Text>
    </D.FlexCols>
  );
}

/**
 * LOADING UI 컴포넌트
 */
export function LoadingStatus({ _speed, box = false, _height }) {
  if (box) {
    return (
      <D.FlexCols _width="100%" _height={_height ?? '50vh'} _justify="center" _items="center">
        <S.Loading.Wrapper>
          <S.Loading.Back />
          <S.Loading.Face />
        </S.Loading.Wrapper>
      </D.FlexCols>
    );
  }
  return (
    <ModalOverlay visible={false}>
      <S.Loading.Wrapper>
        <S.Loading.Back />
        <S.Loading.Face />
      </S.Loading.Wrapper>
    </ModalOverlay>
  );
}
