import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as D from '../../Common/Display';
import CountTab from '../../Common/CountTab';
import BookingCard from './BookingCard';
import CardList from '../../Common/CardList';
import { useRefScrollUp } from '../../../hooks/ui/scroll';
import { useBookingCountQuery, useBookingListQuery, useBookingRead } from '../../../hooks/queries/mypage';
import { useInView } from 'react-intersection-observer';
import { myBookingCategory, myBookingList } from '../../../constants/site';
import { useNavigate } from 'react-router-dom';
import { ErrorStatus, LoadingStatus } from '../../Common/Status';

const ctgParams = {
  all: '전체',
  request: '예약 신청',
  confirm: '예약 확정',
  end: '이용 완료',
  cancel: '불가/취소',
};

/**
 * 마이페이지 > 예약 목록 컴포넌트
 */
export default function BookingList({ category }) {
  const navaigate = useNavigate();
  const [page, setPage] = useState([]);
  const [ref, inView] = useInView();
  const [tabs, setTabs] = useState(
    myBookingCategory.map((ctg, idx) => ({ name: ctgParams[ctg], category: ctg, count: null, accent: idx > 0 }))
  );
  const { data, isFetching, isFetchingNextPage, fetchNextPage, status, hasNextPage } = useBookingListQuery(
    ctgParams[category]
  );
  const { data: count, refetch: requestCount, isLoading: isCountLoading } = useBookingCountQuery();
  const { mutate: read, isSuccess: isReadSuccess } = useBookingRead();
  const [scrollRef, _] = useRefScrollUp();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  useEffect(() => {
    if (!data) return;
    let arr = [];
    data.pages.map((x) => {
      arr.push(...x.products);
    });
    setPage(arr);
  }, [data]);

  useLayoutEffect(() => {
    if (count) {
      const total = count.reduce((cts, data) => cts + data.cnt, 0);
      setTabs((tb) =>
        tb.map((tb, idx) =>
          idx === 0
            ? { ...tb, count: total }
            : {
                ...tb,
                count: count[idx - 1].cnt,
                accent: idx > 0 && !count[idx - 1].read,
              }
        )
      );
    }
  }, [count]);

  useLayoutEffect(() => {
    if (category) {
      read(ctgParams[category]);
    }
  }, [category]);

  useLayoutEffect(() => {
    requestCount();
  }, [isReadSuccess]);

  return isCountLoading ? (
    ''
  ) : (
    <D.FlexCols _gap={40} _width="100%">
      {tabs && (
        <CountTab
          tabs={tabs}
          activeIdx={tabs.findIndex((tb) => tb.category === category)}
          onTabClick={(tabIdx) => {
            navaigate(myBookingList(tabs[tabIdx].category), { relative: false });
          }}
        />
      )}
      {status === 'loading' &&
      !(
        count.find((v) => v.status === ctgParams[category]) &&
        count.find((v) => v.status === ctgParams[category]).cnt === 0
      ) ? (
        <LoadingStatus box />
      ) : page.length > 0 ? (
        <D.Container _media="laptop" _m_padding="0 20px 116px" ref={scrollRef}>
          <CardList
            count={3}
            gap={40}
            medias={[
              { media: 'laptop', count: 2 },
              { media: 'tabletSm', direction: 'column', gap: 40 },
            ]}>
            {page.map((data) => {
              return <BookingCard data={data} key={data.reservationId} />;
            })}
          </CardList>
          <div ref={ref}>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
        </D.Container>
      ) : (
        <ErrorStatus message={`${ctgParams[category]} 내역이 없습니다.`} />
      )}
    </D.FlexCols>
  );
}
