import React from 'react';
import ApplyDetails from '../../../components/MyPage/Apply/ApplyDetails';
import ApplyList from '../../../components/MyPage/Apply/ApplyList';
import MyPageSection from '../../../components/MyPage/MyPageSection';

/**
 * 마이페이지 > 마이 의뢰 > 지원자 목록 페이지
 */
export default function MyRequestApplys() {
  return (
    <MyPageSection title="지원자 리스트">
      <ApplyList />
      <ApplyDetails />
    </MyPageSection>
  );
}
