import { useQueries, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { getBanner, getHotel, getReviewMain, getSpaceHot } from '../../service/client/home';

const HOME_KEYS = {
  banner: ['banner'],
  hotel: ['hotel'],
  spaceHot: ['spaceHot'],
  reviewMain: ['reviewMain'],
};

// 배너 목록 요청 qeury
export const useBannerQuery = () => {
  return useQuery(HOME_KEYS.banner, async () => {
    const { data } = await getBanner();

    return data.data;
  });
};

// 홈 데이터 요청 query
export const useMainQuery = (token) => {
  const arr = [getHotel(token), getSpaceHot(token), getReviewMain(token)];

  const data = useQueries({
    queries: arr.map((x, index) => {
      return {
        queryKey: ['main', '_wish', index, token ? 1 : 0],
        queryFn: () => x,
        // enabled: false,
      };
    }),
  });

  const refetch = useCallback(() => {
    data.forEach((d) => d.refetch());
  }, [data]);

  return { data, refetch };
};

// 추천 인기공간 목록 요청 쿼리
export const useSpaceHotQuery = () => {
  return useQuery(HOME_KEYS.spaceHot, async () => {
    const { data } = await getSpaceHot();

    return data.data;
  });
};

//   export const useReviewMainQuery = (params) => {
//     return useQuery(HOME_KEYS.reviewMain, async () => {
//       const { data } = await getReviewMain();

//       return data.data;
//     });
// };
