import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

const stickyStyleFn = (el, top) => {
  if (!el || !top) {
    return;
  }
  const alreadySticky = getComputedStyle(el).position === 'sticky';
  if (!alreadySticky) {
    el.style.position = 'sticky';
  }
  el.style.top = top + 'px';
};

const getComputedNum = (value) => {
  return value ? Number(value.split('px')[0]) : 0;
};

/**
 * 스티키 포지션 구현 훅
 */
export const useSticky = (top) => {
  const elRef = useRef(null);
  const [sticky, setSticky] = useState(false);
  const [stickyTop, setStickyTop] = useState(null);
  const w = document.body;
  const wTop = () => w.scrollTop;

  useEffect(() => {
    let detectScroll = undefined;

    if (detectScroll) {
      w.removeEventListener('scroll', detectScroll);
    }

    if (stickyTop) {
      detectScroll = () => {
        setSticky(wTop() >= stickyTop);
      };
      w.addEventListener('scroll', detectScroll);
      detectScroll();
    }

    return () => {
      w.removeEventListener('scroll', detectScroll);
    };
  }, [stickyTop]);

  useEffect(() => {
    let resetStickyTop;

    if (elRef.current) {
      if (resetStickyTop) {
        window.removeEventListener('resize', resetStickyTop);
      }

      resetStickyTop = () => {
        let sTop = top ?? getComputedNum(getComputedStyle(elRef.current).top);
        stickyStyleFn(elRef.current, sTop);
        setStickyTop(sTop);
      };
      window.addEventListener('resize', resetStickyTop);
      resetStickyTop();
    }

    return () => {
      window.removeEventListener('resize', resetStickyTop);
    };
  }, [top, elRef]);

  return [elRef, sticky];
};

export const resetScroll = () => {
  window.scrollTo(0, 0);
  document.body.scrollTo(0, 0);
};

export const useScrollUp = () => {
  const location = useLocation();

  useEffect(() => {
    resetScroll();
  }, [location]);
};

export const useRefScrollUp = () => {
  const rRef = useRef(null);

  useEffect(() => {
    if (rRef.current) {
      resetScroll();
    }
  }, [rRef.current]);

  return [rRef, resetScroll];
};
