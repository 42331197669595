import axios from 'axios';

/**
 * Axios 인스턴스 생성
 */
export const client = axios.create({
	baseURL: 'https://alabwaba.com',
	timeout: 100000,
	headers: {
		'x-Requested-With': 'XMLHttpRequest',
		'Access-Control-Allow-Credentials': true,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Cache-Control': 'no-cache',
	},
	responseType: 'json',
});

const executingRequests = {};

// Axios request-interceptor 설정
client.interceptors.request.use(
	function (config) {
		const loginInfo = localStorage.getItem('ALBB_LOGIN');
		if (loginInfo && !config.headers.Authorization) {
			config.headers.Authorization = `Bearer ${JSON.parse(loginInfo).token}`;
		}
		return config;
	},
	function (request) {
		const currentRequest = request;

		if (executingRequests[currentRequest.url]) {
			const source = executingRequests[currentRequest.url];
			delete executingRequests[currentRequest.url];
			source.cancel();
		}

		const source = axios.CancelToken.source();
		currentRequest.CancelToken = source.token;
		executingRequests[currentRequest.url] = source;

		return currentRequest;
	}
);

// Axios response-interceptor 설정
client.interceptors.response.use(
	function (response) {
		if (executingRequests[response.request.reponseURL]) {
			delete executingRequests[response.request.reponseURL];
		}

		return response;
	},
	function (error) {
		const { config } = error;
		const originalRequest = config;

		if (axios.isCancel(error)) {
			console.error('cancel err: ', error);
			return new Promise(() => {});
		}

		if (executingRequests[originalRequest.url]) {
			delete executingRequests[originalRequest.url];
		}

		if (error.response) {
			console.log('err response: ', error.response);
			return error.response;
		} else {
			console.log('err message: ', error.message);
			return error.message;
		}
	}
);

client.defaults.withCredentials = true;

// token auth
export const authConfig = (token) => ({
	headers: {
		Authorization: `Bearer ${token}`,
	},
});
