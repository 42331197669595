import { atom, selector } from 'recoil';
import { STORE_KEYS } from '.';
import { MODAL_KEYS } from '.';

export const applyModal = atom({
  key: STORE_KEYS.applyModal,
  default: {
    id: null,
  },
});

export const applySelectModal = atom({
  key: STORE_KEYS.applySelectModal,
  default: {
    id: null,
  },
});

export const reviewModal = atom({
  key: STORE_KEYS.reviewModal,
  default: {
    id: null,
    contents: [],
  },
});

export const reportModal = atom({
  key: STORE_KEYS.reportModal,
  default: {
    id: null,
    stdBox: null,
  },
});

export const alertModal = atom({
  key: STORE_KEYS.alertModal,
  default: {
    display: false,
    text: '',
    handleClose: null,
  },
});

export const confirmModal = atom({
  key: STORE_KEYS.confirmModal,
  default: {
    display: false,
    title: '',
    text: '',
    cancel: '',
    confirm: '',
    handleConfirm: null,
    handleCancel: null,
  },
});

export const customAlert = selector({
  key: `custom_${STORE_KEYS.alertModal}`,
  get: ({ get }) => get(alertModal),
  set: ({ set, reset, get }, newValue) => {
    if (newValue === null) {
      reset(alertModal);
    } else {
      set(alertModal, {
        ...get(alertModal),
        ...newValue,
        display: true,
      });
    }
  },
});

export const customConfirm = selector({
  key: `custom_${STORE_KEYS.confirmModal}`,
  get: ({ get }) => get(confirmModal),
  set: ({ set, reset, get }, newValue) => {
    if (newValue === null) {
      reset(confirmModal);
    } else {
      set(confirmModal, {
        ...get(confirmModal),
        ...newValue,
        display: true,
      });
    }
  },
});

export const aliemModalState = atom({
  key: MODAL_KEYS.aliemModal,
  default: false,
});
export const picViewerModal = atom({
  key: STORE_KEYS.picViewrModal,
  default: {
    mode: false,
    srcs: [],
  },
});

export const loginModalState = atom({
  key: MODAL_KEYS.loginModal,
  default: {
    mode: false,
    redirect: null,
  },
});
export const findIdModalState = atom({
  key: MODAL_KEYS.findIdModal,
  default: false,
});
export const findPwModalState = atom({
  key: MODAL_KEYS.findPwModal,
  default: false,
});

// 회원가입, SNS 회원가입
export const joinModalState = atom({
  key: MODAL_KEYS.joinModal,
  default: {
    mode: false,
    sns: null,
  },
});
export const snsJoinState = selector({
  key: `${MODAL_KEYS.joinModal}_sns`,
  get: ({ get }) => get(joinModalState).sns ?? null,
  set: ({ set }, newValue) => {
    const email = newValue.email;
    const prv = newValue.provider;
    const prvId = newValue.providerId;

    set(joinModalState, {
      mode: true,
      ...(email &&
        prv &&
        prvId && {
          sns: {
            email: email,
            provider: prv,
            providerId: prvId,
          },
        }),
    });
  },
});

export const searchModalState = atom({
  key: MODAL_KEYS.searchModal,
  default: false,
});
export const scheduleModalState = atom({
  key: MODAL_KEYS.scheduleModal,
  default: false,
});
export const conditionsModalState = atom({
  key: MODAL_KEYS.conditionsModal,
  default: false,
});

export const asideModalState = atom({
  key: MODAL_KEYS.asideModal,
  default: false,
});
