import React from 'react';
import styled from 'styled-components';
import * as T from '../Common/Text';
import * as D from '../Common/Display';
import { Badge } from '../Common/Badge';
import PeopleIcon from '../../assets/icons/utils/ico-people.svg';
import { LikeBtn } from '../Common/Button';
import { spaceBook } from '../../constants/site';
import { Link } from 'react-router-dom';
import { SpaceBookmark } from '../Common/BookMark';
import { useResponsiveImg } from '../../hooks/ui/image';
import { cdnUrl } from '../../constants/url';

const S = {
	Card: styled(Link)`
		width: 100%;
		height: 200px;
		display: flex;
		border-radius: 50px;
		overflow: hidden;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
		background-color: rgba(245, 229, 248, 0.1);
		transition: all 0.25s linear;

		@media (${(props) => props.theme.media.tabletSm}) {
			flex-direction: column;
			height: 383px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
		}

		&:hover {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		}
	`,
	Thumbnail: styled.div`
		position: relative;
		width: 300px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${(props) => props.theme.colors.gray50};
		overflow: hidden;

		@media (${(props) => props.theme.media.tabletSm}) {
			width: 100%;
			height: 200px;
		}

		img {
			height: 100%;
		}
	`,
	Heart: styled.div`
		position: absolute;
		top: 8px;
		right: 8px;
		@media (${(props) => props.theme.media.tabletSm}) {
			top: 24px;
			right: 24px;
		}
	`,
	Contents: styled.div`
		display: flex;
		flex-direction: column;
		gap: 19px;
		flex: 1;
		padding: 24px 24px 14px;

		@media (${(props) => props.theme.media.tabletSm}) {
			padding: 24px;
			gap: 8px;
		}
	`,
};

/**
 * 공간 목록 카드 컴포넌트
 * @param {object} props.room 공간 데이터 
 */
export default function RoomCard({ room }) {
	const [imgRef, src] = useResponsiveImg(cdnUrl(room.thumbnail));

	return (
		<S.Card to={spaceBook(room.spaceId)}>
			<S.Thumbnail ref={imgRef}>
				<img src={src} alt={''} />
				<S.Heart>
					<SpaceBookmark type="space" styleType="icon" id={room.spaceId} like={room.bookmark} />
				</S.Heart>
			</S.Thumbnail>
			<S.Contents>
				<D.FlexCols _gap={8}>
					<T.Text _size="xxxl" _weight="medium" _line="xxs" _media="tabletSm" _m_size="xl">
						{room.spaceName}
					</T.Text>
					<T.LinelimitText line={2} _height={44} _media="tabletSm" _m_size="s">
						{room.introduction}
					</T.LinelimitText>
				</D.FlexCols>
				<D.FlexRows _gap={8} _items="center">
					{room.category.slice(0, 3).map((x, index) => (
						<Badge text={x} _color="pink600" key={index} />
					))}
					<D.FlexRows _gap={6} _items="center">
						<img src={PeopleIcon} alt="인원" />
						<T.Text _size="s">
							{room.minPeople}명 ~ {room.maxPeople}명
						</T.Text>
					</D.FlexRows>
				</D.FlexRows>
				<T.CostText
					cost={room.pricePerson.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
					per="1인 예상"
					_c_size="xxxl"
					_c_weight="bold"
				/>
			</S.Contents>
		</S.Card>
	);
}
