import React from 'react';

import marker from '../../../../assets/icons/utils/ico-search_location.svg';

import styled from 'styled-components';
import { InputField } from '../../../../components/Common/InputField';
import { FlexCols, FlexRows } from '../../../../components/Common/Display';
import { Text, LinelimitText } from '../../../../components/Common/Text';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  maxPriceState,
  minPriceState,
  searchLocationState,
  userSelectState,
} from '../../../../store/request';

import HotelList from './HotelList';
import UserList from './UserList';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoxDiv = styled(FlexCols)`
  padding: 64px 40px;
  width: 500px;
  align-items: center;
  gap: 60px;

  background: #ffffff;
  ${(props) => props.theme.effectStyle.boxShadow}
  border-radius: 50px;

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 40px 20px;
  }
`;

const TopDiv = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;

const LocationDiv = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 20px;

  .marker {
    width: 23px;
    height: 23px;
  }
  span {
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.colors.gray900};
  }
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const Button = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  border-radius: 10px;

  & > span {
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  background: ${(props) => props.theme.colors.gray50};
  // selected
  ${(props) =>
    props.isSelected &&
    `background: ${props.theme.colors.purple50};
    border: 2px solid ${props.theme.colors.mainPurple};
    color: ${props.theme.colors.mainPurple};`}
`;

const Message = styled.span`
  width: 100%;
  font-size: ${(props) => props.theme.fontSize.xxs};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray600};
  text-align: end;
`;

const InputDiv = styled(FlexRows)`
  & input {
    text-align: end;
  }
`;

// component: 날짜별 옵션 선택 box
// /space/request 페이지에 노출 및 호출
function SelectOptionsByDate({ setNowSteps }) {
  // 선호 위치에서 선택한 content
  const searchLocation = useRecoilValue(searchLocationState);
  // 직접 선택, 호텔 제안받기 유형인지 확인
  const [isUserSelect, setIsUserSelect] = useRecoilState(userSelectState);
  // 직접 선택하기에서 최소, 최대 비용
  const [minPrice, setMinPrice] = useRecoilState(minPriceState);
  const [maxPrice, setMaxPrice] = useRecoilState(maxPriceState);

  return (
    <Wrapper>
      <BoxDiv>
        <TopDiv>
          {/* box header */}
          <LocationDiv>
            <img className="marker" src={marker} alt="marker-img" />
            <button onClick={() => setNowSteps(2)}>
              <span>{searchLocation.length > 0 ? searchLocation : '선호 위치를 입력해 주세요'}</span>
            </button>
          </LocationDiv>

          <Row>
            <ButtonWrapper>
              <Button isSelected={isUserSelect === true} onClick={() => setIsUserSelect(true)}>
                <span>직접 선택하기</span>
              </Button>
              <Button isSelected={isUserSelect === false} onClick={() => setIsUserSelect(false)}>
                <span>*호텔 제안받기</span>
              </Button>
            </ButtonWrapper>
            <Message>*(호텔 제안 받기) 날짜별로 시간만 설정해 주시면 호텔의 제안을 받을 수 있습니다.</Message>
          </Row>

          {/* 옵션 직접 선택 box : 호텔 제안받기 box  */}
          {isUserSelect ? <UserList /> : <HotelList />}
        </TopDiv>

        {/* 최소, 최대 비용 입력 box */}
        {isUserSelect && (
          <FlexCols _gap={16} _items="start">
            <Text _weight="medium" _size="xxl" _color="gray900">
              가격범위
            </Text>
            <FlexCols _gap={8}>
              <InputDiv _gap={16} _justify="center">
                <InputField
                  label="최저"
                  name="min"
                  type="text"
                  placeholder="0원"
                  value={minPrice}
                  _width="calc(50% - 8px)"
                  _height="40px"
                  handleChange={(value) => (!isNaN(value) ? setMinPrice(+value) : '')}
                />
                <InputField
                  label="최고"
                  name="max"
                  type="text"
                  placeholder="0원"
                  value={maxPrice}
                  _width="calc(50% - 8px)"
                  _height="40px"
                  handleChange={(value) => (!isNaN(value) ? setMaxPrice(+value) : '')}
                  error={minPrice > maxPrice || (minPrice === 0 && maxPrice === 0)}
                  errorMsg={
                    (minPrice > maxPrice && '최저 금액보다 큰 금액을 입력해주세요.') ||
                    (minPrice === 0 && maxPrice === 0 && '0보다 큰 금액을 입력해주세요.')
                  }
                />
              </InputDiv>
              <LinelimitText _align="end" _color="gray700" _size="xxs" _weight="medium">
                *시간 당 공간 금액
              </LinelimitText>
            </FlexCols>
          </FlexCols>
        )}
      </BoxDiv>
    </Wrapper>
  );
}

export default SelectOptionsByDate;
