import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import HiddenInfo from '../../../../components/Common/HiddenInfo';
import { defaultUserOptionState, stringPeriodState2, userOptionState } from '../../../../store/request';
import UserBox from './UserBox';

const HideDiv = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

// component: 날짜별 직접 옵션 box
// /space/request 페이지, SelectOptionsByDate에서 노출
function UserList() {
  // 선택한 이용 날짜
  const period = useRecoilValue(stringPeriodState2);

  // 직접 선택하기 옵션 초기 설정값(local component)
  const defaultUserOption = useRecoilValue(defaultUserOptionState);
  const [selectOption, setSelectOption] = useState(defaultUserOption);
  // 상위 컴포넌트(index.js)에서 사용하는 사용자 옵션 설정
  const setUserOption = useSetRecoilState(userOptionState);

  // 옵션을 변경할 때마다 상위 컴포넌트 사용자 옵션 변경
  useEffect(() => {
    setUserOption(selectOption);
  }, [setUserOption, selectOption]);

  return (
    <HideDiv>
      {/* toggle button */}
      {period?.map((date, index) => (
        <HiddenInfo key={index} title={date} _hidden={true}>
          {/* 사용자 옵션 설정 목록 */}
          <UserBox index={index} date={date} selectOption={selectOption} setSelectOption={setSelectOption} />
        </HiddenInfo>
      ))}
    </HideDiv>
  );
}

export default UserList;
