import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  // width: 320px;
  // height: 18px;
  padding: 0 8px;
  font-size: ${(props) => props.theme.fontSize.xxs};
  font-weight: ${(props) => props.theme.fontWeight.normal};

  // input error
  color: ${(props) =>
    props.isError ? props.theme.colors.error : props.theme.colors.gray600};
`;

/**
 * STATUS MESSAGE 컴포넌트
 */
function Message({ isError, text }) {
  return <Span isError={isError}>{text}</Span>;
}

export default Message;
