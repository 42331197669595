import React, { useState } from 'react';

import styled from 'styled-components';
import LayoutModal from './LayoutModal';
import { Text } from '../../../../components/Common/Text';

const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 78px);
  gap: 8px;

  @media (${(props) => props.theme.media.tabletSm}) {
    width: 100%;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Time = styled.button`
  padding: 8px;
  width: 100%;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isSelected
      ? `
      background: ${props.theme.colors.purple50};
      border: 2px solid ${props.theme.colors.mainPurple};
      `
      : `
      background: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.gray300};
      `}
  ${(props) =>
    props.disabled &&
    `
    background: ${props.theme.colors.gray100};
    border: none;
    font-color: ${props.theme.colors.gray300};
    `}
`;

// 0 ~ 23시를 나타내는 문자를 가지는 배열
const times = new Array(24).fill(0).map((_, index) => {
  return {
    time: `${String(index).padStart(2, 0)}:00`,
  };
});

// component: 시간 선택 모달창(체크인, 체크아웃)
// /space/book/:id 페이지에 노출, selectBox 컴포넌트의 hideBox에서 호출
function SelectTimeModal({ closeModal, setTime, title }) {
  // 시간 선택
  const [selected, setSelected] = useState('');

  return (
    <LayoutModal
      title={title}
      b_text="확인"
      padding="24px 40px 80px"
      m_padding="20px 20px 80px"
      closeModal={closeModal}
      onClick={() => {
        if (!!selected) {
          setTime(selected);
        }
        closeModal();
      }}
    >
      <List>
        {times.map((item, index) => {
          return (
            <Time
              key={index}
              _items="center"
              _justify="center"
              isSelected={selected === item.time}
              onClick={() => setSelected(item.time)}
              disabled={item.disabled}
            >
              <Text _color="gray900" _weight="medium" _size="l">
                {item.time}
              </Text>
            </Time>
          );
        })}
      </List>
    </LayoutModal>
  );
}

export default SelectTimeModal;
