import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import leftArrow from '../../../assets/icons/utils/ico-arrow_left.svg';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { RoundBtn } from '../../../components/Common/Button';
import { useLoginOnly } from '../../../hooks/utils/login';
import {
  categoryTypeState,
  hotelOptionState,
  maxPriceState,
  minPriceState,
  moreInfoState,
  searchLocationState,
  spaceRequestState,
  userOptionState,
  userSelectState,
  requestPeriodState,
} from '../../../store/request';
import { useUser } from '../../../store/user';
import SelectCategoryType from './Components/SelectCategoryType';
import SelectLocation from './Components/SelectLocation';
import SelectMoreInfo from './Components/SelectMoreInfo';
import SelectOptionsByDate from './Components/SelectOptionsByDate';
import SelectSchedule from './Components/SelectSchedule';
import { useSpaceRequestQuery } from '../../../hooks/queries/request';
import { useNavigate, useParams } from 'react-router-dom';
import { customAlert } from '../../../store/modal';
import { requestMemberRequestDetails } from '../../../service/client/mypage';
import { bookPeriodState } from '../../../store/search';
// import { headerUi } from '../../../store/ui';

const Wrapper = styled.div`
  width: 100%;
`;

const SpaceRequestWrapper = styled.div`
  max-width: 980px;
  height: 100%;
  margin: 0 auto;
  padding: 56px 20px 0 20px;
`;

const TopDiv = styled.div`
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  width: 100%;
`;
const Title = styled.span`
  margin-top: 80px;
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray900};
`;

const StepDiv = styled.div`
  width: 100%;
`;
const StepList = styled.ul`
  margin-bottom: 18px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
`;
const StepButton = styled.span`
  line-height: 22px;
  letter-spacing: 0.5px;
  font-size: ${(props) => props.theme.fontSize.s};
  ${(props) =>
    props.current
      ? `
      font-weight: ${props.theme.fontWeight.bold};
      color: ${props.theme.colors.mainPurple};
      `
      : props.checked
      ? `
      font-weight: ${props.theme.fontWeight.bold};
      color: ${props.theme.colors.gray700};
      `
      : `
      font-weight: ${props.theme.fontWeight.normal};
      color: ${props.theme.colors.gray700};
      `}

  @media (${(props) => props.theme.media.tabletSm}) {
    display: ${(props) => !props.current && 'none'};
  }
`;

const ToTalBar = styled.div`
  height: 5px;
  position: relative;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 231px;
  background: ${(props) => props.theme.colors.gray50};
  z-index: 15;
`;
const GaugeBar = styled.div`
  position: absolute;
  width: ${(props) => props.nowSteps * 20 + 10}%;
  height: 5px;
  background: ${(props) => props.theme.colors.mainPurple};
  border-radius: 231px 0px 0px 231px;
  z-index: 18;
`;
const Current = styled.div`
  position: absolute;
  left: calc(${(props) => props.nowSteps * 20 + 10}% - 30px);
  top: -11px;
  width: 60px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;

  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.bolder};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.mainPurple};
  border-radius: 20px;
  z-index: 19;

  /* Weight
700
Size
14px
Line height
16.71px */
`;

const ButtonDiv = styled.div`
  margin: 80px 0 120px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
const BackButton = styled.button`
  height: 48px;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 99px;
  img {
    width: 8px;
    height: 12px;
  }
  span {
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.colors.gray800};
  }
`;

const steps = [
  { buttonName: '이벤트 유형', title: '원하시는 이벤트 유형을 선택해 주세요.' },
  { buttonName: '이용일시', title: '날짜를 선택해 주세요.' },
  { buttonName: '선호 위치', title: '선호 위치를 선택해 주세요.' },
  { buttonName: '날짜별 옵션', title: '날짜별 옵션을 선택해 주세요.' },
  { buttonName: '중요사항 입력', title: '중요사항을 입력해 주세요.' },
];

// page: 공간 의뢰 페이지
const SpaceRequest = ({ modify = false }) => {
  // const setHeaderMode = useSetRecoilState(headerUi);

  const { id } = useParams();
  const navigate = useNavigate();
  // 얼럿창
  const setAlert = useSetRecoilState(customAlert);

  // 이벤트 유형 선택
  const [categoryType, setCategoryType] = useRecoilState(categoryTypeState);
  // 선호 위치
  const [searchLocation, setSearchLocation] = useRecoilState(searchLocationState);
  // 날짜별 옵션에서 입력하는 최저, 최대 가격
  const [minPrice, setMinPrice] = useRecoilState(minPriceState);
  const [maxPrice, setMaxPrice] = useRecoilState(maxPriceState);
  // 날짜별 옵션에서 직접 선택한 옵션
  const [userOption, setUserOption] = useRecoilState(userOptionState);
  // 날짜별 옵션에서 호텔 제안받기 옵션
  const [hotelOption, setHotelOption] = useRecoilState(hotelOptionState);
  // 날짜별 옵션에서 직접 선택(true)/호텔 선택인지 확인(false)
  const [isUserSelect, setIsUserSelect] = useRecoilState(userSelectState);
  // 중요사항 입력
  const [moreInfo, setMoreInfo] = useRecoilState(moreInfoState);
  // 날짜 선택 {startDate:시작일, endDate:마지막일} : string으로 저장
  const period = useRecoilValue(requestPeriodState);
  // 날짜 선택  : Date 포맷으로 저장
  const setBookPeriod = useSetRecoilState(bookPeriodState);
  // 의뢰 정보 요청 데이터
  const [spaceReq, setSpaceReq] = useRecoilState(spaceRequestState);

  // 의뢰 요청 가능한 상태 확인(true일 때, 의뢰 요청 가능)
  const [canReq, setCanReq] = useState(false);
  // 의뢰 요청 단계
  const [nowSteps, setNowSteps] = useState(0);
  // 선호 위치 입력값
  const [word, setWord] = useState(spaceReq.location ?? '');

  // 사용자 정보
  const { token } = useUser();
  useSpaceRequestQuery(spaceReq, token, canReq);

  // 의뢰 정보를 요청 데이터 포맷으로 변경하여 반환
  const setSpaceRequestObj = useCallback(() => {
    const userOpts = { ...spaceReq };

    userOpts.startDate = period.startDate;
    userOpts.endDate = period.endDate;
    userOpts.location = searchLocation;
    userOpts.minPrice = minPrice;
    userOpts.maxPrice = maxPrice;
    userOpts.contents = moreInfo.contents;
    userOpts.requestDueDate = moreInfo.requestDueDate;
    userOpts.userSelect = isUserSelect;
    userOpts.category = categoryType;
    userOpts.details = isUserSelect ? userOption : hotelOption;
    userOpts.mode = modify ? 'update' : 'insert';

    userOpts.requestId = modify ? id : null;
    const { minPrice: min, maxPrice: max, details, ...rest } = userOpts;
    const hotelDetails = details.map((item) => {
      const { arrangementId, meal, ...rest } = item;
      return rest;
    });
    const hotelOpts = { ...rest, details: hotelDetails };

    // isUserSelect 값에 따라 날짜별 옵션 선택(직접, 호텔)
    isUserSelect ? setSpaceReq(userOpts) : setSpaceReq(hotelOpts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUserSelect,
    period,
    searchLocation,
    minPrice,
    maxPrice,
    moreInfo,
    isUserSelect,
    categoryType,
    userOption,
    hotelOption,
  ]);

  // 단계별 required(null) check
  const isButtonDisabled = () => {
    const exception1 = [
      categoryType.length > 0,
      !!period.startDate && !!period.endDate,
      !!searchLocation,
      null,
      !!moreInfo.requestDueDate,
    ];

    if (nowSteps === 3) {
      let hasEmptyItem = [];

      if (isUserSelect) {
        // user exception
        for (const item of userOption) {
          let hasEmptyAmount = [];

          hasEmptyAmount.push(!item.people > 0);
          for (const pkg of item.meal) {
            hasEmptyAmount.push(pkg.amount === 0);
          }
          hasEmptyItem.push(hasEmptyAmount.includes(true));
        }
        if (
          !(spaceReq.maxPrice > 0) ||
          !(spaceReq.minPrice >= 0) ||
          spaceReq.maxPrice < spaceReq.minPrice ||
          hasEmptyItem.includes(true)
        ) {
          return exception1.splice(3, 1, false);
        }
      } else {
        // hotel exception
        for (const item of hotelOption) {
          hasEmptyItem.push(item.people === 0);
        }

        // result: true (입력되지 않은 값 있음), false (모든 값 입력됨)
        if (hasEmptyItem.includes(true)) {
          return exception1.splice(3, 1, false);
        }
      }
      exception1.splice(3, 1, true);
    }

    return !exception1[nowSteps];
  };

  // 의뢰하기 단계 별 required(null) check, submit 함수
  const handleButton = () => {
    const exception = [
      categoryType.length > 0,
      !!period.startDate && !!period.endDate,
      !!searchLocation,
      !!moreInfo.requestDueDate,
    ];

    if (nowSteps === 0) {
      if (!exception[0]) {
        return;
      }
    }
    if (nowSteps === 1) {
      if (!exception[1]) {
        return;
      }
    }
    if (nowSteps === 2) {
      if (!exception[2]) {
        return;
      }
    }
    if (nowSteps === 3) {
      let hasEmptyItem = [];

      if (isUserSelect) {
        // user exception
        for (const item of userOption) {
          let hasEmptyAmount = [];
          for (const pkg of item.meal) {
            hasEmptyAmount.push(pkg.amount === 0);
          }
          hasEmptyItem.push(
            item.checkIn === '' ||
              item.checkOut === '' ||
              item.people === 0 ||
              // item.arrangementId === 0 ||
              // item.meal.length === 0 ||
              hasEmptyAmount.includes(true)
          );
        }
        if (spaceReq.maxPrice === '' || spaceReq.minPrice === '' || spaceReq.maxPrice < spaceReq.minPrice) {
          return;
        }

        // result: true (입력되지 않은 값 있음), false (모든 값 입력됨)
        if (hasEmptyItem.includes(true)) {
          return;
        }
      } else {
        // hotel exception
        for (const item of hotelOption) {
          hasEmptyItem.push(item.checkIn === '' || item.checkOut === '' || item.people === 0);
        }

        // result: true (입력되지 않은 값 있음), false (모든 값 입력됨)
        if (hasEmptyItem.includes(true)) {
          return;
        }
      }
    }
    if (nowSteps === 4) {
      if (!exception[3]) {
        return;
      }
      return setCanReq(true);
    }
    setNowSteps((prev) => prev + 1);
  };

  const handleModError = () => {
    setAlert({
      text: '올바른 접근이 아닙니다.',
      handleClose: () => {
        navigate(-1);
      },
    });
  };
  // [수정모드]
  const handleModify = async (id) => {
    await requestMemberRequestDetails({ id: id })
      .then((res) => {
        const data = res.data.data;
        const fData = data[0];
        setCategoryType(
          fData.category.map((c, i) => ({
            categoryId: c.categoryId,
          }))
        );
        setBookPeriod([new Date(fData.startDate)].concat(data.length > 1 ? [new Date(fData.endDate)] : []));
        setWord(fData.location);
        setSearchLocation(fData.location);
        setMoreInfo({
          contents: fData.contents,
          requestDueDate: fData.requestDueDate,
        });
        setIsUserSelect(fData.userSelect);
        if (fData.userSelect) {
          setMinPrice(fData.minPrice);
          setMaxPrice(fData.maxPrice);
          setUserOption(
            data.map((d) => ({
              arrangementId: +d.arrangementId, //[수정] - 테이블
              checkIn: d.checkIn,
              checkOut: d.checkOut,
              ...(d.meal && { meal: d.meal }),
              people: d.people,
              requestDate: d.requestDate,
              room: d.room,
            }))
          );
        } else {
          setHotelOption(
            data.map((d) => ({
              checkIn: d.checkIn,
              checkOut: d.checkOut,
              people: d.people,
              requestDate: d.requestDate,
              room: d.room,
            }))
          );
        }
      })
      .catch((err) => {
        handleModError();
      });
  };

  // setSpaceRequestObj 함수의 args가 변경될 떄마다 함수 실행(예약 요청 데이터 변경)
  useEffect(() => {
    setSpaceRequestObj();
  }, [setSpaceRequestObj]);

  useEffect(() => {
    if (canReq) {
      setCanReq(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canReq]);

  // 의뢰 요청인지 의뢰 요청 수정인지 확인
  useLayoutEffect(() => {
    if (modify) {
      if (!id || isNaN(id)) {
        handleModError();
      } else {
        handleModify(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modify, id]);

  return useLoginOnly(
    <Wrapper>
      <SpaceRequestWrapper>
        <TopDiv>
          <Title>{steps[nowSteps].title}</Title>
          {/* 의뢰 요청 단계 header */}
          <StepDiv>
            <StepList>
              {steps.map((step, index) => (
                <li key={index}>
                  <StepButton
                    checked={nowSteps > index}
                    current={nowSteps === index}
                    // onClick={() => setNowSteps(index)}
                  >
                    {step.buttonName}
                  </StepButton>
                </li>
              ))}
            </StepList>
            <ToTalBar>
              <GaugeBar nowSteps={nowSteps}></GaugeBar>
              <Current nowSteps={nowSteps}>
                <span>STEP.{nowSteps + 1}</span>
              </Current>
            </ToTalBar>
          </StepDiv>
        </TopDiv>

        {/* main 컴포넌트 */}
        {/* 이벤트 유형 선택 */}
        {nowSteps === 0 && <SelectCategoryType />}
        {/* 이용일시 선택 */}
        {nowSteps === 1 && <SelectSchedule />}
        {/* 이용일시 선택 */}
        {nowSteps === 2 && <SelectLocation word={word} setWord={setWord} />}
        {/* 선호 위치 선택 */}
        {nowSteps === 3 && <SelectOptionsByDate setNowSteps={setNowSteps} />}
        {/* 날짜별 옵션 선택 */}
        {nowSteps === 4 && <SelectMoreInfo />}

        <ButtonDiv>
          {nowSteps !== 0 && (
            <BackButton onClick={() => setNowSteps((prev) => prev - 1)}>
              <img src={leftArrow} alt="arrow-img" />
              <span>이전</span>
            </BackButton>
          )}
          <RoundBtn
            text={nowSteps === 4 ? (modify ? '수정하기' : '의뢰하기') : '다음'}
            _width="300px"
            onClick={handleButton}
            _primary
            _capsule
            disabled={isButtonDisabled()}
          />
        </ButtonDiv>
      </SpaceRequestWrapper>
    </Wrapper>
  );
};

export default SpaceRequest;
