import React from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import userIcon from "../../../assets/icons/user/ico-profile_72.svg";
import camIcon from "../../../assets/icons/utils/ico-camera.svg";
import { IMG_PATTERN } from "../../../constants/pattern";
import { useUpdateProfileQuery } from "../../../hooks/queries/user";
import { customAlert } from "../../../store/modal";
import { useUser } from "../../../store/user";

const S = {
  Wrapper: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    margin: 0 auto;
  `,
  Profile: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    background: #fff;
    overflow: hidden;

    img {
      min-height: 100%;
      max-height: 120%;
    }
  `,
  Default: styled.div`
    width: 100%;
    height: 100%;
    background: url(${userIcon}) center / 100% no-repeat;
  `,
  CamBtn: styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    label {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background-color: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
      cursor: pointer;
      img {
        width: 16px;
      }
    }
  `,
};

/**
 * 사용자 프로필 수정/뷰어 컴포넌트
 */
export default function UserProfile({ name = "uprofile" }) {
  const { profile, loggedIn, modify, token } = useUser();
  const { mutate: updateProfile } = useUpdateProfileQuery();
  const setAlert = useSetRecoilState(customAlert);

  // 파일 등록 핸들러: validation & request
  const handleFileSubmit = async (e) => {
    const target = e.currentTarget;

    if (!target.files || target.files.length <= 0) {
      return;
    }

    const file = target.files[0];

    if (!IMG_PATTERN.test(file.name)) {
      setAlert({ text: "이미지 파일을 등록해 주세요." });
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setAlert({ text: "용량 5mb 이하의 이미지 파일을 등록해 주세요." });
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    updateProfile({
      profile: formData,
      token: token,
      callback: (newUrl) => {
        modify({
          profileImage: newUrl,
        });
        target.value = "";
      },
    });
  };

  return (
    <S.Wrapper>
      <S.Profile>
        {loggedIn && profile ? <img src={`http://cdn.alabwaba.com${profile}?h=72`} /> : <S.Default />}
        {loggedIn && (
          <S.CamBtn>
            <input type="file" name={name} id={name} onChange={handleFileSubmit} />
            <label htmlFor={name}>
              <img src={camIcon} />
            </label>
          </S.CamBtn>
        )}
      </S.Profile>
    </S.Wrapper>
  );
}
