import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import ImageSlider from '../../components/Home/ImageSlider';
// import Categories from '../../components/Home/Categories';
import BottomMenuListBox from '../../components/Home/BottomMenuListBox';
import CardSlider from '../../components/Common/CardSlider';
import ClientBox from '../../components/Home/ClientBox';
import { Container, FlexCols, xScrollBoxCss, NonScrollBarCss } from '../../components/Common/Display';
import { Text } from '../../components/Common/Text';
import { useMainQuery } from '../../hooks/queries/home';
import ReviewCard03 from '../../components/Review/ReviewCard03';
import CategoryList from '../../components/Space/CategoryList';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { headerUi } from '../../store/ui';
import { ErrorStatus } from '../../components/Common/Status';
import { Line } from '../../components/Common/Line';
import { useUser } from '../../store/user';
import { S as SliderStyle } from '../../components/Common/CardSlider';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
`;

const Tbr = styled.br`
  display: none;
  @media (${(props) => props.theme.media.tablet}) {
    display: block;
  }
`;

const CardScrollList= styled(SliderStyle.SliderWrappper)`
  overflow-x: hidden;
  margin-top: 56px;


  > div {
    width: 100%;
    overflow-x: auto;
    
    
    ${xScrollBoxCss(7)}
    ${isMobile && NonScrollBarCss}
    padding-bottom: 15px;


    > div {
      display: inline-flex;
      gap: 20px;
      padding-right: 20px;
    }


  }
`

const styleProps = {
  section: {
    as: 'section',
    _width: '100%',
    _media: 'tablet',
    _items: 'center',
    _m_gap: 24,
    _m_items: 'flex-start',
  },
  title: {
    as: 'h3',
    _size: 'xxxxl',
    _weight: 'medium',
  },
};

/**
 * @constant sliderMin 슬라이드 최소 갯수
 * @description 슬라이드가 5개 이하일 때에는 슬라이드처리 하지 않고 가로 스크롤 목록으로 노출한다.
 */
const sliderMin = 6;

/**
 * 메인 페이지
 */
function Home() {
  const navigate = useNavigate();
  const setHeaderUi = useSetRecoilState(headerUi);
  const { token } = useUser();
  const { data, refetch } = useMainQuery(token);

  // 데이터 fetch
  useLayoutEffect(() => {
    refetch();
  }, [token]);

  // header UI 설정
  useEffect(() => {
    setHeaderUi((h) => ({ ...h, mobileSearchBar: true }));
  }, []);

  return data.filter((x) => x.status === 'success').length !== 3 ? (
    <Wrapper />
  ) : (
    <Wrapper>
      {/* 배너 */}
      <ImageSlider />
      {/* 공간  카테고리 */}
      <FlexCols _width="100%" _items="center" _justify="center">
        <Container _mx_width="1220px" _padding="60px 0 30px">
          <FlexCols _width="100%" _items="center" _justify="center" _gap={40}>
            <Text a="h3" _size="xxxl" _weight="medium">
              원하는 공간을 바로 확인해 보세요!
            </Text>
            <CategoryList
              handleCategoryChange={(value) => {
                navigate(`/space/list?category=${value}`);
              }}
              category={-1}
              mobile="swiper"
            />
          </FlexCols>
        </Container>
        <Line _color="gray200" />
      </FlexCols>
      {/* 신규 호텔 , 추천 인기 공간, 리뷰 영역 */}
      <Container _mx_width="1220px" _padding="80px 20px 160px" _media="tablet" _m_padding="60px 20px 120px">
        <FlexCols _gap={140} _width="100%" _media="tablet" _m_gap={120}>
          <FlexCols _gap={120} _width="100%" _media="tablet" _m_gap={100}>
            {[
              { title: '새로운 신규 호텔', type: 'hotel' },
              {
                title: (
                  <span>
                    서두르세요! <Tbr />
                    알아봐봐 추천 인기 공간
                  </span>
                ),
                type: 'space',
                errorMsg: '준비 중입니다.',
              },
              { title: '이보다 좋은 리뷰는 없다', type: 'review' },
            ].map((section, sIdx) => (
              <FlexCols key={sIdx} {...styleProps.section}>
                <Text {...styleProps.title}>{section.title}​</Text>
                {data[sIdx].data.data.data.length > 0 ? (
                  data[sIdx].data.data.data.length > sliderMin?
                   <CardSlider
                    cards={(data[sIdx].data.data.data).map((client, cIdx) => {
                      if (section.type === 'review') return <ReviewCard03 review={client} />;
                      return <ClientBox key={cIdx} bookmarkMode={true} type={section.type} {...client} />;
                    })}
                  />
                  : 
                  <SliderStyle.Wrapper>
                    <CardScrollList >
                      <div>
                        <div>
                      {
                        (data[sIdx].data.data.data).map((client, cIdx) => {
                          if (section.type === 'review') return <ReviewCard03 review={client} />;
                          return <ClientBox key={cIdx} bookmarkMode={true} type={section.type} {...client} />;
                        }) 
                      }
                        </div>
                      </div>
                    </CardScrollList>
                  </SliderStyle.Wrapper>
                ) : (
                  <ErrorStatus _height="300px" message="준비 중입니다." />
                )}
              </FlexCols>
            ))}
          </FlexCols>
          <BottomMenuListBox />
        </FlexCols>
      </Container>
    </Wrapper>
  );
}

export default Home;
