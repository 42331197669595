/**
 * 한국단위 숫자 반환 함수
 * @param {String | Number} num 
 * @returns {String} 한국단위 숫자
 */
export const KoNum = (num) => (isNaN(num) ? '0' : num.toLocaleString('ko-kr'));

/**
 * 숫자 반환 함수
 * @param {String} numStr 
 * @returns {Boolean} 숫자
 */
export const flatNum = (numStr) => {
  const res = numStr.replaceAll(',', '').trim();
  return isNaN(res) ? 0 : Number(res);
};
