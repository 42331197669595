import React, { useLayoutEffect } from 'react';
import * as D from '../../Common/Display';
import styled from 'styled-components';
import { Text } from '../../Common/Text';
import { FreeTable } from '../../Common/Table';
import { Line } from '../../Common/Line';
import HiddenInfo from '../../Common/HiddenInfo';
import { Badge } from '../../Common/Badge';
import { useMemberRequestDetailsQuery } from '../../../hooks/queries/mypage';
import { LoadingStatus } from '../../Common/Status';

const S = {
	DetailsCard: {
		Wrapper: styled.div``,
		Top: styled.div``,
		Contents: styled.div`
			display: flex;
			flex-direction: column;
			gap: 24px;
			padding: 24px;
		`,
	},
};

/**
 * 마이페이지 > 의뢰 상세 컴포넌트
 */
export default function RequestDetails({ id, handleModifyMode }) {
	const { isLoading, error, data } = useMemberRequestDetailsQuery({ id: id });

	useLayoutEffect(() => {
		if (data) {
			handleModifyMode(new Date(data[0].requestDueDate) > new Date());
		}
	}, [data]);

	return (
		<D.Container _width="100%" _mn_height="100vh">
			{isLoading ? (
				<LoadingStatus box />
			) : (
				<D.FlexCols _gap={24}>
					<Text _size="xxxl" _weight="medium" _media="tablet" _m_size="xxl">
						세부 의뢰 정보
					</Text>
					<D.FlexCols _gap={16}>
						{data.map((x, index) => (
							<HiddenInfo title={x.requestDate} _hidden={index > 0} key={index}>
								<D.FlexCols _gap={24}>
									<FreeTable
										data={[
											{
												th: '카테고리',
												td: (
													<D.FlexRows _gap={8} _justify="flex-end">
														{x.category?.map((x, index) => (
															<>
																<Badge _color="pink600" text={x.categoryName} key={index} />
															</>
														))}
													</D.FlexRows>
												),
											},
											{ th: '선호 위치', td: x.location },
											{ th: '의뢰 마감 날짜', td: x.requestDueDate },
											{ th: '시간', td: `${x.checkIn} ~ ${x.checkOut}` },
											{ th: '객실', td: `${x.room ?? 0} 실` },
											{ th: '인원', td: `${x.people} 명` },
										]}
									/>
									{x.meal && (
										<React.Fragment>
											<Line _color="gray200" />
											<FreeTable
												caption="식사 및 패키지"
												_th_size="s"
												_th_weight="normal"
												data={x.meal.map((m) => ({ th: m.mealName, td: m.amount.toString().padStart(2, '0') }))}
											/>
										</React.Fragment>
									)}
									{x.arrangementName && (
										<React.Fragment>
											<Line _color="gray200" />
											<FreeTable data={[{ th: '테이블 배치', td: `${x.arrangementName} 최대(${x.people}명)` }]} />
										</React.Fragment>
									)}
									{x.minPrice && x.maxPrice ? (
										<React.Fragment>
											<Line _color="gray200" />
											<FreeTable
												data={[
													{
														th: '1인 예상가격',
														td: (
															<Text _size="l" _weight="medium" _color="gray800">
																{x.minPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 ~{' '}
																{x.maxPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
																<Text _size="s" _color="gray600">
																	/ 1인
																</Text>
															</Text>
														),
													},
												]}
											/>
										</React.Fragment>
									) : (
										<></>
									)}
									<Line _color="gray200" />
									<D.FlexCols _width="100%" _gap={16}>
										<Text _size="l" _weight="bold">
											중요 사항
										</Text>
										<Text as="p" _color="gray600" _size="l">
											{x.contents}
										</Text>
									</D.FlexCols>
								</D.FlexCols>
							</HiddenInfo>
						))}
					</D.FlexCols>
				</D.FlexCols>
			)}
		</D.Container>
	);
}
