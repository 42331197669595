import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import * as D from '../Display';
import { Text } from '../Text';

const S = {
  Tabs: styled.div`
    width: 100%;
    padding: 0 16px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};

    ${D.xScrollBoxCss()}
    ${isMobile && D.NonScrollBarCss}

    ul {
      display: flex;
      gap: 67px;
      flex-wrap: nowrap;
      width: max-content;
      max-width: 100%;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      /* padding: 0 8px; */
      ul {
        gap: 0;
        max-width: max-content;
      }
    }
  `,
  Tab: styled.li`
    position: relative;
    width: 136px;
    height: 67px;
    max-width: ${(props) => `calc(${100 / props.count}% - ${(67 * (props.count - 1)) / props.count}px)`};

    span {
      line-height: 1 !important;
      color: ${(props) => props.theme.colors[props.active ? 'gray900' : 'gray700']} !important;
    }

    ${(props) =>
      props.new &&
      css`
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 8px;
          right: 8px;
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: ${(props) => props.theme.colors.error};
        }
      `}

    ${(props) =>
      props.active &&
      css`
        &::before {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: ${(props) => props.theme.colors.gray900};
        }
      `}

		@media (${(props) => props.theme.media.tabletSm}) {
      width: 100px;
      height: 63px;
      max-width: 100px;
    }
  `,
};

/**
 * 카운팅 탭 컴포넌트
 * @description 마이페이지에서 사용하는 컴포넌트입니다.
 */
export default function CountTab({ tabs, activeIdx, onTabClick }) {
  const tabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef && activeIdx) {
      tabsRef.current.childNodes[activeIdx].scrollIntoView();
    }
  }, [activeIdx, tabsRef]);

  return (
    <S.Tabs>
      <ul ref={tabsRef}>
        {tabs.map((tab, idx) => (
          <S.Tab new={tab.accent} active={idx === activeIdx} key={tab.name} count={tabs.length}>
            <D.FlexCols
              as="button"
              onClick={() => {
                onTabClick(idx);
              }}
              _width="100%"
              _height="100%"
              _justify="center"
              _items="center"
              _gap={8}
              _media="tabletSm"
              _m_gap={4}>
              <Text _size="xxxl" _weight="medium" _media="tabletSm" _m_size="xl">
                {tab.count ?? 0}
              </Text>
              <Text _size="s" _weight="medium">
                {tab.name || tab.status}
              </Text>
            </D.FlexCols>
          </S.Tab>
        ))}
      </ul>
    </S.Tabs>
  );
}
