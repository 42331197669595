import React, { useState } from 'react';

import leftArrow from '../../../../assets/icons/utils/ico-chevron_up.svg';

import styled from 'styled-components';
import { Text } from '../../../../components/Common/Text';
import LayoutModal from './LayoutModal';

const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 78px);
  gap: 8px;

  @media (${(props) => props.theme.media.tabletSm}) {
    width: 100%;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 78px);
  }
`;

const Time = styled.button`
  padding: 8px;
  width: 100%;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isSelected
      ? `
      background: ${props.theme.colors.purple50};
      border: 2px solid ${props.theme.colors.mainPurple};
      `
      : `
      background: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors.gray300};
      `}
  ${(props) =>
    props.disabled &&
    `
    background: ${props.theme.colors.gray100};
    border: none;
    font-color: ${props.theme.colors.gray300};
    `}
`;

const ImgDiv = styled.button`
  display: none;
  position: absolute;
  top: 21px;
  left: 24px;
  img {
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
  }
  @media (${(props) => props.theme.media.tabletSm}) {
    display: flex;
  }
`;

const times = new Array(24).fill(0).map((item, index) => {
  return {
    // disabled: index === 3 || index === 5 || index === 10 ? true : false,
    time: `${String(index).padStart(2, 0)}:00:00`,
  };
});

// component: 시간 선택 modal(체크인, 체크아웃)
// /space/request 페이지에서 노출, UserBox에서 호출
function SelectTimeModal({ closeModal, time, setTime, title }) {
  // 시간 선택
  const [selected, setSelected] = useState(time);

  return (
    <LayoutModal
      title={title}
      w_width="502px"
      b_text="확인"
      padding="24px 40px"
      m_padding="20px 20px 24px 20px"
      closeModal={closeModal}
      onClick={() => {
        // 체크인/아웃 시간 입력
        setTime(selected);
        closeModal();
      }}
    >
      <ImgDiv onClick={closeModal}>
        <img src={leftArrow} alt="arrow-img" />
      </ImgDiv>

      <List>
        {times.map((item, index) => {
          return (
            <Time
              key={index}
              _items="center"
              _justify="center"
              isSelected={selected === item.time}
              onClick={() => setSelected(item.time)}
              disabled={item.disabled}
            >
              <Text _color="gray900" _weight="medium" _size="l">
                {item.time.substring(0, 5)}
              </Text>
            </Time>
          );
        })}
      </List>
    </LayoutModal>
  );
}

export default SelectTimeModal;
