import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isLoggedIn } from '../../store/user';

/**
 * 로그인 체크 함수
 */
export const useLoginOnly = (comp) => {
  const loggedIn = useRecoilValue(isLoggedIn);

  return loggedIn ? comp : <Navigate to="/" />;
};
