import React from "react";
import styled from "styled-components";
import { useState } from "react";

import * as D from "../../../../components/Common/Display";
import { Line } from "../../../../components/Common/Line";
import { Text } from "../../../../components/Common/Text";
import { cdnUrl } from "../../../../constants/url";

import bottmArrow from "../../../../assets/icons/utils/ico-arrow_btm.svg";

const HideDiv = styled(D.FlexCols)`
  width: 100%;
  padding: 20px 24px;
`;
const HideHead = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 14px;
    height: 8px;
    transform: ${(props) => (props.isHidden ? "none" : "rotate(180deg)")};
  }
`;
const HideContent = styled(D.FlexCols)`
  padding: 10px 0;
  gap: 10px;
  align-items: center;
  & img {
    width: 120px;
  }
`;

// component: 호텔 정보를 출력하는 토글 버튼 (객실 정보, 식사 및 패키지 정보)
// /space/book/:id 페이지에 노출 및 호출
const HideElement = ({ index, name, image, description }) => {
  const [isHidden, setIsHidden] = useState(!!index ? true : false);

  return (
    <div>
      <Line _color="gray300" _m_top={!index && 40} />
      <HideDiv>
        <HideHead
          isHidden={isHidden}
          onClick={() =>
            setIsHidden((prev) => {
              return !prev;
            })
          }
        >
          <Text _size="xxl" _weight="medium" _color="gray900">
            {name}
          </Text>
          <img src={bottmArrow} alt="arrow-bottom-icon" />
        </HideHead>
        {!isHidden && (
          <HideContent>
            {!!image && <img src={cdnUrl(image)} alt="room/img" />}
            <Text _size="l" _space="pre-line">
              {description}
            </Text>
          </HideContent>
        )}
      </HideDiv>
    </div>
  );
};

export default HideElement;
