import React from 'react';
import styled, { css } from 'styled-components';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import exit from '../assets/icons/utils/ico-x.svg';
import rightArrow from '../assets/icons/utils/ico-arrow_right.svg';
import booking from '../assets/icons/menu/ico-menu_booking.svg';
import bell from '../assets/icons/menu/ico-menu_bell_black.svg';
import wish from '../assets/icons/menu/ico-menu_wish.svg';
import { SITE } from '../constants/site';
import { useSetRecoilState } from 'recoil';
import { joinModalState, loginModalState, customAlert } from '../store/modal';
import { useUser } from '../store/user';
import * as D from '../components/Common/Display';
import UserProfile from '../components/Account/Profile';
import { Text } from '../components/Common/Text';

// 전체 wrapper
const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
  overflow-y: auto;

  ${({ active, theme }) =>
    active &&
    css`
      transform: translateX(0);
      ${theme.effectStyle.selected}
    `}
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 39;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SideHeader = styled.div`
  padding: 60px 24px 40px 24px;
  width: 100%;
  background: ${(props) => props.theme.colors.gray50};
  display: flex;
  gap: 16px;

  .exit-img {
    position: absolute;
    top: 27px;
    right: 27px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const Contents = styled.div`
  height: calc(100% - ${(props) => (props.loggedIn ? 172 : 156)}px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;

  > div {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: ${(props) => (props.loggedIn ? 4 : 8)}px;

  button {
    line-height: 1;
    color: ${(props) => props.theme.colors.gray600};
    font-weight: ${(props) => props.theme.fontWeight.normal};
  }

  .login-menu {
    font-size: ${(props) => props.theme.fontSize.l};
  }
`;
const MainText = styled.span`
  font-size: ${(props) => props.theme.fontSize.xxl};
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;
const MypageLink = styled(RouterLink)`
  width: 80px;
  height: 18px;
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    font-size: ${(props) => props.theme.fontSize.xs};
    color: ${(props) => props.theme.colors.gray600};
  }

  img {
    width: 4.5px;
    height: 9px;
    color: red;
    opacity: 0.7;
  }
`;

const Center = styled.div`
  width: 100%;
  height: 193px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
`;
const CenterRow = styled.div`
  width: 100%;
  height: 73px;
  display: flex;
`;
const CenterColumn = styled.button`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & > img {
    width: 48px;
    height: 48px;
    color: black;
  }

  span {
    font-size: ${(props) => props.theme.fontSize.s};
  }
`;
const CenterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 100%;
  height: 48px;

  background: ${(props) => props.theme.colors.mainPurple};
  border-radius: 30px;

  font-size: ${(props) => props.theme.fontSize.l};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

const Bottom = styled.div`
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
const Link = styled(RouterLink)`
  width: 100%;
  height: 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;

  & > span {
    font-size: ${(props) => props.theme.fontSize.l};
    color: ${(props) => props.theme.colors.gray800};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  & > img {
    width: 14px;
    height: 14px;
    margin: 9px;
  }
`;

/**
 * Aside 컴포넌트
 * @param {boolean} param.asideOn aside 활성화 여부
 * @param {function} param.closeAside aside 닫기 함수
 */
export default function Aside({ asideOn, closeAside }) {
  const setLogin = useSetRecoilState(loginModalState);
  const setJoin = useSetRecoilState(joinModalState);
  const setAlert = useSetRecoilState(customAlert);
  const navigate = useNavigate();
  const { logout, nickname, loggedIn } = useUser();

  // 로그인 필수 서비스 핸들러
  const handleLoginService = (siteAddr) => {
    if (loggedIn) {
      navigate(siteAddr);
    } else {
      setAlert({
        text: '로그인 후 이용해 주세요.',
        handleClose: () => {
          setLogin({ mode: true, redirect: siteAddr });
          closeAside();
        },
      });
    }
  };

  return (
    <React.Fragment>
      {asideOn && <Overlay onClick={closeAside} />}
      <Wrapper active={asideOn}>
        <SideHeader>
          <img className="exit-img" src={exit} alt="exit-img" onClick={closeAside} />
          {loggedIn && <UserProfile />}
          <TextContainer loggedIn={loggedIn}>
            <MainText>{loggedIn ? nickname : '로그인을 해주세요.'}</MainText>
            {!loggedIn && (
              <D.FlexRows _gap={24} _items="center" _width="100%" _justify="flex-start">
                <button
                  className="login-menu"
                  onClick={() => {
                    setLogin({ mode: true });
                    closeAside();
                  }}>
                  로그인
                </button>
                <button
                  className="login-menu"
                  onClick={() => {
                    setJoin({ mode: true });
                    closeAside();
                  }}>
                  회원가입
                </button>
              </D.FlexRows>
            )}
            {loggedIn && (
              <D.FlexRows _gap={4} _items="center" _justify="space-between" _width="100%">
                <MypageLink to="/mypage">
                  <span>마이페이지</span>
                  <img src={rightArrow} alt="arrow-img" />
                </MypageLink>
                <Text
                  as="button"
                  _size="xs"
                  onClick={() => {
                    logout();
                    closeAside();
                  }}>
                  로그아웃
                </Text>
              </D.FlexRows>
            )}
          </TextContainer>
        </SideHeader>
        <Contents loggedIn={loggedIn}>
          <Center>
            <CenterRow>
              {[
                {
                  to: SITE.myBooking,
                  src: booking,
                  alt: '내 예약',
                  text: 'MY 예약',
                },
                {
                  to: SITE.myRequest,
                  src: bell,
                  alt: '내 의뢰',
                  text: 'MY 의뢰',
                },
                {
                  to: SITE.myWish,
                  src: wish,
                  alt: '내 위시리스트',
                  text: '위시리스트',
                },
              ].map((item, idx) => (
                <CenterColumn
                  key={idx}
                  onClick={() => {
                    handleLoginService(item.to);
                  }}>
                  <img src={item.src} alt={item.alt} />
                  <span>{item.text}</span>
                </CenterColumn>
              ))}
            </CenterRow>
            <CenterButton
              onClick={() => {
                handleLoginService(SITE.spaceRequest);
              }}>
              원하는 공간을 의뢰해 보세요!
            </CenterButton>
          </Center>
          <Bottom>
            {loggedIn && (
              <React.Fragment>
                {[
                  { to: SITE.myChat, text: 'MY 채팅' },
                  { to: SITE.myReview, text: 'MY 리뷰' },
                ].map((item, idx) => (
                  <Link key={idx} to={item.to}>
                    <span>{item.text}</span>
                    <img src={rightArrow} alt="arrow-img" />
                  </Link>
                ))}
              </React.Fragment>
            )}
            {[
              { to: SITE.onToOne, text: '1:1 문의' },
              { to: SITE.notice, text: '공지사항' },
              // { to: SITE.company, text: '회사소개' },
              { to: SITE.useRule, text: '이용약관' },
              { to: SITE.privacyRule, text: '개인정보처리방침' },
            ].map((item, idx) => (
              <Link key={idx} to={item.to}>
                <span>{item.text}</span>
                <img src={rightArrow} alt="arrow-img" />
              </Link>
            ))}
          </Bottom>
        </Contents>
      </Wrapper>
    </React.Fragment>
  );
}
