import React from 'react';
import MyPageSection from '../../../components/MyPage/MyPageSection';
import ReviewList from '../../../components/MyPage/Review/ReviewList';

/**
 * 마이페이지 > 마이 리뷰 페이지
 * @returns 
 */
export default function MyReviews() {
  return (
    <MyPageSection title="MY 리뷰">
      <ReviewList />
    </MyPageSection>
  );
}
