import React from 'react';
import styled from 'styled-components';
import Message from '../Message/Message';

const Wrapper = styled.div`
  width: ${(props) => props.width ?? '100%'};
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  & > input {
    // padding: 8px 16px;
    // width: 320px;
    // height: 48px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.gray900};

    // border: 1px solid ${(props) => props.theme.colors.gray300};
    // border-radius: 10px;

    // input error
    // border: 1px solid ${(props) => props.theme.colors.error};

    &::placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const InputWrapper = styled.div`
  padding: 8px 16px;
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '48px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.error : props.theme.colors.gray300};
  border-radius: 10px;

  // input error
  // border: 1px solid ${(props) => props.theme.colors.error};

  & > input {
    width: 100%;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.gray900};

    &::placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const Title = styled.span`
  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray900};
`;

/**
 * textarea 래퍼 컴포넌트
 */
function InputLayout({
  width,
  height,
  title,
  name,
  placeholder,
  message,
  value,
  setValue,
  isError = false,
  children,
}) {
  return (
    <Wrapper width={width}>
      {title && <Title>{title}</Title>}
      {name && placeholder && (
        <InputWrapper width={width} height={height} isError={isError}>
          <input
            type="text"
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
          {/* 인증 버튼 자리 */}
          {children}
        </InputWrapper>
      )}
      {message && <Message isError={isError} text={message} />}
    </Wrapper>
  );
}

export default InputLayout;
