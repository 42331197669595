import React from 'react';
import * as D from '../../components/Common/Display';
import { Text } from '../../components/Common/Text';
import { RoundBtn } from '../../components/Common/Button';
import { Link, useNavigate } from 'react-router-dom';
import errIcon from '../../assets/icons/error/ico-404.svg';
import { SITE } from '../../constants/site';

/**
 * 404 에러 페이지
 */
export default function Error404() {
	const navigate = useNavigate();
	return (
		<D.Container _width="100vh" _height="100vh" _padding="20px">
			<D.Flex
				_width="100%"
				_height="100%"
				_justify="center"
				_direction="row"
				_items="center"
				_gap={64}
				_media="tabletSm"
				_m_direction="column"
			>
				<img src={errIcon} alt="404" />
				<D.FlexCols _gap={84} _media="tabletSm" _m_gap={40}>
					<D.FlexCols _gap={18} _items="flex-start" _media="tabletSm" _m_items="center">
						<Text as="p" _weight="medium" _media="tabletSm" _m_align="center" _break="keep-all">
							죄송합니다.
							<br />
							요청하신 페이지를 찾을 수 없습니다.
						</Text>
						<Text as="p" _media="tabletSm" _m_align="center" _break="keep-all">
							잘못된 url 주소를 입력하셨거나 찾을 수 없는 경로입니다.
							<br />
							올바른 주소로 입력하였는지 다시 한 번 확인해주세요.
						</Text>
					</D.FlexCols>
					<RoundBtn
						_capsule
						_primary
						text="홈페이지 돌아가기"
						onClick={() => {
							navigate(SITE.home);
						}}
					/>
				</D.FlexCols>
			</D.Flex>
		</D.Container>
	);
}
