import React, { useState } from 'react';
import styled from 'styled-components';
import { InputField } from '../../Common/InputField';
import * as D from '../../Common/Display';
import { Text } from '../../Common/Text';
import { RoundBtn } from '../../Common/Button';
import WithDrawalForm from './WithDrawalForm';
import { useSetRecoilState } from 'recoil';
import { useUser } from '../../../store/user';
import { useUpdateUserQuery } from '../../../hooks/queries/user';
import { useForm } from 'react-hook-form';
import { NAME_PATTERN, PWD_PATTERN } from '../../../constants/pattern';
import { customAlert } from '../../../store/modal';

const S = {
  Wrapper: styled.form`
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 20px;
    padding: 40px;

    @media (${(props) => props.theme.media.tablet}) {
      padding: 0;
      border: 0;
    }
  `,
};

/**
 * 마이페이지 > 사용자 정보 편집 컴포넌트
 */
export default function EditForm() {
  const [wdMode, setWdMode] = useState(false);
  const setAlert = useSetRecoilState(customAlert);
  const { mutate: updateInfo } = useUpdateUserQuery();
  const { email, nickname, snsLogin, token, modify } = useUser();
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      unick: nickname,
      ...(!snsLogin && {
        upwd: '',
        upwd02: '',
      }),
    },
  });

  const handleModify = (data) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    updateInfo({
      token: token,
      info: {
        nickname: data.unick,
        password: snsLogin || data.upwd.length === 0 ? null : data.upwd,
      },
      callback: () => {
        setAlert({
          text: '회원 정보가 변경되었습니다.',
        });
        modify({
          nickname: data.unick,
        });
        reset({
          upwd: '',
          upwd02: '',
        });
      },
    });
  };

  return (
    <S.Wrapper as="form" onSubmit={handleSubmit(handleModify)}>
      <D.FlexCols _gap={24} _width="100%">
        <InputField label="아이디" value={email} name="uId" readOnly />
        {[
          {
            label: '닉네임',
            name: 'unick',
            placeholder: '닉네임(사용하실 닉네임을 입력해 주세요.)',
            maxLength: 10,
            registerProps: {
              required: { value: true, message: '닉네임을 입력해 주세요.' },
              pattern: { value: NAME_PATTERN, message: '*문자, 숫자를 조합하여 1~10자리로 입력해 주세요.' },
            },
          },
          ...((!snsLogin && [
            {
              label: '비밀번호',
              name: 'upwd',
              placeholder: '비밀번호를 입력해 주세요.',
              type: 'password',
              guide: '*영문, 숫자를 조합하여 8~16자리로 입력해 주세요.',
              registerProps: {
                required: { value: false, message: '비밀번호를 입력해 주세요.' },
                pattern: { value: PWD_PATTERN, message: '*영문, 숫자를 조합하여 8~16자리로 입력해 주세요.' },
                onChange: () => {
                  if (getValues('upwd').length <= 0) {
                    clearErrors('upwd02');
                    register('upwd02', { required: false, pattern: false });
                  } else {
                    register('upwd02', {
                      required: { value: true, message: '비밀번호가 서로 일치하지 않습니다, 다시 확인해 주세요.' },
                      pattern: {
                        value: new RegExp(getValues('upwd')),
                        message: '비밀번호가 서로 일치하지 않습니다, 다시 확인해 주세요.',
                      },
                    });
                  }
                },
              },
            },
            {
              label: '비밀번호 확인',
              name: 'upwd02',
              placeholder: '새 비밀번호를 다시 입력해주세요.',
              type: 'password',
            },
          ]) ||
            []),
        ].map((item, idx) => (
          <InputField
            key={idx}
            label={item.label}
            name={item.name}
            type={item.type}
            placeholder={item.placeholder}
            readOnly={item.readOnly}
            register={register}
            registerProps={item.registerProps}
            maxLength={item.maxLength}
            error={errors[item.name] && errors[item.name].message.length > 0}
            errorMsg={errors[item.name] ? errors[item.name].message : ''}
            guide={item.guide}
          />
        ))}
      </D.FlexCols>
      <D.FlexCols _gap={16} _items="center">
        <RoundBtn _primary _capsule text="수정 완료" _width="320px" type="submit" />
        <Text
          as="button"
          type="button"
          onClick={() => {
            setWdMode(true);
          }}
          _size="xxs"
          _color="gray600"
          _decoration="underline">
          회원 탈퇴
        </Text>
      </D.FlexCols>
      <WithDrawalForm
        on={wdMode}
        handleFormClose={() => {
          setWdMode(false);
        }}
      />
    </S.Wrapper>
  );
}
