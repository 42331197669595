import React, { useState } from 'react';
import styled from 'styled-components';

import search from '../../../assets/search.svg';
import erase from '../../../assets/x.png';
import marker from '../../../assets/icons/utils/ico-search_location.svg';
import { yScrollBoxCss } from '../../Common/Display';
import { useSearchLocationQuery } from '../../../hooks/queries/search';

const Wrapper = styled.div`
  padding: 24px;
  width: 400px;
  max-width: calc(100% - 40px);
  min-height: 334px;
  margin: 0 auto;

  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray400};
  background: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.effectStyle.selected}
`;

const InputDiv = styled.div`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid ${(props) => props.theme.colors.mainPurple};

  .search {
    width: 24px;
    height: 24px;
  }
  .erase {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  & > input {
    width: 100%;
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.gray900};

    &::placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const ResultList = styled.ul`
  margin-top: 12px;
  width: 100%;
  height: 220px;
  overflow-y: scroll;
  ${yScrollBoxCss()}
`;
const ResultItem = styled.li`
  width: 100%;
  height: 44px;
  padding: 12px 16px;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: ${(props) => props.theme.fontSize.base};
    color: ${(props) => props.theme.colors.gray900};

    strong {
      font-weight: ${(props) => props.theme.fontWeight.bold};
      color: ${(props) => props.theme.colors.purple200};
    }
  }
  .marker {
    width: 20px;
    height: 20px;
  }
`;

export default function KeywordOption({ value, handleSubmit }) {
  const [word, setWord] = useState(value);
  const { data: searchResult, error, isLoading } = useSearchLocationQuery(word);

  const handleChange = (e) => setWord(e.currentTarget.value);

  return (
    <Wrapper>
      <InputDiv>
        <img className="search" src={search} alt="search-img" />
        <input
          name="word"
          // defaultValue={word} // [2023.07.31] 수정
          id="word"
          placeholder="동 또는 업체 명을 입력해 주세요."
          value={word}
          onChange={handleChange}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        {word && <img className="erase" src={erase} alt="erase-img" onClick={() => setWord('')} />}
      </InputDiv>
      <ResultList>
        {searchResult?.map((item, i) => (
          <ResultItem key={i}>
            <button
              onClick={() => {
                handleSubmit(item);
              }}>
              <img className="marker" src={marker} alt="marker-img" />
              <div dangerouslySetInnerHTML={{ __html: `${item.name.replaceAll(word, `<strong>${word}</strong>`)}` }} />
            </button>
          </ResultItem>
        ))}
      </ResultList>
    </Wrapper>
  );
}
