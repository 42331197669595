import React from 'react';
import { FlexRows } from '../Common/Display';
import styled from 'styled-components';
import yellowStar from '../../assets/icons/utils/ico-star_yellow.svg';
import grayStar from '../../assets/icons/utils/ico-star_gray.svg';
import halfStar from '../../assets/icons/utils/ico-star_half.svg';

const sizes = {
  m: 22,
  s: 16,
  xs: 10,
};

const StarImg = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => sizes[props.size]}px;
  height: ${(props) => sizes[props.size]}px;

  > svg {
    width: ${(props) => sizes[props.size]}px;
    height: ${(props) => sizes[props.size]}px;
  }
`;

/**
 * 리뷰 별점 컴포넌트
 * @param {number} props.score 별점 점수
 * @param {'m' | 's' | 'x'} props.size 컴포넌트 크기
 */
export default function ReviewStars({ score, size = 'm' }) {
  if (isNaN(score) || score < 0) {
    score = 0;
  }

  if (score > 5) {
    score = 5;
  }

  return (
    <FlexRows _height="22px">
      {Number.isInteger(score) ? (
        <React.Fragment>
          {new Array(score).fill(0).map((_, idx) => (
            <StarImg key={idx} src={yellowStar} size={size} />
          ))}
          {new Array(5 - score).fill(0).map((_, idx) => (
            <StarImg key={idx} src={grayStar} size={size} />
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {new Array(Math.floor(score)).fill(0).map((_, idx) => (
            <StarImg key={idx} src={yellowStar} size={size} />
          ))}
          <StarImg src={halfStar} size={size} />
          {score < 4 &&
            new Array(Math.floor(5 - score)).fill(0).map((_, idx) => <StarImg key={idx} src={grayStar} size={size} />)}
        </React.Fragment>
      )}
    </FlexRows>
  );
}
