import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';

import { Flex, FlexCols } from '../../../../components/Common/Display';
import { Text } from '../../../../components/Common/Text';
import { hotelOptionState } from '../../../../store/request';
import SelectTimeModal from './SelectTimeModal';

import plus from '../../../../assets/icons/utils/ico-plus.svg';
import minus from '../../../../assets/icons/utils/ico-minus.svg';

// === components/Header/HotelBox

const RowDiv = styled(Flex)`
  z-index: 42;
  width: 100%;
  height: auto;
  gap: 16px;
`;
const Row = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const InputDiv = styled(Flex)`
  width: ${(props) => props._width ?? '150px'};
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.colors.gray300};

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 8px 16px;
    height: 40px;
  }
`;
const ImgDiv = styled(Flex)`
  width: ${(props) => props._width ?? '24px'};
  height: ${(props) => props._height ?? '24px'};
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors[props._borderColor] ?? props.theme.colors.gray300};
  border-radius: 50px;
  cursor: pointer;

  img {
    width: ${(props) => props.img_width ?? '12px'};
    height: ${(props) => props.img_hegiht ?? '12px'};
  }
`;
const UnitDiv = styled(Flex)`
  padding: 0 5px;
  justify-content: flex-end;
`;
const Unit = styled.button`
  width: 32px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 30px;

  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.xxs};
  color: ${(props) => props.theme.colors.gray900};
  text-align: center;
`;

const Button = styled.button`
  overflow: hidden;
  height: 100%;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};
  cursor: pointer;

  & > form {
    width: 45px;
    & > input {
      width: 100%;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: ${(props) => props.theme.fontSize.normal};
      color: ${(props) => props.theme.colors.gray900};
    }
  }
`;
const PeopleText = styled(Text)`
  display: inline-block;
  width: 45px;
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};
`;

const units = [-10, -50, 10, 50];

// component: 해당 날짜 옵션 box(호텔 제안)
// /space/request 페이지, SelectOptionsByDate에서 호출, HotelList에서 노출
function HotelBox({ index, date, selectOption, setSelectOption }) {
  // 상위 컴포넌트(index.js)에서 사용하는 호텔 제안 설정
  const hotelOption = useRecoilValue(hotelOptionState);

  // 수량을 집접 입력(true|false)
  const [edit, setEdit] = useState(null);
  // 0,1,2 - modal종료,체크인,체크아웃
  const [onModal, setOnModal] = useState(0);
  // 체크인 시간
  const [checkIn, setCheckIn] = useState(
    !!hotelOption[index]?.checkIn ? hotelOption[index].checkIn : '10:00:00'
  );
  // 체크아웃 시간
  const [checkOut, setCheckOut] = useState(
    !!hotelOption[index]?.checkOut ? hotelOption[index].checkOut : '15:00:00'
  );
  // 객실 인원
  const [room, setRoom] = useState(!!hotelOption[index]?.room ? hotelOption[index].room : 0);
  // 인원
  const [people, setPeople] = useState(!!hotelOption[index]?.people ? hotelOption[index].people : 1);

  // 옵션 변경 저장(날짜별, 상위 컴포넌트 사용자 옵션)
  // > 1일 옵션 변경, 상위 컴포넌트에서 1일 옵션 수정
  const getHotelOption = useCallback(() => {
    const optionObj = { ...selectOption[index] };

    optionObj.checkIn = checkIn;
    optionObj.checkOut = checkOut;
    optionObj.people = people;
    optionObj.room = room;

    setSelectOption((prev) => {
      const prevArr = [...prev];
      prevArr[index] = optionObj;

      return prevArr;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIn, checkOut, people, room]);

  // 수량 직접 입력 종료(input>span 변경)
  const handleSubmit = (e) => {
    e.preventDefault();
    setEdit(null);
  };

  useEffect(() => {
    getHotelOption();
  }, [getHotelOption]);

  // 옵션 초기 설정(local user option)
  useEffect(() => {
    const jsonDate = date.split('. ').join('-');
    if ((hotelOption[index]?.requestDate ?? jsonDate) !== jsonDate) {
      setCheckIn('10:00:00');
      setCheckOut('15:00:00');
      setPeople(1);
      setRoom(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <>
      {/* 체크인 modal */}
      {onModal === 1 && (
        <SelectTimeModal
          closeModal={() => setOnModal(0)}
          time={checkIn}
          setTime={(selectTime) => setCheckIn(selectTime)}
          title="체크인"
        />
      )}
      {/* 체크아웃 modal */}
      {onModal === 2 && (
        <SelectTimeModal
          closeModal={() => setOnModal(0)}
          time={checkOut}
          setTime={(selectTime) => setCheckOut(selectTime)}
          title="체크아웃"
        />
      )}
      <FlexCols gap="44px">
        <RowDiv>
          <Row>
            <Text _weight="medium" _size="l" _color="gray900">
              체크인
            </Text>
            <InputDiv _justify="center">
              <Button onClick={() => setOnModal(1)}>{checkIn.substring(0, 5)}</Button>
            </InputDiv>
          </Row>
          <Row>
            <Text _weight="medium" _size="l" _color="gray900">
              체크아웃
            </Text>
            <InputDiv _justify="center">
              <Button onClick={() => setOnModal(2)}>{checkOut.substring(0, 5)}</Button>
            </InputDiv>
          </Row>
          <FlexCols _width="100%" _gap={8}>
            <Row>
              <Text _weight="medium" _size="l" _color="gray900">
                객실
              </Text>
              <InputDiv _justify="space-between">
                <ImgDiv onClick={() => setRoom((prev) => (prev === 0 ? 0 : prev - 1))}>
                  <img src={minus} alt="minus-img" />
                </ImgDiv>
                <Button onClick={() => setEdit('room')}>
                  {edit === 'room' ? (
                    <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                      <input
                        required
                        autoFocus
                        autoComplete="off"
                        type="text"
                        name="room"
                        value={room}
                        onChange={(e) => {
                          const {
                            currentTarget: { value },
                          } = e;
                          if (!isNaN(value)) {
                            setRoom(+value);
                          }
                        }}
                      />
                    </form>
                  ) : (
                    <PeopleText>{String(room).padStart(2, '0')}</PeopleText>
                  )}
                </Button>
                <ImgDiv onClick={() => setRoom((prev) => parseInt(prev) + 1)}>
                  <img src={plus} alt="plus-img" />
                </ImgDiv>
              </InputDiv>
            </Row>
          </FlexCols>
          <FlexCols _width="100%" _gap={8}>
            <Row>
              <Text _weight="medium" _size="l" _color="gray900">
                인원
              </Text>
              <InputDiv _justify="space-between">
                <ImgDiv onClick={() => setPeople((prev) => (prev === 0 ? 0 : prev - 1))}>
                  <img src={minus} alt="minus-img" />
                </ImgDiv>
                <Button onClick={() => setEdit('people')}>
                  {edit === 'people' ? (
                    <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                      <input
                        required
                        autoFocus
                        autoComplete="off"
                        type="text"
                        name="people"
                        value={people}
                        onChange={(e) => {
                          const {
                            currentTarget: { value },
                          } = e;
                          if (!isNaN(value)) {
                            setPeople(+value);
                          }
                        }}
                      />
                    </form>
                  ) : (
                    <PeopleText>{String(people).padStart(2, '0')}</PeopleText>
                  )}
                </Button>
                <ImgDiv onClick={() => setPeople((prev) => parseInt(prev) + 1)}>
                  <img src={plus} alt="plus-img" />
                </ImgDiv>
              </InputDiv>
            </Row>
            <UnitDiv _width="100%" _height="20px" _gap={4}>
              {units.map((item, index) => (
                <Unit
                  key={index}
                  onClick={() => {
                    let sum = people + item;
                    const result = sum < 0 ? 0 : sum;
                    return setPeople(result);
                  }}
                >
                  {item}
                </Unit>
              ))}
            </UnitDiv>
          </FlexCols>
        </RowDiv>
      </FlexCols>
    </>
  );
}

export default HotelBox;
