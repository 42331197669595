import moment from 'moment/moment';
import { atom, selector } from 'recoil';
import { REQUEST_KEY } from '.';
import { bookPeriodState } from './search';

// 공간 의뢰 페이지에서 선택한 이벤트 유형 저장(카테고리 id)
export const categoryTypeState = atom({
  key: REQUEST_KEY.categoryType,
  default: [],
});

// 선택한 날짜 포맷 변경
export const stringPeriodState2 = selector({
  key: 'dates-to-string2_state',
  get: ({ get }) => {
    const period = get(bookPeriodState);

    let resultArr = [];
    const daytime = 86400000;

    const howlong = Math.ceil((period[period.length - 1] - period[0]) / daytime);

    for (let i = 0; i <= howlong; i++) {
      resultArr.push(moment(period[0]).add(i, 'days').format('yyyy. MM. DD'));
    }
    return resultArr;
  },
});

// 예약 기간 저장
export const requestPeriodState = selector({
  key: REQUEST_KEY.requestPeriod,
  get: ({ get }) => {
    const period = get(stringPeriodState2).map((item) => item.split('. ').join('-'));

    return period?.length > 0 && period?.length === 1
      ? {
          startDate: period[0],
          endDate: period[0],
        }
      : {
          startDate: period[0],
          endDate: period[period.length - 1],
        };
  },
});

// 자동 완성 목록에서 선택한 선호 위치 저장(공간 예약페이지, )
export const searchLocationState = atom({
  key: REQUEST_KEY.searchLocation,
  default: '',
});

export const maxPriceState = atom({
  key: REQUEST_KEY.options.maxPrice,
  default: '',
});
export const minPriceState = atom({
  key: REQUEST_KEY.options.minPrice,
  default: '',
});

export const userSelectState = atom({
  key: REQUEST_KEY.options.isUserSelect,
  default: false,
});

export const hotelOptionState = atom({
  key: REQUEST_KEY.options.hotelOption,
  default: [],
});

// 날짜별 옵션에서 직접 선택한 옵션 저장
export const userOptionState = atom({
  key: REQUEST_KEY.options.userOption,
  default: [],
});

// 직접 선택하기 옵션 초기 설정(공간 예약 페이지,)
export const defaultUserOptionState = selector({
  key: REQUEST_KEY.options.defaultUserOption,
  get: ({ get }) => {
    const period = get(stringPeriodState2);

    return (
      period?.length > 0 &&
      period?.map((item) => {
        return {
          requestDate: item.split('. ').join('-'),
          checkIn: '',
          checkOut: '',
          people: '',
          arrangementId: 0,
          meal: [],
          room: 0,
        };
      })
    );
  },
});

export const defaultHotelOptionState = selector({
  key: REQUEST_KEY.options.defaultHotelOption,
  get: ({ get }) => {
    const period = get(stringPeriodState2);

    return period?.map((item) => {
      return {
        requestDate: item.split('. ').join('-'),
        checkIn: '',
        checkOut: '',
        people: '',
        room: 0,
      };
    });
  },
});

// 의뢰 요청 데이터
export const spaceRequestState = atom({
  key: REQUEST_KEY.spaceRequest,
  default: {
    startDate: '', // string
    endDate: '', // string
    location: '', // string
    minPrice: '', // string
    maxPrice: '', // string
    contents: '', // string
    requestDueDate: '', // string
    userSelect: true, // boolean
    category: [], // [{categoryId: num},..]
    details: [
      // {
      //   requestDate: '', // string
      //   checkIn: '', // string
      //   checkOut: '', // string
      //   people: '', // number
      //   arrangementId: '', // number
      //   meal: [], // {mealId: number, amount: number} +
      // },
    ],
  },
});

export const moreInfoState = atom({
  key: REQUEST_KEY.moreInfo,
  default: {
    requestDueDate: null,
    contents: '',
  },
});
