import React from 'react';
import { DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';
import './index.css';
import styled from 'styled-components';
import moment from 'moment';
import { getDuration } from '../../../hooks/utils/filter';

const today = new Date();
const getDisabled = () => {
  const y = today.getFullYear();
  const m = today.getMonth() + 1;
  const dbs = [];
  for (let i = 0; i < today.getDate() - 1; i++) {
    dbs.push(
      moment(new Date(`${y}/${m}/01`))
        .add(i, 'days')
        .toDate()
    );
  }
  for (let i = moment().endOf('month').toDate().getDate(); i >= today.getDate(); i--) {
    dbs.push(moment(new Date(`${y + 1}/${m}/${i}`)).toDate());
  }

  return dbs;
};

const DatePickerWrap = styled.div`
  display: block;
  width: 100%;
  min-width: 280px;
  min-height: 390px;
  overflow-x: visible;
`;

/**
 * 일자 선택 컴포넌트
 */
export default function RDatePicker({ dates = [], handleChange, mobile, maxDueDate = null, availableDate = [] }) {
  const disableObj = getDuration([moment(new Date()), moment(new Date()).add({ year: 1 })]);

  const newDateObj = disableObj.filter((date) => !availableDate.includes(date)).map((item) => new Date(item));

  return (
    <DatePickerWrap>
      <DayPicker
        mode="range"
        defaultMonth={moment(new Date(today.getFullYear(), today.getMonth())).toDate()}
        fromMonth={moment(new Date(today.getFullYear(), today.getMonth())).toDate()}
        toMonth={moment(new Date(today.getFullYear() + 1, today.getMonth())).toDate()}
        disabled={
          !!maxDueDate
            ? [...getDisabled(), maxDueDate]
            : availableDate.length > 0
            ? [...getDisabled(), ...newDateObj]
            : getDisabled()
        }
        numberOfMonths={mobile ? 13 : 2}
        locale={ko}
        pagedNavigation
        showOutsideDays
        disableNavigation={mobile}
        selected={
          dates.length > 0
            ? {
                from: dates[0],
                to: dates.length > 1 ? dates[1] : undefined,
              }
            : undefined
        }
        onSelect={(v) => {
          if (dates.filter((d) => d !== undefined).length === 2) {
            handleChange([v === undefined ? dates[0] : v.to ? v.to : v.from]);
          } else {
            const duration = getDuration([v.from, v.to]);
            const canDate =
              availableDate.length > 0 ? !duration.map((date) => availableDate.includes(date)).includes(false) : true;

            if (!canDate) {
              handleChange([(dates[0] !== v.from && v.from) || (dates[0] !== v.to && v.to)]);
            } else {
              handleChange(
                moment(v.to).format('YYMMDD') === moment(v.from).format('YYMMDD') ? [v.from] : [v.from, v.to]
              );
            }
          }
        }}
        formatters={{
          formatCaption: (date, _) => `${date.getFullYear()}년 ${date.getMonth() + 1}월`,
        }}
      />
    </DatePickerWrap>
  );
}
