import React, { useEffect, useState } from 'react';

import plus from '../../../../assets/icons/utils/ico-plus.svg';
import minus from '../../../../assets/icons/utils/ico-minus.svg';

import * as D from '../../../../components/Common/Display';
import styled from 'styled-components';
import { Text } from '../../../../components/Common/Text';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { spaceDetailsState } from '../../../../store/space';
import { spaceBookPriceArrState, stringPeriodState } from '../../../../store/book';
import MealBox from './MealBox';
import { convertNumber } from '../../../../constants/number';
import { customAlert } from '../../../../store/modal';

const RowDiv = styled(D.Flex)`
  &:first-child {
    z-index: 42;
  }
  width: 100%;
  height: auto;
  gap: 16px;
`;

const Row = styled(D.Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const InputDiv = styled(D.Flex)`
  width: ${(props) => props._width ?? '150px'};
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.colors.gray300};

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 8px 16px;
    height: 40px;
  }
`;
const ImgDiv = styled.button`
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors[props._borderColor] ?? props.theme.colors.gray300};
  border-radius: 50px;
  & > img {
    width: 12px;
    height: 12px;
  }
`;
const UnitList = styled(D.Flex)`
  padding: 0 5px;
  justify-content: flex-end;
`;
const Unit = styled.button`
  width: 32px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 30px;

  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.xxs};
  color: ${(props) => props.theme.colors.gray900};
  text-align: center;
`;

const Subtitle = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.l};
  color: ${(props) => props.theme.colors.gray900};
`;
const LinkText = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.normal};
  font-size: ${(props) => (props.isSelect ? props.theme.fontSize.s : props.theme.fontSize.xxs)};
  color: ${(props) => (props.isSelect ? props.theme.colors.gray800 : props.theme.colors.purple300)};
  text-decoration: underline;
  cursor: pointer;

  @media (${(props) => props.theme.media.tabletSm}) {
    font-size: ${(props) => props.theme.fontSize.xxs};
  }
`;

const List = styled(D.FlexCols)`
  padding: 0 24px;

  @media (${(props) => props.theme.media.tabletSm}) {
    gap: 16px;
  }
  & > div {
    @media (${(props) => props.theme.media.tabletSm}) {
      gap: 4px;
    }
  }
`;

const AddMealButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.purple300};
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 15px;
    height: 15px;
  }
`;

const PeopleText = styled(Text)`
  display: inline-block;
  width: 45px;
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};
`;

const Button = styled.button`
  overflow: hidden;
  height: 100%;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};

  & > form {
    width: 45px;
    & > input {
      width: 100%;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: ${(props) => props.theme.fontSize.normal};
      color: ${(props) => props.theme.colors.gray900};
    }
  }
`;

const UNITS = [-10, -50, 10, 50];

// component: 날짜별 옵션 선택 시 날짜별 box(토글 element)
// /space/book/:id 페이지에 노출, spaceBook/index, selectBox 컴포넌트에서 호출
function HideBox({ index, tempData, setInfoModal, setTempData, hasRooms }) {
  // 모든 날짜 별 예약 비용
  // [1일비용, 2일비용....]
  const setPriceArr = useSetRecoilState(spaceBookPriceArrState);
  // 실시간으로 저장하는 호텔 예약 정보
  const spaceDetails = useRecoilValue(spaceDetailsState);
  // 설정한 예약 날짜
  const stringPeriod = useRecoilValue(stringPeriodState);
  // 얼럿창
  const setAlert = useSetRecoilState(customAlert);

  // "people": 날짜별 옵션에서 인원을 직접 입력, null: 버튼으로 인원 변경
  const [edit, setEdit] = useState(null);
  // 해당 날짜의 예약 총 금액
  const [dayPrice, setDayPrice] = useState(0);
  // 테이블 배치 옵션 정보
  const [tableSet, setTableSet] = useState(null);

  // 해당 날짜 옵션 변경 시, 예약 금액 계산
  useEffect(() => {
    const start = tempData?.checkIn.length > 0 && +tempData.checkIn.substring(0, 2);
    const end = tempData?.checkOut.length > 0 && +tempData.checkOut.substring(0, 2);
    const usingTime = start === end ? 24 : start > end ? 24 - (start - end) : end - start;

    const timePrice = usingTime * spaceDetails.priceTime;
    const personPrice = tempData?.people * spaceDetails.pricePerson ?? 0;
    const mealPrice = tempData?.meal.reduce((acc, obj) => acc + obj.mealPrice * obj.amount, 0);
    const roomPrice = tempData?.room.reduce((acc, obj) => acc + obj.roomPrice * obj.amount, 0);
    const result = timePrice + personPrice + mealPrice + roomPrice;

    setDayPrice(isNaN(result) ? 0 : result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempData]);

  // 날짜별 비용이 모두 저장된 배열에 해당 날짜 예약 비용 변경
  useEffect(() => {
    setPriceArr((prev) => {
      const priceObj =
        prev.length > stringPeriod.length ? [...prev].slice(0, stringPeriod.length) : [...prev];

      priceObj[index] = dayPrice;
      return priceObj;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringPeriod, dayPrice]);

  // 모든 날짜 별 옵션 데이터에서 해당 날짜 옵션을 변경하는 함수
  const setData = (key, value) =>
    setTempData((dt) =>
      dt.map((dt, _idx) =>
        _idx === index
          ? {
              ...dt,
              [key]: value,
            }
          : dt
      )
    );

  // 인원을 직접 입력하는 상태 종료
  const handleSubmit = (key) => {
    if (!tempData.hasOwnProperty(key)) {
      return;
    }
    const value = tempData[key];
    // 호텔에서 설정한 최소, 최대 인원이 넘지 않게 조건문
    const nValue =
      key === 'people'
        ? value < spaceDetails.minPeople
          ? spaceDetails.minPeople
          : value > spaceDetails.maxPeople
          ? spaceDetails.maxPeople
          : value
        : value;
    setData(key, nValue);
    setEdit(null);
  };

  // value가 숫자일 때만, setValue 실행
  const onlyNumber = (key, value) => {
    if (!isNaN(value)) {
      setData(key, +value);
    }
  };

  // 현재 설정된 옵션 인원(value) 1씩 감소
  const minusFn = (key, value) => {
    if (!tempData.hasOwnProperty(key)) {
      return;
    }
    // 호텔에서 설정한 최소, 최대 인원이 넘지 않게 조건문
    const nValue =
      key === 'people'
        ? tempData?.people <= spaceDetails.minPeople
          ? spaceDetails.minPeople
          : tempData?.people - 1
        : value < 1
        ? 0
        : value - 1;
    setData(key, nValue);
  };

  // 현재 설정된 옵션 인원(value) 1씩 증가
  const plusFn = (key, value) => {
    if (!tempData.hasOwnProperty(key)) {
      return;
    }
    const nValue =
      key === 'value'
        ? tempData?.people >= spaceDetails.maxPeople
          ? spaceDetails.maxPeople
          : tempData?.people + 1
        : value + 1;
    setData(key, nValue);
  };

  // 호텔의 테이블 배치 중 선택한 테이블 배치 저장
  useEffect(() => {
    !!tempData &&
      setTableSet(
        spaceDetails.arrange.find((item) => item.spaceArrangementId === tempData.spaceArrangementId)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempData]);

  return (
    <>
      {/* modal : 하이드 박스 모달(4) */}
      <D.FlexCols _gap={40}>
        {/* 체크인 체크아웃 인원 */}
        <RowDiv>
          <Row>
            <Subtitle>체크인</Subtitle>
            <InputDiv _justify="center">
              <Button onClick={() => setInfoModal(0, index)}>{tempData?.checkIn}</Button>
            </InputDiv>
          </Row>
          <Row>
            <Subtitle>체크아웃</Subtitle>
            <InputDiv _justify="center">
              <Button onClick={() => setInfoModal(1, index)}>{tempData?.checkOut}</Button>
            </InputDiv>
          </Row>
          <D.FlexCols _width="100%" _gap={8}>
            <Row>
              <Subtitle>인원</Subtitle>
              <InputDiv _justify="space-between">
                <ImgDiv
                  onClick={() => {
                    minusFn('people', tempData.people);
                  }}
                >
                  <img src={minus} alt="minus-img" />
                </ImgDiv>

                <Button onClick={() => setEdit('people')}>
                  {edit === 'people' ? (
                    <form
                      onSubmit={() => {
                        handleSubmit('people');
                      }}
                      onBlur={() => {
                        handleSubmit('people');
                      }}
                    >
                      <input
                        required
                        autoFocus
                        type="text"
                        name="people"
                        value={tempData?.people}
                        onChange={(e) => {
                          onlyNumber('people', e.currentTarget.value);
                        }}
                      />
                    </form>
                  ) : (
                    <PeopleText>{String(tempData?.people).padStart(2, '0')}</PeopleText>
                  )}
                </Button>

                <ImgDiv
                  onClick={() => {
                    plusFn('people', tempData.people);
                  }}
                >
                  <img src={plus} alt="plus-img" />
                </ImgDiv>
              </InputDiv>
            </Row>
            <UnitList _width="100%" _height="20px" _gap={4}>
              {UNITS.map((item) => (
                <Unit
                  key={item}
                  onClick={() => {
                    const sum = tempData?.people + item;

                    const result =
                      (sum > spaceDetails.maxPeople && spaceDetails.maxPeople) ||
                      (sum < spaceDetails.minPeople && spaceDetails.minPeople) ||
                      sum;

                    return setData('people', result);
                  }}
                >
                  {item}
                </Unit>
              ))}
            </UnitList>
          </D.FlexCols>
        </RowDiv>
        {/* 
            @수정 
            - 2023.04.25
            - 객실 수 선택 추가
            - 2023.09.12
            - 객실 옵션 선택 추가
          */}
        {/* 객실 선택 box */}
        {spaceDetails.meal[0] && (
          <D.FlexCols>
            <D.Flex _items="center" _justify="space-between" _gap={16}>
              <Subtitle>객실</Subtitle>
              {tempData?.room.length === 0 ? (
                <LinkText
                  onClick={() => {
                    if (!hasRooms) return setAlert({ text: '객실 정보가 없습니다.' });
                    setInfoModal(4, index);
                  }}
                >
                  옵션을 선택해주세요.
                </LinkText>
              ) : (
                <List _width="100%" _justify="center" _gap={24}>
                  {tempData?.room.map((item, idx) => (
                    <MealBox
                      key={idx}
                      objKey="room"
                      d_index={index}
                      m_index={idx}
                      item={item}
                      setTempData={setTempData}
                    />
                  ))}
                  <AddMealButton onClick={() => setInfoModal(4, index)}>
                    <img src={plus} alt="plus-img" />
                  </AddMealButton>
                </List>
              )}
            </D.Flex>
          </D.FlexCols>
        )}
        {/* 식사 및 패키지 선택 box */}
        {spaceDetails.meal[0] && (
          <D.FlexCols>
            <D.Flex _items="center" _justify="space-between" _gap={16}>
              <Subtitle>식사 및 패키지</Subtitle>
              {tempData?.meal.length === 0 ? (
                <LinkText onClick={() => setInfoModal(2, index)}>옵션을 선택해주세요.</LinkText>
              ) : (
                <List _width="100%" _justify="center" _gap={24}>
                  {tempData?.meal.map((item, idx) => (
                    <MealBox
                      key={idx}
                      objKey="meal"
                      d_index={index}
                      m_index={idx}
                      item={item}
                      setTempData={setTempData}
                    />
                  ))}
                  <AddMealButton onClick={() => setInfoModal(2, index)}>
                    <img src={plus} alt="plus-img" />
                  </AddMealButton>
                </List>
              )}
            </D.Flex>
          </D.FlexCols>
        )}
        {/* 테이블 배치 선택 box */}
        {spaceDetails?.arrange.length > 0 && (
          <React.Fragment>
            <D.Flex _items="center" _justify="space-between">
              <Subtitle>테이블 배치</Subtitle>
              <LinkText isSelect={!!tableSet} onClick={() => setInfoModal(3, index)} _size="l" _m_size="xs">
                {!tableSet
                  ? '타입을 선택해주세요.'
                  : `${tableSet?.arrangementName} (최대 ${tableSet?.arrangementPeople}명)`}
              </LinkText>
            </D.Flex>
            <D.Flex _items="center" _justify="space-between">
              <Subtitle>1인당 (예상)</Subtitle>
              <Text _size="l" _weight="bold" _color="gray900">{`₩ ${convertNumber(
                Math.ceil(dayPrice / tempData?.people)
              )}`}</Text>
            </D.Flex>
          </React.Fragment>
        )}
      </D.FlexCols>
    </>
  );
}

export default HideBox;
