import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { getSpaceRequest } from '../../service/client/request';
import {
  categoryTypeState,
  hotelOptionState,
  maxPriceState,
  minPriceState,
  moreInfoState,
  searchLocationState,
  spaceRequestState,
  userOptionState,
} from '../../store/request';
import { customAlert } from '../../store/modal';
import { bookPeriodState } from '../../store/search';
import { SITE } from '../../constants/site';
import { MYPAGE_KEYS } from './mypage';

const REQUEST_KEYS = {
  request: ['request', 'space'],
};

export const useSpaceRequestQuery = (reqData, token, isReq) => {
  const reset1 = useResetRecoilState(categoryTypeState);
  const reset2 = useResetRecoilState(minPriceState);
  const reset3 = useResetRecoilState(maxPriceState);
  const reset4 = useResetRecoilState(userOptionState);
  const reset5 = useResetRecoilState(hotelOptionState);
  const reset6 = useResetRecoilState(searchLocationState);
  const reset7 = useResetRecoilState(moreInfoState);
  const reset8 = useResetRecoilState(spaceRequestState);
  const reset9 = useResetRecoilState(bookPeriodState);
  const setAlert = useSetRecoilState(customAlert);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSuccess = () => {
    reset1();
    reset2();
    reset3();
    reset4();
    reset5();
    reset6();
    reset7();
    reset8();
    reset9();
    setAlert({
      text: '의뢰를 완료하였습니다.',
      handleClose: () => {
        navigate(SITE.home, { replace: true });
      },
    });
    queryClient.invalidateQueries({ queryKey: ['memberRequest'] });
  };
  return useQuery(
    [...REQUEST_KEYS.request],
    async () => {
      const query = await getSpaceRequest(reqData, token);
      return query;
    },
    {
      enabled: isReq,
      onSuccess,
    }
  );
};
