import React from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { useSpaceCategoryQuery } from '../../../../hooks/queries/category';
import { categoryTypeState } from '../../../../store/request';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const List = styled.ul`
  width: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`;
const Item = styled.li`
  width: 120px;
  height: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
  gap: 4px;

  background: ${(props) => (props.selected ? props.theme.colors.purple50 : props.theme.colors.white)};
  border: ${(props) => (props.selected ? '2px' : '1px')} solid
    ${(props) => (props.selected ? props.theme.colors.mainPurple : props.theme.colors.gray300)};

  ${(props) => props.theme.effectStyle.boxShadow};
  border-radius: 20px;
  cursor: pointer;

  .category-img {
    width: 60px;
    height: 60px;
  }
`;

// component: 이벤트 유형 선택 box
// /space/request 페이지에 노출 및 호출
function SelectCategoryType() {
  // 전체 이벤트 유형 조회
  const { data: categories, isLoading } = useSpaceCategoryQuery();
  // 선택한 이벤트 유형
  const [categoryType, setCategoryType] = useRecoilState(categoryTypeState);

  return (
    <Wrapper>
      <List>
        {!isLoading &&
          categories.map(({ categoryId, categoryName, categoryIcon }) => {
            let result = [];
            const categoryObj = [...categoryType];

            const hasCategory = categoryObj.filter((item) => item.categoryId === categoryId).length > 0;

            const handleClick = (e) => {
              if (hasCategory) {
                result = categoryObj.filter((item) => item.categoryId !== categoryId);
              } else {
                categoryObj.push({ categoryId });
                result = categoryObj;
              }

              setCategoryType(result);
            };

            return (
              <Item key={categoryId} selected={hasCategory} onClick={handleClick}>
                <img
                  className="category-img"
                  src={`http://cdn.alabwaba.com${categoryIcon}`}
                  alt="category-img"
                />
                <span>{categoryName}</span>
              </Item>
            );
          })}
      </List>
    </Wrapper>
  );
}

export default SelectCategoryType;
