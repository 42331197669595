import React, { useState } from 'react';

import leftArrow from '../../../../assets/icons/utils/ico-chevron_up.svg';

import styled from 'styled-components';
import LayoutModal from './LayoutModal';
import { Text } from '../../../../components/Common/Text';

const List = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;

const Item = styled.button`
  width: 115px;
  height: 108px;
  padding: 30px 16px;

  border: 1px solid
    ${(props) => (props.isSelected ? props.theme.colors.mainPurple : props.theme.colors.gray300)};
  border-radius: 20px;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.gray800};
`;
const Name = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.gray800};
  line-height: 1.5;
  white-space: pre;
  text-align: center;
`;
const ImgDiv = styled.button`
  display: none;
  position: absolute;
  top: 21px;
  left: 24px;
  img {
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
  }
  @media (${(props) => props.theme.media.tabletSm}) {
    display: flex;
  }
`;

// component: 테이블 타입 선택 modal
// /space/request 페이지에서 노출, UserBox에서 호출
function SelectTableTypeModal({ closeModal, data, arrangementId, setArrangementId }) {
  // 테이블 타입 id
  const [selected, setIsSelected] = useState(arrangementId);

  return (
    <LayoutModal
      w_width="502px"
      title="테이블 타입 선택"
      b_text="확인"
      padding="24px 40px"
      m_padding="20px 20px 24px 20px"
      closeModal={closeModal}
      onClick={() => {
        if (!!selected) {
          setArrangementId(selected);
        }
      }}
    >
      <ImgDiv onClick={closeModal}>
        <img src={leftArrow} alt="arrow-img" />
      </ImgDiv>
      <List>
        {data.map((item, index) => {
          return (
            <Item
              key={index}
              isSelected={selected === item.arrangementId}
              onClick={() => setIsSelected(item.arrangementId)}
            >
              <Name>{item.arrangementName}</Name>
            </Item>
          );
        })}
      </List>
    </LayoutModal>
  );
}

export default SelectTableTypeModal;
