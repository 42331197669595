import React from 'react';
import styled from 'styled-components';
import bell from '../../assets/icons/menu/ico-menu-bell.svg';
import hand from '../../assets/icons/menu/ico-menu-hand.svg';
import chat from '../../assets/icons/menu/ico-menu-chat.svg';
import { Link } from 'react-router-dom';
import { chatRoom, SITE } from '../../constants/site';
import { useUser } from '../../store/user';
import { useSetRecoilState } from 'recoil';
import { customAlert, loginModalState } from '../../store/modal';
import { getChatInquiry } from '../../service/client/mypage';
import { getNewChatId } from '../../service/client/chat';

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (${(props) => props.theme.media.tabletM}) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 55px 48px;
  ${(props) => props.theme.effectStyle.boxShadow}
  border-radius: 999px;

  @media (${(props) => props.theme.media.tabletM}) {
    padding: 44px 32px;
    width: 100%;
    flex: auto;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  text-align: left;

  strong {
    font-size: ${(props) => props.theme.fontSize.xxxl};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.colors.purple900};
    line-height: 1.5;
    word-break: keep-all;
    @media (${({ theme }) => theme.media.tablet}) {
      font-size: ${(props) => props.theme.fontSize.xl};
    }
  }

  p {
    font-size: ${(props) => props.theme.fontSize.s};
    color: ${(props) => props.theme.colors.gray800};
    word-break: keep-all;
    white-space: pre-wrap;
    line-height: 1.5;
  }
`;

/**
 * 홈 > 하단 카테고리 메뉴
 */
export default function BottomMenuListBox() {
  const { loggedIn } = useUser();
  const setAlert = useSetRecoilState(customAlert);
  const setLogin = useSetRecoilState(loginModalState);

  const chatting = async (e) => {
    const room = await getNewChatId();
    window.open(`${chatRoom(room.data.data.room)}?s=0`, '_blank');
  };

  return (
    <ListWrapper>
      {[
        {
          subtitle: '공간 의뢰하기',
          description: '나에게 필요한 공간을 찾아\n알아봐봐의 파트너들에게 의뢰해 보세요.',
          img: bell,
          onlyUser: true,
          to: SITE.spaceRequest,
        },
        {
          subtitle: '공간 파트너 요청하기',
          description: '소유하신 공간을 공유하여\n알아봐봐의 파트너가 되어보세요.',
          img: hand,
          onlyUser: false,
          to: SITE.partnerRequest,
        },
        {
          subtitle: '알아봐봐에게 문의하기',
          description: '궁금하신 점이 있으신가요?',
          img: chat,
          onlyUser: false,
          onClick: () => {
            chatting(0);
          },
        },
      ].map((item, idx) => (
        <Box
          key={idx}
          {...(item.onlyUser &&
            !loggedIn && {
              as: 'button',
              onClick: () => {
                setAlert({
                  text: '로그인 후 이용하실 수 있습니다',
                  handleClose: () => setLogin({ mode: true, redirect: item.to }),
                });
              },
            })}
          {...((!item.onlyUser || (item.onlyUser && loggedIn)) &&
            (item.onClick
              ? {
                  as: 'button',
                  onClick: () => {
                    item.onClick();
                  },
                }
              : { as: Link, to: item.to }))}>
          <Column>
            <strong className="subtitle">{item.subtitle}</strong>
            <p className="description">{item.description}</p>
          </Column>
          <img src={item.img} alt={item.subtitle} />
        </Box>
      ))}
    </ListWrapper>
  );
}
