import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.css";
import { cdnUrl } from "../../constants/url";
import { useBannerQuery } from "../../hooks/queries/home";
import { useMobile } from "../../hooks/ui/mediaQuery";
import { S as Style } from "../Common/PicSlider";

const S = {
  Wrapper: styled(Style.Wrapper)`
    position: relative;
    width: 100%;
    max-width: 1180px;
    height: auto !important;

    > div {
      position: relative;
      border-radius: 0;
      width: 100vw;
      height: 100%;
      max-height: 100%;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
    }

    @media (max-width: 1180px) {
      > div {
        width: 100%;
        left: 0;
        transform: translateX(0);
      }
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      min-height: 54vw;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      > img {
        width: 100%;
        height: auto;
      }
    }
  `,
  Nav: styled(Style.Nav)`
    display: block;
    left: 5px;
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    background-size: 10px;

    ${({ next }) =>
      next &&
      css`
        left: auto;
        right: 5px;
      `}

    @media (${(props) => props.theme.media.tablet}) {
      display: none;
    }
  `,
  Page: styled(Style.Page)`
    display: block;
    bottom: auto;
    bottom: 24px;

    @media (${(props) => props.theme.media.tablet}) {
      bottom: 6px;
      height: 25px;
      padding: 4px 8px;
      font-size: 14px;
    }
  `,
};

/**
 * 홈 > 슬라이더 배너 컴포넌트
 */
function ImageSlider() {
  const pageRef = useRef(null);
  const [isMobile] = useMobile(767);
  const { isLoading, data } = useBannerQuery();
  const [images, setImages] = useState([]);
  const [slider, setSlider] = useState(null);

  const onNavClick = (idx) => {
    if (slider) {
      if (idx > 0) {
        slider.slideNext();
      } else {
        slider.slidePrev();
      }
    }
  };

  useEffect(() => {
    if (data) {
      setImages(isMobile ? data.pc : data.mobile);
    }
  }, [data, isMobile]);

  return isLoading || !data ? (
    <S.Wrapper>
      <div style={{ width: '100%', padding: '31.25% 0 0', height: 0 }} />
    </S.Wrapper>
  ) : (
    <S.Wrapper>
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={true}
        onSwiper={(swiper) => {
          setSlider(swiper);
        }}
        onSlideChange={(swiper) => {
          if (pageRef && pageRef.current) {
            pageRef.current.innerText = swiper.realIndex + 1;
          }
        }}
        speed={1000}>
        {images.map((img, idx) => (
          <SwiperSlide key={idx}>
            <img src={cdnUrl(`/banner/${img}`)} />
          </SwiperSlide>
        ))}
      </Swiper>
      <S.Nav
        _nav={true}
        onClick={() => {
          onNavClick(-1);
        }}
      />
      <S.Nav
        _nav={true}
        onClick={() => {
          onNavClick(1);
        }}
        next
      />
      <S.Page>
        <span ref={pageRef}>1</span> / <span>{images.length}</span>
      </S.Page>
    </S.Wrapper>
  );
}

export default ImageSlider;
