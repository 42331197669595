import React, { useState } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../../hooks/ui/mediaQuery';
import { RoundBtn } from '../../Common/Button';
import { yScrollBoxCss } from '../../Common/Display';
import RDatePicker from '../../Common/RDatePicker';

const Wrapper = styled.div`
  width: max-content;
  max-width: calc(100vw - 40px);
  height: auto;
  max-height: calc(100vh - 100px);
  background: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.effectStyle.selected};
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  padding: 48px 0;
  gap: 40px;
  align-items: center;

  @media (${(props) => props.theme.media.tabletSm}) {
    /* top: 150px; */
    gap: 10px;
    padding: 24px 0;
    max-height: calc(100vh - 150px);
  }
`;

const CalendarDiv = styled.div`
  padding: 0 40px;

  @media (${(props) => props.theme.media.tabletSm}) {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 400px;
    ${yScrollBoxCss(4)}
    > div {
      height: auto;
    }
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 0 24px;
  }
`;

export default function DatesOption({ value, handleSubmit }) {
  const [dates, setDates] = useState(value);
  const [isMobile, isResizing] = useMobile(767);

  return isResizing ? null : (
    <Wrapper>
      <CalendarDiv>
        <div>
          <RDatePicker
            dates={dates}
            handleChange={(v) => {
              setDates(v);
            }}
            mobile={isMobile}
          />
        </div>
      </CalendarDiv>
      <ButtonDiv>
        <RoundBtn
          disabled={dates.length === 0}
          _width="300px"
          text="적용"
          _primary
          _capsule
          type="button"
          onClick={() => {
            handleSubmit(dates);
          }}
        />
      </ButtonDiv>
    </Wrapper>
  );
}
