import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FormModal } from '../../layouts/Modal';
import { RoundBtn } from '../Common/Button';
import * as D from '../Common/Display';
import { InputField, RadiosField } from '../Common/InputField';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { customAlert, reportModal } from '../../store/modal';
import { useUser } from '../../store/user';
import { useReviewReportQuery } from '../../hooks/queries/space';

const getMaxLeft = (elW) => {
  const l = window.innerWidth - (elW + 10);
  return l > 0 ? l : 0;
};
const getMaxTop = (elH) => {
  const t = window.innerHeight - (elH + 10);
  return t > 0 ? t : 0;
};

/**
 * 리뷰 신고 컴포넌트
 * @param {element} props.stdBox 기준 박스 - UI를 위해 받는 property 
 */
export default function ReviewReport({ reviewId, stdBox }) {
  // ui
  const ui_w = 300;
  const ui_h = 540;
  const ui_bp = 767;
  const [pos, setPos] = useState({ top: 0, left: 0 });
  // utils
  const setAlert = useSetRecoilState(customAlert);
  const { loggedIn, token } = useUser();
  const resetReport = useResetRecoilState(reportModal);
  const [info, setInfo] = useState({
    reason: '',
    contents: '',
  });
  const { mutate: requestReport, isSuccess } = useReviewReportQuery();

  const setUiProps = (box) => {
    if (window.innerWidth <= ui_bp) {
      setPos({ top: 0, left: 0 });
    } else {
      const x = box.getBoundingClientRect().x + box.getBoundingClientRect().width + 10;
      const y = box.getBoundingClientRect().y;
      const maxL = getMaxLeft(ui_w);
      const maxT = getMaxTop(ui_h);
      const t = y > maxT ? maxT : y;
      const l = x > maxL ? maxL : x;
      const s = document.body.scrollTop - (y > maxT ? maxT - y : 0);
      document.body.scrollTo({ top: s, left: 0, behavior: 'smooth' });
      setPos({ top: t, left: l });
    }
  };

  const handleSubmit = () => {
    requestReport({
      params: {
        reviewId: reviewId,
        reason: info.reason,
        contents: info.contents,
      },
      token: token,
    });
  };

  useLayoutEffect(() => {
    let resetPos;
    if (stdBox) {
      window.removeEventListener('resize', resetPos);
      resetPos = setUiProps(stdBox);
      window.addEventListener('resize', resetPos);
      setUiProps(stdBox);
    }
    return () => {
      window.removeEventListener('resize', resetPos);
    };
  }, [stdBox]);

  useEffect(() => {
    if (isSuccess) {
      setAlert({ text: '리뷰 신고가 접수되었습니다.' });
      resetReport();
    }
  }, [isSuccess]);

  if (!reviewId || !loggedIn) {
    return;
  }

  return (
    <FormModal
      _size="s2"
      title="리뷰 신고하기"
      overlayVisible={false}
      action={
        <RoundBtn
          disabled={info.reason.length <= 0 || (info.reason === '기타' && info.contents.length <= 0)}
          onClick={handleSubmit}
          text="확인"
          _primary
          _capsule
          _width="100%"
        />
      }
      _position="fixed"
      _top={pos.top}
      _left={pos.left}
      _height={ui_h + 'px'}
      handleModalClose={() => {
        resetReport();
      }}>
      <D.FlexCols
        _width="100%"
        _gap={12}
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
        <RadiosField
          handleChange={(v) => {
            setInfo((info) => ({ ...info, reason: v }));
          }}
          options={[
            '예약과 관련 없는 비방 내용',
            '음란, 욕설 등 부적절한 내용',
            '부적절한 홍보 또는 광고 내용',
            '관련 없는 사진',
            '개인정보 유출 위험',
            '기타',
          ].map((v) => ({ value: v, text: v }))}
          name="reportReason01"
          value={info.reason}
        />
        <InputField
          textarea
          name="reportReason02"
          placeholder="구체적인 신고사유를 입력해주세요."
          _height="88px"
          value={info.contents}
          handleChange={(v) => {
            setInfo((info) => ({ ...info, contents: v }));
          }}
        />
      </D.FlexCols>
    </FormModal>
  );
}
