import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useReviewListQuery } from '../../../hooks/queries/mypage';
import CardList from '../../Common/CardList';
import { ErrorStatus, LoadingStatus } from '../../Common/Status';
import ReviewCard02 from '../../Review/ReviewCard02';

/**
 * 마이페이지 > 리뷰 목록 컴포넌트
 */
export default function ReviewList({ handleReviewClick }) {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, status, hasNextPage } = useReviewListQuery();
  const [page, setPage] = useState([]);

  const [ref, inView] = useInView();

  // infinite scroll
  useEffect(() => {
    let arr = [];
    if (inView && hasNextPage) {
      fetchNextPage();
      data.pages.map((x) => {
        arr.push(...x.products);
      });
      setPage(arr);
    }
  }, [inView, hasNextPage]);

  return status === 'loading' ? (
    <LoadingStatus box />
  ) : (
    <>
      {data?.pages[0].products?.length ? (
        <div>
          <CardList direction="column" gap={24}>
            {(page == '' ? data?.pages[0].products : page).map((review, idx) => {
              return <ReviewCard02 key={idx} review={review} type={review.type === '공간' ? 'space' : 'manager'} />;
            })}
          </CardList>
          <div ref={ref}>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
        </div>
      ) : (
        <ErrorStatus message="리뷰 내역이 없습니다." />
      )}
    </>
  );
}
