import React, { useEffect, useState } from 'react';

import leftArrow from '../../../../assets/icons/utils/ico-chevron_up.svg';
import chevronIcon from '../../../../assets/icons/utils/ico-chevron_up.svg';

import styled from 'styled-components';
import { FlexCols } from '../../../../components/Common/Display';
import { useMealCategoryQuery } from '../../../../hooks/queries/category';
import LayoutModal from './LayoutModal';

const Wrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  height: 48px;

  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => (props._hidden ? props.theme.fontWeight.gray700 : props.theme.fontWeight.gray900)};

  background: ${(props) => (props._hidden ? props.theme.colors.white : props.theme.colors.gray50)};

  border: 1px solid ${(props) => props.theme.colors.gray300};
  ${(props) => !props._hidden && `border-bottom: none`};
  border-radius: ${(props) => (props._hidden ? '10px' : '10px 10px 0 0')};

  &::after {
    content: '';
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url(${chevronIcon}) center no-repeat;
    transform: rotate(${(props) => (props._hidden ? 180 : 0)}deg);
  }
`;
const SelectList = styled(FlexCols)`
  /* position: absolute; */
  top: 48px;
  left: 0;

  width: 100%;
  background: ${(props) => props.theme.colors.white};

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  border: 1px solid ${(props) => props.theme.colors.gray300};
  z-index: 20;
`;
const SelectButton = styled.button`
  padding: 10px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;

  font-size: ${(props) => props.theme.fontSize.l};
  // font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.gray900};
  &:hover {
    background: ${(props) => props.theme.colors.purple50};
  }
`;
const ImgDiv = styled.button`
  display: none;
  position: absolute;
  top: 21px;
  left: 24px;
  img {
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);
  }
  @media (${(props) => props.theme.media.tabletSm}) {
    display: flex;
  }
`;

const packageNames = ['코스', '단품', '객실', '뷔페'];

// component: 식사 패키지 선택 modal
// /space/request 페이지에서 노출, UserBox에서 호출
function SelectPackageModal({ closeModal, setMeal }) {
  // 전체 식사 패키지 조회
  const { isLoading, data } = useMealCategoryQuery();

  // 전체 식사 패키지
  const [menu, setMenu] = useState('');
  // 선택한 식사 패키지
  const [packageInfo, setPackageInfo] = useState({
    // mealName: '',
    // mealId: '',
    // option: '',
  });

  const [hidden1, setHidden1] = useState(true);
  // const [hidden2, setHidden2] = useState(true);

  // 전체 식사 패키지 저장
  useEffect(() => {
    const result = !isLoading && data.filter((item) => packageNames.includes(item.mealName));

    setMenu(result);
  }, [data, isLoading]);

  return (
    <LayoutModal
      w_width="380px"
      title="식사 및 패키지"
      b_text="확인"
      padding="24px 40px"
      m_padding="20px 20px 24px 20px"
      closeModal={closeModal}
      onClick={() =>
        setMeal((prev) => [
          ...prev,
          {
            ...packageInfo,
          },
        ])
      }
    >
      <ImgDiv onClick={closeModal}>
        <img src={leftArrow} alt="arrow-img" />
      </ImgDiv>
      <Wrapper>
        <div>
          <Button
            _hidden={hidden1}
            onClick={() => {
              // setHidden2(true);
              setHidden1((prev) => !prev);
            }}
          >
            {!!packageInfo.mealName ? packageInfo.mealName : '식사 및 패키지'}
          </Button>
          {!hidden1 && (
            <SelectList>
              {menu.map((meal, index) => (
                <SelectButton
                  key={index}
                  onClick={() => {
                    setHidden1(true);
                    setPackageInfo((prev) => {
                      return {
                        ...prev,
                        mealName: meal.mealName,
                        mealId: meal.mealId,
                      };
                    });
                  }}
                >
                  {meal.mealName}
                </SelectButton>
              ))}
            </SelectList>
          )}
        </div>
        {/* <div>
          <Button
            _hidden={hidden2}
            onClick={() => {
              setHidden1(true);
              setHidden2((prev) => !prev);
            }}
          >
            {!!packageInfo.option ? packageInfo.option : '옵션'}
          </Button>
          {!hidden2 && (
            <SelectList>
              <SelectButton
                onClick={() => {
                  setHidden2(true);
                  setPackageInfo((prev) => {
                    return {
                      ...prev,
                      option: 'A',
                    };
                  });
                }}
              >
                A
              </SelectButton>
              <SelectButton
                onClick={() => {
                  setHidden2(true);
                  setPackageInfo((prev) => {
                    return {
                      ...prev,
                      option: 'B',
                    };
                  });
                }}
              >
                B
              </SelectButton>
              <SelectButton
                onClick={() => {
                  setHidden2(true);
                  setPackageInfo((prev) => {
                    return {
                      ...prev,
                      option: 'C',
                    };
                  });
                }}
              >
                C
              </SelectButton>
            </SelectList>
          )}
        </div> */}
      </Wrapper>
    </LayoutModal>
  );
}

export default SelectPackageModal;
