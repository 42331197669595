import React, { useRef, useState, useEffect } from 'react';
import { circlePin, groupInfowindow, PinkPinLg, WhitePin } from './icons/Marker';
import * as S from './styles/mapStyle';
import './styles/customStyle.css';
import { infoWindow as uiInfowindow } from './icons/Marker';
import { useUserLocation } from '../../store/user';
import { useMapListQuery } from '../../hooks/queries/space';
import { KoNum } from '../../utils/number';
import MobileInfoWindow from './icons/MobileInfoWindow';
import { useMobile } from '../../hooks/ui/mediaQuery';
const { naver } = window;

const mapOptios = {
  whiteIcon: (count = 0) => ({
    content: WhitePin(count),
    size: new naver.maps.Size(22, 35),
    anchor: new naver.maps.Point(11, 35),
  }),
  pinkIcon: {
    content: PinkPinLg,
    size: new naver.maps.Size(22, 35),
    anchor: new naver.maps.Point(20, 53),
  },
  locIcon: {
    content: circlePin,
    size: new naver.maps.Size(22, 35),
    anchor: new naver.maps.Point(11, 35),
  },
  infoWindow: {
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transprent',
    anchor: new naver.maps.Point(0, 0),
    anchorSize: new naver.maps.Size(0, 0),
    anchorColor: 'transparent',
    pixelOffset: new naver.maps.Point(20, -20),
  },
};

const SAMSUNG_DONG_LATLNG = [37.51148310935, 127.06033711446]; // 삼성동 코엑스

/**
 * 메인 지도 컴포넌트 (검새 조회)
 * @param props.filter 검색 필터
 * @param props.serMobileIsOn 모바일 환경 핸들러
 */
function Map({ filter, setMobileIsOn }) {
  // map
  const mRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapInfo, setMapInfo] = useState(null);
  // pin, infowindow
  const [markers, setMarkers] = useState([]);
  const [locMarker, setLocMarker] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [mInfoWindow, setMInfoWindow] = useState(null);
  const miwRef = useRef(null);
  // user
  const userLocation = useUserLocation();
  // query
  const [mapFilter, setMapFilter] = useState(null);
  const { data: resData, refetch } = useMapListQuery(mapFilter);
  const [isMobile, _] = useMobile(767);

  const clearMap = () => {
    if (markers) {
      for (const mk of markers) {
        mk.setMap(null);
      }
    }
    if (infoWindow) {
      infoWindow.close();
    }
    if (mInfoWindow) {
      setMInfoWindow(null);
      setMobileIsOn(false);
    }
  };

  const updateMarkers = (data, map) => {
    if (data.isGroup) {
      setMarkers(
        data.data.map((mk) => {
          const nmk = new naver.maps.Marker({
            position: new naver.maps.LatLng(mk.lat, mk.lng),
            map: map,
            icon: mapOptios.whiteIcon(mk.cnt),
          });
          naver.maps.Event.addListener(nmk, 'click', function (e) {
            map.setCenter(new naver.maps.LatLng(mk.lat, mk.lng));
            map.setZoom(data.nextZoom, 'linear');
          });
          return nmk;
        })
      );
    } else {
      const rData = data.data.reduce((rs, r) => {
        const k = `${r.lat}|${r.lng}`;
        const n = r.hotelName.replaceAll(' ', '').trim();
        return rs[k]
          ? { ...rs, [k]: rs[k][n] ? { ...rs[k], [n]: [...rs[k][n], r] } : { ...rs[k], [n]: [r] } }
          : { ...rs, [k]: { [n]: [r] } };
      }, {});

      const cmMks = [];
      const gpMks = [];
      const totalMks = [];
      for (const k of Object.keys(rData)) {
        const nks = Object.keys(rData[k]);
        if (nks.length === 1) cmMks.push(rData[k][nks[0]]);
        else gpMks.push(nks.map((nk) => rData[k][nk]));
      }

      for (const cmMk of cmMks) {
        const nmk = new naver.maps.Marker({
          position: new naver.maps.LatLng(cmMk[0].lat, cmMk[0].lng),
          map: map,
          icon: mapOptios.whiteIcon(cmMk.length === 1 ? 0 : cmMk.length),
        });
        addInfoWindow({ type: 'common', marker: nmk, data: cmMk, map: map });
        totalMks.push(nmk);
      }

      for (const gpMk of gpMks) {
        const nmk = new naver.maps.Marker({
          position: new naver.maps.LatLng(gpMk[0][0].lat, gpMk[0][0].lng),
          map: map,
          icon: mapOptios.whiteIcon(gpMk.reduce((len, mk) => len + mk.length, 0)),
        });
        addInfoWindow({ type: 'group', marker: nmk, data: gpMk, map: map });
        totalMks.push(nmk);
      }
      setMarkers(totalMks);
    }
  };

  /*
    @type: common, group
  */
  const addInfoWindow = (params) => {
    if (isMobile) addMobileInfoWindow(params);
    else addPcInfoWindow(params);
  };

  const addPcInfoWindow = (params) => {
    const { type, marker, data, map } = params;

    const iw = new naver.maps.InfoWindow({
      content:
        type === 'common'
          ? uiInfowindow({
              name: data[0].hotelName,
              room: data[0].spaceName,
              price: `${KoNum(data[0].priceTime)}`,
              link: data.length > 1 ? `/details/${data[0].hotelId}` : `/book/${data[0].spaceId}`,
              img: data[0].thumbnail,
              count: data.length,
            })
          : groupInfowindow(data),
      ...mapOptios.infoWindow,
    });

    let mapClickFn;
    iw.addListener('open', () => {
      marker.setIcon(mapOptios.pinkIcon);
      marker.setZIndex(99);
      setInfoWindow(iw);
      mapClickFn = naver.maps.Event.addListener(map, 'click', function (e) {
        iw.close();
      });
    });
    iw.addListener('close', () => {
      marker.setIcon(
        mapOptios.whiteIcon(
          data.length === 1 ? 0 : type === 'group' ? data.reduce((cnt, d) => cnt + d.length, 0) : data.length
        )
      );
      marker.setZIndex(90);
      naver.maps.Event.removeListener(mapClickFn);
      setInfoWindow(null);
    });
    naver.maps.Event.addListener(marker, 'click', (e) => {
      if (iw.getMap()) iw.close();
      else iw.open(map, marker);
    });
  };

  const addMobileInfoWindow = (params) => {
    const { marker, data } = params;

    naver.maps.Event.addListener(marker, 'click', (e) => {
      if (mInfoWindow) setMInfoWindow(null);
      setMInfoWindow(data);
      setMobileIsOn(true);
    });
  };

  const updateUserLocMarker = (map, latlng) => {
    if (locMarker) {
      locMarker.setMap(null);
    }
    setLocMarker(
      new naver.maps.Marker({
        position: new naver.maps.LatLng(latlng[0], latlng[1]),
        map: map,
        icon: mapOptios.locIcon,
      })
    );
  };

  useEffect(() => {
    if (mRef && naver) {
      const nvMap = new naver.maps.Map(mRef.current, {
        center: userLocation.agree
          ? new naver.maps.LatLng(userLocation.latlng[0], userLocation.latlng[1])
          : new naver.maps.LatLng(SAMSUNG_DONG_LATLNG[0], SAMSUNG_DONG_LATLNG[1]),
        zoom: 13,
        minZoom: 8,
        zoomControl: false,
        scaleControl: false,
      });
      let idleTimeout = null;
      nvMap.addListener('idle', function (props) {
        if (idleTimeout) {
          clearTimeout(idleTimeout);
        }
        idleTimeout = setTimeout(() => {
          setMapInfo({
            zoom: props.__targets.zoom.target.zoom,
            bounds: props.__targets.projection.target.bounds,
          });
          idleTimeout = null;
        }, 500);
      });
      setMap(nvMap);
      setMapInfo({
        zoom: nvMap.zoom,
        bounds: nvMap.bounds,
      });
    }
  }, [mRef]);

  useEffect(() => {
    if (map && userLocation.agree) {
      map.setCenter(new naver.maps.LatLng(userLocation.latlng[0], userLocation.latlng[1]));
      updateUserLocMarker(map, userLocation.latlng);
    }
  }, [userLocation, map]);

  useEffect(() => {
    if (mapInfo && filter) {
      setMapFilter({
        ...filter,
        ...(filter.type === null && {
          lng: [mapInfo.bounds._min._lat, mapInfo.bounds._max._lat],
          lat: [mapInfo.bounds._min._lng, mapInfo.bounds._max._lng],
          zoom: mapInfo.zoom,
        }),
      });
    }
  }, [mapInfo, filter]);

  useEffect(() => {
    if (mapFilter) refetch();
  }, [mapFilter]);

  useEffect(() => {
    if (resData && map) {
      clearMap();
      updateMarkers(resData, map);
    }
  }, [resData, map, isMobile]);

  useEffect(() => {
    let clickFn;
    if (!map) {
      return;
    }
    if (mInfoWindow) {
      clickFn = naver.maps.Event.addListener(map, 'click', function (e) {
        setMInfoWindow(null);
        setMobileIsOn(false);
      });
    } else {
      naver.maps.Event.removeListener(clickFn);
    }
  }, [mInfoWindow, map]);

  return (
    <S.PageWrapper>
      <S.MapFrame ref={mRef} />
      {isMobile && mInfoWindow && <MobileInfoWindow ref={miwRef} data={mInfoWindow} />}
      {/* {!userLocation.isLoading && resData && resData.data.length <= 0 && <S.StatusBar>no data (test)</S.StatusBar>} */}
    </S.PageWrapper>
  );
}

const SearchMap = React.memo(Map, (p, n) => JSON.stringify(p.filter) === JSON.stringify(n.filter));
export default SearchMap;
