import React, { useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { EMAIL_PATTERN, PWD_PATTERN } from "../../../constants/pattern";
import { NormalModal } from "../../../layouts/Modal";
import { checkValidAccount, getAuthCheck, registerNewPwd } from "../../../service/client/user";
import { customAlert, findIdModalState, findPwModalState, loginModalState } from "../../../store/modal";
import { RoundBtn, TabBtn } from "../../Common/Button";
import * as D from "../../Common/Display";
import { InputField } from "../../Common/InputField";
import { Text } from "../../Common/Text";
import PassAuth from "../Auth/PassAuth";

const findPwdInitialState = {
  authOn: false,
  authResult: -1, // 0: 실패, 1: 성공
  accountResult: -1, // 0: 실패, 1: 성공
  registerResult: -1, // 0: 성공, 1: 실패
};
const findPwdReducer = (state, action) => {
  switch (action.type) {
    case "authOn":
      return { ...state, authOn: action.payload };
    case "authResult":
      return { ...state, authResult: action.payload };
    case "accountResult":
      return { ...state, accountResult: action.payload };
    case "registerResult":
      return { ...state, registerResult: action.payload };
    case "initialize":
      return findPwdInitialState;
    default:
      throw new Error();
  }
};

/**
 * 비밀번호 찾기 컴포넌트
 */
const FindPwd = () => {
  const [state, dispatch] = useReducer(findPwdReducer, findPwdInitialState);
  const [token, setToken] = useState(null);
  const [phone, setPhone] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [failMsg, setFailMsg] = useState(null);
  const {
    register,
    formState: { errors },
    getValues,
    // handleSubmit,
  } = useForm({
    mode: "onBlur",
  });

  const setIsFindId = useSetRecoilState(findIdModalState);
  const resetFindPwd = useResetRecoilState(findPwModalState);
  const setLogin = useSetRecoilState(loginModalState);
  const setAlert = useSetRecoilState(customAlert);
  const findPwdMode = useRecoilValue(findPwModalState);

  // 인증 처리
  const handleAuth = async () => {
    if (!token) {
      resetFindPwd();
    }

    const response = await getAuthCheck(token);
    const { data, status } = response.data;

    if (status === 200) {
      setPhone(data.phone);
    } else {
      setFailMsg("본인 인증에 실패하였습니다.");
    }
    dispatch({ type: "authResult", payload: status === 200 ? 1 : 0 });
  };

  // 계정 확인
  const handleCheckAccount = async (e) => {
    e.preventDefault();

    const formData = {
      username: getValues("uid"),
      phone: phone,
    };

    const response = await checkValidAccount(formData);
    const { data, message, status } = response.data;

    if (status === 200) {
      setMemberId(data.memberId);
    } else {
      setFailMsg(message ?? `${getValues("uid")}계정이 없습니다. \n SNS 가입 여부를 확인해 주세요.`);
    }
    dispatch({ type: "accountResult", payload: status === 200 ? 1 : 0 });
  };

  // 새 비밀번호 등록
  const handleNewPwd = async (e) => {
    e.preventDefault();

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = {
      memberId: memberId,
      password: getValues("upwd"),
    };

    const response = await registerNewPwd(formData);
    const { message, status } = response.data;

    if (status !== 200) {
      resetFindPwd();
      setAlert({ text: message ?? "비밀번호 등록에 실패했습니다. \n 다시 시도해 주세요." });
    }
    dispatch({ type: "registerResult", payload: status === 200 ? 1 : 0 });
  };

  return (
    <React.Fragment>
      {state.authOn && state.authResult < 0 && (
        <PassAuth onAuth={findPwdMode && state.authOn} handleToken={setToken} handlePopupClose={handleAuth} handleFail={resetFindPwd} />
      )}
      {(!state.authOn || (state.authOn && state.authResult >= 0)) && (
        <NormalModal
          _size="m3"
          handleModalClose={() => {
            resetFindPwd();
          }}
          overlayClick={false}>
          <D.Container _width="100%" _padding="0 20px" _media="tabletSm" _m_padding="0">
            <D.FlexCols _width="100%" _gap={40}>
              {state.authResult < 1 && (
                <D.FlexCols _width="100%" _gap={40} _items="center">
                  <Text _size="xxl" _weight="bold" _line="xxs">
                    알아봐봐 정보를 잊으셨나요?
                  </Text>
                  <D.FlexRows _justify="space-between" _width="100%">
                    <TabBtn
                      id="find-id"
                      text="아이디 찾기"
                      handleClick={(e) => {
                        setIsFindId(true);
                        resetFindPwd();
                      }}
                    />
                    <TabBtn id="find-password" current text="비밀번호 찾기" />
                  </D.FlexRows>
                  {state.authResult === 0 && <Text>{failMsg}</Text>}
                  {(!state.authOn || state.authResult === 0) && (
                    <RoundBtn
                      _primary
                      onClick={() => {
                        setToken(null);
                        dispatch({ type: "authOn", payload: true });
                        dispatch({ type: "authResult", payload: -1 });
                      }}
                      text="휴대폰 인증"
                    />
                  )}
                </D.FlexCols>
              )}
              {state.authResult === 1 && state.accountResult < 0 && (
                <D.FlexCols as="form" _width="100%" _gap={40} _items="center" onSubmit={handleCheckAccount}>
                  <Text _size="xxl" _weight="bold" _line="xxs">
                    아이디 인증
                  </Text>
                  <D.FlexCols _width="100%" _gap={24} _items="flex-start">
                    <InputField
                      label="아이디(이메일)"
                      name="uid"
                      placeholder="이메일을 입력해주세요."
                      register={register}
                      registerProps={{
                        required: { value: true, message: "아이디(이메일)을 입력해 주세요." },
                        pattern: { value: EMAIL_PATTERN, message: "이메일을 정확히 입력해 주세요." },
                      }}
                      error={errors["uid"]}
                      errorMsg={errors["uid"] ? errors["uid"].message : ""}
                    />
                    <InputField label="휴대폰 번호" name="uphone" value={phone} readOnly />
                  </D.FlexCols>
                  <RoundBtn _primary _capsule type="submit" text="확인" />
                </D.FlexCols>
              )}
              {state.accountResult === 0 && (
                <D.FlexCols _width="100%" _gap={40}>
                  <D.FlexCols _width="100%" _gap={8} _items="center">
                    <Text _size="xxl" _weight="bold" _line="xxs">
                      아이디 인증 결과
                    </Text>
                    <Text>{failMsg}</Text>
                  </D.FlexCols>
                  <RoundBtn
                    _primary
                    onClick={() => {
                      resetFindPwd();
                    }}
                    text="확인"
                  />
                </D.FlexCols>
              )}
              {state.accountResult === 1 && state.registerResult < 0 && (
                <D.FlexCols as="form" _width="100%" _gap={40} _items="center" onSubmit={handleNewPwd}>
                  <Text _size="xxl" _weight="bold" _line="xxs">
                    알아봐봐 비밀번호 재 설정
                  </Text>
                  <D.FlexCols _width="100%" _gap={24} _items="flex-start">
                    {[
                      {
                        label: "새 비밀번호",
                        name: "upwd",
                        placeholder: "새 비밀번호를 입력해 주세요.",
                        type: "password",
                        maxLength: 16,
                        registerProps: {
                          required: { value: true, message: "비밀번호를 입력해 주세요." },
                          pattern: { value: PWD_PATTERN, message: "*영문, 숫자를 조합하여 8~16자리로 입력해 주세요." },
                        },
                      },
                      {
                        label: "비밀번호 확인",
                        name: "upwd02",
                        placeholder: "새 비밀번호를 다시 입력해주세요.",
                        maxLength: 16,
                        type: "password",
                        registerProps: {
                          required: { value: true, message: "비밀번호를 확인해 주세요." },
                          pattern: { value: new RegExp(getValues("upwd")), message: "비밀번호를 확인해 주세요." },
                        },
                      },
                    ].map((item, idx) => (
                      <InputField
                        key={idx}
                        label={item.label}
                        name={item.name}
                        type={item.type}
                        placeholder={item.placeholder}
                        register={register}
                        registerProps={item.registerProps}
                        error={errors[item.name]}
                        errorMsg={errors[item.name] ? errors[item.name].message : ""}
                        maxLength={item.maxLength}
                      />
                    ))}
                  </D.FlexCols>
                  <RoundBtn _primary _capsule type="submit" text="비밀번호 변경" />
                </D.FlexCols>
              )}
              {state.registerResult === 1 && (
                <D.FlexCols as="form" _width="100%" _gap={40} _items="center">
                  <D.FlexCols _width="100%" _gap={8} _items="center">
                    <Text _size="xxl" _weight="bold" _line="xxs">
                      비밀번호 재설정 완료
                    </Text>
                    <Text _align="center">
                      새 비밀번호로 설정 완료되었습니다.
                      <br />
                      로그인 후 이용해 주세요.
                    </Text>
                  </D.FlexCols>
                  <RoundBtn
                    text="로그인"
                    _primary
                    _capsule
                    onClick={() => {
                      resetFindPwd();
                      setLogin({ mode: true });
                    }}
                  />
                </D.FlexCols>
              )}
            </D.FlexCols>
          </D.Container>
        </NormalModal>
      )}
    </React.Fragment>
  );
};

export default FindPwd;
