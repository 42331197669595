import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import leftArrow from "../../../../assets/icons/utils/ico-arrow_left.svg";
import xIcon from "../../../../assets/icons/utils/ico-x.svg";
import { RoundBtn } from "../../../../components/Common/Button";
import { FlexCols } from "../../../../components/Common/Display";
import { Text } from "../../../../components/Common/Text";
import { useMobile } from "../../../../hooks/ui/mediaQuery";

const Overlay = styled.div`
  z-index: 40;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;

  @media (${(props) => props.theme.media.tabletSm}) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }
`;

const Wrapper = styled(FlexCols)`
  z-index: 42;
  position: absolute;
  top: ${(props) => props.top || "50%"};
  left: ${(props) => (props.side ? "auto" : "50%")};
  right: ${(props) => (props.side ? "-10px" : "auto")};
  max-width: 100vw;
  min-width: calc(100% + 10px);
  height: max-content;
  max-height: ${(props) => (props._height ? props._height : "autu")};
  width: ${(props) => props.width ?? "max-content"};
  ${(props) => props.theme.effectStyle.selected};
  background: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  transform: translateY(${(props) => (props.top ? "0" : "-50%")})
    translateX(${(props) => (props.side ? 0 : "-50%")});

  @media (${(props) => props.theme.media.tabletSm}) {
    bottom: 0 !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    border-radius: 20px 20px 0 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 80px);
    transform: translateY(0) translateX(0);
    overflow: hidden;

    ${(props) =>
      props.mobileFloat &&
      css`
        width: calc(100% - 20px);
        max-width: 370px;
        min-width: calc(100% - 20px);
        left: 10px !important;
        top: 50% !important;
        bottom: 0 !important;
        transform: translateY(-50%);
        border-radius: 20px !important;
      `}
  }
`;

const ContentsWrapper = styled(FlexCols)`
  padding: ${(props) => props.padding ?? 0};

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: ${(props) => props.m_padding ?? 0};
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  > button {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @media (${(props) => props.theme.media.tabletSm}) {
      border-radius: 0;
    }
  }
`;

const CloseBtn = styled.button`
  display: none;
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  display: block;
  background: url(${leftArrow}) center no-repeat;

  ${(props) =>
    props.mobileFloat &&
    css`
      @media (${(props) => props.theme.media.tabletSm}) {
        background-image: url(${(props) => (props.mobileFloat ? xIcon : leftArrow)});
        background-size: 15px 15px;
      }
    `}
`;

// component: modal layout
// /space/book/:id 페이지에 노출, SpaceBook/Components의 모든 modal에서 사용
function LayoutModal({
  right,
  w_width,
  _height,
  _top,
  side,
  title = false,
  mobileFloat,
  b_text = "확인",
  padding,
  m_padding,
  onClick,
  isBig = false,
  children,
  closeModal,
}) {
  const modalRef = useRef(null);
  const [isMobile] = useMobile(767);

  useEffect(() => {
    let clickFn;
    let tmFn;
    if (modalRef.current) {
      clickFn = (e) => {
        if (!e.target.closest(".optionWrap")) {
          closeModal();
        }
      };
      tmFn = setTimeout(() => {
        window.addEventListener("click", clickFn);
      }, 150);
    }
    return () => {
      if (clickFn) {
        window.removeEventListener("click", clickFn);
      }
      if (tmFn) {
        clearTimeout(tmFn);
      }
    };
  }, [modalRef]);

  return (
    <>
      {/* overlay 클릭 시 modal 종료 */}
      {isMobile && <Overlay />}
      <Wrapper
        side={side}
        ref={modalRef}
        isBig={isBig}
        width={w_width}
        _height={_height}
        top={_top}
        right={right}
        _items="center"
        className="optionWrap"
        mobileFloat={mobileFloat}
      >
        {/* modal 종료 버튼 */}
        <CloseBtn mobileFloat={mobileFloat} onClick={closeModal} />
        <ContentsWrapper _gap={40} _width="100%" padding={padding} m_padding={m_padding}>
          {title && (
            <Text _weight="medium" _size="xxxl" _color="gray900">
              {title}
            </Text>
          )}
          {children}
        </ContentsWrapper>
        <ButtonDiv>
          <RoundBtn
            text={b_text}
            onClick={() => {
              !!onClick && onClick();
              closeModal();
            }}
            _primary={true}
            _height={"56px"}
            _capsule={false}
            disabled={false}
          />
        </ButtonDiv>
      </Wrapper>
    </>
  );
}

export default LayoutModal;
