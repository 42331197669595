import React from 'react';
import styled from 'styled-components';
import * as D from '../../Common/Display';
import { Text } from '../../Common/Text';
import { Badge, ColorBadge } from '../../Common/Badge';
import { Link } from 'react-router-dom';
import { cdnUrl } from '../../../constants/url';
import { myBookingDetails } from '../../../constants/site';
import { bookColor } from '../../../constants/color';
import rightIcon from '../../../assets/icons/utils/ico-arrow_right_next.svg';

const S = {
  Card: styled.article`
    position: relative;
    /* width: 300px; */
    /* height: 367px; */
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    > a {
      display: block;
      width: 100%;
      height: 100%;
    }
  `,
  Thumbnail: styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 65%;
    background-color: ${(props) => props.theme.colors.gray50};
    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      > img {
      }
    }
  `,
  Contents: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 24px 20px 27px;
  `,
  Title: styled.h3`
    width: 100%;
    height: 26px;
    font-size: ${(props) => props.theme.fontSize.xl};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  Local: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    height: 20px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  BadgeWrap: styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
  `,
};

/**
 * 마이페이지 > 예약 카드 컴포넌트
 * @param {object} props.data 에약 데이터
 */
export default function BookingCard({ data }) {
  return (
    <S.Card>
      <Link to={myBookingDetails(data?.reservationId)} relative={false} state={data}>
        <S.Thumbnail>
          <div>
            <img src={cdnUrl(data.thumbnail, 'h=300&q=20')} />
          </div>
        </S.Thumbnail>
        <S.BadgeWrap>
          <ColorBadge _color={bookColor[data.status.replace(/ /g, '')]} text={data?.status} />
        </S.BadgeWrap>
        <S.Contents>
          <D.FlexCols _gap={8}>
            <S.Title>{data?.hotelName}</S.Title>
            <S.Local>
              <Badge _color="pink600" text="세미나" />
              {data.spaceName}
            </S.Local>
            <Text _size="s" _weight="medium" _color="gray800" _line="xxs">
              {data?.startDate} ~ {data.endDate}
            </Text>
          </D.FlexCols>
          <D.FlexRows as="span" _width="100%" _items="center" _justify="space-between">
            <D.FlexRows _gap={4} _item="center">
              <Text _size="xl" _weight="medium">
                {data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
              </Text>
              <Text _size="xs" _weight="medium" _color="gray500">
                / 시간
              </Text>
            </D.FlexRows>
            <D.FlexRows _items="center" _justify="center">
              <Text _size="xs" _weight="medium" _color="gray800" _line="xxs">
                예약상세
              </Text>
              <img src={rightIcon} />
            </D.FlexRows>
          </D.FlexRows>
        </S.Contents>
      </Link>
    </S.Card>
  );
}
