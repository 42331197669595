import React from 'react';
import styled, { css } from 'styled-components';
import * as D from '../Display';
import { Text } from '../Text';
import checkIcon from '../../../assets/icons/utils/ico-checked_white.svg';

export const S = {
  Input: styled.input`
    width: 100%;
    height: 48px;
    padding: 14px;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 10px;
    font-size: ${(props) => props.theme.fontSize.xs};
    background-color: ${(props) => props.theme.colors[props.readOnly ? 'gray100' : 'white']};
    &::placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }

    &:focus:not(:read-only) {
      border-color: ${(props) => props.theme.colors.purple1};
    }

    ${(props) =>
      props.error &&
      css`
        border-color: ${(props) => props.theme.colors.error};

        &:focus:not(:read-only) {
          border-color: ${(props) => props.theme.colors.error};
        }
      `}

    ${(props) =>
      props.as === 'textarea' &&
      css`
        border-radius: 4px;
        height: ${(props) => props._height ?? '120px'};
        resize: none;
      `}
  `,
  Radio: styled.div`
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    label {
      position: relative;
      padding-left: 26px;
      font-size: ${(props) => props.theme.fontSize.s};
      min-height: 16px;
      line-height: ${(props) => props.theme.lineHeight.xs};

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 10px;
        border: 1px solid ${(props) => props.theme.colors.gray300};
      }
    }
    input:checked + label:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: ${(props) => props.theme.colors.purple1};
    }
  `,
  CheckBox: styled.div`
    position: relative;
    display: flex;
    gap: 4px;
    width: 100%;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    label {
      position: relative;
      padding-left: 22px;
      font-size: ${(props) => props.theme.fontSize.s};
      min-height: 16px;
      line-height: ${(props) => props.theme.lineHeight.xs};
      cursor: pointer;

      &.check-label {
        width: 16px;
        padding-left: 16px;
      }

      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border: 1px solid ${(props) => props.theme.colors.gray300};
        ${(props) =>
          props.type === 'dot' &&
          css`
            border-radius: 10px;
          `}
        ${(props) =>
          props.type === 'square' &&
          css`
            border-radius: 2px;
          `}
      }
    }
    input:checked + label:after {
      content: '';
      position: absolute;
      ${(props) =>
        props.type === 'dot' &&
        css`
          top: 4px;
          left: 4px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: ${(props) => props.theme.colors.purple1};
        `}

      ${(props) =>
        props.type === 'square' &&
        css`
          top: 0;
          left: 0;
          width: 16px;
          height: 16px;
          border: 1px solid ${(props) => props.theme.colors.purple700};
          background: url(${checkIcon}) center no-repeat ${(props) => props.theme.colors.mainPurple};
          border-radius: 2px;
        `}
    }
    .text-label {
      width: calc(100% - 20px);
      line-height: 18px;
    }
  `,
};

/**
 * 텍스트 필드 컴포넌트
 */
export const InputField = React.forwardRef(
  (
    {
      label,
      name,
      id,
      placeholder,
      value,
      type,
      readOnly,
      error,
      errorMsg,
      guide,
      _width,
      _height,
      textarea = false,
      handleChange,
      register = null,
      registerProps,
      maxLength,
      autoComplete,
      defaultValue,
    },
    ref
  ) => {
    if (!name) {
      return;
    }

    return (
      <D.FlexCols _width={_width ?? '100%'} _gap={8}>
        {label && (
          <Text as="label" _size="s" _weight="medium" htmlFor={id ?? name}>
            {label}
          </Text>
        )}
        <S.Input
          // defaultValue={defaultValue ?? null} // [2023.07.31] 수정
          as={textarea ? 'textarea' : 'input'}
          name={name}
          id={id ?? name}
          readOnly={readOnly}
          type={type ?? 'text'}
          error={error}
          placeholder={placeholder ?? ''}
          _height={_height}
          {...(maxLength && { maxLength: maxLength })}
          ref={ref}
          autoComplete={autoComplete ?? 'off'}
          {...(register
            ? { ...register(name, registerProps) }
            : {
                onChange: (e) => {
                  handleChange(e.target.value);
                },
                value: value ?? '',
              })}
        />
        {((errorMsg && errorMsg.length > 0) || (guide && guide.length > 0)) && (
          <Text _size="xxs" _color={error ? 'error' : 'purple1'}>
            {errorMsg || guide}
          </Text>
        )}
      </D.FlexCols>
    );
  }
);

/**
 * 라디오 그룹 컴포넌트
 */
export function RadiosField({ name, value, options, handleChange }) {
  const handleRadioClick = (e) => {
    const v = e.currentTarget.value;
    handleChange(v === value ? '' : v);
  };

  if (!options || !name) {
    return;
  }
  return (
    <D.FlexCols _gap={12} _width="100%">
      {options.map((option, idx) => (
        <S.Radio key={idx}>
          <input
            type="radio"
            name={name}
            id={name + ('_' + idx)}
            value={option.value}
            checked={option.value === value}
            onChange={handleRadioClick}
          />
          <label htmlFor={name + ('_' + idx)}>{option.text}</label>
        </S.Radio>
      ))}
    </D.FlexCols>
  );
}

export const CheckBox = React.forwardRef(
  ({ name, label, labelAs = 'label', value, checked, type, handleChange, register, registerProps }, ref) => {
    if (!value || !name) {
      return;
    }

    return (
      <S.CheckBox type={type || 'dot'}>
        <input
          type="checkbox"
          name={name}
          value={value}
          id={name}
          ref={ref}
          {...(register
            ? { ...register(name, registerProps) }
            : {
                onChange: (e) => {
                  handleChange(e.target.value);
                },
                checked: checked ?? false,
              })}
        />
        {labelAs === 'label' ? (
          <label htmlFor={name}>{label}</label>
        ) : (
          <React.Fragment>
            <label className="check-label" htmlFor={name} />
            <span className="text-label">{label}</span>
          </React.Fragment>
        )}
      </S.CheckBox>
    );
  }
);
