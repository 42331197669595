import { client } from '.';

const SEARCH_URLS = {
  location: '/api/search',
  date: '',
  conditions: '',
};

// 지역 찾기 요청
export const getSearchLocation = (word) =>
  client.post(SEARCH_URLS.location, { query: word });
