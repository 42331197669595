import React, { useState } from 'react';
import * as D from '../Common/Display';
import { Text } from '../Common/Text';
import ClientBox from '../Home/ClientBox';
import styled from 'styled-components';
import { useNearQuery } from '../../hooks/queries/space';

// Import Swiper styles
import 'swiper/css';
import { isMobile } from 'react-device-detect';

const S = {
  Wrapper: styled.section`
    width: 100%;
    /* transform: translate(-50px); */
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media (${(props) => props.theme.media.laptop}) {
      width: 100%;
      transform: translate(0);
    }
    @media (${(props) => props.theme.media.tabletSm}) {
      gap: 16px;
      padding-left: 20px;
    }
  `,
  ListWrapper: styled.div`
    width: 100%;

    @media (${(props) => props.theme.media.tabletM}) {
      width: calc(100% + 20px);
      overflow-x: auto;
      ${D.xScrollBoxCss(6, 'gray300')}
      ${isMobile && D.NonScrollBarCss}
      padding-bottom: 6px;
    }
  `,
  List: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;

    > a {
      flex: 1 !important;
      max-width: 380px;

      > img {
        width: 100%;
      }
    }

    @media (${(props) => props.theme.media.tabletM}) {
      width: max-content;
      ::after {
        content: '';
        width: 20px;
        height: 100%;
      }
      > a {
        width: 300px !important;
      }
    }
  `,
};

/**
 * 근처 추천 공간 컴포넌트
 * @param {object} props.info 공간 정보 
 */
export default function SpaceNearList({ info }) {
  const { isLoading, error, data } = useNearQuery({ id: info.id, lat: info.lat, lng: info.lng });

  return isLoading ? (
    ''
  ) : (
    <S.Wrapper>
      <Text _align="center" _weight="medium" _size="xxxl" _media="tabletSm" _m_size="xl" _m_align="left">
        근처 추천 공간
      </Text>
      <S.ListWrapper>
        <S.List>
          {data.map((item, idx) => (
            <ClientBox type="space" key={idx} {...item} price={item.priceTime} priceTime={1} _img_height={220} />
          ))}
        </S.List>
      </S.ListWrapper>
    </S.Wrapper>
  );
}
