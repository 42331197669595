import { atom, selector } from 'recoil';
import { STORE_KEYS } from '.';

export const footerUi = atom({
  key: STORE_KEYS.footerUi,
  default: {
    display: true,
  },
});

export const headerUi = atom({
  key: STORE_KEYS.headerUi,
  default: {
    mainDisplay: true,
    mobileSearchBar: false,
    subDisplay: false,
    subTitle: '',
  },
});

export const subHeaderUi = selector({
  key: STORE_KEYS.subHeaderUi,
  get: ({ get }) => (get(headerUi).subDisplay ? get(headerUi).subTitle : ''),
  set: ({ set }, newValue) =>
    set(headerUi, {
      mainDisplay: newValue.length <= 0,
      subDisplay: newValue.length > 0,
      subTitle: newValue,
    }),
});

export const floatingChatUi = atom({
  key: STORE_KEYS.floatingChatUi,
  default: {
    id: null,
    managerId: null,
    uiBack: false,
    uiTop: false,
  },
});
