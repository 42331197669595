import styled, { css } from "styled-components";
import { FlexRows } from "../Display";

export const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize[props._size] ?? props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontWeight[props._weight] ?? props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors[props._color] ?? props.theme.colors.gray900};
  line-height: ${(props) => props.theme.lineHeight[props._line] ?? props.theme.lineHeight.base};
  text-decoration: ${(props) => props._decoration ?? "none"};
  text-align: ${(props) => props._align ?? "inherit"};
  white-space: ${(props) => props._space ?? "inherit"};
  word-break: ${(props) => props._break ?? "normal"};

  ${(props) =>
    props._media &&
    css`
      @media (${(props) => props.theme.media[props._media]}) {
        ${props._m_size &&
        `font-size: ${props.theme.fontSize[props._m_size] ?? props.theme.fontSize.s + "px"};`}
        ${props._m_weight &&
        `font-weight: ${props.theme.fontWeight[props._m_weight] ?? props.theme.fontSize.normal};`}
        ${props._m_align && `text-align: ${props._m_align};`}
      }
    `}
`;

export const LinelimitText = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: ${(props) => props._width || "100%"};
  max-height: ${(props) => props._height}px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${(props) => props.line};
`;

/**
 * 가격/비용 컴포넌트
 */
export function CostText({ cost, per, _c_size, _c_weight, _c_color, _p_size, _p_color, _gap }) {
  return (
    <FlexRows _items="center" _gap={8}>
      <Text
        _size={_c_size ?? "xl"}
        _weight={_c_weight ?? "medium"}
        _color={_c_color ?? "gray900"}
        _line="xxs"
      >
        {cost}원
      </Text>
      <Text _size={_p_size ?? "s"} _color={_p_color ?? "gray500"} _line="xxs">{`/ ${per}`}</Text>
    </FlexRows>
  );
}

export const Dot = styled.span`
  display: inline-block;
  border-radius: 999px;
  width: ${(props) => props._size ?? "2px"};
  height: ${(props) => props._size ?? "2px"};
  background-color: ${(props) => props.theme.colors[props._color] ?? props.theme.colors.gray900};
`;

export const DotedText = styled(Text)`
  position: relative;
  padding-left: 8px;
  &::before {
    content: none;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 0;
    width: 2px;
    height: 2px;
    background-color: ${(props) => props.theme.colors[props._color] ?? props.theme.colors.gray900};
  }
`;
