import { atom } from 'recoil';
import { SEARCH_KEYS } from '.';

export const searchState = atom({
  key: SEARCH_KEYS.searchTerm,
  default: '',
});

// header에 입력할 일정을 나타내는 state
export const periodState = atom({
  key: SEARCH_KEYS.period,
  default: null,
});

// 예약페이지, 의뢰페이지에서 일정을 정했을 때 사용할 state
export const bookPeriodState = atom({
  key: SEARCH_KEYS.bookPeriod,
  default: [],
});

// header에서 일정을 선택했을 때state
export const searchPeriodState = atom({
  key: SEARCH_KEYS.searchPeriod,
  default: null,
});

export const searchDateState = atom({
  key: SEARCH_KEYS.searchDate,
  default: null,
});

export const bookDateState = atom({
  key: SEARCH_KEYS.bookDate,
  default: null,
});

export const conditionsState = atom({
  key: SEARCH_KEYS.conditions,
  default: null,
});
