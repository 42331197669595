import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as D from '../../Common/Display';
import backIcon from '../../../assets/icons/utils/ico-arrow_left.svg';
import { BackBtn } from '../../Common/Button';
import moment from 'moment';

const S = {
  Top: styled.div`
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 16px;
    width: 100%;
    padding: 24px 20px;
    background-color: ${(props) => props.theme.colors.gray100};

    strong {
      font-weight: ${(props) => props.theme.fontWeight.bolder};
    }
    span {
      color: ${(props) => props.theme.colors.gray600};
      font-size: ${(props) => props.theme.fontSize.s};
      text-align: right;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      padding: 20px;
      span {
        font-size: ${(props) => props.theme.fontSize.xs};
      }
    }
  `,
  Contents: styled.div`
    width: 100%;
    padding: 24px 20px 80px;
    color: ${(props) => props.theme.colors.gray800};
    white-space: pre-wrap;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
    @media (${(props) => props.theme.media.tabletSm}) {
      padding-bottom: 60px;
    }
  `,
  Back: styled.div`
    display: flex;
    justify-content: flex-end;

    @media (${(props) => props.theme.media.tabletSm}) {
      margin-right: 20px;
    }
  `,
};

/**
 * 공지사항 상세 컴포넌트
 */
export default function Details() {

  const data = useLocation().state;

  return (
    <D.Container _mx_width="1120px" _padding="80px 20px 360px" _media="tabletSm" _m_padding="40px 0 200px">
      <D.FlexCols _width="100%" _items="flex-center" _gap={24}>
        <D.FlexCols _width="100%">
          <S.Top>
            <strong>{data.title}</strong>
            <span>{moment(data.noticeCreateDate).format('YYYY-MM-DD')}</span>
          </S.Top>
          <S.Contents>{data.contents}</S.Contents>
        </D.FlexCols>
        <S.Back>
          <BackBtn text="목록으로" />
        </S.Back>
      </D.FlexCols>
    </D.Container>
  );
}
