import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { waitLoading } from ".";
import {
  requestHotelList,
  requestReviewList,
  requestReviewReport,
  requestHotelDetails,
  requestNear,
  requestAddBookmark,
  requestDelBookmark,
  getMapList,
  requestSpaceList,
  getSpaceDetails,
} from "../../service/client/space";

export const SPACE_KEYS = {
  all: ["space"],
  list: () => [...SPACE_KEYS.all, "list"],
  spaceList: (filter) => [...SPACE_KEYS.all, "spaceList", filter],
  details: (id) => [...SPACE_KEYS.all, "details", id],
  suggest: (id) => [...SPACE_KEYS.all, "suggest", id],
  hotelList: ["hotelList"],
  hotelDetails: ["hotelDetails"],
  near: ["near"],
  reviewList: (sort, id) => [...SPACE_KEYS.all, "review_list", sort, id],
  mapList: (filter) => [...SPACE_KEYS.all, "map_list", filter],
  spaceDetails: (id) => ["spaceDetails", id],
};

// 호텔 목록 요청 쿼리
export const useHotelListQuery = (params) => {
  return useQuery(SPACE_KEYS.hotelList, async () => {
    const { data } = await requestHotelList(params);

    return data.data;
  });
};

// 공간 목록 요청 쿼리
export const useInfiniteSpaceListquery = (params) =>
  useInfiniteQuery({
    queryKey: SPACE_KEYS.spaceList(params),
    queryFn: async ({ pageParam = 1 }) => {
      await waitLoading();
      return await requestSpaceList({ ...params, page: pageParam });
    },
    getNextPageParam: (lastPage, allPages) => {
      const hasNext = allPages.length * 12 < lastPage.data.data.count; // 받는 갯수 기준으로 수정 필요
      return hasNext ? allPages.length + 1 : undefined;
    },
    onError: (err) => {
      console.log(err);
    },
  });

// 공간 목록 - paging
export const useInfiniteHotelListQuery = (params) =>
  useInfiniteQuery({
    queryKey: SPACE_KEYS.hotelList,
    queryFn: async ({ pageParam = 1 }) => {
      return await requestHotelList({ ...params, page: pageParam });
    },
    getNextPageParam: (lastPage, allPages) => {
      const hasNext = allPages.length * 2 < lastPage.data.data.count;
      return hasNext ? allPages.length + 1 : undefined;
    },
  });

// 리뷰 목록 - paging
export const useInfiniteReviewListQuery = (sort, id) =>
  useInfiniteQuery({
    queryKey: SPACE_KEYS.reviewList(sort, id),
    queryFn: async ({ pageParam = 1 }) => {
      return await requestReviewList({
        id: id,
        sort: sort,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const hasNext = allPages?.length * 4 < lastPage?.data?.data?.count;
      return hasNext ? allPages.length + 1 : undefined;
    },
  });

// 호텔 상세 쿼리
export const useHotelDetailsQuery = (params) => {
  return useQuery(SPACE_KEYS.hotelDetails, async () => {
    const { data } = await requestHotelDetails(params);
    await waitLoading();

    return data.data;
  });
};

// 근처 추천 공간 쿼리
export const useNearQuery = (params) => {
  return useQuery(SPACE_KEYS.near, async () => {
    const { data } = await requestNear(params);

    return data.data;
  });
};
// 리뷰 신고하기
export const useReviewReportQuery = () =>
  useMutation({
    mutationFn: requestReviewReport,
  });

// 좋아요(북마크)
export const useSpaceBookmarkQuery = () =>
  useMutation({
    mutationFn: async (params) => {
      if (params.like)
        return await requestAddBookmark({ type: params.type, targetId: params.id }, params.token);
      else return requestDelBookmark({ type: params.type, targetId: params.id }, params.token);
    },
    onSuccess: (d, v, c) => {
      console.log("wish result: ", d, v, c);
      if (v.callback) v.callback();
    },
    onError: (e) => {
      console.log(e);
    },
  });

// 지도 목록 쿼리
export const useMapListQuery = (filter) =>
  useQuery({
    queryKey: SPACE_KEYS.mapList(filter),
    queryFn: async ({ params = filter }) => {
      const res = await getMapList(params);
      return {
        isGroup: params.zoom < 13,
        nextZoom: params.zoom < 10 ? 11 : params.zoom + 1,
        data: res.data.data,
      };
    },
    enabled: false,
  });

// 공간 상세 정보 조회
export const useSpaceDetailsQuery = (id) => {
  const { data, error, isFetching, isSuccess } = useQuery(SPACE_KEYS.spaceDetails(id), async () => {
    const { data } = await getSpaceDetails(id);
    await waitLoading();
    return data.data;
  });
  return { data, error, isLoading: isFetching, isSuccess };
};
