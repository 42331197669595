import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import chatIcon from "../../../assets/icons/menu/ico-menu-chat_floating.svg";
import { chatRoom } from "../../../constants/site";
import { requestNewChatRoom } from "../../../service/client/chat";
import { onModalHiddenState, onModalState } from "../../../store/book";
import { customAlert, loginModalState } from "../../../store/modal";
import { useUser } from "../../../store/user";
import { Text } from "../Text";

const S = {
  Box: styled.div`
    position: sticky;
    left: calc(100% - 120px);
    bottom: 0;
    z-index: ${(props) => (props.back ? 19 : 21)};
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    width: min-content;
    height: 186px;

    @media (max-width: 1440px) {
      left: calc(100% - 80px);
      height: 146px;
    }
    @media (max-width: 800px) {
      ${(props) => props.top && "bottom: 5%;"}
    }

    @media (${(props) => props.theme.media.tablet}) {
      left: calc(100% - 60px);
      height: 106px;
    }
    @media (${(props) => props.theme.media.tabletSm}) {
      position: fixed;
      ${(props) => props.top && "bottom: 5%;"}
    }
  `,
  Button: styled.button`
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: url(${chatIcon}) center no-repeat ${(props) => props.theme.colors.mainPurple};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

    @media (${(props) => props.theme.media.tablet}) {
      width: 50px;
      height: 50px;
      background-size: 22px auto;
    }
  `,
};
// onModal < 2

/**
 * 플로팅 버튼 컴포넌트 
 */
export default function FloatingChat({ id, uiBack, uiTop }) {
  const { pathname } = useLocation();
  const isBookPage = pathname.startsWith("/space/book/");
  const bookOnModal = useRecoilValue(onModalState);
  const isBookModalHidden = useRecoilValue(onModalHiddenState);

  const { loggedIn } = useUser();
  const setLogin = useSetRecoilState(loginModalState);
  const setAlert = useSetRecoilState(customAlert);
  // u
  const bRef = useRef(null);

  const handleChatClick = async (e) => {
    e.preventDefault();

    if (loggedIn) {
      // 문의 채팅방 - 관리자와 채팅 (실시간 조회)
      // popup일 경우 -> 현재 페이지에서 popup parameter  전달
      const res = await requestNewChatRoom(id);
      const w = window.open(`${chatRoom(res.data.data.room)}?s=0`, "_blank");
      if (!w) {
        setAlert({
          text: "팝업창이 차돤되어 있습니다. \n 팝업창 허용 후 이용해 주세요.",
        });
      }
    } else {
      setAlert({
        text: "로그인 후 이용해 주세요.",
        handleClose: () => {
          setLogin({ mode: true });
        },
      });
    }
  };

  return (
    <S.Box isBookModalHidden={isBookModalHidden} isBookPage={isBookPage} bookOnModal={bookOnModal} back={uiBack} top={uiTop} ref={bRef}>
      <S.Button onClick={handleChatClick} />
      <Text _size="s" _weight="medium" _media="tabletM" _m_size="xxs">
        채팅 문의
      </Text>
    </S.Box>
  );
}
