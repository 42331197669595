import styled from "styled-components";

export const Line = styled.hr`
    display: block;
    width: 100%;
    height: ${props => props._height ?? 1}px;
    line-height: 0;
    border: 0;
    background-color: ${props => props.theme.colors[props._color ?? 'gray900']};
    margin-top: ${props => props._m_top ?? 0}px;
    margin-bottom: ${props => props._m_btm ?? 0}px;
`;