import React, { useLayoutEffect, useReducer, useState } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { NormalModal } from "../../../layouts/Modal";
import { checkNewPhone, getAuthCheck, getUserId } from "../../../service/client/user";
import { findIdModalState, findPwModalState, joinModalState, loginModalState } from "../../../store/modal";
import { RoundBtn, TabBtn } from "../../Common/Button";
import * as D from "../../Common/Display";
import { Text } from "../../Common/Text";
import PassAuth from "../Auth/PassAuth";

const S = {
  IdBox: styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.gray100};
    font-size: ${(props) => props.theme.fontSize.xs};
  `,
};
const findIdInitialState = {
  authOn: false,
  authResult: -1, // 0: 성공, 1: 실패
  findResult: -1, // 0: 성공, 1: 실패
};
const findIdReducer = (state, action) => {
  switch (action.type) {
    case "authOn":
      return { ...state, authOn: action.payload };
    case "authResult":
      return { ...state, authResult: action.payload };
    case "findResult":
      return { ...state, findResult: action.payload };
    case "initialize":
      return findIdInitialState;
    default:
      throw new Error();
  }
};

/**
 * 아이디 찾기 컴포넌트
 */
const FindId = () => {
  const resetFindId = useResetRecoilState(findIdModalState);
  const findIdMode = useRecoilState(findIdModalState);
  const setFindPwd = useSetRecoilState(findPwModalState);
  const setLogin = useSetRecoilState(loginModalState);
  const setJoin = useSetRecoilState(joinModalState);
  const [state, dispatch] = useReducer(findIdReducer, findIdInitialState);
  const [email, setEmail] = useState("hello");
  const [token, setToken] = useState(null);
  const [failMsg, setFailMsg] = useState("");

  // 본인인증
  const handleAuth = async () => {
    if (!token) {
      resetFindId();
    }
    const response = await getAuthCheck(token);
    const { status, message, data } = response.data;

    if (status === 200) {
      handleGetId(data.phone);
    } else {
      setFailMsg("본인 인증에 실패하였습니다.");
    }

    dispatch({ type: "authResult", payload: status === 200 ? 1 : 0 });
  };

  // 이용유저인지 확인
  const checkRegisterdUser = async (phone) => {
    const response = await checkNewPhone(phone);
    return response && response.data.status === 400;
  };

  // 아이디 가져오기
  const handleGetId = async (phone) => {
    const isRegisteredUser = await checkRegisterdUser(phone);

    if (!isRegisteredUser) {
      dispatch({ type: "findResult", payload: 0 });
      setFailMsg("가입 정보가 없습니다.");
      return;
    } else {
      const response = await getUserId(phone);
      const { data, message, status } = response.data;

      if (status === 200) {
        setEmail(data.username);
      } else {
        setFailMsg(message ?? "가입 정보가 없습니다.");
      }
      dispatch({ type: "findResult", payload: status === 200 ? 1 : 0 });
    }
  };

  useLayoutEffect(() => {
    setToken(null);
    dispatch({ type: "initialize" });
  }, []);

  return (
    <React.Fragment>
      {state.authOn && state.authResult < 0 && (
        <PassAuth
          onAuth={state.authOn && state.authResult < 0 && findIdMode}
          handleToken={setToken}
          handlePopupClose={handleAuth}
          handleFail={resetFindId}
        />
      )}
      {(!state.authOn || (state.authOn && state.authResult >= 0)) && (
        <NormalModal
          _size="m3"
          handleModalClose={() => {
            resetFindId();
          }}
          overlayClick={false}>
          <D.Container _width="100%" _padding="0 20px" _media="tabletSm" _m_padding="0">
            {state.findResult >= 0 && ( // 조회 결과
              <D.FlexCols _width="100%" _gap={40} _items="center">
                <D.FlexCols _width="100%" _gap={8} _items="center" _justify="center">
                  <Text _size="xxl" _weight="bold" _line="xxs">
                    아이디 찾기 결과
                  </Text>
                  <Text _size="xs">
                    {state.findResult > 0 &&
                      (state.findResult == 1 ? "아이디 확인 후 재 로그인하여 서비스를 이용해 주세요." : failMsg ?? "가입 정보가 없습니다.")}
                  </Text>
                </D.FlexCols>
                {state.findResult == 1 && <S.IdBox>{email}</S.IdBox>}
                <RoundBtn
                  _capsule
                  _primary
                  text={state.findResult == 1 ? "로그인" : "회원가입"}
                  onClick={() => {
                    resetFindId();
                    if (state.findResult == 1) {
                      setLogin({ mode: true, redirect: null });
                    } else {
                      setJoin({ mode: true });
                    }
                  }}
                />
              </D.FlexCols>
            )}
            {state.authResult <= 0 && ( // 인증전 || 인증 실패
              <D.FlexCols _width="100%" _gap={40} _items="center">
                <Text _size="xxl" _weight="bold" _line="xxs">
                  알아봐봐 정보를 잊으셨나요?
                </Text>
                <D.FlexRows _justify="space-between" _width="100%">
                  <TabBtn current text="아이디 찾기" />
                  <TabBtn
                    text="비밀번호 찾기"
                    handleClick={() => {
                      setFindPwd(true);
                      resetFindId();
                    }}
                  />
                </D.FlexRows>
                {state.authResult === 0 && <Text>{failMsg || "본인인증에 실패하였습니다. 다시 시도해 주세요."}</Text>}
                <RoundBtn
                  _primary
                  text="휴대폰 인증"
                  onClick={() => {
                    setToken(null);
                    dispatch({ type: "authResult", payload: -1 });
                    dispatch({ type: "authOn", payload: true });
                  }}
                />
              </D.FlexCols>
            )}
          </D.Container>
        </NormalModal>
      )}
    </React.Fragment>
  );
};

export default FindId;
