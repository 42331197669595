import styled, { css } from "styled-components";

export const Container = styled.div`
  display: block;
  width: ${(props) => props._width ?? "100%"};
  max-width: ${(props) => props._mx_width ?? "100%"};
  height: ${(props) => props._height ?? "auto"};
  min-height: ${(props) => props._mn_height ?? "auto"};
  margin: 0 auto;
  padding: ${(props) => props._padding ?? "0"};
  ${(props) => props.yscroll && yScrollBoxCss()};

  ${(props) =>
    props._media &&
    css`
      @media (${props.theme.media[props._media]}) {
        ${props._m_mx_width && `max-width: ${props._m_mx_width};`}
        ${props._m_mn_height && `height: ${props._m_mn_height};`}
        ${props._m_padding && `padding: ${props._m_padding};`}
      }
    `}
`;

export const Flex = styled.div`
  display: flex;
  gap: ${(props) => props._gap ?? 0}px;
  justify-content: ${(props) => props._justify ?? "inherit"};
  align-items: ${(props) => props._items ?? "inherit"};
  flex-wrap: ${(props) => props._wrap ?? "wrap"};
  width: ${(props) => props._width ?? "auto"};
  height: ${(props) => props._height ?? "auto"};
  ${(props) => props.yscroll && yScrollBoxCss()};

  ${(props) =>
    props._direction &&
    css`
      flex-direction: ${props._direction};
    `}

  ${(props) =>
    props._media &&
    css`
      @media (${props.theme.media[props._media]}) {
        ${props._m_direction && `flex-direction: ${props._m_direction};`}
        ${props._m_gap && `gap: ${props._m_gap}px;`}
        ${props._m_justify && `justify-content: ${props._m_justify};`}
        ${props._m_items && `align-items: ${props._m_items};`}
        ${props._m_wrap && `flex-wrap: ${props._m_wrap};`}
        ${props._m_width && `width: ${props._m_width};`}
        ${props._m_height && `height: ${props._m_height};`}
      }
    `}
`;

export const FlexRows = styled(Flex)`
  flex-direction: row;
`;

export const FlexCols = styled(Flex)`
  flex-direction: column;
`;

export const yScrollBoxCss = (width = 4, color) => css`
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: ${width}px;
  }
  &::-webkit-scrollbar-track {
    width: ${width}px;
    border-radius: ${width / 2}px;
    border: 0;
    background-color: rgba(238, 238, 238, 0.6);
  }
  &::-webkit-scrollbar-thumb {
    width: ${width}px;
    border-radius: ${width / 2}px;
    background-color: ${(props) => (color ? props.theme.colors[color] : "#8757ad")};
  }

  > * {
    height: auto;
  }
`;

export const xScrollBoxCss = (height = 4, color) => css`
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1.5px;

  &::-webkit-scrollbar {
    height: ${height}px;
  }
  &::-webkit-scrollbar-track {
    height: ${height}px;
    border-radius: ${height / 2}px;
    background-color: rgba(238, 238, 238, 0.6);
  }
  &::-webkit-scrollbar-thumb {
    height: ${height}px;
    border-radius: ${height / 2}px;
    background-color: ${(props) => (color ? props.theme.colors[color] : "#8757ad")};
  }

  > * {
    width: max-content;
  }
`;

export const YScrollBox = styled.div`
  height: ${(props) => props._height ?? "100%"};
  ${yScrollBoxCss()};
`;

export const XScrollBox = styled.div`
  width: ${(props) => props._width ?? "100%"};
  ${xScrollBoxCss()}
`;

export const NonScrollBarCss = css`
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    display: none;
    background-color: transparent;
  }
`;
