import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DatesOption from './options/DatesOption';
import KeywordOption from './options/KeywordOption';
import CheckOption from './options/CheckOption';
import { getDuration, useSearchSpace } from '../../hooks/utils/filter';
import { useMobile } from '../../hooks/ui/mediaQuery';

const S = {
  Wrapper: styled.div`
    position: relative;
    padding: 8px;
    min-width: 376px;
    width: max-content;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: ${(props) => props.theme.fontSize.s};
    color: ${(props) => props.theme.colors.gray500};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    ${(props) => props.theme.effectStyle.gnbShadow};
    border-radius: 30303px;

    .wrap {
      display: flex;
      flex: 1;
      align-items: center;
    }

    .search-button {
      background-color: ${(props) => props.theme.colors.mainPurple};
      color: ${(props) => props.theme.colors.white};
      font-weight: ${(props) => props.theme.fontWeight.bold};
      padding: 4px 16px;
      border-radius: 99px;
      white-space: nowrap;
    }

    @media (${({ theme }) => theme.media.tabletM}) {
      position: absolute;
      left: 0;
      bottom: 20px;
      width: 100%;
    }

    @media (max-width: 540px) {
      min-width: fit-content;
    }
  `,
  Btn: styled.button`
    position: relative;
    flex: 1;
    text-align: center;
    padding: 0 24px;
    font-weight: ${(props) => props.theme.fontWeight.medium};
    /* color: ${(props) =>
      props.isState
        ? props.theme.colors.gray900
        : props.theme.colors.gray500}; */

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 20px;
      background-color: ${({ theme }) => theme.colors.gray200};
    }
    > span {
      white-space: nowrap;
      color: ${(props) =>
        props.isState
          ? props.theme.colors.gray900
          : props.theme.colors.gray500};
    }

    @media (max-width: 540px) {
      padding: 0 10px;
    }
  `,
  ModalOverlay: styled.div`
    z-index: 90;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 64px;
    left: 50%;
    transform: translateX(-50%);
  `,
  ModalBox: styled.div`
    z-index: 91;
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translate(-50%);
  `,
};

/**
 * 공통 검색 컴포넌트
 * @description 사이트 최상단에 위치한 검색 컴포넌트입니다.
 */
export default function SpaceFilter() {
  const [filterMode, setFilterMode] = useState([false, false, false]);
  const location = useLocation();
  const { searchByDetailsFilter, detailsFilter } = useSearchSpace();
  const [selected, setSelected] = useState({
    type: '',
    keyword: '',
    dates: [],
    details: [],
  });
  const [isMobile, isResizing] = useMobile(540);

  const switchMode = (fIdx) => {
    setFilterMode((m) => m.map((item, idx) => (idx === fIdx ? !item : false)));
  };

  const handleKeywordSubmit = (value) => {
    setSelected((v) => ({
      ...v,
      type: value.type,
      keyword: value.name,
    }));
    switchMode(selected.dates.length > 0 ? 0 : 1);
  };

  const handleDatesSubmit = (value) => {
    setSelected((v) => ({
      ...v,
      dates: value,
      details: getDuration(value).map((d) => ({
        date: d,
        checkIn: 0,
        checkOut: 23,
        people: 1,
      })),
    }));
    switchMode(selected.details.length > 0 ? 1 : 2);
  };

  const handleDetailsSubmit = (value) => {
    setSelected((v) => ({ ...v, details: value }));
    switchMode(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selected.keyword.length <= 0) {
      switchMode(0);
      return;
    }
    if (selected.dates.length <= 0) {
      switchMode(1);
      return;
    }
    if (selected.details.length <= 0) {
      switchMode(2);
      return;
    }

    searchByDetailsFilter(selected);
  };

  const resetFilterMode = () => {
    setFilterMode([false, false, false]);
  };

  useEffect(() => {
    setSelected(detailsFilter);
  }, [detailsFilter]);

  useLayoutEffect(() => {
    resetFilterMode();
  }, [location]);

  useLayoutEffect(() => {
    if (filterMode.filter((v) => v).length === 0) {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [filterMode]);

  return (
    <S.Wrapper as="form">
      <div className="wrap">
        {[
          {
            value: selected.keyword,
            text: (kw) => kw.substring(0, 5) + (kw.length > 5 ? '...' : ''),
            placeholder: '지역 / 업체',
          },
          {
            value: selected.dates,
            text: (v) =>
              isMobile
                ? '세부 일정'
                : v
                    .reduce((ds, d) => {
                      const md = moment(d).format('MM월 DD');
                      return ds[0] === md ? ds : ds.concat(md);
                    }, [])
                    .join(' ~ ') + '일',
            placeholder: '세부 일정',
          },
          {
            value: selected.details,
            text: (tmp) => '상세 조건',
            placeholder: '상세 조건',
          },
        ].map((item, idx) => (
          <S.Btn
            key={idx}
            type="button"
            isState={item.value.length > 0}
            onClick={() => {
              switchMode(idx);
            }}
          >
            <span>
              {item.value.length > 0 ? item.text(item.value) : item.placeholder}
            </span>
          </S.Btn>
        ))}
      </div>
      <button type="submit" className="search-button" onClick={handleSubmit}>
        검색
      </button>
      {filterMode.filter((v) => v).length > 0 && (
        <S.ModalOverlay
          onClick={(e) => {
            e.preventDefault();
            if (e.target === e.currentTarget) {
              resetFilterMode();
            }
          }}
        >
          <S.ModalBox>
            {filterMode[0] && (
              <KeywordOption
                value={selected.keyword}
                handleSubmit={handleKeywordSubmit}
              />
            )}
            {filterMode[1] && (
              <DatesOption
                value={selected.dates}
                handleSubmit={handleDatesSubmit}
              />
            )}
            {filterMode[2] && (
              <CheckOption
                value={selected.details}
                handleSubmit={handleDetailsSubmit}
              />
            )}
          </S.ModalBox>
        </S.ModalOverlay>
      )}
    </S.Wrapper>
  );
}
