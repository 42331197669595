import { useMutation, useQuery, useQueries, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { waitLoading } from '.';
import {
  requestReviewList,
  requestMemberRequest,
  requestMemberRequestCount,
  requestMemberRequestDetails,
  requestReviewWrite,
  requestPartner,
  requestMemberApplication,
  requestMemberApplicationCount,
  requestBookingList,
  requestBookingCount,
  requestBookingDetails,
  requestBookingRead,
  requestChatList,
  requestChatContentList,
  getChatInquiry,
  requestWishList,
  getWishCount,
} from '../../service/client/mypage';

export const MYPAGE_KEYS = {
  reviewList: ['reviewList'],
  memberRequest: (sort) => ['memberRequest', sort],
  memberRequestCount: ['memberRequestCount'],
  memberRequestDetails: (id) => ['memberRequestDetails', id],
  memberApplication: (params) => ['memberApplication', params],
  // memberApplicationCount: ['memberApplicationCount'],
  booking: ['mybooking'],
  bookingList: (category) => [...MYPAGE_KEYS.booking, 'bookingList', category],
  bookingCount: () => [...MYPAGE_KEYS.booking, 'bookingCount'],
  bookingDetails: (id) => [...MYPAGE_KEYS.booking, 'bookingDetails', id],
  bookingRead: () => [...MYPAGE_KEYS.booking, 'bookingRead'],
  chatingList: ['chatingList'],
  chatingContentList: ['chatingContentList'],
  chatingInquiry: ['chatingInquiry'],
  wishList: (id) => ['wishList', id],
  wishCount: (id) => ['wishCount', id],
};

// 리뷰 요청 query
export const useReviewListQuery = () => {
  const fetchReviewList = async ({ pageParam = 1 }) => {
    const { data } = await requestReviewList({ page: pageParam });
    await waitLoading();

    return {
      products: data.data,
      offset: pageParam,
      isLast: data.data.length,
      // isLast 옵션이 없어짐
    };
  };

  const { data, fetchNextPage, isSuccess, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery(
    MYPAGE_KEYS.reviewList,
    fetchReviewList,
    {
      // infinite query 에서는 필요한 getNextPageParam처럼 사용자가 만든 함수가 필요하기 때문에
      // 따로 필요한 함수나 변수만 return
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.isLast !== 0) {
          return lastPage.offset + 1;
        }
        // 마지막 페이지면 undefined가 리턴되어서 hasNextPage는 false가 됨
        return undefined;
      },
    }
  );

  return { data, fetchNextPage, isFetching, isFetchingNextPage, status, isSuccess, hasNextPage };
};

// 요청 목록 
export const useRequestQuery = (sort) => {
  const fetchRequestList = async ({ pageParam = 1 }) => {
    const { data } = await requestMemberRequest({ sort: sort, page: pageParam });
    await waitLoading(500);
    return {
      products: data.data,
      offset: pageParam,
      isLast: data.data.length,
    };
  };

  const { data, fetchNextPage, isSuccess, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery(
    MYPAGE_KEYS.memberRequest(sort),
    fetchRequestList,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.isLast !== 0) {
          return lastPage.offset + 1;
        }
        return undefined;
      },
    }
  );
  return { data, fetchNextPage, isFetching, isFetchingNextPage, status, isSuccess, hasNextPage };
};

export const useMemberRequestCountsQuery = (params) => {
  // 의뢰 - 의뢰 상세 조회
  return useQuery(
    MYPAGE_KEYS.memberRequestCount,
    async () => {
      const { data } = await requestMemberRequestCount();

      return data.data;
    },
    {
      enabled: false,
    }
  );
};

export const useMemberRequestCountQuery = (params) => {
  // 의뢰 - 의뢰 목록 개수 조회

  return useQuery(MYPAGE_KEYS.memberRequest, async () => {
    const { data } = await requestMemberRequest();

    return data.data;
  });
};

export const useMemberRequestDetailsQuery = (params) => {
  // 의뢰 - 의뢰 상세 조회
  return useQuery(MYPAGE_KEYS.memberRequestDetails(params.id), async () => {
    const { data } = await requestMemberRequestDetails(params);
    await waitLoading(500);
    return data.data;
  });
};

export const useMemberApplicationQuery = (params) => {
  // 의뢰 - 지원 목록 조회
  return useInfiniteQuery({
    queryKey: MYPAGE_KEYS.memberApplication(params),
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await requestMemberApplication({ ...params, page: pageParam });
      await waitLoading(500);
      return data.data;
    },
    getNextPageParam: (lastPage, allPages) => {
      const hasNext = allPages?.length * 10 < lastPage?.data?.data?.count;
      return hasNext ? allPages.length + 1 : undefined;
    },
  });
};

export const useBookingListQuery = (sort) => {
  // 예약 - 예약 목록
  const fetchBookingList = async ({ pageParam = 1 }) => {
    const { data } = await requestBookingList({ sort: sort, page: pageParam });
    if (data.data.length > 0) {
      await waitLoading();
    }
    return {
      products: data.data,
      offset: pageParam,
      isLast: data.data.length,
    };
  };

  const { data, fetchNextPage, isSuccess, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery(
    MYPAGE_KEYS.bookingList(sort),
    fetchBookingList,
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.isLast !== 0) {
          return lastPage.offset + 1;
        }
        return undefined;
      },
    }
  );
  return { data, fetchNextPage, isFetching, isFetchingNextPage, status, isSuccess, hasNextPage };
};

export const useBookingCountQuery = (params) => {
  // 예약 - 예약 목록 갯수
  return useQuery(
    MYPAGE_KEYS.bookingCount(params),
    async () => {
      const { data } = await requestBookingCount(params);

      return data.data;
    },
    { enabled: false }
  );
};

export const useBookingDetailsQuery = (params) => {
  // 예약 - 예약 상세
  return useQuery(MYPAGE_KEYS.bookingDetails([params.id, new Date().toString()]), async () => {
    const { data } = await requestBookingDetails(params);
    await waitLoading(500);
    return data.data;
  });
};

export const useChatingListQuery = (params) => {
  // 채팅 - 채팅 목록
  const fetchChatingList = async ({ page: pageParam }) => {
    const { data } = await requestChatList({ page: 1 });
    await waitLoading(500);
    return {
      products: data.data,
      offset: pageParam,
      isLast: data.data.length,
      // isLast 옵션이 없어짐
    };
  };

  const { data, fetchNextPage, isSuccess, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery(
    MYPAGE_KEYS.chatingList,
    fetchChatingList,
    {
      // infinite query 에서는 필요한 getNextPageParam처럼 사용자가 만든 함수가 필요하기 때문에
      // 따로 필요한 함수나 변수만 return
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.isLast !== 0) {
          return lastPage.offset + 1;
        }
        // 마지막 페이지면 undefined가 리턴되어서 hasNextPage는 false가 됨
        return undefined;
      },
    }
  );

  return { data, fetchNextPage, isFetching, isFetchingNextPage, status, isSuccess, hasNextPage };
};

export const useChatingContentListQuery = (params) => {
  // 채팅 상세
  return useQuery(MYPAGE_KEYS.chatingContentList, async () => {
    const { data } = await requestChatContentList(params);
    return data.data;
  });
};

export const useChatingInquiryQuery = (params) => {
  // 채팅 - 문의채팅 조회
  return useQuery(MYPAGE_KEYS.chatingInquiry, async () => {
    const { data } = await getChatInquiry();

    return data.data.room;
  });
};

export const useWishListQuery = (sort, id) => {
  // 위시리스트 - 좋아요 목록
  const fetchWishList = async ({ pageParam = 1 }) => {
    const { data } = await requestWishList({ sort: sort, page: pageParam });
    await waitLoading();
    return {
      products: data.data,
      offset: pageParam,
      isLast: data.data.length,
    };
  };

  const { data, fetchNextPage, isSuccess, hasNextPage, isFetching, isFetchingNextPage, status, refetch, isRefetching } =
    useInfiniteQuery(MYPAGE_KEYS.wishList(sort), fetchWishList, {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.isLast !== 0) {
          return lastPage.offset + 1;
        }
        return undefined;
      },
      enabled: true,
      refetchOnMount: true,
    });
  return { data, fetchNextPage, isFetching, isFetchingNextPage, status, isSuccess, hasNextPage, refetch, isRefetching };
};

export const useWishCountQuery = (params) => {
  // 위시리스트 - 위시리스트 목록 갯수
  return useQuery(MYPAGE_KEYS.wishCount(params), async () => {
    const { data } = await getWishCount();

    return data.data;
  });
};

export const useEditRequestData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newData) => requestMemberRequest(newData),
    onSuccess: (data, variables) => {
      const newPagesArray = data.data.data.map((x) => {
        return x;
      });
      queryClient.setQueryData(MYPAGE_KEYS.memberRequest, (data) => ({
        pages: [
          {
            products: newPagesArray,
            offset: variables.page,
            isLast: newPagesArray?.length,
          },
        ],
        pageParam: data,
      }));
    },
  });
};

export const useEditBookingData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newData) => requestBookingList(newData),
    onSuccess: (data, variables) => {
      const newPagesArray = data.data.data.map((x) => {
        return x;
      });
      queryClient.setQueryData(MYPAGE_KEYS.bookingList, (data) => ({
        pages: [
          {
            products: newPagesArray,
            offset: variables.page,
            isLast: newPagesArray?.length,
          },
        ],
        pageParam: data,
      }));
    },
  });
};

export const useEditChattingData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newData) => {
      return requestChatContentList({ room: newData });
    },
    onSuccess: (data, variables) => {
      const newPagesArray = data.data.data.map((x) => {
        return x;
      });
      queryClient.setQueryData(MYPAGE_KEYS.chatingContentList, (data) => ({
        pages: [
          {
            products: newPagesArray,
            offset: variables.page,
            isLast: newPagesArray?.length,
          },
        ],
        pageParam: data,
      }));
    },
  });
};

export const useChatingInquiry = () => {
  return useMutation(requestReviewList);
};

export const useBookingRead = () => {
  return useMutation(async (category) => {
    return await requestBookingRead({ sort: category });
  });
};

export const usePartnerData = () => {
  return useMutation(async (params) => await requestPartner(params));
};

// 리뷰 작성
export const useReviewWriteData = () =>
  useMutation({
    mutationFn: async (params) => await requestReviewWrite(params),
  });
