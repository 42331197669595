import React from 'react';
import styled from 'styled-components';
import { Flex, FlexRows, yScrollBoxCss } from '../../../../components/Common/Display';
import { Text } from '../../../../components/Common/Text';
import LayoutModal from './LayoutModal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { bookPeriodState } from '../../../../store/search';
import RDatePicker from '../../../../components/Common/RDatePicker';
import { availableDateState } from '../../../../store/book';

const TextDiv = styled(Flex)``;

const CalendarDiv = styled(FlexRows)`
  position: relative;
  height: max-content;
  max-height: 100%;
  ${yScrollBoxCss()};

  @media (min-height: 680px) {
    max-height: calc(100vh - 420px);
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    height: calc(100vh - 320px);
  }
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 30px;
  right: 35px;

  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray900};
  text-decoration: underline;
`;

// component: 예약 날짜 선택 모달창
// /space/book/:id 페이지에 노출, selectBox 컴포넌트의 hideBox에서 호출
function SelectScheduleModal({ closeModal }) {
  // 예약 날짜 선택
  const [bookPeriod, setBookPeriod] = useRecoilState(bookPeriodState);
  const usingDate = useRecoilValue(availableDateState);

  return (
    <LayoutModal
      _top="110px"
      b_text="확인"
      padding="24px 20px 80px"
      m_padding="20px 20px 80px"
      isBig={true}
      closeModal={closeModal}
      onClick={closeModal}
      side
    >
      <TextDiv _width="100%" _gap={230} _justify="center">
        <Text _size="xxxl" _weight="medium" _color="gray900">
          날짜 선택
        </Text>
        <RemoveButton onClick={() => setBookPeriod([])}>날짜 지우기</RemoveButton>
      </TextDiv>
      <CalendarDiv _width="100%" _gap={40} _justify="center">
        <div>
          <RDatePicker availableDate={usingDate} dates={bookPeriod} handleChange={setBookPeriod} />
        </div>
      </CalendarDiv>
    </LayoutModal>
  );
}

export default SelectScheduleModal;
