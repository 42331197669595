import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import AliemList from './components/Aliem/AliemList';
import Aside from './layouts/Aside';
import Footer from './layouts/Footer';
import GlobalWrapper from './layouts/GlobalWrapper';
import Header from './layouts/Header';
import SubHeader from './layouts/SubHeader';
import {
  alertModal,
  aliemModalState,
  asideModalState,
  picViewerModal,
  findIdModalState,
  findPwModalState,
  joinModalState,
  loginModalState,
  customConfirm,
  confirmModal,
} from './store/modal';
import { floatingChatUi, footerUi, headerUi } from './store/ui';
import { useScrollUp } from './hooks/ui/scroll';
import { reportModal, reviewModal } from './store/modal';
import ReviewViewer from './components/Review/ReviewViewer';
import ReviewReport from './components/Review/ReviewReport';
import { AlertModal as Alert, ConfirmModal } from './layouts/Modal';
import { customAlert } from './store/modal';
import Join from './components/Account/Join';
import Login from './components/Account/Login';
import FindId from './components/Account/FindId';
import FindPwd from './components/Account/FindPwd';
import SnsAuth from './components/Account/Auth/SnsAuth';
import PicViewer from './components/PicViewer';
import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import FloatingChat from './components/Common/Floating/Floatingchat';

function App() {
  const location = useLocation();
  // layout
  const footerMode = useRecoilValue(footerUi);
  const headerMode = useRecoilValue(headerUi);
  const floatingChat = useRecoilValue(floatingChatUi);
  // modal
  const reviewMode = useRecoilValue(reviewModal);
  const reportMode = useRecoilValue(reportModal);
  const alertMode = useRecoilValue(customAlert);
  const confirmMode = useRecoilValue(customConfirm);
  // viewer modal
  const picViewrState = useRecoilValue(picViewerModal);
  // account modal
  const login = useRecoilValue(loginModalState);
  const join = useRecoilValue(joinModalState);
  const findId = useRecoilValue(findIdModalState);
  const findPwd = useRecoilValue(findPwModalState);
  // header
  const [onAliem, setOnAliem] = useRecoilState(aliemModalState);
  const [asideOn, setAsideOn] = useRecoilState(asideModalState);
  // reset status
  const resetReview = useResetRecoilState(reviewModal);
  const resetReport = useResetRecoilState(reportModal);
  const resetAlert = useResetRecoilState(alertModal);
  const resetPicViewer = useResetRecoilState(picViewerModal);
  const resetLogin = useResetRecoilState(loginModalState);
  const resetFindId = useResetRecoilState(findIdModalState);
  const resetFindPwd = useResetRecoilState(findPwModalState);
  const resetAliem = useResetRecoilState(aliemModalState);
  const resetFloatingChat = useResetRecoilState(floatingChatUi);
  const resetHeaderUi = useResetRecoilState(headerUi);
  const resetConfirm = useResetRecoilState(confirmModal);

  useLayoutEffect(() => {
    resetReview();
    resetReport();
    resetAlert();
    resetConfirm();
    resetPicViewer();
    resetFindId();
    resetFindPwd();
    resetLogin();
    resetAliem();
    resetHeaderUi();
    resetFloatingChat();
    setOnAliem(false);
    setAsideOn(false);
  }, [location]);

  useScrollUp();

  return (
    <GlobalWrapper>
      <Helmet>
        <title>알아봐봐</title>
      </Helmet>
      {/* LAYOUT */}
      {headerMode.mainDisplay && <Header />}
      {headerMode.subDisplay && <SubHeader title={headerMode.subTitle} />}
      <main>
        <Outlet />
      </main>
      <Aside asideOn={asideOn} closeAside={() => setAsideOn(false)} />
      {floatingChat.id && <FloatingChat {...floatingChat} />}
      {footerMode.display && <Footer />}
      {/* MODAL */}
      {reviewMode.id && <ReviewViewer reviewId={reviewMode.id} reviewContents={reviewMode.contents} />}
      {reportMode.id && <ReviewReport reviewId={reportMode.id} stdBox={reportMode.stdBox} />}
      {alertMode.display && <Alert {...alertMode} />}
      {confirmMode.display && <ConfirmModal {...confirmMode} />}
      {onAliem && <AliemList />}
      {/* ACCOUNT MODAL */}
      <SnsAuth />
      {login.mode && <Login redirectPath={login.redirect} />}
      {join.mode && <Join />}
      {findId && <FindId />}
      {findPwd && <FindPwd />}
      {/* PIC VIEWER */}
      <PicViewer active={picViewrState.mode} srcs={picViewrState.srcs} />
    </GlobalWrapper>
  );
}

export default App;
