import React, { useState, useEffect, useLayoutEffect } from 'react';
import * as D from '../Common/Display';
import GridPics from '../Common/GridPics';
import { RowTab } from '../Common/Tab';
import { Text } from '../Common/Text';
import SpaceInfo from './SpaceInfo';
import SpaceReviewList from './SpaceReviewList';
import RoomList from './RoomList';
import SpaceNearList from './SpaceNearList';
import styled from 'styled-components';
import shareIcon from '../../assets/icons/utils/ico-share.svg';
import chatIcon from '../../assets/icons/utils/ico-chat.svg';
import pinIcon from '../../assets/icons/utils/ico-pin.svg';
import { Badge } from '../Common/Badge';
import ReviewStars from '../Review/ReviewStars';
import ReviewReport from '../Review/ReviewReport';
import MatterPortViewer from '../Common/MatterPortViewer';
import { useHotelDetailsQuery } from '../../hooks/queries/space';
import { customAlert, picViewerModal } from '../../store/modal';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from '../../utils/copy';
import { SpaceBookmark } from '../Common/BookMark';
import { floatingChatUi } from '../../store/ui';
import { LoadingStatus } from '../Common/Status';

export const S = {
  Top: {
    Wrapper: styled.div`
      position: relative;
      width: 100%;

      @media (${(props) => props.theme.media.tabletSm}) {
        padding: 32px 20px 0;
      }
    `,
    Scores: styled.div`
      display: flex;
      gap: 2px;
      align-items: center;

      @media (${(props) => props.theme.media.tabletSm}) {
        position: absolute;
        top: 0;
        left: 20px;
      }
    `,
    Utils: styled.ul`
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      gap: 24px;
      width: max-content;

      a,
      button {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: ${(props) => props.theme.fontSize.s};
        font-weight: ${(props) => props.theme.fontWeight.medium};
      }

      @media (${(props) => props.theme.media.tabletSm}) {
        right: 20px;
        top: 0;
        bottom: auto;
        gap: 16px;

        a,
        button {
          font-size: 0;
          span {
            display: none;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    `,
  },
  PictureWrap: styled.div`
    z-index: 20;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;

    @media (${(props) => props.theme.media.tabletSm}) {
      border-radius: 0;
    }
  `,
  DimsWrap: styled.div`
    z-index: 22;
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(${({ show }) => (show ? '0' : '-100%')});
    transition: 0.3s ease-in;
  `,
  TransBtn: styled.button`
    z-index: ${({ show }) => (show ? 23 : -1)};
    position: absolute;
    top: 24px;
    right: 24px;
    opacity: ${({ show }) => (show ? 1 : 0)};
    padding: 0 16px;
    line-height: 38px;
    height: 38px;
    background-color: #fff;
    border-radius: 999px;
    transition: 0.2s ease-in;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  `,
  TabFrame: styled.div`
    width: 980px;
    height: auto;

    @media (${(props) => props.theme.media.tablet}) {
      width: 100%;
    }
  `,
  TabConts: styled.div`
    margin-top: 60px;
    position: relative;
    z-index: 20;
    width: calc(100% + 40px);
    padding: 20px 20px 0;
    /* width: 100%; */
    /* padding: 20px 0 0; */
    transform: translateX(-20px);
    overflow-y: hidden;
    overflow-x: visible;

    @media (${(props) => props.theme.media.tablet}) {
      margin-top: 40px;
    }
  `,
  TabCont: styled.div`
    width: 100%;
    height: 100%;
    transform: translateX(0);
    background-color: #fff;
  `,
};

/**
 * 공간 상세 컴포넌트
 * @param {string} props.id 공간 아이디
 */
export default function Details({ id }) {
  const { isLoading, error, data } = useHotelDetailsQuery({ id: id });
  const setAlert = useSetRecoilState(customAlert);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [dimsMode, setDimsMode] = useState(true);
  const setPicViewer = useSetRecoilState(picViewerModal);
  const setFloatingChat = useSetRecoilState(floatingChatUi);

  useLayoutEffect(() => {
    if (data) {
      console.log(data);
      setFloatingChat({ id: data.managerId });
    }
  }, [data]);

  if (error) {
    setAlert({
      text: '호텔 정보를 찾을 수 없습니다.',
      handleClose: () => {
        navigate('/');
      },
    });
    return null;
  }

  return isLoading ? (
    <LoadingStatus />
  ) : (
    <D.FlexCols as="section" _gap={100} _width="100%">
      <D.FlexCols _gap={80} _width="100%" _items="center" _media="tabletSm" _m_gap={40}>
        <D.Flex _direction="column" _gap={16} _width="100%" _media="tabletSm" _m_direction="column-reverse" _m_gap={20}>
          <S.Top.Wrapper>
            <D.FlexCols _gap={12}>
              <D.FlexCols _gap={4}>
                <Text as="h2" _weight="medium" _size="xxxxl" _media="tablet" _m_size="xl">
                  {data.hotelName}
                </Text>
                <D.FlexRows _gap={8} _items="center">
                  <D.FlexRows _gap={2} _items="center">
                    <img src={pinIcon} />
                    <Text _size="s" _color="gray600">
                      {data.address}
                    </Text>
                  </D.FlexRows>
                  <S.Top.Scores>
                    <ReviewStars score={data.star} size="s" />
                    <Text _weight="medium" _size="s">
                      {data.star}
                    </Text>
                  </S.Top.Scores>
                </D.FlexRows>
              </D.FlexCols>
              <D.FlexRows as="ul" _gap={8}>
                {data.category.map((x, index) => (
                  <Badge text={x} _color="pink600" key={index} />
                ))}
              </D.FlexRows>
            </D.FlexCols>
            <S.Top.Utils>
              <li>
                <button
                  onClick={() => {
                    copyToClipboard(window.location.href);
                    setAlert({ text: '링크를 클립보드에 복사했습니다.' });
                  }}>
                  <img src={shareIcon} />
                  <span>공유하기</span>
                </button>
              </li>
              <li>
                <SpaceBookmark styleType="text" type="hotel" like={data.bookmark} id={id} />
              </li>
            </S.Top.Utils>
          </S.Top.Wrapper>
          <S.PictureWrap>
            {/* 
                @수정
                - 2023.04.24: 사진모음 삭제, 3d 사진 + 전체 사진 뷰어만 노출  
              */}
            {/* <GridPics
							pics={data.image}
							handleThumbClick={() => {
								setDimsMode(true);
							}}
							allMode
						/> */}
            <S.DimsWrap show={dimsMode}>
              {/*<img src={`https://cdn.alabwaba.com${data.image[0]}`} alt="메인 이미지" />*/}
              <MatterPortViewer url={data.vrImage} />
            </S.DimsWrap>
            <S.TransBtn
              show={dimsMode}
              onClick={() => {
                // setDimsMode(false);
                setPicViewer({
                  mode: true,
                  srcs: data.image,
                });
              }}>
              사진보기
            </S.TransBtn>
          </S.PictureWrap>
        </D.Flex>
        <S.TabFrame>
          <RowTab
            activeTab={activeTab}
            tabs={['공간', '업체 설명', '공간 리뷰', '담당자 리뷰'].map((item, idx) => ({
              text: item,
              onClick: (idx) => {
                setActiveTab(idx);
              },
            }))}
          />
          <S.TabConts>
            {activeTab === 0 && (
              <S.TabCont>
                <RoomList id={id} />
              </S.TabCont>
            )}
            {activeTab === 1 && (
              <S.TabCont>
                <SpaceInfo
                  introduction={data.introduction}
                  latlng={[data.lat, data.lng]}
                  facilities={data.facility.filter((item) => item.type === '편의')}
                  free={data.facility.filter((item) => item.type === '무료')}
                  faq={data.faq}
                  address={`${data.addressLocation} ${data.addressDetails}`}
                  direction={data.directions}
                />
              </S.TabCont>
            )}
            {activeTab === 2 && (
              <S.TabCont>
                <SpaceReviewList type="공간" id={id} />
              </S.TabCont>
            )}
            {activeTab === 3 && (
              <S.TabCont>
                <SpaceReviewList type="담당자" id={id} />
              </S.TabCont>
            )}
          </S.TabConts>
        </S.TabFrame>
      </D.FlexCols>
      {/* 근처 업체 보기 */}
      <SpaceNearList info={data} />
      <ReviewReport />
    </D.FlexCols>
  );
}
