import styled from "styled-components";
import { yScrollBoxCss } from "../../../../components/Common/Display";

// MAIN MODAL STYLE
export const Modal = {
  Overlay: styled.div`
    z-index: 40;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: auto;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    cursor: pointer;
    display: none;
    @media (${(props) => props.theme.media.tabletSm}) {
      display: ${(props) => (props.isHide ? "flex" : "none")};
    }
  `,
  Wrapper: styled.div`
    position: relative;
    z-index: 41;
    width: 380px;
    overflow: visible;
  `,
  FormBox: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    label {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      padding-left: 4px;

      font-size: ${(props) => props.theme.fontSize.xxs};
      font-weight: ${(props) => props.theme.fontWeight.normal};
      color: ${(props) => props.theme.colors.gray800};
      & input {
        width: 100%;
        height: auto;
      }
    }
    & span {
      padding: 0 8px;
    }
  `,
  SelectionBox: styled.div`
    z-index: 41;
    position: relative;
    align-items: center;

    padding: 48px 40px;
    position: sticky;
    width: 100%;
    top: 130px;
    ${(props) => props.theme.effectStyle.boxShadow}
    border-radius: 20px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray400};
    margin: 0 auto;

    @media (${(props) => props.theme.media.tablet}) {
      top: 110px;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      padding: 64px 45px ${(props) => (props.isHide ? "0" : "64px")};
      position: fixed;
      height: ${(props) => (props.isHide ? "64px" : "auto")};
      max-height: calc(100dvh - 80px);
      top: auto !important;
      bottom: 0 !important;
      left: 0;
      right: 0;
      z-index: 40;
      border-radius: 20px 20px 0 0;
      overflow-y: ${(props) => (props.isHide ? "hidden" : "visible")};
    }
  `,
};

// 예약자 정보
export const Personal = {
  ScrollSection: styled.div`
    width: 100%;
    max-height: calc(100vh - 400px);
    ${yScrollBoxCss()}

    > div {
      display: flex;
      flex-direction: column;
      gap: 40px;
      height: auto;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      max-height: calc(100vh - 400px);
    }
  `,
  PrevBtn: styled.button`
    height: 48px;
    width: 76px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => props.theme.fontWeight.bolder};
    color: ${(props) => props.theme.colors.gray800};
    img {
      width: 12px;
      height: 11px;
      transform: rotate(-90deg);
    }

    span {
      text-decoration: underline;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      display: none;
    }
  `,
  Policy: styled.ul`
    line-height: 30px;

    li {
    }
  `,
};
