import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { SITE } from '../../constants/site';
import { getAvailableDate, getSpaceBook } from '../../service/client/book';
import {
  reqSpaceBookState,
  spaceBookDetailState,
  spaceBookPersonInfoState,
  spaceBookTotalPriceState,
} from '../../store/book';
import { customAlert } from '../../store/modal';
import { bookPeriodState } from '../../store/search';

const BOOK_KEYS = {
  request: ['book', 'space'],
  available: ['book', 'available', 'date'],
};

// 공간 예약 요청 query
export const useSpaceBookQuery = (onQuery, token, formData) => {
  const reset1 = useResetRecoilState(reqSpaceBookState);
  const reset2 = useResetRecoilState(spaceBookDetailState);
  const reset3 = useResetRecoilState(bookPeriodState);
  const reset4 = useResetRecoilState(spaceBookTotalPriceState);
  const rese5t = useResetRecoilState(spaceBookPersonInfoState);
  const setAlert = useSetRecoilState(customAlert);

  const navigate = useNavigate();

  const onSuccess = () => {
    reset1();
    reset2();
    reset3();
    reset4();
    rese5t();
    setAlert({
      text: '예약을 완료하였습니다.',
      handleClose: () => {
        navigate(SITE.home, { replace: true });
      },
    });
  };
  return useQuery(
    BOOK_KEYS.request,
    async () => {
      const { data } = await getSpaceBook(token, formData);

      return data;
    },
    {
      enabled: onQuery,
      onSuccess,
      onError: () => console.log('예약 실패'),
    }
  );
};

// 예약 가능 날짜 요청 query
export const useAvailableDateQuery = (id) => {
  const { data, error, isFetching } = useQuery(BOOK_KEYS.available, async () => {
    const { data } = await getAvailableDate(id);

    return data;
  });
  return { data, error, isLoading: isFetching };
};
