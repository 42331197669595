import React from 'react';
import * as D from '../Common/Display';
import ReviewCard from '../Review/ReviewCard';
import styled from 'styled-components';
import { Text } from '../Common/Text';
import ReviewStars from '../Review/ReviewStars';
import { useInfiniteReviewListQuery } from '../../hooks/queries/space';
import CardList from '../Common/CardList';
import { ErrorStatus } from '../../components/Common/Status';
import { MoreBtn } from '../Common/Button';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 34px;
    width: calc(100% + 40px);
    padding: 0 20px 20px;
    min-height: 50vh;
    background-color: #fff;
    transform: translateX(-20px);

    @media (${(props) => props.theme.media.tabletSm}) {
      gap: 40px;
      width: 100%;
      transform: translateX(0);
    }
  `,
  List: styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    width: 100%;

    @media (${(props) => props.theme.media.tabletSm}) {
      gap: 24px;
      grid-template-columns: 1fr;
    }
  `,
};

/**
 * 공간 리뷰 컴포넌트
 * @param {string} 리뷰 아이디 
 * @param {'space' || 'type'} 리뷰 타입 
 */
export default function DetailsReviewList({ type = '공간', id }) {
  const {
    isLoading,
    data: pageData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteReviewListQuery(type, id);

  return (
    <S.Wrapper>
      {!isLoading && !pageData && <ErrorStatus />}
      {pageData &&
        (pageData.pages[0].data?.data?.count > 0 ? (
          <D.FlexCols _gap={16} _width="100%" _media="tabletSm" _m_gap={40}>
            <D.FlexCols _gap={16} _width="100%" _items="flex-start">
              <D.FlexCols>
                <Text _size="xxxxl" _weight="medium" _line="xxs">
                  리뷰 {pageData.pages[0].data.data.count}개
                </Text>
                <Text _size="l" _color="gray700" _line="xxs">
                  공간에 대한 리뷰를 확인해 보세요!
                </Text>
              </D.FlexCols>
              <D.FlexRows _gap={4} _items="center">
                <ReviewStars score={pageData.pages[0].data.data.avg} />
                <Text _size="xxxl" _weight="medium" _line="xxs">
                  {pageData.pages[0].data.data.avg + '점'}
                </Text>
              </D.FlexRows>
            </D.FlexCols>
            <D.FlexCols _width="100%" _gap={40} _items="center" _justify="center">
              <CardList
                count={2}
                gap={20}
                medias={[
                  {
                    media: 'tabletSm',
                    _direction: 'column',
                    count: 1,
                    gap: 24,
                  },
                ]}>
                {pageData &&
                  pageData.pages
                    .reduce((acc, page) => acc.concat(page.data.data.review), [])
                    .map((item, idx) => <ReviewCard key={idx} review={item} nameTag={type === '담당자'} />)}
              </CardList>
              {hasNextPage && !isFetchingNextPage && <MoreBtn handleClick={fetchNextPage} text="리뷰 더보기" />}
            </D.FlexCols>
          </D.FlexCols>
        ) : (
          <ErrorStatus message="리뷰 내역이 없습니다" />
        ))}
    </S.Wrapper>
  );
}
