import React from 'react';
import styled from 'styled-components';
import * as D from '../../Common/Display';
import { Text } from '../../Common/Text';
import rightIcon from '../../../assets/icons/utils/ico-arrow_right.svg';
import { Link } from 'react-router-dom';
import { Badge } from '../../Common/Badge';
import moment from 'moment';

const S = {
  RequestCard: {
    Wrapper: styled.article`
      display: flex;
      flex-direction: row;
      gap: 60px;
      width: 100%;
      // height: 78px;
      padding: 24px 20px;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray200};

      @media (${(props) => props.theme.media.tabletSm}) {
        flex-direction: column;
      }
    `,
    Apply: styled(Link)`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 126px;
      height: 65px;
      border: 1px solid ${(props) => props.theme.colors.gray300};
      border-radius: 20px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

      span {
        line-height: 20px;
        font-size: ${(props) => props.theme.fontSize.s};
      }

      strong {
        font: ${(props) => props.theme.textStyle.mainTitle};
        line-height: 30px;
      }

      @media (${(props) => props.theme.media.tabletSm}) {
        width: 100%;
        height: 75px;
      }
    `,
  },
};

/**
 * 마이페이지 > 의뢰 항목 컴포넌트
 * @param {object} props.data 의뢰 데이터 
 */
export default function RequestCard({ data }) {
  return data == '' ? (
    ''
  ) : (
    <S.RequestCard.Wrapper key={data.id}>
      <D.FlexCols _width="calc(100% - 186px)" _gap={42} _media="tabletSm" _m_gap={16} _m_width="100%">
        <D.FlexCols _gap={16} _width="100%" _media="tabletSm" _m_gap={24}>
          <Text _size="xs" _color="gray500">
            작성일 {moment(data.requestCreateDate).format('YYYY.MM.DD')}
          </Text>
          <D.FlexCols _gap={8}>
            {data.categories?.length > 0 && (
              <D.FlexRows _gap={8}>
                {data.categories.map((option, idx) => (
                  <Badge key={idx} text={option} _color="pink600" />
                ))}
              </D.FlexRows>
            )}
            <Text as="h3" _size="xl" _weight="medium">
              {data.location}
            </Text>
          </D.FlexCols>
        </D.FlexCols>
        <D.FlexCols _gap={3} _width="100%">
          <Text _color="gray600" _size="l">
            {data.startDate.split('-')[1]}월 {data.startDate.split('-')[2]}일 ~{data.endDate.split('-')[1]}일{' '}
            {data.endDate.split('-')[2]}일
          </Text>
          <Text _size="xl" _weight="medium">
            {data.minPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 ~{' '}
            {data.maxPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
            <Text _size="s" _color="gray500">
              {' '}
              / 1인
            </Text>
          </Text>
        </D.FlexCols>
      </D.FlexCols>
      <D.FlexCols
        _gap={42}
        _width="126px"
        _justify="flex-end"
        _items="center"
        _media="tabletSm"
        _m_width="100%"
        _m_items="flex-end"
        _m_gap={20}>
        <S.RequestCard.Apply to={`/mypage/request/applys/${data.requestId}`} replace>
          <span>지원</span>
          <strong>{data.applicationCount}</strong>
        </S.RequestCard.Apply>
        <D.FlexRows
          as={Link}
          to={`/mypage/request/details/${data.requestId}`}
          replace
          state={data.requestId}
          _gap={8}
          _items="center">
          <Text _size="s" _weight="medium" _line="xl">
            세부 의뢰 정보
          </Text>
          <img src={rightIcon} />
        </D.FlexRows>
      </D.FlexCols>
    </S.RequestCard.Wrapper>
  );
}
