import React, { useEffect } from "react";
import styled from "styled-components";

const s = {
  Frame: styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 49.15%;
    background-color: #21212108;

    @media (${(props) => props.theme.media.tabletSm}) {
      padding-top: 0;
      height: 68vw;
      max-height: 520px;
    }

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,
};

/**
 * MatterPort Viewer 컴포넌트
 * @description 외부 라이브러리
 * - 참고 링크: https://matterport.github.io/showcase-sdk/sdkbundle_quickstart.html
 */
export default function MatterPortViewer({ url }) {
  // URL-SEVER에서 전달 받음
  //   const initializeViewr = async () => {
  //     const iframe = document.getElementById('showcase-iframe');
  //     try {
  //       const mpSdk = await window.MP_SDK.connect(
  //         iframe, // Obtained earlier
  //         '55tgnk6it2e136fee7fdzch8d', // Your SDK key
  //         '' // Unused but needs to be a valid string
  //       );
  //       onShowcaseConnect(mpSdk);
  //     } catch (e) {
  //       console.error(e);
  //     }

  //     function onShowcaseConnect(mpSdk) {
  //       // start making calls on mpSdk as described in the reference docs
  //     }
  //   };

  useEffect(() => {
    // initializeViewr();
    console.log(url);
  }, []);

  return (
    <s.Frame>
      <iframe title="space" width="100%" height="100%" src={url} allow="fullscreen; vr" id="showcase-iframe" />
    </s.Frame>
  );
}
