import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import tHeartIcon from "../../../assets/icons/utils/ico-heart.svg";
import iHeartBgIcon from "../../../assets/icons/utils/ico-heart_bg.svg";
import iHeartIcon from "../../../assets/icons/utils/ico-heart_line.svg";
import tHeartBgIcon from "../../../assets/icons/utils/ico-heart_pink.svg";
import { useSpaceBookmarkQuery } from "../../../hooks/queries/space";
import { customAlert } from "../../../store/modal";
import { useUser } from "../../../store/user";

const S = {
  TextBtn: styled.button`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};

    img {
      width: 16px !important;
      height: auto;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      font-size: 0;

      img {
        width: 24px !important;
      }
    }
  `,
  IconBtn: styled.button`
    //
  `,
};

/**
 * 북마크 컴포넌트
 * @param {'hotel'|'space'} props.type 북마크 타입(호텔|공간)
 */
// type: hotel || space
export function SpaceBookmark({ type, like: defaultLike, id, wish, styleType }) {
  const { mutate: requestBookmark, isLoading, isSuccess } = useSpaceBookmarkQuery();
  const { loggedIn, token } = useUser();
  const [like, setLike] = useState(wish ? wish : defaultLike);
  const setAlert = useSetRecoilState(customAlert);
  const queryClient = useQueryClient();

  const handleClick = (e) => {
    if (isLoading) return;
    e.preventDefault();
    e.stopPropagation();
    if (!loggedIn) {
      setAlert({ text: "로그인 후 이용해 주세요." });
      return;
    }
    if (type === null) {
      console.error("bookmark 확인: type");
      return;
    }
    requestBookmark({
      like: !like,
      id: id,
      token: token,
      type: type,
      callback: () => {
        setLike((like) => !like);
        queryClient.invalidateQueries({ queryKey: ["_wish"] });
      },
    });
  };

  if (styleType === "icon") {
    return (
      <S.IconBtn onClick={handleClick}>
        <img src={like ? iHeartBgIcon : iHeartIcon} alt="하트" />
      </S.IconBtn>
    );
  }

  if (styleType === "text") {
    return (
      <S.TextBtn onClick={handleClick}>
        <img src={like ? tHeartBgIcon : tHeartIcon} alt="하트" />
        <span>찜하기</span>
      </S.TextBtn>
    );
  }
}
