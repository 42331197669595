import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Tag = styled.span`
  border-radius: 2133213px;
  padding: 2px 8px;
  border: 1px solid ${(props) => props.theme.colors.pink600};
  font-size: ${(props) => props.theme.fontSize.xxs};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.pink600};
  line-height: 1;
  white-space: nowrap;
`;

function TagBox({ tags }) {
  return (
    <Wrapper>
      {tags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
    </Wrapper>
  );
}

export default TagBox;
