import React, { forwardRef, useEffect, useRef, useState } from 'react';

import calendarIcon from '../../../../assets/ico-calendar.svg';
import arrowUpIcon from '../../../../assets/icons/utils/ico-arrow_up.svg';
import arrowLeftIcon from '../../../../assets/icons/utils/ico-arrow_left.svg';
import arrow from '../../../../assets/icons/utils/ico-chevron_up.svg';
import styled, { css } from 'styled-components';
import { Flex, FlexCols, FlexRows, yScrollBoxCss } from '../../../../components/Common/Display';
import { DotedText, Text } from '../../../../components/Common/Text';
import SelectScheduleModal from './SelectScheduleModal';
import { RoundBtn } from '../../../../components/Common/Button';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  spaceBookDetailState,
  spaceBookPersonInfoState,
  spaceBookPriceArrState,
  spaceBookTotalPriceState,
  stringPeriodState,
} from '../../../../store/book';
import { convertNumber } from '../../../../constants/number';
import { isLoggedIn, useUser } from '../../../../store/user';
import { customAlert } from '../../../../store/modal';
import { bookPeriodState } from '../../../../store/search';
import { useSwipeable } from 'react-swipeable';
import { floatingChatUi } from '../../../../store/ui';
import HiddenInfo from '../../../../components/Common/HiddenInfo';
import HideBox from './HideBox';
import SelectTimeModal from './SelectTimeModal';
import SelectPackageModal from './SelectPackageModal';
import SelectTableTypeModal from './SelectTableTypeModal';
import moment from 'moment';
import { spaceDetailsState } from '../../../../store/space';
import RuleInfoModal from './RuleInfoModal';
import { useForm } from 'react-hook-form';
import { InputField } from '../../../../components/Common/InputField';
import { EMAIL_PATTERN, TEL_PATTERN } from '../../../../constants/pattern';
import * as St from './styled';
import { CheckBox } from '../../../../components/Common/InputField';
import SelectRoomModal from './SelectRoomModal';

const MainDiv = styled(FlexCols)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 100%;

  /* 예약일 때만 */
  /* ${(props) => {
    return props.isScroll && yScrollBoxCss();
  }} */

  @media (${(props) => props.theme.media.tabletSm}) {
    display: ${(props) => (props.isHide ? 'none' : 'flex')};
    width: 100%;
    gap: 32px;
  }
`;

const SelectDateDiv = styled.div`
  padding: 8px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 48px;

  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 99px;

  .calendar-icon {
    width: 24px;
    height: 24px;
  }
  cursor: pointer;
`;

const OptionDiv = styled(FlexCols)`
  max-width: 300px;
  max-height: calc(100vh - 520px);
  ${yScrollBoxCss()}
  @media (${(props) => props.theme.media.tabletSm}) {
    max-width: 100%;
    max-height: calc(100vh - 480px);
  }
`;

const TopCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: 8px;
  display: none;
  @media (${(props) => props.theme.media.tabletSm}) {
    display: block;
  }
`;
const Top = {
  Base: styled.button`
    ${TopCss}
    text-align: center;

    &::after {
      display: inline-block;
      content: '';
      width: 24px;
      height: 24px;
      background: url(${arrowUpIcon}) center no-repeat;
      transform: rotate(${(props) => (props.active ? 180 : 0)}deg);
    }
  `,
  Prev: styled.div`
    ${TopCss}
    padding: 16px;

    button {
      width: 24px;
      height: 24px;
    }
  `,
};

const CostWrapper = styled(FlexCols)`
  @media (${(props) => props.theme.media.tabletSm}) {
    width: 100%;
  }
`;
const ERROR_MSG = {
  all: '*모든 항목을 입력하셔야 예약 신청이 가능합니다',
  username: '2글자 이상의 이름을 입력 해주세요',
  email: '이메일 주소를 입력 해주세요',
  phone: '"-"을 사용하지 않고 숫자만 입력 해주세요',
  agree: '개인정보 수집 동의에 체크 해주세요',
};

// component: 예약 box
// /space/book/:id 페이지에 노출 및 사용
const SelectBox = ({ setOnBookQuery, onModal, setOnModal, hidden, setHidden, hasRooms }) => {
  // 날짜를 입력했을 때, onModal 0 -> 2로 넘어가는 걸 막기위한 state
  const [checkedModal1, setCheckedModal1] = useState(false);

  // 공간 detail
  const spaceDetails = useRecoilValue(spaceDetailsState);
  // 필요 varialbles
  const loggedIn = useRecoilValue(isLoggedIn);
  const setAlert = useSetRecoilState(customAlert);
  // 총 비용
  const [totalPrice, setTotalPrice] = useRecoilState(spaceBookTotalPriceState);
  // 날짜 별 가격 배열
  const priceArr = useRecoilValue(spaceBookPriceArrState);

  // 캘린더 모달 & 옵션 모달
  const [onScheduleModal, setOnScheduleModal] = useState(false);
  const [infoModal, setInfoModal] = useState([-1, -1, -1, -1, -1]);
  // 예약 기간
  const stringPeriod = useRecoilValue(stringPeriodState);
  const resetStringPeriod = useResetRecoilState(bookPeriodState);
  const isPeriod = stringPeriod.length > 0;
  // 모든 날짜 별 옵션 데이터
  // [{1일 옵션}, {2일 옵션}...]
  const [tempData, setTempData] = useState([]);

  // 예약자 정보
  const [bookPersonInfo, setBookPersonInfo] = useRecoilState(spaceBookPersonInfoState);
  const [reqDetail, setReqDetail] = useRecoilState(spaceBookDetailState);
  // 로그인 사용자 정보
  const userInfo = useUser();
  // 예약자 정보 form 처리
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  // 모달 UI 스와이프
  const swipeHandler = useSwipeable({
    onSwipedUp: () => {
      setHidden(false);
    },
    onSwipedDown: () => {
      setHidden(true);
    },
  });

  // @use: 예약 전체 폼 체크
  // return true: 다음 단계 진행, false: 입력 확인
  // 예약 required
  // 1 인원 > 0
  // 2 식사 패키지가 설정되어 있을 때 인원 > 0
  const reqException = () => {
    let hasEmptyItem = [];

    for (const item of reqDetail) {
      let hasEmptyAmount = [];
      // 날짜별 옵션: 식사 및 패키지 확인
      for (const pkg of item.meal) {
        hasEmptyAmount.push(pkg.amount === 0);
      }
      hasEmptyItem.push(
        // item.checkIn === '' ||
        //   item.checkOut === '' ||
        item.people === 0 ||
          // item.spaceArrangementId === null ||
          // item.meal.length === 0 ||
          hasEmptyAmount.includes(true)
      );
    }
    return hasEmptyItem.length === 0 || hasEmptyItem.includes(true);
  };

  // @use: 로그인 체크
  const getException = () => {
    const exception = reqException();
    if (loggedIn) {
      setOnModal(1);
    } else {
      return setAlert({ text: '로그인이 필요한 서비스 입니다.' });
    }
    if (!checkedModal1) {
      return setCheckedModal1(true);
    }
    if (!exception) {
      setOnModal(2);
      return;
    }
  };

  /* @use: 옵션 데이터 변경 */
  const modifyData = (idx, key, value) => {
    key === 'meal'
      ? setTempData((dt) => {
          // 패키지 옵션 중복 확인
          const hasHotelMealIdIndex = dt[idx].meal?.findIndex(
            (meal) => meal?.hotelMealId === value?.hotelMealId
          );
          if (hasHotelMealIdIndex >= 0) {
            setAlert({ text: '이미 등록된 패키지 입니다' });
            return dt;
          }

          const mealArr = [...dt[idx].meal];
          mealArr.push(value);

          return dt.map((dt, _idx) => (_idx === idx ? { ...dt, [key]: mealArr } : dt));
        })
      : key === 'room'
      ? setTempData((dt) => {
          // 객실 옵션 중복 확인
          const hasHotelRoomIdIndex = dt[idx].room?.findIndex((room) => room.roomId === value.roomId);

          if (hasHotelRoomIdIndex >= 0) {
            setAlert({ text: '이미 등록된 객실 입니다' });
            return dt;
          }

          const roomArr = [...dt[idx].room];
          roomArr.push(value);

          return dt.map((dt, _idx) => (_idx === idx ? { ...dt, [key]: roomArr } : dt));
        })
      : setTempData((dt) => dt.map((dt, _idx) => (_idx === idx ? { ...dt, [key]: value } : dt)));
  };

  /* @use: 예약자 정보 SUBMIT -> 취소 약관 */
  const onSubmit = ({ username, phone, email }) => {
    const infoObj = { ...bookPersonInfo };
    infoObj.reservationEmail = email;
    infoObj.reservationName = username;
    infoObj.reservationPhone = phone;
    setBookPersonInfo(infoObj);
    return setOnModal(3);
  };

  /* @use: 옵션 모달 전체 OFF */
  const closeAllModal = () => {
    setInfoModal(new Array(5).fill(0).map((_) => -1));
  };

  /* @use: 옵션 모달 ON */
  const selecctModal = (modalIdx, num) => {
    setInfoModal(new Array(5).fill(0).map((_, idx) => (idx === modalIdx ? num : -1)));
  };

  /* @use: 옵션 데이터 초기 생성 */
  useEffect(() => {
    if (stringPeriod.length > 0) {
      const data = stringPeriod.map((date) => {
        return {
          reservationDate: moment(date).format('yyyy-MM-DD'),
          checkIn: '00:00',
          checkOut: '23:00',
          meal: [],
          people: spaceDetails?.minPeople,
          spaceArrangementId: null,
          room: [],
        };
      });

      setTempData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringPeriod]);

  /* @use: 총 비용 처리 */
  useEffect(() => {
    const result = priceArr.length > 0 && priceArr.reduce((acc, cur) => acc + cur);

    setTotalPrice(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceArr]);

  /* @use: 옵션 선택 처리: tempData 와 실데이터 분리 필요성: 확인 안 누르고 창 닫았을 경우 고려 */
  useEffect(() => {
    setReqDetail(tempData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempData]);

  return (
    <>
      {/* 모바일 OVERLAY */}
      {onModal <= 2 && (
        <St.Modal.Overlay
          isHide={!hidden}
          onClick={() => {
            setOnModal(0);
            setTempData([]);
            resetStringPeriod();
            setHidden(true);
            closeAllModal();
          }}
        />
      )}
      <St.Modal.Wrapper>
        <St.Modal.SelectionBox isHide={hidden}>
          {/* @role: 전체 모달 상단 (mobile: 스와이프 버튼) */}
          {stringPeriod.length > 0 ? (
            <Top.Prev>
              <button
                onClick={() => {
                  if (onModal < 2) {
                    setOnModal(0);
                    setTempData([]);
                    resetStringPeriod();
                  } else {
                    setOnModal((prev) => prev - 1);
                  }
                }}
              >
                <img src={arrowLeftIcon} alt="arrow-img" />
              </button>
            </Top.Prev>
          ) : (
            <Top.Base active={!hidden} onClick={() => setHidden((h) => !h)} {...swipeHandler} />
          )}
          {onModal < 2 && (
            <MainDiv isScroll={onModal > 1} isHide={hidden}>
              {/* component: 날짜 선택 box(예약 페이지 not selectBox)  */}
              <SelectDateDiv onClick={() => setOnScheduleModal((prev) => !prev)}>
                <Text
                  _size={isPeriod && 'l'}
                  _weight="normal"
                  _color={!isPeriod && 'purple300'}
                  _decoration={!isPeriod && 'underline'}
                  _space="nowrap"
                >
                  {!isPeriod
                    ? `날짜를 선택해 주세요.`
                    : stringPeriod.length === 1
                    ? stringPeriod
                    : `${String(stringPeriod[0])} - ${String(stringPeriod[stringPeriod.length - 1])}`}
                </Text>
                <img className="calendar-icon" src={calendarIcon} alt="calendar-img" />
              </SelectDateDiv>
              {/* component: 날짜 별 옵션 선택 box  */}
              {onModal === 1 && stringPeriod.length > 0 && (
                <OptionDiv>
                  <FlexCols _gap={12}>
                    {stringPeriod?.map((item, index) => {
                      const date = item.split('. ').join('-');
                      return (
                        <HiddenInfo key={index} title={item} _hidden={true}>
                          <HideBox
                            date={date}
                            tempData={tempData[index]}
                            setTempData={setTempData}
                            index={index}
                            setInfoModal={selecctModal}
                            hasRooms={hasRooms}
                          />
                        </HiddenInfo>
                      );
                    })}
                  </FlexCols>
                </OptionDiv>
              )}

              {/* selectBox footer  */}
              <FlexCols _gap={40} _width="100%" _justify="center" _media="tabletSm" _m_gap={24}>
                <CostWrapper _gap={8}>
                  <Flex _width="100%" _justify="space-between">
                    <Text _size="l" _weight="medium" _color="gray900">
                      총 (예상)
                    </Text>
                    <Text _size="xxxxl" _weight="bold" _color="gray900">
                      ₩ {convertNumber(totalPrice)}
                    </Text>
                  </Flex>
                  <Text _size="xxs" _color="gray600">
                    *예약 요청을 위한 예상 가격으로 실제 가격과 다를 수 있습니다.
                  </Text>
                </CostWrapper>
                <RoundBtn
                  _width="100%"
                  text="예약 요청하기"
                  onClick={getException}
                  _primary
                  _capsule
                  disabled={onModal === 0 ? !isPeriod : onModal === 1 ? reqException() : true}
                />
              </FlexCols>
            </MainDiv>
          )}
          {/* 예약자 정보 입력 */}
          {onModal > 1 && (
            <FlexCols _gap={40} as="form" onSubmit={handleSubmit(onSubmit)}>
              <Text _size="xxxxl" _weight="bold">
                예약자 정보
              </Text>
              <St.Personal.ScrollSection>
                <div>
                  <ul>
                    <li>&#183; 예약자 본인의 정보를 입력해 주세요.</li>
                    <li>
                      &#183; 해당 정보는 예약 관련 중요사항을 예약자님께 전송해 예약 진행을 하실 수 있도록
                      하기 위한 용도로만 사용됩니다.
                    </li>
                    <li>&#183; 예약 정보 수신을 위해 정확한 정보를 기입해 주시기 바랍니다.</li>
                  </ul>
                  <FlexCols _width="100%" _gap={40}>
                    <FlexCols _width="100%" _gap={10}>
                      <FlexCols _width="100%" _gap={6}>
                        <InputField
                          defaultValue={userInfo.nickname}
                          name="username"
                          type="text"
                          placeholder="이름 (예약자 명)"
                          register={register}
                          registerProps={{
                            required: ERROR_MSG.username,
                            minLength: 2,
                          }}
                          // value={'ddddddddddddd'}
                          error={!!errors.username}
                          errorMsg={!!errors.username && ERROR_MSG.username}
                        />
                        <InputField
                          name="phone"
                          id="phone"
                          placeholder="연락처 (휴대폰 번호)"
                          type="text"
                          error={!!errors.phone}
                          errorMsg={!!errors.phone && ERROR_MSG.phone}
                          register={register}
                          registerProps={{
                            required: ERROR_MSG.phone,
                            pattern: TEL_PATTERN,
                          }}
                        />
                        <InputField
                          defaultValue={userInfo.email}
                          name="email"
                          id="email"
                          placeholder="이메일"
                          type="text"
                          error={Object.values(errors).length > 0}
                          errorMsg={Object.values(errors).length > 0 && ERROR_MSG.all}
                          register={register}
                          registerProps={{
                            required: ERROR_MSG.email,
                            pattern: EMAIL_PATTERN,
                          }}
                        />
                      </FlexCols>
                      <CheckBox
                        name="agree"
                        id="agree"
                        label="(필수) 개인정보 수집 및 이용 동의"
                        value="동의"
                        type="square"
                        register={register}
                        registerProps={{ required: ERROR_MSG.agree }}
                      />
                    </FlexCols>
                  </FlexCols>
                </div>
              </St.Personal.ScrollSection>
              <Flex _width="100%" _justify="center" _direction="row-reverse">
                <RoundBtn
                  type="submit"
                  text="예약 요청하기"
                  _primary
                  _capsule
                  disabled={Object.values(errors).length > 0}
                />
                <St.Personal.PrevBtn onClick={() => setOnModal((prev) => prev - 1)}>
                  <img src={arrow} alt="arrow-left" />
                  <span>이전</span>
                </St.Personal.PrevBtn>
              </Flex>
            </FlexCols>
          )}
          {/* 날짜 선택 modal */}
          {onScheduleModal && <SelectScheduleModal closeModal={() => setOnScheduleModal(false)} />}
          {/* 체크인, 아웃 시간 선택 modal */}
          {infoModal[0] >= 0 && (
            <SelectTimeModal
              closeModal={() => setInfoModal(0)}
              setTime={(selectTime) => modifyData(infoModal[0], 'checkIn', selectTime)}
              title="체크인 시간"
            />
          )}
          {infoModal[1] >= 0 && (
            <SelectTimeModal
              closeModal={() => setInfoModal(0)}
              setTime={(selectTime) => modifyData(infoModal[1], 'checkOut', selectTime)}
              title="체크아웃 시간"
            />
          )}
          {/* 식사 패키지 선택 modal */}
          {infoModal[2] >= 0 && spaceDetails?.meal[0] && (
            <SelectPackageModal
              setMeal={(obj) => modifyData(infoModal[2], 'meal', obj)}
              closeModal={() => setInfoModal(0)}
            />
          )}
          {/* 테이블 타입 선택 modal */}
          {infoModal[3] >= 0 && (
            <SelectTableTypeModal
              setArrangementId={(id) => modifyData(infoModal[3], 'spaceArrangementId', id)}
              closeModal={() => setInfoModal(0)}
            />
          )}
          {/* 객실 선택 modal */}
          {infoModal[4] >= 0 && (
            <SelectRoomModal
              setRoom={(obj) => {
                modifyData(infoModal[4], 'room', obj);
              }}
              closeModal={() => setInfoModal(0)}
            />
          )}
          {/* 규정 확인 modal */}
          {onModal === 3 && (
            <RuleInfoModal
              setOnBookQuery={setOnBookQuery}
              isRuleCheck={() => {
                setOnBookQuery(true);
              }}
              closeModal={() => setOnModal(2)}
            />
          )}
        </St.Modal.SelectionBox>
      </St.Modal.Wrapper>
      {/* 날짜 선택 */}
    </>
  );
};

export default SelectBox;
