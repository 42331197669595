import React, { useState, useEffect } from 'react';
import * as D from '../../Common/Display';
import styled from 'styled-components';
import { Line } from '../../Common/Line';
import { Text } from '../../Common/Text';
import checkedIcon from '../../../assets/icons/utils/ico-checked_white.svg';
import { useSetRecoilState } from 'recoil';
import { useUser } from '../../../store/user';
import { customAlert } from '../../../store/modal';
import { useNavigate } from 'react-router-dom';
import { SITE } from '../../../constants/site';
import { EMAIL_PATTERN } from '../../../constants/pattern';
import { useAddInquiryData } from '../../../hooks/queries/service';

const S = {
  Submit: styled.button.attrs({
    type: 'submit',
  })`
    padding: 0 24px;
    height: 48px;
    line-height: 48px;
    border-radius: 30px;
    color: #fff;
    background-color: ${(props) => props.theme.colors.purple1};
    width: max-content;
    font-size: ${(props) => props.theme.fontSize.l};
  `,
  Field: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    width: 100%;

    label {
      font-weight: ${(props) => props.theme.fontWeight.bold};
    }

    input[type='text'],
    textarea {
      width: 100%;
      height: 48px;
      padding: 14px;
      border: 1px solid ${(props) => props.theme.colors.gray300};
      border-radius: 10px;
      font-size: ${(props) => props.theme.fontSize.l};
    }

    textarea {
      height: 400px;
      resize: none;
    }
  `,
  CheckField: styled.div`
    position: relative;
    width: 100%;

    input[type='checkbox'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    label {
      display: grid;
      grid-template-columns: 18px auto;
      gap: 12px;
      font-size: ${(props) => props.theme.fontSize.s};
      line-height: 18px;

      &::before {
        content: '';
        width: 18px;
        height: 18px;
        border: 1px solid ${(props) => props.theme.colors.gray300};
        border-radius: 1px;
        background: #fff;
        transform: translateY(-1px);
      }
    }

    input:checked + label::before {
      border-color: ${(props) => props.theme.colors.primary700};
      background: url(${checkedIcon}) center no-repeat ${(props) => props.theme.colors.purple1};
    }
  `,
};

/**
 * 일대일 문의 컴포넌트
 */
export default function OneToOneForm() {
  const { mutate: submitForm, isSuccess, error } = useAddInquiryData();
  const { loggedIn, email, nickname } = useUser();
  const setAlert = useSetRecoilState(customAlert);
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [info, setInfo] = useState({
    userName: loggedIn ? nickname : '',
    email: loggedIn ? email : '',
    title: '',
    contents: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const k in info) {
      if (info[k].length <= 0) {
        setAlert({ text: '필수 사항을 모두 입력해 주세요.' });
        return;
      }
    }

    if (!EMAIL_PATTERN.test(info.email)) {
      setAlert({ text: '이메일 주소를 정확히 입력해 주세요.' });
      return;
    }

    if (!agree) {
      setAlert({
        text: '요청하신 답변을 위해 \n 개인정보 수집 및 이용에 동의 해 주세요.',
      });
      return;
    }

    submitForm(info);
  };

  useEffect(() => {
    if (isSuccess) {
      setAlert({
        text: '일대일 문의가 접수되었습니다. \n 답변은 입력하신 이메일로 발송됩니다.',
        handleClose: () => {
          navigate(SITE.home);
        },
      });
    }
    if (error) {
      setAlert({
        text: '문의 등록 중 오류가 발생했습니다. \n 다시 시도해 주세요.',
        handleClose: () => {
          navigate(SITE.home);
        },
      });
    }
  }, [isSuccess, error]);

  return (
    <D.Container as="section" _mx_width="1120px" _padding="100px 20px 130px">
      <D.FlexCols _width="100%" _gap={40}>
        <D.FlexCols _width="100%" _gap={8}>
          <Text as="h3" _weight="bold" _size="xxxxl" _media="tabletSm" _m_size="xxl">
            1:1 문의
          </Text>
          <Text as="p" _size="s" _color="gray800">
            해당 문의사항 답변은 입력하신 이메일 주소로 발송되므로 정확한 정보를 입력해 주세요.
          </Text>
          <Line _height={2} _m_top={12} _color="purple1" />
        </D.FlexCols>
        <D.FlexCols _width="100%" _gap={60} _justify="center" _items="center" as="form" onSubmit={handleSubmit}>
          <D.FlexCols _width="100%" _gap={32}>
            <D.Flex
              _direction="row"
              _width="100%"
              _justify="space-between"
              _gap={20}
              _wrap="wrap"
              _media="tabletSm"
              _m_direction="column"
              _m_gap={32}>
              <S.Field>
                <label htmlFor="name">이름 (필수)</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={info.userName}
                  required
                  onChange={(e) => {
                    setInfo((prevState) => ({
                      ...prevState,
                      userName: e.target.value,
                    }));
                  }}
                />
              </S.Field>
              <S.Field>
                <label htmlFor="mail">이메일 (필수)</label>
                <input
                  type="text"
                  id="mail"
                  name="mail"
                  required
                  value={info.email}
                  onChange={(e) => {
                    setInfo((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                />
              </S.Field>
            </D.Flex>
            <S.Field>
              <label htmlFor="title">문의 제목 (필수)</label>
              <input
                type="text"
                id="title"
                name="title"
                required
                onChange={(e) => {
                  setInfo((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }));
                }}
              />
            </S.Field>
            <S.Field>
              <label htmlFor="contents">문의 내용 (필수)</label>
              <textarea
                type="text"
                id="contents"
                name="contents"
                required
                onChange={(e) => {
                  setInfo((prevState) => ({
                    ...prevState,
                    contents: e.target.value,
                  }));
                }}
              />
            </S.Field>
            <S.CheckField>
              <input
                type="checkbox"
                id="agree"
                name="agree"
                checked={agree}
                onChange={() => {
                  setAgree(!agree);
                }}
              />
              <label htmlFor="agree">
                문의하기 시 요청하신 관련 답변을 위해 개인정보(이름, 이메일)를 수집 및 이용하는데 동의하십니까?
              </label>
            </S.CheckField>
          </D.FlexCols>
          <S.Submit type="submit">문의 하기</S.Submit>
        </D.FlexCols>
      </D.FlexCols>
    </D.Container>
  );
}
