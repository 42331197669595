// STORE 키 목록
export const STORE_KEYS = {
  // ui
  footerUi: 'ui_footer',
  headerUi: 'ui_header',
  subHeaderUi: 'ui_sub_header',
  floatingChatUi: 'ui_floating_chat',
  // user
  user: 'data_user',
  userId: 'data_id',
  userLoc: 'data_loc',
  // modal
  alertModal: 'modal_alert',
  confirmModal: 'modal_confirm',
  applyModal: 'modal_apply',
  applySelectModal: 'modal_applySelect',
  reviewModal: 'modal_reivew',
  reportModal: 'modal_report',
  picViewrModal: 'modal_pic_viewer',
};

// 계정 키 목록
export const ACCOUNT_KEYS = {
  account: 'user_logged-in',
};

// 검색 키 목록
export const SEARCH_KEYS = {
  // 검색할 단어(지역/업체 이름)
  searchTerm: 'search-term_state',
  // 검색 요청 기간
  period: 'period_state',
  searchPeriod: 'search-period_state',
  searchDate: 'search-date_state',
  bookPeriod: 'book-period_state',
  bookDate: 'book-date_state',
  // 상세 조건
  conditions: 'conditions_state',
};

// modal on/off 키 목록
export const MODAL_KEYS = {
  aliemModal: 'aliem-modal_state',

  loginModal: 'login-modal_state',
  findIdModal: 'findId-modal_state',
  findPwModal: 'findPw-modal_state',
  joinModal: 'join-modal_state',

  searchModal: 'search-modal_state',
  scheduleModal: 'schedule-modal_state',
  conditionsModal: 'conditions-modal_state',
  asideModal: 'aside-modal_state',
};

// 의뢰 키 목록
export const REQUEST_KEY = {
  categoryType: 'category-type_state',
  requestPeriod: 'request-period_state',
  searchLocation: 'search-location_state',
  options: {
    maxPrice: 'max-price_state',
    minPrice: 'min-price_state',
    isUserSelect: 'is-user-select_state',
    hotelOption: 'hotel-option_state',
    userOption: 'user-option_state',
    defaultUserOption: 'default-user-option_state',
    defaultHotelOption: 'default-hotel-option_state',
  },
  spaceRequest: 'space-request_state',
  moreInfo: 'more-info_state',
};

// 공간예약 키 목록
export const BOOK_KEYS = {
  onModalHiddenState: 'on-book-modal-hidden_state',
  onModalState: 'on-book-modal_state',
  onSpaceBookQuery: 'on-space-book-query_state',
  spaceBookTotalPrice: 'space-book-total-price_state',
  spaceBookPriceArr: 'space-book-price-arr_state',
  spaceBookArrange: 'space-book-arrange_state',
  spaceBookMeal: 'space-book-meal_state',
  spaceBookPeriod: 'space-book-period_state',
  spaceBookPersonInfo: 'space-book-person--info_state',
  spaceBookDetails: 'space-book-details_state',
  requestSpaceBook: 'request-space-book_state',
  dateToString: 'dates-to-string_state',
};

// 채팅 키 목록
export const CHAT_KEYS = {
  hotelName: 'chat-hotelName_state'
}

// 공간 키 목록
export const SPACE_KEYS = {
  spaceDetails: 'space-details_state',
};
