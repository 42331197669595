import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import marker from '../../assets/icons/utils/ico-search_location.svg';
import people from '../../assets/people.svg';
import { spaceBook, spaceDetails } from '../../constants/site';
import { SpaceBookmark } from '../Common/BookMark';
import * as D from '../../components/Common/Display';
import { Badge, ColorBadge } from '../../components/Common/Badge';

const ClientWrapper = styled.li`
  position: relative;
  width: 280px;
  cursor: pointer;
  display: block;

  .img-box {
    position: relative;
    width: 100%;
    height: ${(props) => props._img_height ?? 180}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 16px;
    padding: 0;
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.gray100};

    img {
      min-width: 100%;
      min-height: 100%;
    }

    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 32px;
      height: 32px;
    }

    span {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  ${(props) =>
    props._blur &&
    css`
      opacity: 0.5;
    `}
  ${(props) =>
    props._shadow &&
    css`
      border-radius: 20px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

      > div:nth-child(2) {
        padding: 0 20px 16px;
      }
    `}
`;
const ClientRow = styled.div`
  display: flex;
  flex-direction: column;
`;
const Location = styled.div`
  // left-right padding
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
  img {
    width: 16px;
    height: 16px;
  }
  span {
    font-size: ${(props) => props.theme.fontSize.xs};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.gray600};
  }
`;

// + 업체 이름
const Name = styled.span`
  // left-right padding
  font-size: ${(props) => props.theme.fontSize.xl};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray900};
  width: 100%;
  height: 26px;
  line-height: 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MaxPeople = styled.span`
  // left-right padding
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 18px;
    height: 18px;
  }
  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.mainPurple};
`;
const PriceWrapper = styled.div`
  // left-right padding  + tagbox
  margin: 8px 0;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  gap: 4px;
`;
const Price = styled.span`
  font-size: ${(props) => props.theme.fontSize.xl};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray900};
`;
const Time = styled.span`
  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray500};
`;

const UserInfo = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray900};
`;
const Username = styled.span``;
const GradeInfo = styled.div``;

const Star = styled.span`
  color: #f7d046;
  margin-right: 4px;
`;
const Score = styled.span``;

const Contents = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 8px 0;
  height: 40px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  font-size: ${(props) => props.theme.fontSize.s};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.gray800};
`;

/**
 * 홈 > 목록 카드 컴포넌트
 */
function ClientBox({
  type = 'space',
  bookmarkMode = true,
  thumbnail,
  bookmark,
  addressLocation,
  location,
  hotelName,
  spaceName,
  place,
  priceTime,
  price,
  maxPeople,
  username,
  content,
  grades,
  spaceId,
  hotelId,
  category,
  handleClick,
  introduction,
  as,
  wish, // 마이페이지 위시리스트 -> 항상 좋아요 icon 색상 들어와야하기위함
  _img_height,
  _shadow,
  _blur,
  badge,
}) {
  return (
    <ClientWrapper
      as={as ?? Link}
      {...(!handleClick && {
        to: type === 'space' ? spaceBook(spaceId) : spaceDetails(hotelId),
        target: '_blank',
      })}
      {...(handleClick !== null && {
        onClick: () => {
          handleClick();
        },
      })}
      _img_height={_img_height}
      _shadow={_shadow}
      _blur={_blur}>
      <div className="img-box">
        <img className="img" src={`https://cdn.alabwaba.com${thumbnail}`} />
        {bookmarkMode && (
          <SpaceBookmark
            type={hotelId ? 'hotel' : 'space'}
            styleType="icon"
            id={hotelId ? hotelId : spaceId}
            like={bookmark}
            wish={wish}
          />
        )}
        {badge && <ColorBadge text={badge.text} _color={badge.color} />}
      </div>
      <ClientRow>
        {(addressLocation || location) && (
          <Location>
            <img src={marker} alt="marker-img" />
            <span>{addressLocation || location}</span>
          </Location>
        )}
        <Name>
          {hotelName} {type === 'space' && `- ${spaceName}`}
        </Name>
        {maxPeople && (
          <MaxPeople>
            <img src={people} alt="people-img" />
            <span>최대 {maxPeople}인</span>
          </MaxPeople>
        )}
        {(priceTime || price) && (
          <PriceWrapper>
            {price ? (
              <Price>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Price>
            ) : (
              <Price>{priceTime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Price>
            )}
            <Time>/ 시간</Time>
          </PriceWrapper>
        )}
        {username && grades && (
          <UserInfo>
            <Username>{username}</Username>
            <GradeInfo>
              <Star>{grades.stars}</Star>
              <Score>{grades.score}</Score>
            </GradeInfo>
          </UserInfo>
        )}
        {content && <Contents>{content}</Contents>}
        {introduction && <Contents>{introduction}</Contents>}
        {category && (
          <D.FlexRows _gap={8}>
            {category.slice(0, 3).map((ctg, idx) => (
              <Badge key={idx} text={ctg} _color="pink600" />
            ))}
          </D.FlexRows>
        )}
      </ClientRow>
    </ClientWrapper>
  );
}

export default ClientBox;
