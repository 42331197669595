import React from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { cdnUrl } from "../../../constants/url";
import { picViewerModal } from "../../../store/modal";

const S = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
  `,
  Pics: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 100%;
    overflow: hidden;
    border-radius: 50px;

    @media (${(props) => props.theme.media.tabletSm}) {
      border-radius: 0;
      grid-template-columns: 1fr;
    }
  `,
  Pic: styled.div`
    position: relative;
    width: 100%;
    padding-top: 100%;
    cursor: ${({ cursor }) => (cursor ? cursor : "inherit")};
    overflow: hidden;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.colors.gray50};
      overflow: hidden;

      > img {
        height: 100%;
      }
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      padding-top: 0;
      height: 68vw;
      max-height: 520px;
    }
  `,
  SubPics: styled.div`
    display: grid;
    grid-template-areas: "a b" "c d";
    gap: 8px;

    @media (${(props) => props.theme.media.tabletSm}) {
      display: none;
    }
  `,
  AllBtn: styled.button`
    position: absolute;
    bottom: 24px;
    right: 25px;
    height: 38px;
    line-height: 38px;
    padding: 0 16px;
    border-radius: 19px;
    font-size: ${(props) => props.theme.fontSize.xxs};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    border: 1px solid ${(props) => props.theme.colors.gray300};
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.gray900};
  `,
};

/**
 * 그리드 형식 이미지 노출 컴포넌트
 * @description 기존에 공간 상세에서 사용했던 컴포넌트입니다.
 */
export default function GridPics({ pics, allMode = true, handleThumbClick }) {
  const setViewer = useSetRecoilState(picViewerModal);

  return (
    <S.Wrapper>
      <S.Pics>
        <S.Pic
          {...(handleThumbClick && {
            onClick: handleThumbClick,
            cursor: "pointer",
          })}>
          <div>{pics.length > 0 && <img src={cdnUrl(pics[0], "h=600&q=70")} />}</div>
        </S.Pic>
        <S.SubPics>
          {pics.slice(1, 5).map((sub, idx) => (
            <S.Pic key={idx}>
              <div>{idx < pics.length && <img src={cdnUrl(sub, "h=300&q=70")} alt={sub.description} />}</div>
            </S.Pic>
          ))}
          {pics.slice(1, 5).length < 4 &&
            new Array(4 - pics.slice(1).length).fill(0).map((_, idx) => (
              <S.Pic key={idx}>
                <div />
              </S.Pic>
            ))}
        </S.SubPics>
      </S.Pics>
      {allMode && (
        <S.AllBtn
          onClick={() => {
            setViewer({
              mode: true,
              srcs: pics,
            });
          }}>
          사진 모두 보기
        </S.AllBtn>
      )}
    </S.Wrapper>
  );
}
