import React, { useEffect } from 'react';
import * as D from '../Common/Display';
import { Text } from '../Common/Text';
import { Line } from '../Common/Line';
import styled from 'styled-components';
import InfoList from '../Common/InfoList';
import DetailsMap from '../Map/DetailsMap';
import { cdnUrl } from '../../constants/url';

const styleProps = {
  wrap: {
    _width: '100%',
    _gap: 24,
    _justify: 'flex-start',
    _items: 'flex-start',
    _media: 'tabletSm',
    _m_gap: 16,
  },
  title: {
    _weight: 'medium',
    _line: 'xxs',
    _media: 'tabletSm',
    _size: 'xxl',
  },
};

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    width: 100%;

    @media (${(props) => props.theme.media.tabletSm}) {
      padding: 0 20px;
    }
  `,
  Map: styled.div`
    width: 100%;
    height: 500px;
    border-radius: 50px;
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.gray50};

    @media (${(props) => props.theme.media.tabletSm}) {
      border-radius: 0;
      height: 300px;
    }
  `,
  IconList: styled.ul`
    display: flex;
    gap: 16px 0px;
    padding: 0 90px;
    flex-wrap: wrap;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      width: 25%;
      height: 50px;
      gap: 4px;
      font-size: ${(props) => props.theme.fontSize.l};
      color: ${(props) => props.theme.colors.gray800};

      > img {
        height: 50px;
      }
    }

    @media (${(props) => props.theme.media.tablet}) {
      padding: 0 45px;
    }
    @media (${(props) => props.theme.media.tabletSm}) {
      padding: 0;
      gap: 8px 0;
      li {
        width: 50%;
        height: 40px;
        font-size: ${(props) => props.theme.fontSize.s};

        > img {
          height: 40px;
        }
      }
    }
  `,
  DiscList: styled.ul`
    li {
      position: relative;
      padding-left: 24px;

      &::before {
        content: '';
        position: absolute;
        top: 9px;
        left: 9px;
        width: 5px;
        height: 5px;
        border-radius: 3px;
        background-color: ${(props) => props.theme.colors.gray900};
      }

      @media (${(props) => props.theme.media.tabletSm}) {
        font-size: ${(props) => props.theme.fontSize.s};
      }
    }
  `,
};

/**
 * 공간 정보 컴포넌트
 * @description 공간 상세 페이지에서 사용하는 컴포넌트입니다.
 */
export default function SpaceInfo({ latlng, introduction, faq, address, facilities, free, direction }) {
  return (
    <S.Wrapper>
      <D.FlexCols {...styleProps.wrap}>
        <Text {...styleProps.title}>소개</Text>
        <Text _size="l" _media="tabletSm" _m_size="base" _weight="light">
          {introduction}
        </Text>
      </D.FlexCols>
      {facilities && facilities.length > 0 && (
        <React.Fragment>
          <Line _color="gray300" />
          <D.FlexCols {...styleProps.wrap}>
            <Text {...styleProps.title}>편의시설​</Text>
            <S.IconList>
              {facilities.map((item, idx) => (
                <li key={idx}>
                  <img src={cdnUrl(item.icon)} /> {item.name}
                </li>
              ))}
            </S.IconList>
          </D.FlexCols>
        </React.Fragment>
      )}
      {free && free.length > 0 && (
        <React.Fragment>
          <Line _color="gray300" />

          <D.FlexCols {...styleProps.wrap}>
            <Text {...styleProps.title}>무료제공​</Text>
            <S.IconList>
              {free.map((item, idx) => (
                <li key={idx}>
                  <img src={cdnUrl(item.icon)} /> {item.name}
                </li>
              ))}
            </S.IconList>
          </D.FlexCols>
        </React.Fragment>
      )}
      <Line _color="gray300" />
      <D.FlexCols _width="100%" _gap={100}>
        {faq && faq.length > 0 && (
          <D.FlexCols {...styleProps.wrap}>
            <Text {...styleProps.title}>FAQ</Text>
            <InfoList
              data={faq.map((item) => ({
                title: `Q. ${item.question}`,
                contents: `A. ${item.answer}`,
              }))}
            />
          </D.FlexCols>
        )}
        <D.FlexCols _gap={16} _width="100%" _media="tablet" _m_gap={40}>
          <Text {...styleProps.title}>위치</Text>
          <Text _size="xl" _weight="medium" _media="tabletSm" _m_size="s">
            {address}
          </Text>
          <S.Map>
            <DetailsMap latlng={latlng} />
          </S.Map>
          <D.FlexCols _gap={8} _width="100%">
            <Text _size="xl" _weight="medium">
              오시는 길
            </Text>
            <S.DiscList>
              <li>{direction}</li>
            </S.DiscList>
          </D.FlexCols>
        </D.FlexCols>
        <Line _color="gray300" />
      </D.FlexCols>
    </S.Wrapper>
  );
}
