import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import backIcon from '../../../assets/icons/utils/ico-arrow_left.svg';
import heartLineIcon from '../../../assets/icons/utils/ico-heart_line.svg';
import heartBgIcon from '../../../assets/icons/utils/ico-heart_bg.svg';
import downIcon from '../../../assets/icons/utils/ico-arrow_btm.svg';

const StyledButton = styled.button`
  flex: 1;
  width: 100%;
  max-width: ${(props) => props._width ?? '100%'};
  height: ${(props) => props._height ?? '48px'};
  border-radius: 24px;
  text-align: center;
  line-height: 48px;
  color: ${(props) => props.theme.colors.gray900};
  /* background-color: ${(props) => (props.primary ? '#a279d3' : '#FFF')}; */
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  font-size: ${(props) => props.theme.fontSize.l};
  font-weight: ${(props) => props.theme.fontWeight.bold};

  ${(props) =>
    props._primary &&
    css`
      background-color: ${(props) => props.theme.colors.mainPurple};
      color: #fff;
      border-color: ${(props) => props.theme.colors.purple1};
    `}

  ${(props) =>
    props._dark &&
    css`
      background-color: ${(props) => props.theme.colors.gray800};
      color: #fff;
      border-color: ${(props) => props.theme.colors.gray800};
    `}

  ${(props) =>
    props._gray &&
    css`
      background-color: ${(props) => props.theme.colors.gray200};
      border-color: ${(props) => props.theme.colors.gray300};
      color: ${(props) => props.theme.colors.gray500};
      font-weight: ${(props) => props.theme.fontWeight.normal};
    `}

  &:disabled {
    background-color: ${(props) => props.theme.colors.gray300};
    border-color: ${(props) => props.theme.colors.gray300};
    color: ${(props) => props.theme.colors.white};
  }
`;

const S = {
  RoundedBtn: styled(StyledButton)`
    border-radius: ${(props) => (props.capsule ? 24 : 8)}px;
  `,
  BackBtn: styled.button.attrs({ type: 'button' })`
    width: max-content;
    padding: 0 32px 0 48px;
    height: 48px;
    line-height: 48px;
    margin: 0 auto;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 24px;
    color: ${(props) => props.theme.colors.gray800};
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    background: url(${backIcon}) 30px center no-repeat;

    @media (${(props) => props.theme.media.tabletSm}) {
      height: 42px;
      line-height: 42px;
      padding: 0 20px 0 36px;
      font-size: ${(props) => props.theme.fontSize.xxs};
      background-position: 20px center;
      background-size: 8px 12px;
    }
  `,
  LikeBtn: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props._width ?? '32px'};
    height: ${(props) => props._width ?? '32px'};
  `,
  TabBtn: styled.button`
    padding: 8px 16px;
    flex: 1;
    height: 100%;
    border-radius: 10px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};

    ${(props) =>
      props.current
        ? `background: ${props.theme.colors.purple50};
      border: 2px solid ${props.theme.colors.mainPurple};
      color: ${props.theme.colors.mainPurple};`
        : `background: ${props.theme.colors.gray50};
      color: ${props.theme.colors.gray800};`}
  `,
  MoreBtn: styled.button`
    display: flex;
    gap: 4px;
    align-items: center;
    width: max-content;
    height: 38px;
    padding: 10px 16px;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 20px;
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      background: url(${downIcon}) center no-repeat;
    }
  `,
};

export function RoundBtn({ type, text, onClick, _width, _height, _primary, _dark, _gray, _capsule, disabled }) {
  return (
    <S.RoundedBtn
      capsule={_capsule}
      _primary={_primary}
      _width={_width}
      _height={_height}
      _dark={_dark}
      _gray={_gray}
      type={type ?? 'button'}
      {...(onClick && {
        onClick: (e) => {
          e.preventDefault();
          onClick();
        },
      })}
      disabled={disabled ? true : false}>
      {text}
    </S.RoundedBtn>
  );
}

export function BackBtn({ text }) {
  const navigate = useNavigate();
  return (
    <S.BackBtn
      onClick={() => {
        navigate(-1);
      }}>
      {text}
    </S.BackBtn>
  );
}

export function LikeBtn({ roomId, like, userId, _width }) {
  return (
    <S.LikeBtn _width={_width}>
      <img src={like ? heartBgIcon : heartLineIcon} alt="좋아요" />
    </S.LikeBtn>
  );
}

export function TabBtn({ current, text, handleClick }) {
  return (
    <S.TabBtn
      current={current}
      onClick={() => {
        handleClick();
      }}>
      {text}
    </S.TabBtn>
  );
}

export function MoreBtn({ handleClick, text = '더 보기' }) {
  return (
    <S.MoreBtn
      type="button"
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}>
      {text}
    </S.MoreBtn>
  );
}
