import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import * as D from '../../Common/Display';
import styled, { css } from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useRulesQuery } from '../../../hooks/queries/service';
import { useScrollUp } from '../../../hooks/ui/scroll';
import { isMobile } from 'react-device-detect';

const S = {
  Tabs: styled.div`
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    ${D.xScrollBoxCss(2)}
    ${isMobile && D.NonScrollBarCss}

    > div {
      display: flex;
    }
  `,
  Tab: styled.button`
    padding: 16px;
    background-color: #fff;
    border: 0;
    color: ${(props) => props.theme.colors[props.active ? 'gray900' : 'gray400']};
    font-weight: ${(props) => props.theme.fontWeight.medium};

    ${(props) =>
      props.active &&
      css`
        z-index: 20;
        position: relative;
        overflow: visible;

        &::after {
          z-index: 21;
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: ${props.theme.colors.gray900};
        }
      `}
  `,
  Contents: styled.p`
    width: 100%;
    height: auto;
    color: ${(props) => props.theme.colors.gray800};
    font-size: ${(props) => props.theme.fontSize.s};
    white-space: pre-wrap;

    @media (${(props) => props.theme.media.tabletSm}) {
      padding: 0 20px;
    }
  `,
};

const RULES = [
  { text: '서비스 이용약관', type: 'TERMS' },
  { text: '개인정보처리방침', type: 'PRIVACY' },
  { text: '위치기반서비스 이용약관', type: 'LOCATION' },
  { text: '마케팅 방침', type: 'MARKETING' },
];

/**
 * 약관 컴포넌트
 */
export default function RulesComponent() {
  const [active, setActive] = useState(RULES[0].type);

  const tRef = useRef(null);
  const [params] = useSearchParams();
  const { isLoading, data } = useRulesQuery();

  // 약관 - 선택 카테고리 활성화
  useLayoutEffect(() => {
    const param = params.get('rule');
    if (param && RULES.filter((item) => item.type === param.toUpperCase()).length > 0) {
      setActive(param.toLocaleUpperCase());
    }
  }, [params]);

  // 페이지 스크롤 이동
  useEffect(() => {
    if (active && tRef) {
      const tg = tRef.current.children;
      const idx = RULES.reduce((acc, rule, idx) => (rule.type === active ? idx : acc), -1);
      if (idx && tg) {
        tg[idx].scrollIntoView({ inline: 'center' });
      }
    }
    document.body.scrollTo(0, 0);
  }, [tRef, active]);

  return (
    <D.Container _mx_width="1120px" _padding="80px 20px 260px" _media="tabletSm" _m_padding="0 0 160px">
      <D.FlexCols _width="100%" _gap={60} _media="tabletSm" _m_gap={24}>
        <S.Tabs>
          <div ref={tRef}>
            {RULES.map((item, idx) => (
              <S.Tab
                key={idx}
                active={active === item.type}
                onClick={() => {
                  setActive(item.type);
                }}>
                {item.text}
              </S.Tab>
            ))}
          </div>
        </S.Tabs>
        {isLoading && (
          <S.Contents>
            <D.Container _mn_height="70vh" />
          </S.Contents>
        )}
        {data && <S.Contents>{data.data.data.filter((rule) => rule.type === active)[0].contents}</S.Contents>}
      </D.FlexCols>
    </D.Container>
  );
}
