import { client } from '.';

const SERVICE_URL = {
  // review
  reviewWrite: '/api/review/write',
  reviewList: '/api/review/list',
  // member
  memberRequest: '/api/member/request',
  memberRequestCount: '/api/member/request/count',
  memberRequestDetails: '/api/member/request/details',
  memberApplication: '/api/member/application',
  memberApplicationCount: '/api/member/application/count',
  // booking
  bookingList: '/api/reservation/list',
  bookingCount: '/api/reservation/count',
  bookingDetails: '/api/reservation/details',
  bookingRead: '/api/reservation/read',
  bookingUpdate: '/api/reservation/status/update',
  // chat
  chatList: '/api/chatting/list',
  chatContentList: '/api/chatting/get',
  chatingInquiry: '/api/chatting/inquiry',
  // wish
  wishList: '/api/bookmark/list',
  wishCount: '/api/bookmark/count',
  partner: '/api/partner',
  // apply select
  applySelect: '/api/member/application/select',
};

// 마이 리뷰 요청
export const requestReviewList = (params) => client.post(SERVICE_URL.reviewList, params);
export const requestReviewWrite = (params) =>
  client.post(SERVICE_URL.reviewWrite, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// 마이 의뢰 요청
export const requestMemberRequest = (params) => client.post(SERVICE_URL.memberRequest, params);
export const requestMemberRequestCount = () => client.get(SERVICE_URL.memberRequestCount);
export const requestMemberRequestDetails = (params) => client.post(SERVICE_URL.memberRequestDetails, params);
export const requestMemberApplication = (params) => client.post(SERVICE_URL.memberApplication, params);
export const requestMemberApplicationCount = (params) => client.post(SERVICE_URL.memberApplicationCount, params);

// 마이 예약 요청
export const requestBookingCount = () => client.get(SERVICE_URL.bookingCount);
export const requestBookingList = (params) => client.post(SERVICE_URL.bookingList, params);
export const requestBookingDetails = (params) => client.post(SERVICE_URL.bookingDetails, params);
export const requestBookingRead = (params) => client.post(SERVICE_URL.bookingRead, params);
export const requestBookingCancel = (id) => client.post(SERVICE_URL.bookingUpdate, { id: id, status: '예약 취소' });
export const requestBookingConfirm = (id) => client.post(SERVICE_URL.bookingUpdate, { id: id, status: '예약 확정' });

// 마이 채팅 요청
export const requestChatList = (params) => client.post(SERVICE_URL.chatList, params);
export const requestChatContentList = (params) => client.post(SERVICE_URL.chatContentList, params);
export const getChatInquiry = () => client.get(SERVICE_URL.chatingInquiry);

// 위시리스트 요청
export const requestWishList = (params) => client.post(SERVICE_URL.wishList, params);
export const getWishCount = () => client.get(SERVICE_URL.wishCount);
export const requestPartner = (params) => client.post(SERVICE_URL.partner, params);

// 의뢰 요청
export const requestApplySelect = (id) => client.post(SERVICE_URL.applySelect, { id: id });
