import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import chevronIcon from '../../../assets/icons/utils/ico-chevron_up.svg';

const S = {
  Wrapper: styled.article`
    position: relative;
    width: 100%;
    transition: height 0.4s ease-in-out;
    height: ${(props) => (isNaN(props._height) ? props._height : props._height + 48 + 'px')};
    min-height: 48px;
    overflow: hidden;
  `,
  Top: styled.button`
    position: relative;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors[props._hidden ? 'gray300' : 'gray400']};
    border-radius: ${(props) => (props._hidden ? '20px' : '20px 20px 0 0')};
    background-color: ${(props) => props.theme.colors[props._hidden ? 'white' : 'gray50']};
    transition: border-radius 0.1s ${(props) => (props._hidden ? '0.35s' : '0')} ease-in-out;
    font-weight: ${(props) => props.theme.fontWeight.bolder};
    font-size: ${(props) => props.theme.fontSize.l};
    font-weight: ${(props) => props.theme.fontWeight.bolder};
    font-size: ${(props) => props.theme.fontSize.l};
    text-align: left;

    &::after {
      content: '';
      display: inline-block;
      width: 32px;
      height: 32px;
      background: url(${chevronIcon}) center no-repeat;
      transform: rotate(${(props) => (props._hidden ? 180 : 0)}deg);
    }
  `,
  Contetns: styled.div`
    position: relative;
    width: 100%;
    padding: 24px;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 0 0 20px 20px;
    border-top: 0;
  `,
};

const getElH = (el) => el.getBoundingClientRect().height;

/**
 * 드롭다운 형식 정보 노출 컴포넌트
 */
export default function HiddenInfo({ children, title, _hidden = true }) {
  const [hidden, setHidden] = useState(_hidden);
  const [contsH, setContsH] = useState(-1);

  const contsRef = useRef(null);

  useEffect(() => {
    let autoTm;
    if (autoTm) clearTimeout(autoTm);

    if (hidden) {
      if (contsH < 0) return;
      setContsH(getElH(contsRef.current));
      autoTm = setTimeout(() => {
        setContsH(0);
      }, 30);
    } else {
      setContsH(getElH(contsRef.current));
      autoTm = setTimeout(() => {
        setContsH('auto');
      }, 400);
    }

    return () => {
      if (autoTm) {
        clearTimeout(autoTm);
      }
    };
  }, [contsRef, hidden]);

  const handleClose = (e) => {
    e.preventDefault();
    setHidden((h) => !h);
  };

  return (
    <S.Wrapper _height={contsH}>
      <S.Top _hidden={hidden} {...(contsRef && { onClick: handleClose })}>
        {title}
      </S.Top>
      <S.Contetns ref={contsRef}>{children}</S.Contetns>
    </S.Wrapper>
  );
}
