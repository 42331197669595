import React, { useEffect, useState } from 'react';
import { FlexCols } from '../../Common/Display';
import styled from 'styled-components';
import { useChatingContentListQuery, useChatingListQuery, useEditChattingData } from '../../../hooks/queries/mypage';
import { useInView } from 'react-intersection-observer';
import * as D from '../../Common/Display';
import { Text } from '../../Common/Text';
import { Line } from '../../Common/Line';
import { chatRoom } from '../../../constants/site';
import { RecoilState, useRecoilState } from 'recoil';
import { chatHotelName } from '../../../store/chat';
import { ErrorStatus, LoadingStatus } from '../../Common/Status';
import { Link } from 'react-router-dom';

const ChatCard = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 24px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.gray50};
  cursor: pointer;

  h3 {
    font-size: ${(props) => props.theme.fontSize.xxl};
    font-weight: ${(props) => props.theme.fontWeight.bold};
  }

  p {
    width: 100%;
    height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${(props) => props.theme.colors.gray800};
    line-height: ${(props) => props.theme.lineHeight.xxs};
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 20px;

    h3 {
      font-size: ${(props) => props.theme.fontSize.xl};
    }
    p {
      font-size: ${(props) => props.theme.fontSize.s};
    }
  }
`;

/**
 * 채팅 목록 컴포넌트
 */
export default function ChatList({ handleChatClick, setChatViewShow }) {
  const { data, isFetching, isFetchingNextPage, fetchNextPage, status, hasNextPage } = useChatingListQuery({ page: 1 });
  const [page, setPage] = useState([]);
  const [ref, inView] = useInView();

  // const { mutate: editChatting } = useEditChattingData();
  const [hotelName, setHotelName] = useRecoilState(chatHotelName);

  useEffect(() => {
    let arr = [];
    if (inView && hasNextPage) {
      // fetchNextPage();
      data.pages.map((x) => {
        arr.push(...x.products);
      });
      setPage(arr);
    }
  }, [inView, data]);

  return status === 'loading' ? (
    <LoadingStatus box />
  ) : (
    <>
      {data?.pages[0].products.length > 0 ? (
        <FlexCols _width="100%" _gap={16}>
          {(page == '' ? data?.pages[0].products : page).map((chat, index) => (
            <ChatCard
              key={index}
              tabIndex={0}
              role="button"
              as={Link}
              onClick={() => {
                // handleChatClick(chat.room);
                // editChatting(chat.room);
                // setChatViewShow(true);
                setHotelName(chat.hotelName);
              }}
              to={`${chatRoom(chat.room)}?s=0`}
              target="_blank">
              <h3>{chat.hotelName ? `${chat.hotelName} - ${chat.manager}` : '알아봐봐 매니저'}</h3>
              <p>{chat.lastChat}</p>
            </ChatCard>
          ))}
          <div ref={ref}>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
        </FlexCols>
      ) : (
        <ErrorStatus message="채팅 내역이 없습니다." />
      )}
    </>
  );
}
