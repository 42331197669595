import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MyPageSection from '../../../components/MyPage/MyPageSection';
import WishList from '../../../components/MyPage/Wishes/WishList';
import { myWishCategory, SITE } from '../../../constants/site';

/**
 * 마이페이지 > 위시리스트 페이지
 * @returns 
 */
export default function MyWishes() {
  const { category } = useParams();
  const navigate = useNavigate();

  // 유효 카테고리가 아닐 때 유효한 카테고리로 이동
  if (myWishCategory.findIndex((ctg) => ctg === category) < 0) {
    navigate(SITE.myWish);
    return null;
  }

  return (
    <MyPageSection>
      <WishList category={category} />
    </MyPageSection>
  );
}
