import React from 'react';

import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import RDatePicker from '../../../../components/Common/RDatePicker';
import { bookPeriodState } from '../../../../store/search';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CalendarWrapper = styled.div`
  padding: 48px 40px;

  ${(props) => props.theme.effectStyle.boxShadow}
  border-radius: 50px;

  & > div {
    width: 100%;
    height: auto;
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 48px 20px;
    background: ${(props) => props.theme.colors.white};
    box-shadow: none;
    padding: 0;

    .rdp-table {
      margin: 0 auto;
    }
  }
`;

// component: 이용일시 선택 box
// /space/request 페이지에 노출 및 호출
function SelectSchedule() {
  // 선택한 일정 저장
  const [bookPeriod, setBookPeriod] = useRecoilState(bookPeriodState);

  return (
    <Wrapper>
      <CalendarWrapper>
        <div>
          {/* 달력 component */}
          <RDatePicker dates={bookPeriod} handleChange={setBookPeriod} />
        </div>
      </CalendarWrapper>
    </Wrapper>
  );
}

export default SelectSchedule;
