import React, { useLayoutEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import CardList from '../../components/Common/CardList';
import { ErrorStatus } from '../../components/Common/Status';
import { useInfiniteAliemListQuery } from '../../hooks/queries/user';
import { NormalModal } from '../../layouts/Modal';
import { aliemModalState } from '../../store/modal';
import * as D from '../Common/Display';
import { Text } from '../Common/Text';
import AliemCard from './AliemCard';

const S = {
  CardWrap: styled.div`
    width: calc(100% + 10px);
    height: 512px;
    ${D.yScrollBoxCss()}

    > .inner {
      width: 100%;
      padding-right: 20px;
      height: auto;
    }

    > .error {
      height: 100% !important;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      height: calc(100dvh - 120px);
    }
  `,
};

/**
 * 알림 목록 컴포넌트
 */
export default function AliemList() {
  const closeAliem = useResetRecoilState(aliemModalState);
  const {
    data: pageData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteAliemListQuery();

  useLayoutEffect(() => {
    refetch();
  }, []);

  return (
    <NormalModal title="알림" _size="m2" handleModalClose={closeAliem}>
      <D.FlexCols _gap={24} _width="100%" as="section">
        <Text as="h3" _size="xxl" _weight="bold">
          알림
        </Text>
        <S.CardWrap>
          {pageData &&
            (pageData.pages[0].data.data.count > 0 ? (
              <div className="inner">
                <CardList
                  count={1}
                  gap={0}
                  infinite={true}
                  handleNextPage={fetchNextPage}
                  nextLoading={isLoading || isFetchingNextPage}
                  hasNextPage={hasNextPage}
                  pageLen={pageData.pages.length}>
                  {pageData.pages
                    .reduce((acc, page) => acc.concat(page.data.data.notification), [])
                    .map((aliem, idx) => (
                      <AliemCard key={idx} data={aliem} />
                    ))}
                </CardList>
              </div>
            ) : (
              <ErrorStatus _height="100%" message="도착한 알림이 없습니다" />
            ))}
        </S.CardWrap>
      </D.FlexCols>
    </NormalModal>
  );
}
