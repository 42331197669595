import { client } from '.';

const REQUEST_URLS = {
  spaceRequest: '/api/request/insert',
};

const authConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// 공간 의뢰 요청
export const getSpaceRequest = (data, token) =>
  client.post(REQUEST_URLS.spaceRequest, data, authConfig(token));
