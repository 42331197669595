import React from 'react';
import { FormModal } from '../../../layouts/Modal';
import * as D from '../../Common/Display';
import { FreeTable } from '../../Common/Table';
import { Line } from '../../Common/Line';
import DownLoadLink from '../../Common/DownloadLink';
import { RoundBtn } from '../../Common/Button';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { applyModal, customConfirm, customAlert } from '../../../store/modal';
import { Link, useNavigate } from 'react-router-dom';
import { SITE, spaceBook } from '../../../constants/site';
import { requestApplySelect } from '../../../service/client/mypage';

/**
 * 마이페이지 > 예약 상세 컴포넌트
 * @returns 
 */
export default function ApplyDetails() {
  const applyId = useRecoilValue(applyModal).id;
  const applyContent = useRecoilValue(applyModal).content;
  const resetApplyModal = useResetRecoilState(applyModal);
  const setConfirmModal = useSetRecoilState(customConfirm);
  const setAlert = useSetRecoilState(customAlert);
  const navigate = useNavigate();

  const handleApplySelect = async (id) => {
    const res = await requestApplySelect(id);
    if (res && res.data.status === 200) {
      setConfirmModal({
        title: '알림',
        text: '의뢰가 예약되었습니다.',
        confirm: '확인',
        handleConfirm: () => {
          navigate(SITE.myBooking);
        },
      });
    } else {
      setAlert({
        text: '예약 확정에 실패하였습니다. \n 다시 시도해 주세요.',
      });
    }
  };

  if (applyId === null) {
    return <></>;
  }
  
  console.log(applyContent);
  
  return (
    <FormModal
      title="지원 내용"
      _size="m3"
      action={
        <D.FlexRows _width="100%" _gap={16}>
          <RoundBtn onClick={() => navigate(spaceBook(applyContent.spaceId))} text="공간 상세보기" />
          <RoundBtn
            text="선택하기"
            _primary
            onClick={() => {
              setConfirmModal({
                title: '한번 더 확인해주세요!',
                text: '의뢰는 하나의 업체만 선택가능합니다. \n 선택하신 공간을 예약하시겠습니까?',
                confirm: '예약확정',
                cancel: '취소',
                handleConfirm: () => {
                  handleApplySelect(applyId);
                },
                handleCancel: () => {},
              });
              resetApplyModal();
            }}
          />
        </D.FlexRows>
      }
      handleModalClose={() => resetApplyModal()}>
      <D.Container _padding="0 20px" _media="tabletSm" _m_padding="0">
        <D.FlexCols _gap={24}>
          <FreeTable
            _gap={24}
            data={[
              { th: '지원 업체', td: applyContent.hotelName },
              { th: '위치', td: applyContent.location },
            ]}
          />
          <Line _color="gray200" />
          <FreeTable
            _gap={24}
            _th_width={104}
            _td_align="left"
            data={[
              { th: '제목', td: applyContent.title },
              {
                th: '지원 내용',
                td: applyContent.content,
              },
              {
                th: '제안 내용',
                td: applyContent.offer,
              },
              {
                th: '견적서',
                td: (
                  <D.FlexCols>
                    <DownLoadLink url={`https://cdn.alabwaba.com/application/${applyContent.savedName}`} text="다운로드" />
                  </D.FlexCols>
                ),
              },
            ]}
          />
        </D.FlexCols>
      </D.Container>
    </FormModal>
  );
}
