import React from 'react';
import styled, { css } from 'styled-components';

const S = {
  LinedTable: styled.table`
    width: 100%;
    tbody {
      width: 100%;

      tr {
        border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
        font-size: ${(props) => props.theme.fontSize.l};

        th,
        td {
          padding-top: 12px;
          padding-bottom: 12px;
        }

        th {
          font-weight: ${(props) => props.theme.fontWeight.bold};
          padding-left: 24px;
          text-align: left;
        }

        td {
          padding-right: 24px;
          text-align: ${(props) => props._td_align ?? 'inherit'};
        }
      }
    }
  `,
  FreeTable: {
    Table: styled.table`
      width: 100%;
      tbody {
        width: 100%;
      }
    `,
    Caption: styled.caption`
      margin-bottom: 15px;
      font-size: ${(props) => props.theme.fontSize[props._size ?? 'l']};
      font-weight: ${(props) => props.theme.fontWeight[props._weight ?? 'bold']};
      text-align: left;
    `,
    Tr: styled.tr`
      width: 100%;
      > th,
      > td {
        padding-top: ${(props) => (props._gap ?? 8) / 2}px;
        padding-bottom: ${(props) => (props._gap ?? 8) / 2}px;
      }
      &:first-child > th,
      &:first-child > td {
        padding-top: 0;
      }

      &:last-child > th,
      &:last-child > td {
        padding-bottom: 0;
      }
    `,
    Th: styled.th`
      font-size: ${(props) => props.theme.fontSize[props._size ?? 'l']};
      font-weight: ${(props) => props.theme.fontWeight[props._weight ?? 'bold']};
      text-align: left;
      line-height: 1.2;
    `,
    Td: styled.td`
      font-size: ${(props) => props.theme.fontSize[props._size ?? 'l']};
      font-weight: ${(props) => props.theme.fontWeight[props._weight ?? 'normal']};
      text-align: ${(props) => props._align ?? 'right'};
      line-height: 1.2;
    `,
  },
};

/**
 * 라인 테이블 컴포넌트
 * @description border로 줄나눔한 컴포넌트
 */
export function LinedTable({ _th_width, _td_align, data }) {
  return (
    <S.LinedTable _td_align={_td_align}>
      <colgroup>
        <col width={_th_width ? `${_th_width}px` : 'auto'} />
        <col width={_th_width ? `calc(100% - ${_th_width}px)` : 'auto'} />
      </colgroup>
      <tbody>
        {data.map((line, idx) => (
          <tr key={idx}>
            <th>{line.th}</th>
            <td>{line.td}</td>
          </tr>
        ))}
      </tbody>
    </S.LinedTable>
  );
}

/**
 * 기본 테이블 컴포넌트
 */
export function FreeTable({ _th_width, _th_size, _th_weight, _td_size, _td_weight, _td_align, _gap, caption, data }) {
  return (
    <S.FreeTable.Table>
      {caption && <S.FreeTable.Caption>{caption}</S.FreeTable.Caption>}
      <colgroup>
        <col width={_th_width ? `${_th_width}px` : 'auto'} />
        <col width={_th_width ? `calc(100% - ${_th_width}px)` : 'auto'} />
      </colgroup>
      <tbody>
        {data.map((line, idx) => (
          <S.FreeTable.Tr key={idx} _gap={_gap}>
            <S.FreeTable.Th _size={_th_size} _weight={_th_weight}>
              {line.th}
            </S.FreeTable.Th>
            <S.FreeTable.Td _size={_td_size} _weight={_td_weight} _align={_td_align}>
              {line.td}
            </S.FreeTable.Td>
          </S.FreeTable.Tr>
        ))}
      </tbody>
    </S.FreeTable.Table>
  );
}
