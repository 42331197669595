import { client } from '.';

const SERVICE_URL = {
  rules: '/api/policy',
  oneToOne: '/api/inquiry',
  noticeCount: '/api/notice/count',
  noticeList: '/api/notice',
};

// 약관 요청
export const getSiteRules = () => client.get(SERVICE_URL.rules);

// 일대일 문의 요청
export const requestOneToOne = (params) => client.post(SERVICE_URL.oneToOne, params);

// 공지 갯수 요청
export const requestNoticeCount = (params) => client.post(SERVICE_URL.noticeCount, params);

// 공지 목록 요청
export const requestNoticeList = (params) => client.post(SERVICE_URL.noticeList, params);
