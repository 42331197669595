import React, { useCallback, useEffect, useRef, useState } from 'react';

import people from '../../../assets/icons/utils/ico-people.svg';
import pinIcon from '../../../assets/icons/utils/ico-pin.svg';
import shareIcon from '../../../assets/icons/utils/ico-share.svg';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { Badge } from '../../../components/Common/Badge';
import { SpaceBookmark } from '../../../components/Common/BookMark';
import * as D from '../../../components/Common/Display';
import { Line } from '../../../components/Common/Line';
import MatterPortViewer from '../../../components/Common/MatterPortViewer';
import RDatePicker from '../../../components/Common/RDatePicker';
import { LoadingStatus } from '../../../components/Common/Status';
import { CostText, Text } from '../../../components/Common/Text';
import ReviewStars from '../../../components/Review/ReviewStars';
import SpaceNearList from '../../../components/Space/SpaceNearList';
import { convertNumber } from '../../../constants/number';
import { spaceDetails as spaceDetailsLink } from '../../../constants/site';
import { useAvailableDateQuery, useSpaceBookQuery } from '../../../hooks/queries/book';
import { useSpaceDetailsQuery } from '../../../hooks/queries/space';
import { useMobile } from '../../../hooks/ui/mediaQuery';
import {
  availableDateState,
  onModalHiddenState,
  onModalState,
  onSpaceBookQueryState,
  reqSpaceBookState,
  spaceBookDetailState,
  spaceBookPeriodState,
  spaceBookPersonInfoState,
  spaceBookTotalPriceState,
} from '../../../store/book';
import { customAlert, picViewerModal } from '../../../store/modal';
import { bookPeriodState } from '../../../store/search';
import { spaceDetailsState } from '../../../store/space';
import { floatingChatUi } from '../../../store/ui';
import { useUser } from '../../../store/user';
import { copyToClipboard } from '../../../utils/copy';
import { PackageList, RoomList } from './Components/PackageList';
import SelectBox from './Components/SelectBox';
import { cdnUrl } from '../../../constants/url';
import HideElement from './Components/HideElement';

const Head = styled(D.Flex)`
  @media (${(props) => props.theme.media[props._media]}) {
    display: none;
  }
`;
const Utils = styled.ul`
  display: flex;
  gap: 24px;
  width: max-content;

  a,
  button {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    right: 20px;
    top: 0;
    bottom: auto;
    gap: 16px;
    a,
    button {
      font-size: 0;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const ContentDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 100px;
  justify-content: space-between;

  @media (${(props) => props.theme.media.tablet}) {
    gap: 40px;
  }

  @media (${(props) => props.theme.media.tabletM}) {
    gap: 30px;
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 0 20px;
    flex-direction: column;
  }
`;
const Contents = styled(D.FlexCols)`
  flex: 1;
  gap: 42px;
`;

const S = {
  Top: {
    Wrapper: styled.div`
      position: relative;
      width: 100%;
      display: none;

      @media (${(props) => props.theme.media.tabletSm}) {
        display: flex;
        padding: 32px 20px 0;
      }
    `,
    Scores: styled.div`
      display: flex;
      gap: 2px;
      align-items: center;

      @media (${(props) => props.theme.media.tabletSm}) {
        position: absolute;
        top: 0;
        left: 20px;
      }
    `,
    Utils: styled.ul`
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      gap: 24px;
      width: max-content;

      a,
      button {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: ${(props) => props.theme.fontSize.s};
        font-weight: ${(props) => props.theme.fontWeight.medium};
      }

      @media (${(props) => props.theme.media.tabletSm}) {
        right: 20px;
        top: 0;
        bottom: auto;
        gap: 16px;

        a,
        button {
          font-size: 0;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    `,
  },
  PictureWrap: styled.div`
    z-index: 20;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;

    @media (${(props) => props.theme.media.tabletSm}) {
      border-radius: 0;
    }
  `,
  DimsWrap: styled.div`
    z-index: 22;
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(${({ show }) => (show ? '0' : '-100%')});
    transition: 0.3s ease-in;
  `,
  TransBtn: styled.button`
    z-index: ${({ show }) => (show ? 23 : -1)};
    position: absolute;
    top: 24px;
    right: 24px;
    opacity: ${({ show }) => (show ? 1 : 0)};
    padding: 0 16px;
    line-height: 38px;
    height: 38px;
    background-color: #fff;
    border-radius: 999px;
    transition: 0.2s ease-in;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  `,
  Images: {
    Wrapper: styled(D.Flex)`
      width: 60px;
      height: 60px;

      justify-content: center;
      align-items: center;
      @media (${(props) => props.theme.media.tabletSm}) {
        align-items: start;
      }
    `,
    Img: styled.img`
      width: ${(props) => props._width ?? 'auto'};
      height: ${(props) => props._height ?? 'auto'};
    `,
  },
  Contents: {
    // weight 500, size 22px, gray900
    // weight 500, size 16px, gray900
    // weight 400, size 16px, gray900
    // weight 400, size 16px, gray800
    Title: styled(Text)``,
    Contents: styled(Text)``,
  },
};

const TableButtons = styled(D.Flex)`
  justify-content: flex-start;
`;

const TableButton = styled.button`
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 20px;
  padding: 30px 16px;
  &.selected {
    border: 1px solid ${(props) => props.theme.colors.mainPurple};
  }
`;

const CalendarDiv = styled(D.FlexRows)`
  padding: 0 10px;
  pointer-events: ${(props) => (props.isClick ? 'auto' : 'none')};

  @media (max-width: 1100px) {
    .rdp-months {
      flex-direction: column;
      gap: 40px;
    }
    .rdp-caption_end {
      margin: 0;
    }
  }

  @media (${(props) => props.theme.media.tabletSm}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    ${D.yScrollBoxCss()}
  }
`;

// page: 공간 예약 페이지
function SpaceBook() {
  const { id } = useParams();
  const navigate = useNavigate();
  // 호텔 상세 정보
  const { isLoading, data, error, isSuccess } = useSpaceDetailsQuery(id);
  // 플로팅 챗 버튼 컨트롤
  const setFloatingChat = useSetRecoilState(floatingChatUi);
  const resetFloatingChat = useResetRecoilState(floatingChatUi);
  // 알럿창
  const setAlert = useSetRecoilState(customAlert);
  // 예약 요청하는 formData
  const [reqSpaceBook, setReqSpaceBook] = useRecoilState(reqSpaceBookState);
  // 호텔 info
  const [spaceDetails, setSpaceDetails] = useRecoilState(spaceDetailsState);
  // 예약 > details,
  const reqDetail = useRecoilValue(spaceBookDetailState);
  // 예약할 날짜
  const [bookPeriod, setBookPeriod] = useRecoilState(bookPeriodState); // 예약가건 설정
  const spaceBookPeriod = useRecoilValue(spaceBookPeriodState); // 예약 기간
  const totalPrice = useRecoilValue(spaceBookTotalPriceState); // 총 예악 금액
  // 예약자 정보
  const bookPersonInfo = useRecoilValue(spaceBookPersonInfoState);
  // 예약 규정 안내 확인 state
  const [onBookQuery, setOnBookQuery] = useRecoilState(onSpaceBookQueryState);

  // selectBox 모달창 단계 설정 (0>1>2>3 단계로 옵션 선택)
  const [onModal, setOnModal] = useRecoilState(onModalState);
  const [hidden, setHidden] = useRecoilState(onModalHiddenState);
  // 사진 뷰어 컨트롤 (사진뷰어|3D 이미지)
  const [dimsMode, setDimsMode] = useState(true);
  const setPicViewer = useSetRecoilState(picViewerModal);
  // 사용자 정보
  const { token } = useUser();
  // 예약 요청 API 쿼리
  useSpaceBookQuery(onBookQuery, token, reqSpaceBook);
  const { data: dateData, isLoading: dateLoading } = useAvailableDateQuery(id);

  // ui
  const [usingDate, setUsingDate] = useRecoilState(availableDateState);
  const [isMobile] = useMobile(767);

  useEffect(() => {
    setUsingDate(dateData?.data);
  }, [dateData, dateLoading, setUsingDate]);
  // ui
  const bRef = useRef(null);

  // 예약 정보를 요청 데이터 포맷으로 변경하여 반환
  const getSpaceBookObj = useCallback(() => {
    const reqObj = { ...reqSpaceBook };

    reqObj.spaceId = spaceDetails?.spaceId;
    reqObj.startDate = spaceBookPeriod.startDate;
    reqObj.endDate = spaceBookPeriod.endDate;
    reqObj.details = reqDetail;
    reqObj.reservationName = bookPersonInfo.reservationName;
    reqObj.reservationPhone = bookPersonInfo.reservationPhone;
    reqObj.reservationEmail = bookPersonInfo.reservationEmail;
    reqObj.price = totalPrice;

    setReqSpaceBook(reqObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDetails, bookPersonInfo, spaceBookPeriod, reqDetail, totalPrice]);

  // 호텔 정보 저장
  useEffect(() => {
    setSpaceDetails(data);
  }, [data, setSpaceDetails]);

  // 예약요청정보 입력시 예약정보객체 데이터 세팅하도록 처리(Initial)
  useEffect(() => {
    getSpaceBookObj();
  }, [getSpaceBookObj]);

  // 플로팅버튼 PC|모바일 스크롤 처리
  // 모바일에서 예약창이 플로팅 버튼을 덮도록, PC에서 예약창이 플로팅 버튼 위에 올라오도록 처리합니다.
  useEffect(() => {
    let scHandler, top, end;

    if (!data) return;

    if (isMobile) {
      if (scHandler) {
        document.body.removeEventListener('scroll', scHandler);
        scHandler = null;
      }
      setFloatingChat({
        id: data.managerId,
        uiBack: !hidden,
        uiTop: true,
      });
      return;
    }

    if (bRef.current) {
      if (scHandler) document.body.removeEventListener('scroll', scHandler);
      top = bRef.current.getBoundingClientRect().top - window.innerHeight + document.body.scrollTop + 80;
      end = top + bRef.current.getBoundingClientRect().height;

      scHandler = (e) => {
        setFloatingChat({
          id: data.managerId,
          uiBack: document.body.scrollTop > top && document.body.scrollTop < end,
          uiTop: false,
        });
      };

      document.body.addEventListener('scroll', scHandler);
      setFloatingChat({
        id: data.managerId,
        uiBack: document.body.scrollTop > top && document.body.scrollTop < end,
        uiTop: false,
      });
    }

    return () => {
      document.body.removeEventListener('scroll', scHandler);
      resetFloatingChat();
    };
  }, [bRef.current, isMobile, hidden, data]);

  // 호텔 정보를 조회하지 못할 때, 예외 처리
  if (error) {
    setAlert({
      text: '공간 정보를 찾을 수 없습니다.',
      handleClose: () => {
        navigate('/');
      },
    });
    return null;
  }

  return (
    <D.Container
      _mx_width="1200px"
      _mn_height="100vh"
      _padding="84px 20px 120px 20px"
      _media="tabletSm"
      _m_padding="0 0 125px"
    >
      {isLoading || !data ? (
        <LoadingStatus />
      ) : (
        <D.FlexCols as="section" _gap={80} _width="100%" _media="tabletSm" _m_gap={68}>
          <D.FlexCols _gap={16} _width="100%">
            {/* header */}
            <Head _media="tabletSm">
              <D.FlexCols _gap={8} _width="100%">
                <D.FlexRows _gap={8}>
                  <Text as="h2" _weight="medium" _size="xxxxl" _media="tablet" _m_size="xl">
                    {data.spaceName}
                  </Text>
                  <D.FlexRows as="ul" _gap={8} _items="center">
                    {data.category.map((item, index) => (
                      <Badge key={index} text={item} _color="pink600" />
                    ))}
                  </D.FlexRows>
                </D.FlexRows>
                <D.FlexRows _justify="space-between">
                  <D.FlexRows _gap={10}>
                    <CostText
                      cost={convertNumber(data.priceTime)}
                      per="시간 당 공간금액"
                      _c_size="xxl"
                      _c_weight="medium"
                      _c_color="gray800"
                      _p_size="xxl"
                      _p_color="gray800"
                    />
                    <D.FlexRows _gap={6} _items="center">
                      <img src={people} alt="people-img" />
                      <Text _size="s" _weight="medium" _color="gray900">
                        {`${data.minPeople} ~ ${data.maxPeople}`}명
                      </Text>
                    </D.FlexRows>
                  </D.FlexRows>

                  <Utils>
                    <li>
                      <button
                        onClick={() => {
                          copyToClipboard(window.location.href);
                          setAlert({ text: '링크를 클립보드에 복사했습니다.' });
                        }}
                      >
                        <img src={shareIcon} alt="share-icon" />
                        <span>공유하기</span>
                      </button>
                    </li>
                    <li>
                      <SpaceBookmark type="space" styleType="text" id={id} like={data.bookmark} />
                    </li>
                  </Utils>
                </D.FlexRows>
              </D.FlexCols>
            </Head>
            <S.PictureWrap>
              {/*
                @수정
                - 2023.04.24: 사진모음 삭제, 3d 사진 + 전체 사진 뷰어만 노출
              */}
              {/* <GridPics
								pics={data.image}
								handleThumbClick={() => {
									setDimsMode(true);
								}}
								allMode
							/> */}
              <S.DimsWrap show={dimsMode}>
                <MatterPortViewer url={data.vrImage}/>
              </S.DimsWrap>
              <S.TransBtn
                  show={dimsMode}
                onClick={() => {
                  setPicViewer({
                    mode: true,
                    srcs: data.image,
                  });
                }}
              >
                사진보기
              </S.TransBtn>
            </S.PictureWrap>
            {/* 별점 only mobile */}
            <S.Top.Wrapper>
              <D.FlexCols _gap={12}>
                <D.FlexCols _gap={4}>
                  <Text
                    as={Link}
                    to={spaceDetailsLink(data.hotelId)}
                    _weight="medium"
                    _size="xxxxl"
                    _media="tablet"
                    _m_size="xl"
                  >
                    {data.hotelName}
                  </Text>
                  <D.FlexRows _gap={8} _items="center">
                    <D.FlexRows _gap={2} _items="center">
                      <img src={pinIcon} alt="mark-icon" />
                      <Text _size="s" _color="gray600">
                        {data.addressLocation}
                      </Text>
                    </D.FlexRows>
                    <S.Top.Scores>
                      <ReviewStars score={data.star} size="s" />
                      <Text _weight="medium" _size="s">
                        {data.star}
                      </Text>
                    </S.Top.Scores>
                  </D.FlexRows>
                </D.FlexCols>
                <D.FlexRows as="ul" _gap={8}>
                  {data.category.map((item, index) => (
                    <Badge text={item} _color="pink600" key={index} />
                  ))}
                </D.FlexRows>
              </D.FlexCols>
              <S.Top.Utils>
                <li>
                  <button
                    onClick={(e) => {
                      copyToClipboard(window.location.href);
                      setAlert({ text: '링크를 클립보드에 복사했습니다.' });
                    }}
                  >
                    <img src={shareIcon} alt="share-icon" />
                    공유하기
                  </button>
                </li>
                <li>
                  <SpaceBookmark type="space" styleType="text" id={id} like={data.bookmark} />
                </li>
              </S.Top.Utils>
            </S.Top.Wrapper>
          </D.FlexCols>
          <ContentDiv>
            <Contents ref={bRef}>
              <D.Flex>
                <D.FlexCols _gap={8} _width="100%">
                  <Text as={Link} to={spaceDetailsLink(data.hotelId)} _size="xxxl" _weight="medium">
                    {data.hotelName}
                  </Text>
                  <Text _size="l" _weight="normal" _color="gray900">
                    {data.addressLocation}
                  </Text>
                  <Line _color="gray300" _m_top={16} />
                </D.FlexCols>
              </D.Flex>

              <D.FlexCols _gap={48} _width="100%">
                {/* 카테고리 */}
                <D.Flex _width="100%">
                  <D.FlexCols _gap={24} _width="100%">
                    {data.character.map((item, index) => (
                      <D.Flex _width="100%" _wrap="nowrap" key={index}>
                        <S.Images.Wrapper>
                          <S.Images.Img src={cdnUrl(item.icon)} _width="30px" _height="27px" />
                        </S.Images.Wrapper>
                        <D.FlexCols _gap={8} style={{ flex: '2' }}>
                          <Text _size="l" _weight="medium" _color="gray900">
                            {item.title}
                          </Text>
                          <Text _size="l" _weight="normal" _color="gray800">
                            {item.contents}
                          </Text>
                        </D.FlexCols>
                      </D.Flex>
                    ))}
                  </D.FlexCols>
                  <Line _color="gray300" _m_top={40} />
                </D.Flex>
                {/* 이벤트 상세 설명 */}
                <D.Flex _width="100%">
                  <D.FlexCols _gap={24} _width="100%">
                    <Text _size="xxxl" _weight="medium">
                      이벤트공간 상세설명
                    </Text>
                    <Text _size="l" _color="gray800">
                      {data.introduction}
                    </Text>
                  </D.FlexCols>
                  <Line _color="gray300" _m_top={40} />
                </D.Flex>
                {/* 공간 예약, calendar */}
                <D.Flex _width="100%">
                  <D.FlexCols _gap={24} _width="100%">
                    <Text _size="xxxl" _weight="medium">
                      공간 예약
                    </Text>
                    <CalendarDiv isClick={!(onModal > 1)} _justify="center">
                      <div>
                        <RDatePicker
                          availableDate={usingDate}
                          dates={bookPeriod}
                          handleChange={setBookPeriod}
                        />
                      </div>
                    </CalendarDiv>
                  </D.FlexCols>
                  <Line _color="gray300" _m_top={40} />
                </D.Flex>

                {/* 객실 정보 */}
                <D.Flex _width="100%">
                  <D.FlexCols _gap={24} _width="100%">
                    <Text _size="xxxl" _weight="medium">
                      객실 정보
                    </Text>
                    <RoomList spaceRooms={data?.room} />
                  </D.FlexCols>
                  {/* {id, name, description} */}
                  <D.FlexCols _width="100%">
                    {data?.room.map(
                      (item, index) =>
                        !!item && (
                          <HideElement
                            key={item?.hotelRoomId}
                            index={index}
                            name={item?.roomName}
                            image={item?.roomImage}
                            description={item?.roomDescription}
                          />
                        )
                    )}
                  </D.FlexCols>
                </D.Flex>

                {/* 패키지 정보 */}
                {data.meal && data.meal[0] && (
                  <D.Flex _width="100%">
                    <D.FlexCols _gap={24} _width="100%">
                      <Text _size="xxxl" _weight="medium">
                        식사 및 패키지 정보
                      </Text>
                      <PackageList spaceMeals={data.meal} />
                    </D.FlexCols>
                    <D.FlexCols _width="100%">
                      {data.meal.map((item, index) => (
                        <HideElement
                          key={item?.hotelMealId}
                          index={index}
                          name={item?.mealName}
                          image={item?.mealImage}
                          description={item?.mealDescription}
                        />
                      ))}
                    </D.FlexCols>
                  </D.Flex>
                )}
                {/* 테이블 배치 정보*/}
                <D.Flex _width="100%">
                  <D.FlexCols _gap={24} _width="100%">
                    <Text _size="xxxl" _weight="medium">
                      테이블 배치 정보
                    </Text>
                    <TableButtons _width="100%" _gap={16}>
                      {data.arrange.map((item) => (
                        <TableButton key={item.spaceArrangementId}>
                          <D.FlexCols _items="center">
                            <Text _size="l" _color="gray800" _space="pre-line">
                              {`${item.arrangementName}\n(최대 ${item.arrangementPeople}명)`}
                            </Text>
                          </D.FlexCols>
                        </TableButton>
                      ))}
                    </TableButtons>
                  </D.FlexCols>
                  <Line _color="gray300" _m_top={40} />
                </D.Flex>
                {/* 취소 및 환불 규정 */}
                <D.Flex _width="100%">
                  <D.FlexCols _gap={24} _width="100%">
                    <Text _size="xxxl" _weight="medium">
                      취소 및 환불 규정
                    </Text>
                    <D.Flex>
                      <Text _size="l" _color="gray900" _space="pre-line">
                        {data.cancelPolicy}
                      </Text>
                    </D.Flex>
                  </D.FlexCols>
                  <Line _color="gray300" _m_top={40} />
                </D.Flex>
              </D.FlexCols>
            </Contents>
            {/* 예약 box */}
            <SelectBox
              hidden={hidden}
              setHidden={setHidden}
              onModal={onModal}
              setOnModal={setOnModal}
              setOnBookQuery={setOnBookQuery}
              hasRooms={!!data?.room[0]}
            />
          </ContentDiv>
          <SpaceNearList info={data} />
        </D.FlexCols>
      )}
    </D.Container>
  );
}

export default SpaceBook;
