import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RequestList from '../../../components/MyPage/Request/RequestList';
import { myRequestCategory, SITE } from '../../../constants/site';

/**
 * 마이페이지 > 마이 의뢰 > 의뢰 목록 페이지
 */
export default function MyRequestList() {
  const { category } = useParams();
  const navigate = useNavigate();

  // 유효 카테고리가 아닐 때 유효한 카테고리로 이동
  if (myRequestCategory.findIndex((ctg) => ctg === category) < 0) {
    navigate(SITE.myRequest);
    return null;
  }

  return <RequestList category={category} />;
}
