import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FlexCols } from '../../Common/Display';
import { Text } from '../../Common/Text';
import { Link, useLocation } from 'react-router-dom';
import UserProfile from '../../Account/Profile';
import { useUser } from '../../../store/user';
import { SITE } from '../../../constants/site';

const S = {
  Wrapper: styled.div`
    z-index: 20;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1920px;
    height: auto;
    min-height: 940px;
    margin: 0 auto;
    border-left: 1px solid ${(props) => props.theme.colors.gray300};
  `,
  Aside: styled.div`
    position: sticky;
    top: 80px;
    left: 0;
    width: 256px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    padding-bottom: 64px;

    @media (${(props) => props.theme.media.tablet}) {
      display: none;
    }
  `,
  Main: styled.div`
    width: calc(100% - 256px);
    padding: 80px 0 116px 214px;
    border-left: 1px solid ${(props) => props.theme.colors.gray300};

    > * {
      width: 100%;
      max-width: 980px;
    }

    @media (max-width: 1919px) {
      padding-right: 20px;
      padding-left: calc(50% - 738px);

      > * {
        margin-right: 0;
      }
    }

    @media (max-width: 1532px) {
      padding-left: 40px;
    }

    @media (max-width: 1276px) {
      padding-left: 40px;
      padding-right: 20px;
    }

    @media (${(props) => props.theme.media.tablet}) {
      width: 100%;
      padding: 0;
      > * {
        max-width: 100%;
      }
    }
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 14px 24px;
    background-color: ${(props) => props.theme.colors.gray50};
  `,
  Profile: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    background: #fff;
    overflow: hidden;

    img {
      min-height: 100%;
      max-height: 120%;
    }
  `,
};

const NAVIGATIONS = [
  { path: SITE.myBooking, name: '회원 정보 변경' },
  { path: SITE.myBooking, name: 'MY 예약' },
  { path: SITE.myRequest, name: 'MY 의뢰' },
  { path: SITE.myWish, name: '위시리스트' },
  { path: SITE.myChat, name: 'MY 채팅' },
  { path: SITE.myReview, name: 'MY 리뷰' },
  { path: SITE.onToOne, name: '1:1 문의' },
];

const isCurrentPath = (originPath, currentPath) => {
  return currentPath.substring(0, originPath.length + 1) === originPath;
};

/**
 * 마이페이지 전체(공통) 레이아웃 컴포넌트
 */
export function MyPageWrapper({ children }) {
  const pathName = useLocation().pathname;
  const { nickname } = useUser();

  return (
    <S.Wrapper>
      <S.Aside>
        <S.Info>
          <UserProfile name="mpProfile" />
          <Text _size="xl" _weight="bolder" _align="center">
            {nickname}
          </Text>
        </S.Info>
        <FlexCols _gap={40} _items="center" _justify="center">
          {NAVIGATIONS.map((nav, idx) => (
            <Text
              as={Link}
              to={nav.path}
              _color={isCurrentPath(nav.path, pathName) ? 'purple1' : 'gray700'}
              _weight="medium"
              _size="l"
              key={idx}>
              {nav.name}
            </Text>
          ))}
        </FlexCols>
      </S.Aside>
      <S.Main>{children}</S.Main>
    </S.Wrapper>
  );
}
