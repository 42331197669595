import React, { useState } from "react";
import chatIcon from "../../../assets/icons/utils/ico-chat.svg";
import { Container, FlexRows } from "../../../components/Common/Display";
import { Text } from "../../../components/Common/Text";
import BookingDetails from "../../../components/MyPage/Booking/BookingDetails";
import MyPageSection from "../../../components/MyPage/MyPageSection";
import ReviewForm from "../../../components/Review/ReviewForm";
import { chatRoom } from "../../../constants/site";
import { useBookingDetailsQuery } from "../../../hooks/queries/mypage";
import { useLoginOnly } from "../../../hooks/utils/login";
import { requestNewChatRoom } from "../../../service/client/chat";

/**
 * 마이페이지 > 마이 예약 > 상세 페이지
 */
export default function MyBookingDetails() {
  // 리뷰 아이디 목록
  const [reviewIds, setReviewIds] = useState(null);
  // 상세 데이터 조회
  const { data} = useBookingDetailsQuery({
    id: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1],
  });
  // 채팅하기 클릭 핸들러
  const handleChatClick = async () => {
    const res = await requestNewChatRoom(data.managerId);
    window.open(`${chatRoom(res.data.data.room)}?s=0`, "_blank");
  };

  return useLoginOnly(
    <Container _mx_width="1020px" _padding="80px 20px 136px" _media="tablet" _m_padding="60px 0 120px">
      <MyPageSection
        title="예약 상세"
        meta={
          <FlexRows style={{ cursor: "pointer" }} onClick={handleChatClick} _gap={4} _items="center">
            <img src={chatIcon} alt="chat/icon" /> <Text _weight="medium">채팅 문의</Text>
          </FlexRows>
        }
      >
        <BookingDetails handleReviewOpen={setReviewIds} />
        {reviewIds && (
          <ReviewForm
            hotelId={reviewIds.hotelId}
            spaceId={reviewIds.spacId}
            managerId={reviewIds.managerId}
            spaceName={reviewIds.spaceName}
            handleClose={() => {
              setReviewIds(null);
            }}
          />
        )}
      </MyPageSection>
    </Container>
  );
}
