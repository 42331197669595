import React from 'react';
import downloadIcon from '../../../assets/icons/utils/ico-download.svg';
import styled from 'styled-components';

const SLink = styled.button`
  display: inline-block;
  padding-right: 24px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.l};
  background: url(${downloadIcon}) calc(100% - 7px) center no-repeat;
  text-align: right;

  @media (${(props) => props.theme.media.tablet}) {
    font-size: ${(props) => props.theme.fontSize.s};
  }
`;

/**
 * 다운로드 링크 컴포넌트
 */
export default function DownLoadLink({ url, text }) {
  return (
    <SLink
      onClick={(e) => {
        e.preventDefault();
        window.open(url, '_blank');
      }}>
      {text}
    </SLink>
  );
}
