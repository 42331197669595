import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import arrowLeft from '../../assets/icons/utils/ico-arrow_left.svg';
import arrowRight from '../../assets/icons/utils/ico-arrow_right.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSpaceCategoryQuery } from '../../hooks/queries/category';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  min-height: 24px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #fff;

    img {
      width: 8px;
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  ${(props) =>
    props.mobile &&
    css`
      width: calc(100% + 20px);
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      > div {
        display: felx;
        width: auto;
      }
    `}
`;

const SliderWrapper = styled.div`
  width: calc(100% - 60px);

  .slick-track {
    transition: 0.5s linear;
  }

  .slick-arrow {
    display: none !important;
  }
`;

const Item = styled.div`
  width: max-content;
  height: 100%;
  height: 62px;
  padding: 0 30px;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;
    height: 100%;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: ${(props) => (props.active ? '#000' : 'transparent')};

    img {
      width: 36px;
      height: 36px;
      margin: 0 auto;

      @media (${(props) => props.theme.media.mobile}) {
        width: 24px;
        height: 24px;
      }
    }

    span {
      font-size: ${(props) => props.theme.fontSize.xxs};
      font-weight: ${(props) => props.theme.fontWeight.medium};
      color: ${(props) => props.theme.colors.gray800};
      white-space: pre-wrap;
    }

    &:hover {
      border-color: ${(props) => (props.active ? '#000' : props.theme.colors.gray400)};
    }
  }

  ${(props) =>
    props.mobile &&
    css`
      padding: 0 16px;
    `}
`;

const MobileSwiper = {
  Wrapper: styled.div`
    width: 767px;
    max-width: 100%;
    height: auto;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    overflow-y: visible;

    .mb-slider,
    .mb-slider .slick-list,
    .mb-slider .slick-track {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .slick-dots {
      position: relative;
      bottom: 0;
      margin-top: 24px;
      width: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 16px;

      ul {
        height: 16px;
      }

      li {
        width: 8px;
        height: 8px;
        margin: 0;

        button {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 8px !important;
          height: 8px !important;
          overflow: hidden;

          &::before {
            content: '' !important;
            opacity: 1;
            background-color: #eee !important;
            width: 100% !important;
            height: 100% !important;
            border-radius: 8px;
          }
        }
        &.slick-active button::before {
          opacity: 1;
          background-color: ${(props) => props.theme.colors.purple300} !important;
        }
      }
    }
  `,
  Slide: styled.div`
    display: grid !important;
    grid-template-columns: repeat(4, minmax(25%, 1fr)) !important;
    width: 100%;
    gap: 16px 0;
  `,
  Item: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 78px !important;
    gap: 4px;

    button {
      width: 88px;
      max-width: 100%;
      height: 78px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 4px;
      padding: 8px 0;

      img {
        width: 40px;
        height: 40px;
      }

      span {
        font-size: ${(props) => props.theme.fontSize.xxs};
        color: ${(props) => props.theme.colors.gray800};
      }
    }

    @media (${(props) => props.theme.media.mobile}) {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  `,
};

/**
 * 카테고리 목록 컴포넌트
 * @description
 * - 모바일 환경에서 슬라이드로 노출
 */
const CategoryList = ({ category, handleCategoryChange, mobile = 'slider' }) => {
  const [slider, setSlider] = useState(null);
  const wRef = useRef(null);
  const cRef = useRef(null);
  const [scrollCnts, setScrollCnts] = useState(0);
  const [currentCnt, setCurrentCnt] = useState(0);
  const { data: categories, isLoading } = useSpaceCategoryQuery();
  const [sliderLoading, setSliderLoading] = useState(true);

  const handleSlideChagne = (o, n) => {
    setCurrentCnt(Math.ceil(n / scrollCnts[0]));
  };

  useEffect(() => {
    if (!categories || !wRef) {
      return;
    }

    if (isMobile && category !== -1) {
      const center = wRef.current.querySelector(`[data-ctg="${category}"]`);
      if (center) {
        center.scrollIntoView({ inline: 'center' });
      }
      return;
    }

    if (!slider) {
      return;
    }

    let timeoutFn;
    const sliderUiFn = () => {
      if (timeoutFn) {
        clearTimeout(timeoutFn);
      }
      timeoutFn = setTimeout(() => {
        const sw = wRef.current.getBoundingClientRect().width;
        const slideEls = wRef.current.querySelectorAll('.ctg-slide');
        const tl = Array.from(slideEls).reduce((t, el) => t + el.getBoundingClientRect().width, 0);
        const avl = tl / slideEls.length;
        const scCnt = Math.ceil(tl / sw);
        const elCnt = Math.round(sw / avl);
        const scArr = new Array(scCnt)
          .fill(0)
          .map((_, idx) => (idx === scCnt - 1 ? slideEls.length - elCnt * idx : elCnt))
          .slice(1);
        let step = 0;
        let goSum = 0;

        if (category !== -1) {
          const centerIdx = categories.findIndex((ctg) => ctg.categoryName === category);
          while (goSum + scArr[step] <= centerIdx) {
            goSum += scArr[step];
            step++;
          }
        }
        setScrollCnts(scArr);
        setCurrentCnt(step);
        slider.slickGoTo(goSum);
      }, 100);
    };
    window.addEventListener('resize', sliderUiFn);
    sliderUiFn();
    setSliderLoading(false);

    return () => {
      if (sliderUiFn) {
        window.removeEventListener('resize', sliderUiFn);
      }
    };
  }, [slider, categories, wRef, cRef, isMobile, category]);

  // PC slider: button style
  if (categories && !isMobile) {
    return (
      <Wrapper>
        <button
          onClick={() => {
            slider.slickPrev();
          }}
          disabled={currentCnt === 0}>
          <img src={arrowLeft} alt="이전 카테고리" />
        </button>
        <SliderWrapper ref={wRef}>
          <Slider
            className="ctg-slider"
            slidesToShow={1}
            slidesToScroll={
              scrollCnts[currentCnt >= scrollCnts.length ? 0 : currentCnt < 0 ? scrollCnts.length - 1 : currentCnt]
            }
            draggable={currentCnt < scrollCnts.length}
            variableWidth={true}
            infinite={false}
            easing="linear"
            ref={(c) => {
              setSlider(c);
            }}
            beforeChange={handleSlideChagne}>
            {!sliderLoading &&
              categories?.map((item, idx) => {
                const isSelected = item.categoryName === category;
                return (
                  <Item key={idx} active={isSelected} className="ctg-slide" data-ctg={item.categoryName}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCategoryChange(isSelected ? '' : item.categoryName);
                      }}>
                      <img src={`http://cdn.alabwaba.com${item.categoryIcon}`} alt={item.categoryName} />
                      <span>{item.categoryName}</span>
                    </button>
                  </Item>
                );
              })}
          </Slider>
        </SliderWrapper>
        <button
          onClick={() => {
            slider.slickNext();
          }}
          disabled={currentCnt === scrollCnts.length}>
          <img src={arrowRight} alt="다음 카테고리" />
        </button>
      </Wrapper>
    );
  }

  // mobile slider: nonButton style
  if (categories && isMobile && mobile === 'slider') {
    return (
      <Wrapper mobile>
        <div ref={wRef}>
          {categories?.map((item, idx) => {
            const isSelected = item.categoryName === category;
            return (
              <Item mobile key={idx} active={isSelected} className="ctg-slide" data-ctg={item.categoryName}>
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    handleCategoryChange(isSelected ? '' : item.categoryName);
                  }}>
                  <img src={`http://cdn.alabwaba.com${item.categoryIcon}`} alt={item.categoryName} />
                  <span>{item.categoryName}</span>
                </button>
              </Item>
            );
          })}
        </div>
      </Wrapper>
    );
  }

  // mobile swiper: HOME style
  if (categories && isMobile && mobile === 'swiper') {
    return (
      <MobileSwiper.Wrapper>
        <Slider className="mb-slider" slidesToShow={1} dots infinite arrows={false}>
          {categories
            .reduce((acc, item, idx) => {
              if (idx % 8 === 0 || idx < 1) return acc.concat([new Array(1).fill(item)]);
              else return [...acc].map((arr, aIdx) => (aIdx === acc.length - 1 ? [...arr, item] : arr));
            }, [])
            .map((group, sIdx) => (
              <MobileSwiper.Slide key={sIdx}>
                {group.map((ctg, cIdx) => (
                  <MobileSwiper.Item key={cIdx}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCategoryChange(ctg.categoryName === category ? '' : ctg.categoryName);
                      }}>
                      <img src={`http://cdn.alabwaba.com${ctg.categoryIcon}`} alt={ctg.categoryName} />
                      <span>{ctg.categoryName}</span>
                    </button>
                  </MobileSwiper.Item>
                ))}
              </MobileSwiper.Slide>
            ))}
        </Slider>
      </MobileSwiper.Wrapper>
    );
  }

  return <Wrapper />;
};

export default CategoryList;
