import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import xIcon from '../../../assets/icons/utils/ico-x.svg';
import { FrameModal } from '../../../layouts/Modal';
import { yScrollBoxCss } from '../../Common/Display';
import * as StompJs from '@stomp/stompjs';
import { useChatingContentListQuery, useEditChattingData } from '../../../hooks/queries/mypage';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import jwt_decode from 'jwt-decode';

const S = {
  Wrapper: styled.section`
    width: 570px;
    max-width: 570px;
    border-radius: 16px;
    background-color: #fff;

    @media (${(props) => props.theme.media.tabletSm}) {
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      border-radius: 0;
    }
  `,
  Top: styled.div`
    position: relative;
    width: 100%;
    height: 70px;
    padding: 22px;

    > h3 {
      width: calc(100% - 48px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 24px;
      margin: 0 auto;
      text-align: center;
    }

    > button {
      position: absolute;
      top: 22px;
      right: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }
  `,
  Body: styled.div`
    padding: 0 24px;
    background-color: ${(props) => props.theme.colors.gray50};
    height: 600px;
    max-height: calc(100vh - 200px);
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-width: 1px 0;
    ${yScrollBoxCss(7)}

    @media (${(props) => props.theme.media.tabletSm}) {
      height: calc(100% - 143px);
      max-height: calc(100% - 143px);
    }
  `,
  Btm: styled.form`
    display: flex;
    gap: 8px;
    padding: 16px;
    border-top: 1px solid ${(props) => props.theme.colors.gray200};
  `,
  ChatWrap: styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
  `,
  ChatDate: styled.div`
    position: relative;
    width: 100%;
    height: 18px;
    padding-left: calc(50% - 45px);
    padding-right: calc(50% - 45px);
    line-height: 18px;
    font-size: ${(props) => props.theme.fontSize.xxs};
    color: ${(props) => props.theme.colors.gray500};
    text-align: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: calc(50% - 45px);
      height: 1px;
      background-color: ${(props) => props.theme.colors.gray200};
    }
    &::after {
      left: auto;
      right: 0;
    }
  `,
  Chat: styled.div`
    padding-right: ${(props) => (props.me ? 0 : '20%')};
    padding-left: ${(props) => (props.me ? '20%' : 0)};

    > div {
      width: 100%;
      display: flex;
      flex-direction: ${(props) => (props.me ? 'row-reverse' : 'row')};
      align-items: flex-end;
      gap: 12px;
    }

    .time {
      width: 58px;
      font-size: ${(props) => props.theme.fontSize.xs};
      color: ${(props) => props.theme.colors.gray500};
      white-space: nowrap;
    }

    .speech {
      width: max-content;
      max-width: calc(100% - 70px);
      padding: 12px 16px;
      border-radius: 8px;
      font-size: ${(props) => props.theme.fontSize.l};
      background-color: ${(props) => props.theme.colors[props.me ? 'purple50' : 'gray100']};
      white-space: pre-line;
      word-wrap: break-word;
    }

    @media (${(props) => props.theme.media.tabletSm}) {
      .time {
        width: 56px;
        font-size: ${(props) => props.theme.fontSize.xxs};
      }

      .speech {
        max-width: calc(100% - 68px);
        font-size: ${(props) => props.theme.fontSize.l};
      }
    }
  `,
  TextInput: styled.textarea`
    width: calc(100% - 60px);
    height: 40px;
    border-radius: 8px;
    padding: 10px 16px;
    background-color: ${(props) => props.theme.colors.gray50};
    font-size: ${(props) => props.theme.fontSize.s};
    resize: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }
  `,
  Submit: styled.button.attrs({ type: 'input' })`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 40px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.purple1};
    color: ${(props) => props.theme.colors.white};
  `,
};

function Chat({ time, me, text }) {
  return (
    <S.Chat me={me}>
      <div>
        <p className="speech">{text}</p>
        <span className="time">
          {moment(time).format('a') == 'am' ? '오전' : '오후'} {moment(time).format('h:mm')}
        </span>
      </div>
    </S.Chat>
  );
}

/**
 * 채팅 상세(새 창) 컴포넌트
 */
export default function ChatWindow({ chatId, chatShow, handleChatClose }) {
  const { data } = useChatingContentListQuery({ room: chatId });
  const { mutate: editChatting } = useEditChattingData();
  const client = useRef({});
  const [chatMessages, setChatMessages] = useState([]);
  const [sub, setSub] = useState(null);
  
  // const [ref, inView] = useInView();

  // useEffect(() => {
  //   setSub(jwt_decode('eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyIiwiYXV0aCI6IlJPTEVfTUVNQkVSIiwiZXhwIjoxNjc4Njk5MDYyfQ.x21OswSEDYfboCvOFzzSYtXzVcWyVqZIoCZ7KHCp91IaqmOhUtVRul23zTBA8iBdQpd7E1wX8jUkJbVMaIl6Nw').sub);
  //   connect();

  //   return () => disconnect();
  // }, [chatShow]);

  // infinite scroll
  //  useEffect(() => {
  //   if(inView && hasNextPage){
  //     fetchNextPage();
  //   }
  // }, [inView, data]);

  const connect = () => {
    client.current = new StompJs.Client({
      brokerURL: 'wss://alabwaba.com/ws', // 웹소켓 서버로 직접 접속
      // webSocketFactory: () => new SockJS("/ws-stomp"), // proxy를 통한 접속
      connectHeaders: {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyIiwiYXV0aCI6IlJPTEVfTUVNQkVSIiwiZXhwIjoxNjc4Njk5MDYyfQ.x21OswSEDYfboCvOFzzSYtXzVcWyVqZIoCZ7KHCp91IaqmOhUtVRul23zTBA8iBdQpd7E1wX8jUkJbVMaIl6Nw',
      },
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: () => {
        subscribe();
      },
      onStompError: (frame) => {
        console.error(frame);
      },
    });

    client.current.activate();
  };

  const disconnect = () => {
    client.current.deactivate();
  };

  const subscribe = () => {
    client.current.subscribe(`/sub/342ecbcb-9dea-4b63-8280-40a33ee898bd`, ({ body }) => {
      setChatMessages((_chatMessages) => [..._chatMessages, JSON.parse(body)]);
    });
  };

  const publish = (message) => {
    if (!client.current.connected) {
      return;
    }

    client.current.publish({
      destination: '/pub/send',
      body: JSON.stringify({
        sender: sub,
        room: chatId,
        msg: message,
      }),
    });

    setChatMessages('');
    editChatting(chatId);
  };

  if (chatId <= 0) {
    return;
  }

  return data.pages == undefined ? (
    ''
  ) : (
    <FrameModal handleOverlayClick={handleChatClose}>
      <S.Wrapper>
        <S.Top>
          <h3>베스트 웨스턴 호텔</h3>
          <button type="button" onClick={handleChatClose}>
            <img src={xIcon} alt="닫기" />
          </button>
        </S.Top>
        <S.Body>
          <S.ChatWrap>
            <S.ChatDate>2022.11.11</S.ChatDate>
            {data?.pages[0]?.products.map((x, index) =>
              sub == x.member_id ? (
                <Chat time={x.chat_date} text={x.chat} key={index} me />
              ) : (
                <Chat time={x.chat_date} text={x.chat} key={index} />
              )
            )}
          </S.ChatWrap>
          {/* <div ref={ref}>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}gg</div> */}
        </S.Body>
        <S.Btm>
          <S.TextInput placeholder="채팅을 입력해 주세요." onChange={(e) => setChatMessages(e.target.value)} />
          <S.Submit onClick={() => publish(chatMessages)}>전송</S.Submit>
        </S.Btm>
      </S.Wrapper>
    </FrameModal>
  );
}
