import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import plus from '../../../../assets/icons/utils/ico-plus.svg';
import minus from '../../../../assets/icons/utils/ico-minus.svg';
import { Flex } from '../../../../components/Common/Display';
import { Text } from '../../../../components/Common/Text';

const Row = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const MenuName = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.s};
  color: ${(props) => props.theme.colors.gray800};
`;

const InputDiv = styled(Flex)`
  width: ${(props) => props._width ?? '150px'};
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 99px;
  border: 1px solid ${(props) => props.theme.colors.gray300};

  @media (${(props) => props.theme.media.tabletSm}) {
    padding: 8px 16px;
    height: 40px;
  }
`;

const ImgDiv = styled(Flex)`
  width: ${(props) => props._width ?? '24px'};
  height: ${(props) => props._height ?? '24px'};
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors[props._borderColor] ?? props.theme.colors.gray300};
  border-radius: 50px;
  img {
    width: ${(props) => props.img_width ?? '12px'};
    height: ${(props) => props.img_hegiht ?? '12px'};
  }
`;

const Button = styled.button`
  overflow: hidden;
  height: 100%;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};

  & > form {
    width: 45px;
    & > input {
      width: 100%;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: ${(props) => props.theme.fontSize.normal};
      color: ${(props) => props.theme.colors.gray900};
    }
  }
`;

const Amount = styled(Text)`
  display: inline-block;
  width: 45px;
  overflow: hidden;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: ${(props) => props.theme.fontSize.normal};
  color: ${(props) => props.theme.colors.gray900};
`;

// component: 선택한 식사 패키지 목록 box
// /space/SpaceRequest 페이지에 노출, SelectOptionsByDate의 식사 및 패키지 아래에 출력
function PackageBox({ meal, setMeal, index }) {
  // 수량을 집접 입력(true|false)
  const [edit, setEdit] = useState(false);
  // 식사 패키지 수량
  const [amount, setAmount] = useState(!!meal[index]?.amount ? meal[index].amount : 1);

  // 수량 직접 입력 종료(input>span 변경)
  const handleSubmit = (e) => {
    e.preventDefault();
    setEdit(false);
  };

  // 식사 패키지 수량 변경(상위 컴포넌트 옵션)
  const setMealAmount = useCallback(() => {
    if (meal.length > 0) {
      const mealObj = { ...meal[index], amount };

      const newMealArr = [...meal];
      newMealArr[index] = mealObj;

      setMeal(newMealArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    setMealAmount();
  }, [setMealAmount]);

  return !(meal.length > 0) ? (
    ''
  ) : (
    <Row>
      <MenuName>{meal[index].mealName}</MenuName>
      <InputDiv _justify="space-between">
        <ImgDiv onClick={() => setAmount((prev) => (prev === 0 ? 0 : prev - 1))}>
          <img src={minus} alt="minus-img" />
        </ImgDiv>

        <Button onClick={() => setEdit(true)}>
          {!edit ? (
            <Amount>{String(amount).padStart(2, '0')}</Amount>
          ) : (
            <form onSubmit={handleSubmit} onBlur={handleSubmit}>
              <input
                required
                autoFocus
                type="text"
                name="people"
                value={amount}
                onChange={(e) => {
                  const {
                    currentTarget: { value },
                  } = e;
                  if (!isNaN(value)) {
                    setAmount(+value);
                  }
                }}
              />
            </form>
          )}
        </Button>
        <ImgDiv onClick={() => setAmount((prev) => parseInt(prev) + 1)}>
          <img src={plus} alt="plus-img" />
        </ImgDiv>
      </InputDiv>
    </Row>
  );
}

export default PackageBox;
