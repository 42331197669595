import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import logo from '../assets/icons/logo/ico-logo_136.svg';
import menu from '../assets/icons/menu/ico-menu-hamburger.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SITE } from '../constants/site';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { asideModalState, customAlert, loginModalState } from '../store/modal';
import { useUser } from '../store/user';
import AliemBell from '../components/Aliem/AliemBell';
import SpaceFilter from '../components/Filter/SpaceFilter';
import { headerUi } from '../store/ui';

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
`;
const HeaderWrapper = styled.div`
  position: relative;
  max-width: 1180px;
  margin: 0 auto;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  .header-logo {
    width: 117px;
    line-height: 1;

    img {
      width: 100%;
    }
  }

  @media (${({ theme }) => theme.media.tabletM}) {
    padding: 16px 0;
    height: ${(props) => (props.mobileSearchBar ? '132px' : 'auto')};
    align-items: flex-start;

    .header-logo {
      width: 88px;
    }
  }
`;

const RNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  a,
  button {
    font-size: ${(props) => props.theme.fontSize.s};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }

  .header-menu-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 0;
  }

  @media (${({ theme }) => theme.media.tabletSm}) {
    gap: 16px;
  }

  @media (max-width: 320px) {
    gap: 8px;
    a,
    button {
      font-size: ${(props) => props.theme.fontSize.xxs};
      white-space: nowrap;
    }
  }
`;
const SearchWrapper = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (${(props) => props.theme.media.tabletM}) {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translate(0, 0);
    display: ${(props) => (props.mobileActive ? 'block' : 'none')};
  }
`;

/**
 * 글로벌 Header
 */
function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const setOnAside = useSetRecoilState(asideModalState);
  const setAlert = useSetRecoilState(customAlert);
  const setLogin = useSetRecoilState(loginModalState);
  const mobileSearchBar = useRecoilValue(headerUi).mobileSearchBar;
  // login
  const { loggedIn } = useUser();

  // aside 초기화
  useLayoutEffect(() => {
    setOnAside(false);
  }, [location]);

  return (
    <React.Fragment>
      <Wrapper>
        <HeaderWrapper mobileSearchBar={mobileSearchBar}>
          <Link className="header-logo" to={SITE.home}>
            <img src={logo} alt="알아봐봐" />
          </Link>
          <SearchWrapper mobileActive={mobileSearchBar}>
            <SpaceFilter />
          </SearchWrapper>
          <RNav>
            {/* link ? */}
            <Link className="pc" to={SITE.spaceList}>
              공간 찾기
            </Link>
            <button
              className="pc"
              onClick={() => {
                if (loggedIn) {
                  navigate(SITE.spaceRequest);
                } else {
                  setAlert({
                    text: '로그인 후 이용해 주세요',
                    handleClose: () => {
                      setLogin({ mode: true, redirect: SITE.spaceRequest });
                    },
                  });
                }
              }}>
              의뢰하기
            </button>
            {loggedIn && <AliemBell />}
            <button className="header-menu-img" onClick={() => setOnAside(true)}>
              <img src={menu} alt="메뉴" />
            </button>
          </RNav>
        </HeaderWrapper>
      </Wrapper>
    </React.Fragment>
  );
}

export default Header;
