import React from 'react';
import { Helmet } from 'react-helmet';
import OneToOneForm from '../../../components/Service/OneToOneForm';

/**
 * 일대일 문의 페이지
 */
function OneToOne() {
  return (
    <React.Fragment>
      <Helmet>
        <title>알아봐바 | 일대일문의</title>
      </Helmet>
      <OneToOneForm />
    </React.Fragment>
  );
}

export default OneToOne;
