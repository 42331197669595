import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import rightIcon from "../../assets/icons/utils/ico-arrow_right.svg";
import { SITE } from "../../constants/site";
import { cdnUrl } from "../../constants/url";
import { USER_KEYS } from "../../hooks/queries/user";
import { updateAliemRead } from "../../service/client/user";
import { LinelimitText } from "../Common/Text";

const S = {
  Card: styled.article`
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      padding: 24px 0;
      cursor: pointer;
    }
  `,
  Thumbnail: styled.div`
    width: 120px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.gray50};
    border-radius: 10px;
    overflow: hidden;
  `,
  Contents: styled.div`
    position: relative;
    width: calc(100% - 136px);
    padding-right: ${(props) => (props.new ? 70 : 40)}px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: 0.5;

    span {
      font-size: ${(props) => props.theme.fontSize.s};
      color: ${(props) => props.theme.colors.gray700};
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      width: 6px;
      height: 11px;
      background: url(${rightIcon}) center no-repeat;
    }

    ${(props) =>
      props.new &&
      css`
        opacity: 1;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color: ${(props) => props.theme.colors.error};
        }
      `}
  `,
};

const redirectUrl = (type, id) => {
  switch (type) {
    case "채팅":
      return SITE.myChat; // id 추가 예정 (창 또는 페이지 이동)
    case "예약":
      return SITE.myBooking;
    default:
      return SITE.home;
  }
};

/**
 * 알림 항목 컴포넌트
 */
export default function AliemCard({ data }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // 알림 읽음 처리 핸들러
  const hancleAliemClick = async (type, targetId, notId) => {
    await updateAliemRead({
      type: type,
      ...(type === "채팅" && { targetId: targetId }),
      ...(type === "예약" && { notificationId: notId }),
    });
    queryClient.invalidateQueries({ queryKey: USER_KEYS.aliemNew });
    navigate(redirectUrl(data.type, data.targetId), { replace: true });
  };

  return (
    <S.Card>
      <div
        onClick={(e) => {
          e.preventDefault();
          hancleAliemClick(data.type, data.targetId, data.notificationId);
        }}>
        <S.Thumbnail>
          <img src={cdnUrl(data.thumbnail, "h=80&q=80")} />
        </S.Thumbnail>
        <S.Contents new={!data.read}>
          <LinelimitText line={2} _height={38} _line="xxs" _size="l">
            {data.contents}
          </LinelimitText>
          <span>{moment(data.notificationCreateDate).format("YYYY-MM-DD")}</span>
        </S.Contents>
      </div>
    </S.Card>
  );
}
