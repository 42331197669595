import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import { spaceDetails } from '../../constants/site';
import { NormalModal } from '../../layouts/Modal';
import { reviewModal } from '../../store/modal';
import { Badge, BgBadge } from '../Common/Badge';
import * as D from '../Common/Display';
import PicSlider from '../Common/PicSlider';
import { Text } from '../Common/Text';
import ReviewProfile from './ReviewProfile';

const S = {
  Text: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 16px 24px;
    background-color: ${(props) => props.theme.colors.gray50};
    border-radius: 20px;

    /* > div {
      height: 120px;
      overflow-y: auto;
      overflow-x: hidden;

      > p {
        height: auto;
      }
    } */
  `,
  // Pics: styled.div`
  //   width: 100%;
  //   height: 340px;
  //   overflow-y: auto;
  //   overflow-x: hidden;

  //   > ul {
  //     width: 100%;
  //     height: auto;

  //     li {
  //       width: 100%;
  //       height: 305px;
  //       border-radius: 20px;
  //       overflow: hidden;
  //       background-color: ${(props) => props.theme.colors.gray50};

  //       &:not(:first-child) {
  //         margin-top: 10px;
  //       }

  //       img {
  //         min-height: 100%;
  //       }
  //     }
  //   }
  // `,
  Contents: styled.p`
    min-height: 72px;
    word-break: keep-all;
  `,
};

/**
 * 리뷰 뷰어 컴포넌트
 */
export default function ReviewViewer({ reviewId, reviewContents: data }) {
  const resetViewer = useResetRecoilState(reviewModal);

  if (reviewId === null) {
    return;
  }

  return (
    <NormalModal
      _height="660px"
      handleModalClose={() => {
        resetViewer();
      }}>
      <D.FlexCols _width="100%" _gap={22}>
        <ReviewProfile
          name={data.nickname}
          date={moment(data.reviewCreateDate).format('YYYY.MM.DD')}
          score={data.star}
          profile={data.profileImage}
          _size="large"
        />
        <D.FlexCols _gap={8}>
          <Text
            _size="xxl"
            _weight="bold"
            {...(data.hotelId && {
              as: Link,
              to: spaceDetails(data.hotelId),
            })}>
            {data.hotelName}
          </Text>
          <S.Contents>{data.contents}</S.Contents>
        </D.FlexCols>
        <D.FlexCols _width="100%" _gap={16}>
          {data.type === '공간' ? (
            <D.FlexRows _gap={6} _items="center">
              {data.categoryName && <Badge text={data.categoryName} _color="pink600" />}
              {data.category && (
                <D.FlexRows _gap={8}>
                  {data.category.map((ctg, idx) => (
                    <Badge key={idx} text={ctg} _color="pink600" />
                  ))}
                </D.FlexRows>
              )}
              <Text _size="l" _weight="medium">
                {data.name}
              </Text>
            </D.FlexRows>
          ) : (
            <BgBadge text={data.name + ' 담당자님'} />
          )}
          <PicSlider _height="305px" _nav={true} _nav_position="in" pics={data.image} />
        </D.FlexCols>
        {data.answer && (
          <S.Text>
            <Text _weight="bold" _size="l">
              담당자 답변
            </Text>
            <div>
              <p>{data.answer}</p>
            </div>
          </S.Text>
        )}
      </D.FlexCols>
    </NormalModal>
  );
}
