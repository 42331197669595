import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FlexCols } from '../Common/Display';
import RoomCard from './RoomCard';
import downIcon from '../../assets/icons/utils/ico-arrow_btm.svg';
import { useInfiniteHotelListQuery } from '../../hooks/queries/space';
import { MoreBtn } from '../Common/Button';
import { ErrorStatus } from '../Common/Status';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    width: 100%;
    min-height: 50vh;

    @media (${(props) => props.theme.media.tabletSm}) {
      padding: 0 20px;
    }
  `,
};

/**
 * 공간 목록 컴포넌트
 * @param {string} props.id 공간 아이디 
 */
export default function RoomList({ id }) {
  const { data: pageData, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteHotelListQuery({ id: id });

  return (
    <S.Wrapper>
      {pageData &&
        (pageData.pages[0].data.data.count > 0 ? (
          <React.Fragment>
            <FlexCols _gap={16} _width="100%" _media="tabletSm" _m_gap={40}>
              {pageData &&
                pageData.pages
                  .reduce((acc, page) => acc.concat(page.data.data.space), [])
                  .map((item, idx) => <RoomCard key={idx} room={item} />)}
            </FlexCols>
            {hasNextPage && !isFetchingNextPage && (
              <MoreBtn
                handleClick={fetchNextPage}
                text={`${pageData.pages[0].data.data.count - pageData.pages.length * 2 > 1 ? 2 : 1}개 객실 더보기`}
              />
            )}
          </React.Fragment>
        ) : (
          <ErrorStatus _height="100%" message="공간이 없습니다." />
        ))}
    </S.Wrapper>
  );
}
