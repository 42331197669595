import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useWishCountQuery, useWishListQuery } from '../../../hooks/queries/mypage';
import { useRefScrollUp } from '../../../hooks/ui/scroll';
import CardList from '../../Common/CardList';
import CountTab from '../../Common/CountTab';
import * as D from '../../Common/Display';
import { ErrorStatus, LoadingStatus } from '../../Common/Status';
import ClientBox from '../../Home/ClientBox';

/**
 * 마이페이지 > 위시 목록 컴포넌트
 */
export default function WishList() {
  const [ref, inView] = useInView();

  const [tabs, setTabs] = useState([
    { name: '공간 찜', count: 0 },
    { name: '호텔 찜', count: 0 },
  ]);

  const [activeTab, setActiveTab] = useState(0);
  // const { data, isFetching, isFetchingNextPage, fetchNextPage, status, hasNextPage } = useWishListQuery(
  //   activeTab == 1 ? 'space' : 'hotel'
  // );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, status, hasNextPage, refetch, isRefetching } =
    useWishListQuery(activeTab == 0 ? 'space' : 'hotel');

  const [page, setPage] = useState([]);
  const { data: count, refetch: countRefetch, isLoading: isCountLoading } = useWishCountQuery();
  const [scrollRef, scrollUp] = useRefScrollUp();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  useEffect(() => {
    if (!data) return;
    let arr = [];
    data.pages.map((x) => {
      arr.push(...x.products);
    });
    setPage(arr);
  }, [data]);

  useEffect(() => {
    if (count) {
      setTabs(
        count.map((x, index) => {
          if (x.type === 'hotel') {
            return { name: '호텔 찜', count: x.cnt };
          } else return { name: '공간 찜', count: x.cnt };
        })
      );

      refetch();
      countRefetch();
      scrollUp();
    }
  }, [count, activeTab, data]);

  const handleTabClick = (idx) => {
    setActiveTab(idx);
  };

  return isCountLoading ? (
    ''
  ) : (
    <D.FlexCols _gap={40} _width="100%">
      <CountTab tabs={tabs} activeIdx={activeTab} onTabClick={handleTabClick} />
      <D.Container>
        {status === 'loading' && count[activeTab].cnt > 0 ? (
          <LoadingStatus box />
        ) : data?.pages[0].products.length > 0 ? (
          <D.FlexCols _width="100%" ref={scrollRef}>
            <CardList
              count={3}
              gap={40}
              medias={[
                { media: 'laptop', count: 2 },
                { media: 'tabletSm', direction: 'column', gap: 40 },
              ]}>
              {(page === '' ? data?.pages[0].products : page).map((item, idx) => {
                return <ClientBox key={idx} wish={true} type={activeTab === 0 ? 'space' : 'hotel'} {...item} />;
              })}
            </CardList>
            <div ref={ref}>{isFetching && !isFetchingNextPage ? '' : null}</div>
          </D.FlexCols>
        ) : (
          <ErrorStatus message="찜 내역이 없습니다." />
        )}
      </D.Container>
    </D.FlexCols>
  );
}
