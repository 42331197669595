import { LAMBDA_IMG_PATTERN } from './pattern';

// 이미지 CDN 링크
const IMG_CDN = 'https://cdn.alabwaba.com';

// 이미지 링크 반환 함수
export const cdnUrl = (url, lambda = '') => {
  const imgUrl = `${IMG_CDN}${url}`;

  if (lambda.length < 1) return imgUrl;
  return `${imgUrl}${LAMBDA_IMG_PATTERN.test(imgUrl) ? '?' + lambda : ''}`;
};

// 파일 다운로드 링크
const FILE_LINK = 'https://alabwaba.com/api/file/download?file=';

// 파일 다운로드 링크 반환 함수
export const fileUrl = (url) => `${FILE_LINK}${url}`;
