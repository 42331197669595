import React, { useCallback, useEffect, useState } from 'react';

import calendarIcon from '../../../../assets/ico-calendar.svg';

import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { yScrollBoxCss } from '../../../../components/Common/Display';
import { InputField } from '../../../../components/Common/InputField';
import { moreInfoState } from '../../../../store/request';
import SelectLimitDate from './SelectLimitDate';
import { bookPeriodState } from '../../../../store/search';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextareaDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 380px;
  max-width: calc(100% - 20px);
  height: 210px;
  border-radius: 20px;
  ${(props) => props.theme.effectStyle.boxShadow}
  border: none;
  padding: 24px 10px 24px 24px;
  & > div {
    gap: 0;
  }
  & textarea {
    padding: 0 10px 0 0;
    border: none;
    ${yScrollBoxCss()}
  }
  border-radius: 20px;

  @media (${(props) => props.theme.media.tabletSm}) {
    width: 100%;
  }
`;
const SelectMoreInfoWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 52px;
`;
const SelectDateWrapper = styled.div`
  padding: 8px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 300px;
  max-width: calc(100% - 60px);
  height: 48px;

  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 99px;

  cursor: pointer;
  .calendar-icon {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => (props.isSelect ? props.theme.colors.gray900 : props.theme.colors.purple300)};
    text-decoration: ${(props) => (props.isSelect ? 'none' : 'underline')};
  }
`;

// component: 중요사항 입력 box
// /space/request 페이지에 노출 및 호출
function SelectMoreInfo() {
  // 선택한 날짜(이용일시)
  const period = useRecoilValue(bookPeriodState);
  // 중요사항 data(상위 컴포넌트(index)에서 사용)
  const [moreInfo, setMoreInfo] = useRecoilState(moreInfoState);

  // 마감날짜 캘린더 modal 상태
  const [isSelected, setIsSelected] = useState(false);
  // 중요사항에서 입력하는 문자(input)
  const [contents, setContents] = useState(moreInfo.contents);

  // 중요사항을 입력하면 moreInfo 변경
  const saveContents = useCallback(() => {
    if (contents.length > 0) {
      const newInfo = { ...moreInfo };
      newInfo.contents = contents;
      setMoreInfo(newInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  useEffect(() => {
    saveContents();
  }, [saveContents]);

  // 선택한 이용 날짜가 변경되면 중요사항 초기화
  useEffect(() => {
    setMoreInfo({
      requestDueDate: null,
      contents: '',
    });
  }, [period, setMoreInfo]);

  return (
    <Wrapper>
      {/* 마감 날짜 입력 캘린더 modal */}
      {isSelected && <SelectLimitDate closeModal={() => setIsSelected(false)} />}
      <SelectMoreInfoWrapper>
        <SelectDateWrapper
          isSelect={!!moreInfo.requestDueDate}
          onClick={() => setIsSelected((prev) => !prev)}
        >
          <span>
            {!!moreInfo.requestDueDate ? moreInfo.requestDueDate : '의뢰 마감 날짜를 선택해 주세요.'}
          </span>
          <img className="calendar-icon" src={calendarIcon} alt="calendar-img" />
        </SelectDateWrapper>
        <TextareaDiv>
          <InputField
            label
            name="contents"
            id="contents"
            placeholder="* 새우 알러지가 있어요!
          * 빔 프로젝터가 필요합니다!"
            value={contents}
            type="text"
            _width="100%"
            _height="100%"
            textarea={true}
            handleChange={setContents}
          />
        </TextareaDiv>
      </SelectMoreInfoWrapper>
    </Wrapper>
  );
}

export default SelectMoreInfo;
